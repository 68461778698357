import { Grid, GridItem } from '@chakra-ui/react';
import { ReactElement } from 'react';

export interface Step {
  subtitle: string;
  component: ReactElement;
}

interface Props {
  steps: Step[];
  index: number;
}

export function Stepper({ steps, index }: Props) {
  return (
    <Grid templateColumns={`repeat(${steps.length}, 1fr)`}>
      {steps.map((step, i) => {
        return (
          <GridItem
            key={i}
            colSpan={1}
            className={`stepper ${index >= i && 'stepper-active'}`}
          >
            <div
              className={`${index >= i && 'sgi-colors-teal-700'}`}
              style={{ fontWeight: 'bold' }}
            >
              Step {i + 1}
            </div>
            <div>{step.subtitle}</div>
          </GridItem>
        );
      })}
    </Grid>
  );
}

export default Stepper;
