import { Divider, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import React from 'react';
import { VehicleInfo } from 'Types/InspectionDTO';
import { VinStatusLabel } from 'Types/VehicleDTO';

interface InspectionDetailsVehicleInfoProps {
  vehicleInfo: VehicleInfo;
}
const InspectionDetailsVehicleInfo = ({
  vehicleInfo,
}: InspectionDetailsVehicleInfoProps) => {
  return (
    <VStack alignItems="start">
      <Typography variant="h3">Vehicle Information</Typography>
      <HStack width="100%">
        <VStack width="100%">
          <SimpleGrid width="100%" columns={6}>
            <Typography variant="bodyBold">Year</Typography>
            <Typography aria-label="vehicle-year">
              {vehicleInfo.year}
            </Typography>
            <Typography variant="bodyBold">Make</Typography>
            <Typography aria-label="vehicle-make">
              {vehicleInfo.make}
            </Typography>
            <Typography variant="bodyBold">Model</Typography>
            <Typography aria-label="vehicle-model">
              {vehicleInfo.model}
            </Typography>
          </SimpleGrid>
          <Divider color="blackAlpha.100" borderBottom="1px" />

          <SimpleGrid width="100%" columns={6}>
            <Typography variant="bodyBold">Colour</Typography>
            <Typography aria-label="vehicle-colour">
              {vehicleInfo.color}
            </Typography>
            <Typography variant="bodyBold">Body Style</Typography>
            <Typography aria-label="vehicle-bodyStyle">
              {vehicleInfo.bodyStyle}
            </Typography>
            <Typography variant="bodyBold">GVWR</Typography>
            <Typography aria-label="vehicle-gvwr">
              {vehicleInfo.gvwr && vehicleInfo.gvwr > 0
                ? vehicleInfo.gvwr.toLocaleString() + 'Kg'
                : ''}
            </Typography>
          </SimpleGrid>
          <Divider color="blackAlpha.100" borderBottom="1px" />

          <React.Fragment key={vehicleInfo.status}>
            <SimpleGrid width="100%" columns={6}>
              <Typography variant="bodyBold">VIN Status</Typography>
              <Typography aria-label="vehicle-status">
                {vehicleInfo && vehicleInfo.status
                  ? VinStatusLabel[vehicleInfo.status]
                  : ''}
              </Typography>
            </SimpleGrid>
            <Divider color="blackAlpha.100" borderBottom="1px" />
          </React.Fragment>
        </VStack>
      </HStack>
    </VStack>
  );
};

export default InspectionDetailsVehicleInfo;
