import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Card, Modal, Typography, useToast } from '@sgi/gravity';
import { useUpdateQuestionMutation } from 'api/InspectTechUIAPI';
import { useGetAttachmentQuery } from 'api/InspectTechUIFileUploadAPI';
import ErrorList from 'App/Common/ErrorList';
import {
  faChevronDownIcon,
  faChevronUpIcon,
  faEyeIcon,
  faFileLinesIcon,
  faPencil,
} from 'App/Common/Icons';
import LoadingModal from 'App/Common/LoadingModal';
import { PreviewAttachmentModal } from 'App/Common/PreviewAttachmentModal';
import { inspectionMessages } from 'constants/messageConstants';
import { QuestionCodes } from 'constants/variableConstants';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  InspectionDamageLocationLabel,
  Question,
  QuestionTypeLabel,
} from 'Types/InspectionDTO';
import EditLocationAndDamages from './CTQuestions/EditLocationAndDamages';
import UpdateInspectionQA from './UpdateInspectionQA';
interface InspectionQAProps {
  questions: Question[];
  inspectionId: string;
  vin: string;
  dataVersion?: number;
  isDisabled: boolean;
}

interface GroupedQuestion {
  type: number;
  showContent: boolean;
  questions: Question[];
}

const InspectionQAs = ({
  questions,
  inspectionId,
  vin,
  dataVersion,
  isDisabled,
}: InspectionQAProps) => {
  const [
    updateQuestion,
    {
      data: updatedInspectionForQuestion,
      isSuccess: isUpdateQuestionSuccess,
      isError: isUpdateQuestionError,
      error: updateQuestionError,
    },
  ] = useUpdateQuestionMutation();

  useEffect(() => {
    if (isUpdateQuestionSuccess) {
      showToast({
        description: inspectionMessages.questionUpdated,
        status: 'success',
      });
      setShowUpdateQuestionsDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateQuestionSuccess, updatedInspectionForQuestion]);

  useEffect(() => {
    if (isUpdateQuestionError && updateQuestionError) {
      showToast({
        description: <ErrorList errors={updateQuestionError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateQuestionError, updateQuestionError]);

  const [showUpdateQuestionsDialog, setShowUpdateQuestionsDialog] =
    useState<boolean>(false);

  const [questionTobeUpdated, setQuestionTobeUpdated] = useState<Question>();

  const groupQuestions = () => {
    var result = _(questions)
      .groupBy((x) => x.type)
      .map((value, key) => ({
        type: Number(key),
        showContent: false,
        questions: value,
      }))
      .value();
    return result;
  };

  const { showToast } = useToast();
  const [skip, setSkip] = useState(true);
  const [attachmentName, setAttachmentName] = useState('');
  const [groupedQuestions, setGroupedQuestions] = useState<GroupedQuestion[]>(
    groupQuestions(),
  );
  const {
    data: attachment,
    isFetching: isGetAttachmentFetching,
    isSuccess: isGetAttachmentSuccess,
    isError: isGetAttachmentError,
    error: getAttachmentError,
  } = useGetAttachmentQuery(attachmentName, { skip });

  useEffect(() => {
    if (isGetAttachmentError && getAttachmentError) {
      showToast({
        description: `${getAttachmentError}`,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAttachmentError, getAttachmentError]);

  const showHideQuestionContent = (type: number) => {
    setGroupedQuestions((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.type === type) {
          return { ...obj, showContent: !obj.showContent };
        } else {
          return obj;
        }
      });
      return newState;
    });
  };

  const allowedQuestionsForEdit = (questionCode: string) => {
    return (
      questionCode === QuestionCodes.contactQuestionProofOfOwnership ||
      questionCode === QuestionCodes.ctPreQuestionDiagnosticScan ||
      questionCode === QuestionCodes.ctPostQuestionPostDiagnosticScan ||
      questionCode === QuestionCodes.ctPostQuestionPostRepairBodyMeasurement
    );
  };

  return (
    <>
      <LoadingModal show={isGetAttachmentFetching} />
      {attachment && isGetAttachmentSuccess && (
        <PreviewAttachmentModal
          attachment={attachment}
          onPreviewAttachmentModelClose={() => {
            setAttachmentName('');
            setSkip(true);
          }}
        />
      )}
      <VStack alignItems="start">
        <Typography variant="h3">Inspection Q&A</Typography>
        {groupedQuestions &&
          groupedQuestions.length > 0 &&
          Object.keys(groupedQuestions).map((value: string, key: number) => (
            <Card
              key={`${value}${key}`}
              cardStyleProps={{ width: '100%', padding: '0' }}
            >
              <VStack>
                <Box
                  w="100%"
                  padding="8px 16px"
                  borderTopRadius={8}
                  borderBottomRadius={groupedQuestions[key].showContent ? 0 : 8}
                  _hover={{ cursor: 'pointer', background: '#D3D4D6' }}
                  bg={'#E9EAEA'}
                  onClick={() => {
                    showHideQuestionContent(groupedQuestions[key].type);
                  }}
                  data-testid={`${
                    QuestionTypeLabel[
                      groupedQuestions[key]
                        .type as keyof typeof QuestionTypeLabel
                    ]
                  }`}
                >
                  <HStack justify={'space-between'}>
                    <Typography variant="h4">
                      {
                        QuestionTypeLabel[
                          groupedQuestions[key]
                            .type as keyof typeof QuestionTypeLabel
                        ]
                      }
                    </Typography>
                    {groupedQuestions[key].showContent ? (
                      <Icon aria-label={`question-content-visible-${key}`}>
                        {faChevronUpIcon}
                      </Icon>
                    ) : (
                      <Icon aria-label={`question-content-hidden-${key}`}>
                        {faChevronDownIcon}
                      </Icon>
                    )}
                  </HStack>
                </Box>
                {groupedQuestions[key].questions
                  .filter((q) => q.isHidden !== true)
                  .map((question, index) => (
                    <Box
                      hidden={!groupedQuestions[key].showContent}
                      w="100%"
                      padding="8px 16px"
                      borderBottomRadius={8}
                      key={`${index}${key}`}
                    >
                      {index > 0 && (
                        <hr
                          style={{
                            borderColor: 'gainsboro',
                            paddingBottom: '10px',
                          }}
                        />
                      )}
                      <VStack alignItems="start" w="100%">
                        {question.code ===
                        QuestionCodes.ctPreQuestionLocationAndDamages ? (
                          <VStack alignItems="start" w="100%">
                            <HStack justify={'space-between'} w="100%">
                              <Typography variant="body">
                                {`${index + 1}) ${question.text}`}
                              </Typography>
                              {!isDisabled && (
                                <EditLocationAndDamages
                                  question={question}
                                  inspectionId={inspectionId}
                                  vin={vin}
                                  dataVersion={dataVersion}
                                />
                              )}
                            </HStack>
                            {question.damageDetails?.map((d, i) => (
                              <HStack key={d.location}>
                                <Typography variant="bodyBold">
                                  {d.location &&
                                    `${
                                      InspectionDamageLocationLabel[d.location]
                                    }:`}
                                </Typography>
                                <Typography variant="body">
                                  {d.description}
                                </Typography>
                              </HStack>
                            ))}
                          </VStack>
                        ) : (
                          <>
                            <HStack justify={'space-between'} w="100%">
                              <HStack>
                                <Typography variant="body">
                                  {`${index + 1}) ${question.text}`}
                                </Typography>
                                <Typography variant="subheading2">
                                  {question.response ? 'Yes' : 'No'}
                                </Typography>
                              </HStack>
                              {!isDisabled &&
                                allowedQuestionsForEdit(question.code) && (
                                  <Button
                                    data-testid={`${question.code}-EditButton`}
                                    type="button"
                                    rightIcon={
                                      <Icon
                                        fill="teal.500"
                                        _hover={{ color: 'teal.600' }}
                                        aria-label={`view-file-${index}`}
                                      >
                                        {faPencil}
                                      </Icon>
                                    }
                                    isDestructive={false}
                                    variant="solid"
                                    onClick={() => {
                                      setQuestionTobeUpdated(question);
                                      setShowUpdateQuestionsDialog(true);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                )}
                            </HStack>
                            {question.response === true &&
                              question.attachments &&
                              question.attachments?.length > 0 &&
                              question.attachments.map(
                                (attachment, attIndex) => {
                                  return (
                                    <Box
                                      w="100%"
                                      padding="8px 16px"
                                      borderRadius={8}
                                      bg={'#F4F4F5'}
                                      key={`${index}${attIndex}`}
                                      data-testid={`question-attachments-${question.code}`}
                                    >
                                      <HStack justify={'space-between'}>
                                        <HStack>
                                          <Icon
                                            aria-label={`view-file-${index}}`}
                                          >
                                            {faFileLinesIcon}
                                          </Icon>
                                          <Typography variant="bodySmall">
                                            {attachment.clientFileName}
                                          </Typography>
                                        </HStack>
                                        <HStack>
                                          <Button
                                            type="button"
                                            rightIcon={
                                              <Icon
                                                fill="teal.500"
                                                _hover={{ color: 'teal.600' }}
                                                aria-label={`view-file-${index}`}
                                              >
                                                {faEyeIcon}
                                              </Icon>
                                            }
                                            isDestructive={false}
                                            variant="ghost"
                                            onClick={() => {
                                              setAttachmentName(
                                                attachment.storageFileName,
                                              );
                                              setSkip(false);
                                            }}
                                          >
                                            View
                                          </Button>
                                        </HStack>
                                      </HStack>
                                    </Box>
                                  );
                                },
                              )}
                            {question.response === false &&
                              question.reason &&
                              question.reason !== '' && (
                                <>
                                  <Typography variant="bodyBold">
                                    Comment:
                                  </Typography>
                                  <Typography
                                    data-testid={`question-reason-${question.code}`}
                                  >
                                    {question.reason}
                                  </Typography>
                                </>
                              )}
                          </>
                        )}
                      </VStack>
                    </Box>
                  ))}
              </VStack>
            </Card>
          ))}
      </VStack>
      {questionTobeUpdated && (
        <Modal
          data-testid="contact-updateQuestion-Dialog"
          modalContentProps={{ maxW: '800px', maxH: '-moz-fit-content' }}
          isOpen={showUpdateQuestionsDialog}
          onClose={() => {
            setShowUpdateQuestionsDialog(false);
          }}
          title={`Update Inspection Question`}
          isCentered={true}
          scrollBehavior={'inside'}
          showCloseButton={false}
        >
          <UpdateInspectionQA
            inspectionId={inspectionId}
            vin={vin}
            question={questionTobeUpdated}
            updateQuestion={updateQuestion}
            setShowUpdateQuestionsDialog={setShowUpdateQuestionsDialog}
            dataVersion={dataVersion}
          />
        </Modal>
      )}
    </>
  );
};

export default InspectionQAs;
