import { createApi } from '@reduxjs/toolkit/query/react';
import { ApproveInspectionDTO } from 'Types/ApproveInspectionDTO';
import { CompleteInspectionDTO } from 'Types/CompleteInspectionDTO';
import { ContactDTO } from 'Types/ContactDTO';
import { ContactListDTO } from 'Types/ContactListDTO';
import { CreateContactDTO } from 'Types/CreateContactDTO';
import { CreateInspectionDTO } from 'Types/CreateInspectionDTO';
import { CreateReInspectionDTO } from 'Types/CreateReInspectionDTO';
import { CreateReInspectionResponseDTO } from 'Types/CreateReInspectionResponseDTO';
import { CreateStationDTO } from 'Types/CreateStationDTO';
import { CreateVehicleDTO } from 'Types/CreateVehicleDTO';
import { FeatureFlagsDTO } from 'Types/FeatureFlagsDTO';
import { InspectionContactListDTO } from 'Types/InspectionContactListDTO';
import {
  InspectionDTO,
  InspectionListDTO,
  Question,
} from 'Types/InspectionDTO';
import { PermissionsDTO } from 'Types/PermissionsDTO';
import { SearchContactDTO } from 'Types/SearchContactDTO';
import { SearchInspectionDTO } from 'Types/SearchInspectionDTO';
import { SearchStationDTO } from 'Types/SearchStationDTO';
import { StartInspectionDTO } from 'Types/StartInspectionDTO';
import { StationDTO, StationListDTO } from 'Types/StationDTO';
import { UndoCompleteInspectionDTO } from 'Types/UndoCompleteInspectionDTO';
import { UpdateContactDTO } from 'Types/UpdateContactDTO';
import { UpdateInspectionItemsAndWheelnfoDTO } from 'Types/UpdateInspectionItemsAndWheelnfoDTO';
import {
  UpdateQuestionDTO,
  UpdateQuestionsDTO,
} from 'Types/UpdateQuestionsDTO';
import { UpdateVinStatusDTO } from 'Types/UpdateVinStatusDTO';
import { VehicleDTO, VehicleListDTO } from 'Types/VehicleDTO';
import { axiosBaseQuery } from './axiosBaseQuery';
import { RtkTags } from './RtkTags';

export const inspectTechUIAPI = createApi({
  reducerPath: 'inspecttechui',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(RtkTags),
  endpoints: (builder) => ({
    apiHealthCheck: builder.query<any, undefined>({
      query: () => ({
        url: `api-docs/af-rgl-inspect-ui/index.html`,
        method: 'GET',
        headers: { 'Content-Type': 'text/html' },
      }),
    }),
    featureFlags: builder.query<FeatureFlagsDTO, void>({
      query: () => ({
        url: 'featureFlags',
        method: 'GET',
      }),
    }),
    getPermissions: builder.query<PermissionsDTO, void>({
      query: () => ({
        url: 'permissions',
        method: 'GET',
      }),
    }),
    createVehicle: builder.mutation<string, CreateVehicleDTO>({
      query: (vehicle: CreateVehicleDTO) => ({
        url: 'vehicle/CreateVehicle',
        method: 'POST',
        data: vehicle,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Vehicle, id: 'LIST' }] : [],
    }),
    getVehicle: builder.query<VehicleDTO, string>({
      query: (vin: string) => ({
        url: `vehicle/GetVehicle?vin=${vin}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: RtkTags.Vehicle, id: result.vin }] : [],
    }),
    partialVinSearch: builder.query<VehicleListDTO[], string>({
      query: (partialVin: string) => ({
        url: `vehicle/VinSearch?vin=${partialVin}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                (veh) => ({ type: RtkTags.Vehicle, id: veh.vin } as const),
              ),
              { type: RtkTags.Vehicle, id: 'LIST' },
            ]
          : [],
    }),
    updateVinStatus: builder.mutation<string, UpdateVinStatusDTO>({
      query: (statusUpdate: UpdateVinStatusDTO) => ({
        url: 'vehicle/UpdateVinStatus',
        method: 'POST',
        data: statusUpdate,
      }),
      invalidatesTags: (result, error, statusUpdate) =>
        result ? [{ type: RtkTags.Vehicle, id: statusUpdate.vin }] : [],
    }),
    getInspectionByIdVin: builder.query<
      InspectionDTO,
      { inspectionId: string; vin: string }
    >({
      query: (arg: { inspectionId: string; vin: string }) => ({
        url: `inspection/GetInspection?inspectionId=${arg.inspectionId}&vin=${arg.vin}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result ? [{ type: RtkTags.Inspection, id: result.id }] : [],
    }),
    searchInspections: builder.mutation<
      InspectionListDTO[],
      SearchInspectionDTO
    >({
      query: (inspection: SearchInspectionDTO) => ({
        url: 'inspection/SearchInspections',
        method: 'POST',
        data: inspection,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map(
                (ins) => ({ type: RtkTags.Inspection, id: ins.id } as const),
              ),
              { type: RtkTags.Inspection, id: 'LIST' },
            ]
          : [],
    }),
    //Add Search Contact DTO

    searchContacts: builder.mutation<ContactListDTO[], SearchContactDTO>({
      query: (contact: SearchContactDTO) => ({
        url: 'contact/SearchContact',
        method: 'POST',
        data: contact,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map(
                (cont) => ({ type: RtkTags.Contact, id: cont.id } as const),
              ),
              { type: RtkTags.Contact, id: 'LIST' },
            ]
          : [],
    }),

    vinSearchInspection: builder.query<VehicleDTO[], string>({
      query: (vin: string) => ({
        url: `inspection/SearchVehiclesByVin?query=${vin}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result
          ? [
              ...result.map(
                (veh) =>
                  ({ type: RtkTags.InspectionVehicle, id: veh.vin } as const),
              ),
              { type: RtkTags.InspectionVehicle, id: 'LIST' },
            ]
          : [],
    }),

    createInspection: builder.mutation<string, CreateInspectionDTO>({
      query: (inspection: CreateInspectionDTO) => ({
        url: 'inspection/CreateInspection',
        method: 'POST',
        data: inspection,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Inspection, id: 'LIST' }] : [],
    }),
    createReInspection: builder.mutation<
      CreateReInspectionResponseDTO,
      CreateReInspectionDTO
    >({
      query: (reInspection: CreateReInspectionDTO) => ({
        url: 'inspection/CreateReInspection',
        method: 'POST',
        data: reInspection,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Inspection, id: result.previousId }] : [],
    }),
    completeInspection: builder.mutation<string, CompleteInspectionDTO>({
      query: (completeInspection: CompleteInspectionDTO) => ({
        url: 'Inspection/CompleteInspection',
        method: 'POST',
        data: completeInspection,
      }),
      invalidatesTags: (result, error, Inspection) =>
        result ? [{ type: RtkTags.Inspection, id: Inspection.id }] : [],
    }),
    undoCompleteInspection: builder.mutation<string, UndoCompleteInspectionDTO>(
      {
        query: (undoCompleteInspection: UndoCompleteInspectionDTO) => ({
          url: 'Inspection/UndoCompleteInspection',
          method: 'POST',
          data: undoCompleteInspection,
        }),
        invalidatesTags: (result, error, Inspection) =>
          result ? [{ type: RtkTags.Inspection, id: Inspection.id }] : [],
      },
    ),
    approveInspection: builder.mutation<string, ApproveInspectionDTO>({
      query: (inspection: ApproveInspectionDTO) => ({
        url: 'Inspection/ApproveInspection',
        method: 'POST',
        data: inspection,
      }),
      invalidatesTags: (result, error, Inspection) =>
        result
          ? [
              { type: RtkTags.Inspection, id: Inspection.id },
              { type: RtkTags.Vehicle, id: Inspection.vin },
            ]
          : [],
    }),

    updateInspectionItemsAndWheelInfo: builder.mutation<
      string,
      UpdateInspectionItemsAndWheelnfoDTO
    >({
      query: (
        updatedItemsAndWheelInfo: UpdateInspectionItemsAndWheelnfoDTO,
      ) => ({
        url: `Inspection/UpdateInspectionItemsAndWheelInfo`,
        method: 'POST',
        data: updatedItemsAndWheelInfo,
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: RtkTags.Inspection, id: result }] : [],
    }),
    createContact: builder.mutation<ContactDTO, CreateContactDTO>({
      query: (contact: CreateContactDTO) => ({
        url: 'contact/CreateContact',
        method: 'POST',
        data: contact,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Contact, id: 'LIST' }] : [],
    }),
    getContactById: builder.query<ContactDTO, string>({
      query: (contactId: string) => ({
        url: `contact/GetContact?contactId=${contactId}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: RtkTags.Contact, id: result.id }] : [],
    }),
    updateContact: builder.mutation<ContactDTO, UpdateContactDTO>({
      query: (updatedContact: UpdateContactDTO) => ({
        url: `contact/UpdateContact`,
        method: 'POST',
        data: updatedContact,
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: RtkTags.Contact, id: result.id }] : [],
    }),
    searchContactsFromLookup: builder.query<InspectionContactListDTO[], string>(
      {
        query: (query: string) => ({
          url: `inspection/SearchContacts?query=${query}`,
          method: 'GET',
        }),
        keepUnusedDataFor: 0,
        providesTags: (result) =>
          result
            ? [
                ...result.map(
                  (contact) =>
                    ({ type: RtkTags.Contact, id: contact.id } as const),
                ),
                { type: RtkTags.Contact, id: 'LIST' },
              ]
            : [],
      },
    ),
    getStationEligible: builder.query<boolean, string>({
      query: (stationName: string) => ({
        url: `Inspection/IsStationEligible?stationName=${stationName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Inspection, id: arg }] : [],
    }),
    createStation: builder.mutation<string, CreateStationDTO>({
      query: (station: CreateStationDTO) => ({
        url: 'Station/CreateStation',
        method: 'POST',
        data: station,
      }),
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Station, id: 'LIST' }] : [],
    }),
    getStationById: builder.query<StationDTO, string>({
      query: (stationId: string) => ({
        url: `Station/GetStation?stationId=${stationId}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: RtkTags.Station, id: result.id }] : [],
    }),
    searchStations: builder.mutation<StationListDTO[], SearchStationDTO>({
      query: (station: SearchStationDTO) => ({
        url: 'station/SearchStations',
        method: 'POST',
        data: station,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              ...result.map(
                (sta) => ({ type: RtkTags.Station, id: sta.id } as const),
              ),
              { type: RtkTags.Station, id: 'LIST' },
            ]
          : [],
    }),
    getInspectionQuestions: builder.query<Question[], number>({
      query: (questionType: number) => ({
        url: `inspection/Questions?questionType=${questionType}`,
        method: 'GET',
      }),
    }),
    startInspection: builder.mutation<string, StartInspectionDTO>({
      query: (inspection: StartInspectionDTO) => ({
        url: 'Inspection/StartInspection',
        method: 'POST',
        data: inspection,
      }),
      invalidatesTags: (result, error, Inspection) =>
        result
          ? [
              { type: RtkTags.Inspection, id: Inspection.id },
              { type: RtkTags.Vehicle, id: Inspection.vin },
            ]
          : [],
    }),
    updateQuestions: builder.mutation<string, UpdateQuestionsDTO>({
      query: (questions: UpdateQuestionsDTO) => ({
        url: 'Inspection/UpdateQuestions',
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: (result, error, Inspection) =>
        result
          ? [
              { type: RtkTags.Inspection, id: Inspection.id },
              { type: RtkTags.Vehicle, id: Inspection.vin },
            ]
          : [],
    }),
    updateQuestion: builder.mutation<string, UpdateQuestionDTO>({
      query: (questions: UpdateQuestionDTO) => ({
        url: 'Inspection/UpdateQuestion',
        method: 'POST',
        data: questions,
      }),
      invalidatesTags: (result, error, Inspection) =>
        result
          ? [
              { type: RtkTags.Inspection, id: Inspection.id },
              { type: RtkTags.Vehicle, id: Inspection.vin },
            ]
          : [],
    }),
    anyInProgressInspection: builder.query<boolean, string>({
      query: (vin: string) => ({
        url: `Inspection/AnyInProgressInspection?vin=${vin}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Inspection, id: arg }] : [],
    }),
  }),
});

export const {
  useApiHealthCheckQuery,
  useFeatureFlagsQuery,
  useGetPermissionsQuery,
  useGetVehicleQuery,
  usePartialVinSearchQuery,
  useCreateVehicleMutation,
  useUpdateVinStatusMutation,
  useGetInspectionByIdVinQuery,
  useVinSearchInspectionQuery,
  useCreateInspectionMutation,
  useCreateReInspectionMutation,
  useSearchInspectionsMutation,
  useSearchStationsMutation,
  useSearchContactsMutation,
  useCompleteInspectionMutation,
  useUndoCompleteInspectionMutation,
  useApproveInspectionMutation,
  useUpdateInspectionItemsAndWheelInfoMutation,
  useCreateContactMutation,
  useGetContactByIdQuery,
  useUpdateContactMutation,
  useSearchContactsFromLookupQuery,
  useCreateStationMutation,
  useGetStationByIdQuery,
  useGetInspectionQuestionsQuery,
  useStartInspectionMutation,
  useAnyInProgressInspectionQuery,
  useGetStationEligibleQuery,
  useUpdateQuestionsMutation,
  useUpdateQuestionMutation,
} = inspectTechUIAPI;
