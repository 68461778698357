import { Icon } from '@chakra-ui/react';
import { faCheckIcon, faQuestionIcon, faXMark } from 'App/Common/Icons';
interface WheelStatusBadgeProps {
  status: boolean | undefined;
  label: string;
}

const WheelStatusBadge = ({ status, label }: WheelStatusBadgeProps) => {
  switch (status) {
    case true:
      return (
        <Icon fill="green.500" aria-label={`${label}-pass-badge`}>
          {faCheckIcon}
        </Icon>
      );

    case false:
      return (
        <Icon fill="red.500" aria-label={`${label}-rejected-badge`}>
          {faXMark}
        </Icon>
      );
    default:
      return (
        <Icon fill="gray.500" aria-label={`${label}-unknown-badge`}>
          {faQuestionIcon}
        </Icon>
      );
  }
};

export default WheelStatusBadge;
