export interface ContactDTO {
  id: string;
  firstName: string;
  lastName: string;
  customerNumber: string;
  address: ContactAddressDTO;
  phoneInfo: ContactPhoneDTO;
  email: string | undefined;
  dataVersion: number;
}


export interface ContactPhoneDTO {
  homePhone: string;
  cellPhone: string;
    workPhone: string;  
    workPhoneExtension: string;
  phoneError:boolean|undefined;
}

export const defaultContactPhoneDTO: ContactPhoneDTO = {
  homePhone: '',
  cellPhone: '',
    workPhone: '', 
    workPhoneExtension:'',
  phoneError: undefined
};

export const updContactPhoneDTO: ContactPhoneDTO = {
  homePhone: '',
  cellPhone: '',
    workPhone: '',
    workPhoneExtension:'',
  phoneError: undefined
};


export interface ContactAddressDTO {
  street: string;
  city: string;
  province: string;
  postalCode: string;
}

export const defaultContactAddressDTO: ContactAddressDTO = {
  street: '',
  city: '',
  province: '',
  postalCode: '',
};

export const updContactAddressDTO: ContactAddressDTO = {
  street: '',
  city: '',
  province: '',
  postalCode: '',
};
