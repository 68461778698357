import { Icon } from '@chakra-ui/react';
import {
  faCheckIcon,
  faPending,
  faQuestionIcon,
  faXMark,
} from 'App/Common/Icons';

interface AxleStatusBadgeProps {
  status: string;
}

export const AxleStatusBadge = ({ status }: AxleStatusBadgeProps) => {
  switch (status) {
    case 'Pass':
      return (
        <Icon fill="green.500" aria-label="axle-pass-badge">
          {faCheckIcon}
        </Icon>
      );
    case 'Fail':
      return (
        <Icon fill="red.500" aria-label="axle-rejected-badge">
          {faXMark}
        </Icon>
      );
    case 'Pending':
      return (
        <Icon fill="gray.500" aria-label="axle-pending-badge">
          {faPending}
        </Icon>
      );
    default:
      return (
        <Icon fill="gray.500" aria-label="axle-unknown-badge">
          {faQuestionIcon}
        </Icon>
      );
  }
};

export default AxleStatusBadge;
