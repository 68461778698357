import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { faChevronDownIcon, faChevronUpIcon } from 'App/Common/Icons';
import { useState } from 'react';
import { CommentDTO } from 'Types/InspectionDTO';
import { formatDateString, formatTimeString } from 'Utils/dateUtils';

interface InspectionItemCommentHistoryProps {
  commentHistory: CommentDTO[];
}

export const InspectionItemCommentHistory = ({
  commentHistory,
}: InspectionItemCommentHistoryProps) => {
  const [showHistory, setShowHistory] = useState(false);
  const sortedComments = commentHistory
    ? [...commentHistory].sort((a, b) => (a.date < b.date ? 1 : -1))
    : [];

  return (
    <Card cardStyleProps={{ width: '100%', padding: '0' }}>
      <>
        <Box
          w="100%"
          padding="8px 16px"
          borderTopRadius={8}
          borderBottomRadius={showHistory ? 0 : 8}
          _hover={{ cursor: 'pointer', background: '#D3D4D6' }}
          bg={'#E9EAEA'}
          onClick={() => {
            setShowHistory(!showHistory);
          }}
        >
          <HStack justify="space-between">
            <Typography variant="bodyBold">
              {`Comment History ${
                sortedComments.length > 0 ? `(${sortedComments.length})` : ''
              }`}
            </Typography>
            {showHistory ? (
              <Icon>{faChevronUpIcon}</Icon>
            ) : (
              <Icon>{faChevronDownIcon}</Icon>
            )}
          </HStack>
        </Box>
        {showHistory &&
          sortedComments?.map((comment) => {
            return (
              <VStack align="flex-start" p={4} key={comment.date}>
                <Typography variant="bodyBold">{`${formatDateString(
                  comment.date,
                )} ${formatTimeString(comment.date)} - ${
                  comment.user
                }:`}</Typography>

                <Typography>{comment.value}</Typography>
              </VStack>
            );
          })}
      </>
    </Card>
  );
};

export default InspectionItemCommentHistory;
