import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Input, Typography, useToast } from '@sgi/gravity';
import {
  useGetPermissionsQuery,
  useSearchStationsMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import FormError from 'App/Common/FormElements/FormError';
import LoadingModal from 'App/Common/LoadingModal';
import { stationMessages } from 'constants/messageConstants';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  defaultSearchStationDTO,
  SearchStationCriteria,
  SearchStationDTO,
} from 'Types/SearchStationDTO';
import {
  getLocalStorageItem,
  LocalStorageItemEnum,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'Utils/localStorageUtils';
import * as Yup from 'yup';
import StationSummaryTable from './Components/StationSummaryTable';

export const StationSummaryPage = () => {
  const navigate = useNavigate();
  const [isSearchReset, setIsSearchReset] = useState(true);
  const { showErrorToast } = useToast();
  const { data: permissions, isLoading: isGetPermissionsLoading } =
    useGetPermissionsQuery();

  const [initSearchStationCriteria, setSearchStationCriteria] = useState(
    defaultSearchStationDTO,
  );

  const [
    searchStations,
    {
      data: stations,
      isError: isStationListError,
      error: stationListError,
      isLoading: isStationListLoading,
      isSuccess: stationListSuccess,
    },
  ] = useSearchStationsMutation();

  //Create Formik Component for Search Stations
  const formikSearch = useFormik({
    enableReinitialize: true,
    initialValues: { ...initSearchStationCriteria },
    validationSchema: Yup.object().shape({
      stationName: Yup.string(),
      shopOwner: Yup.string(),
      searchError: Yup.bool().when(['stationName', 'shopOwner'], {
        is: (stationName: string, shopOwner: string) =>
          !stationName && !shopOwner,
        then: Yup.bool().required(
          'At least one criteria is required to  perform a search.',
        ),
        otherwise: undefined,
      }),
    }),
    onSubmit: (values: SearchStationDTO) => {
      if (permissions?.canSearchStations) {
        setIsSearchReset(false);

        //Updating local storage variable with search criteria
        setSearchCriteriaSession();

        //Invoke search Station API
        searchStations({
          stationName: values?.stationName ?? '',
          shopOwner: values?.shopOwner ?? '',
          searchError: values?.searchError ?? false,
        });
      }
    },
  });

  //Checks for the local storage, then sets the value for the Search and Filter form fields if localstoage exist else display form as default.
  useEffect(() => {
    let sessionStationSearch = getLocalStorageItem<SearchStationCriteria>(
      LocalStorageItemEnum.StationSearch,
    );

    if (
      sessionStationSearch == null ||
      sessionStationSearch.searchStation === null
    ) {
      sessionStationSearch = null;
      removeLocalStorageItem(LocalStorageItemEnum.StationSearch);
    } else {
      if (permissions?.canSearchStations) {
        //Update session storage
        setLocalStorageItem(
          LocalStorageItemEnum.StationSearch,
          sessionStationSearch,
        );

        if (sessionStationSearch.searchStation !== null) {
          //Set fields value for search Station form
          setSearchStationCriteria({
            ...sessionStationSearch.searchStation,
            searchError: undefined,
          });
        }

        if (!isEqual(defaultSearchStationDTO, formikSearch.initialValues)) {
          setIsSearchReset(false);

          //Invoke search Station API
          searchStations({
            stationName: formikSearch.initialValues?.stationName ?? '',
            shopOwner: formikSearch.initialValues?.shopOwner ?? '',
            searchError: formikSearch.initialValues?.searchError ?? false,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.canSearchStations, formikSearch.initialValues]);

  useEffect(() => {
    if (isStationListError) {
      showErrorToast({ description: <ErrorList errors={stationListError} /> });
    }
  }, [isStationListError, stationListError, showErrorToast]);

  useEffect(() => {
    if (isEqual(defaultSearchStationDTO, formikSearch.values)) {
      clearSearchResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikSearch.values]);

  //Sets selected/Provided Search crietria into local storage
  function setSearchCriteriaSession() {
    let searchStationCriteria = {
      searchStation: formikSearch.values,
    };
    setLocalStorageItem(
      LocalStorageItemEnum.StationSearch,
      searchStationCriteria,
    );
  }

  //Clears the search results and form on clear button click
  const clearSearchForm = () => {
    formikSearch.resetForm();
    clearSearchResult();
    setSearchStationCriteria({
      ...defaultSearchStationDTO,
      searchError: undefined,
    });
    setLocalStorageItem(LocalStorageItemEnum.StationSearch, null);
  };

  //Clears the search results
  function clearSearchResult() {
    setIsSearchReset(true);
  }

  return (
    <>
      <LoadingModal show={isStationListLoading || isGetPermissionsLoading} />

      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Inspection Stations</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" p={4} borderRadius={8}>
          <VStack w="100%">
            <Box w="100%">
              <form onSubmit={formikSearch.handleSubmit}>
                <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                  {permissions?.canSearchStations ? (
                    <>
                      <GridItem colSpan={1}>
                        <Input
                          id="stationName"
                          name="stationName"
                          data-testid="station-search-stationname"
                          type="text"
                          placeholder="Station Name"
                          onChange={formikSearch.handleChange}
                          onBlur={formikSearch.handleBlur}
                          value={formikSearch.values.stationName}
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Input
                          id="shopOwner"
                          name="shopOwner"
                          data-testid="station-search-shopowner"
                          type="text"
                          placeholder="Shop Owner"
                          onChange={formikSearch.handleChange}
                          onBlur={formikSearch.handleBlur}
                          value={formikSearch.values.shopOwner}
                        />
                      </GridItem>
                      <GridItem colSpan={3}>
                        <HStack justify="flex-start">
                          <Button
                            type="submit"
                            data-testid="station-search-submit"
                          >
                            Search
                          </Button>
                          <Button
                            type="reset"
                            variant="secondary"
                            data-testid="station-search-clear"
                            onClick={() => clearSearchForm()}
                          >
                            Clear
                          </Button>
                        </HStack>
                      </GridItem>
                      <GridItem rowStart={2} colSpan={2}>
                        <FormError
                          testId="station-search-searchError-error"
                          touched={formikSearch.touched?.searchError}
                          error={formikSearch.errors?.searchError}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <>
                      {!isGetPermissionsLoading ? (
                        <Typography variant="h2">
                          {stationMessages.searchPermissionError}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <GridItem colSpan={1}>
                    <HStack justify="flex-end">
                      {permissions?.canCreateStation && (
                        <Button
                          onClick={() => {
                            navigate('/station/create');
                          }}
                        >
                          + Create Station
                        </Button>
                      )}
                    </HStack>
                  </GridItem>
                </Grid>
              </form>
            </Box>
            {!isSearchReset &&
            formikSearch.isValid &&
            stations &&
            stations?.length > 0 ? (
              <Box w="100%">
                <StationSummaryTable stations={stations} />
              </Box>
            ) : (
              <>
                {stationListSuccess &&
                  !isStationListLoading &&
                  !isSearchReset &&
                  formikSearch.isValid && (
                    <p data-testid="stations-list">
                      {stationMessages.noResultsMessage}
                    </p>
                  )}
              </>
            )}
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default StationSummaryPage;
