import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { useCreateInspectionMutation } from 'api/InspectTechUIAPI';
import { useGetStagingAttachmentQuery } from 'api/InspectTechUIFileUploadAPI';
import ErrorList from 'App/Common/ErrorList';
import { faArrowLeftIcon, faEyeIcon, faFileLinesIcon } from 'App/Common/Icons';
import { PreviewAttachmentModal } from 'App/Common/PreviewAttachmentModal';
import { inspectionMessages } from 'constants/messageConstants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import ConfirmDetails from './ConfirmDetails';

interface AdditionalVehicleInfoProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
}

const ConfirmInspection = ({
  step,
  setStep,
  inspection,
  inspectionHelperDetailsDTO,
}: AdditionalVehicleInfoProps) => {
  const navigate = useNavigate();
  const [, setSkip] = useState(true);
  const [attachmentName, setAttachmentName] = useState('');
  const {
    data: attachment,
    isSuccess: isGetAttachmentSuccess,
    isError: isGetAttachmentError,
    error: getAttachmentError,
  } = useGetStagingAttachmentQuery(attachmentName, {
    skip: attachmentName ? false : true,
  });
  useEffect(() => {
    if (isGetAttachmentError && getAttachmentError) {
      toast.error(<ErrorList errors={getAttachmentError} />);
    }
  }, [isGetAttachmentError, getAttachmentError]);

  const handleSubmit = () => {
    createInspection(inspection);
  };

  const [
    createInspection,
    {
      data: createdInspection,
      isError: isCreateInspectionError,
      error: createInspectionError,
      isSuccess: isCreateInspectionSuccess,
    },
  ] = useCreateInspectionMutation();
  useEffect(() => {
    if (isCreateInspectionError) {
      toast.error(<ErrorList errors={createInspectionError} />);
    }
  }, [isCreateInspectionError, createInspectionError]);

  useEffect(() => {
    if (isCreateInspectionSuccess) {
      toast.success(inspectionMessages.created);
      navigate(`/inspection/${createdInspection}/${inspection.vin}`);
    }
  }, [isCreateInspectionSuccess, createdInspection, navigate, inspection.vin]);

  return (
    <>
      {attachment && isGetAttachmentSuccess && (
        <PreviewAttachmentModal
          attachment={attachment}
          onPreviewAttachmentModelClose={() => {
            setAttachmentName('');
            setSkip(true);
          }}
        />
      )}
      <Box>
        <VStack align="flex-start">
          <ConfirmDetails
            inspection={inspection}
            inspectionHelperDetailsDTO={inspectionHelperDetailsDTO}
          />
          {inspection &&
          inspection.questions &&
          inspection.questions.length > 0 ? (
            <Card cardStyleProps={{ width: '100%', padding: '0' }}>
              <VStack>
                <Box
                  w="100%"
                  padding="8px 16px"
                  borderTopRadius={8}
                  bg={'gray.100'}
                >
                  <VStack justify={'space-between'} alignItems="flex-start">
                    <Typography variant="subheading2">
                      Pre-Inspection Question - Contact
                    </Typography>
                  </VStack>
                </Box>
                <Box w="100%" padding="8px 16px">
                  <>
                    {inspection.questions && inspection.questions.length > 0}
                    {inspection.questions.map((question, index) => {
                      return (
                        <Box width="100%" key={index}>
                          <HStack alignItems="start" w="100%" p="16px">
                            <Typography
                              data-testid={`question-text-${question.text}`}
                            >
                              {question.text}
                            </Typography>
                            <Typography variant="bodyBold">
                              {question.response ? 'Yes' : 'No'}
                            </Typography>
                          </HStack>

                          <VStack alignItems="flex-start" p="16px">
                            {question.response &&
                              question.attachments &&
                              question.attachments?.length > 0 &&
                              question.attachments.map(
                                (attachment, attIndex) => {
                                  return (
                                    <VStack
                                      alignItems="flex-start"
                                      w="100%"
                                      key={`${index}-${attIndex}`}
                                    >
                                      <Card
                                        cardStyleProps={{
                                          width: '100%',
                                          padding: '0',
                                        }}
                                      >
                                        <Grid
                                          data-testid={`uploaded-files`}
                                          templateColumns="repeat(5, 1fr)"
                                          p="8px 16px"
                                          gap="16px"
                                          w="100%"
                                          bg="gray.50"
                                        >
                                          <GridItem colSpan={4}>
                                            <HStack alignItems="center">
                                              <Icon
                                                aria-label={`view-file-${index}}`}
                                              >
                                                {faFileLinesIcon}
                                              </Icon>
                                              <Typography variant="bodySmall">
                                                {attachment.clientFileName}
                                              </Typography>
                                            </HStack>
                                          </GridItem>
                                          <GridItem>
                                            <HStack
                                              justify={'end'}
                                              alignItems="center"
                                            >
                                              <Button
                                                type="button"
                                                rightIcon={
                                                  <Icon
                                                    fill="teal.500"
                                                    _hover={{
                                                      color: 'teal.600',
                                                    }}
                                                    aria-label={`view-file-${index}`}
                                                  >
                                                    {faEyeIcon}
                                                  </Icon>
                                                }
                                                isDestructive={false}
                                                variant="tertiary"
                                                onClick={() => {
                                                  setAttachmentName(
                                                    attachment.storageFileName,
                                                  );
                                                  setSkip(false);
                                                }}
                                              >
                                                View
                                              </Button>
                                            </HStack>
                                          </GridItem>
                                        </Grid>
                                      </Card>
                                    </VStack>
                                  );
                                },
                              )}
                            {!question.response &&
                              question.reason &&
                              question.reason !== '' && (
                                <Box width="100%">
                                  <VStack alignItems="start">
                                    <Typography variant="bodyBold">
                                      Comment:
                                    </Typography>
                                    <Typography
                                      data-testid={`question-reason-${question.code}`}
                                    >
                                      {question.reason}
                                    </Typography>
                                  </VStack>
                                </Box>
                              )}
                          </VStack>
                        </Box>
                      );
                    })}
                  </>
                </Box>
              </VStack>
            </Card>
          ) : (
            <></>
          )}
          <HStack w="100%" justifyContent="space-between">
            <Button
              variant="secondary"
              leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
              onClick={() => setStep(step - 1)}
            >
              Previous step
            </Button>
            <Button data-testid="CreateInspection" onClick={handleSubmit}>
              Create Inspection
            </Button>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

export default ConfirmInspection;
