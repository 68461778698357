import { Box, Divider, HStack, SimpleGrid, VStack } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import InspectionApprovalStatusBadge from 'App/Common/InspectionApprovalStatusBadge';
import InspectionStatusBadge from 'App/Common/InspectionStatusBadge';
import {
  InspectionApprovalStatus,
  InspectionApprovalStatusLabel,
  InspectionDTO,
  InspectionStatus,
  InspectionStatusLabel,
} from 'Types/InspectionDTO';
import { formatDateString } from 'Utils/dateUtils';
import { formatId } from 'Utils/inspectionUtils';
import InspectionApprovalModal from './InspectionApprovalModal';

interface InspectionDetailsStatusInfoProps {
  inspection: InspectionDTO;
  approveInspection: Function;
  isLoadingApproveInspection: boolean;
}

const InspectionDetailsStatusInfo = ({
  inspection,
  approveInspection,
  isLoadingApproveInspection,
}: InspectionDetailsStatusInfoProps) => {
  return (
    <HStack alignItems="start" justify="space-between">
      <VStack alignItems="start" w="30%">
        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Inspection ID</Typography>
          <Box>
            <HStack>
              <Typography aria-label="inspection-id">
                {formatId(inspection.id)}
              </Typography>
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />

        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Inspection Status</Typography>
          <Box>
            <HStack>
              <InspectionStatusBadge inspectionStatus={inspection.status} />
              <Typography aria-label="inspection-status">
                {InspectionStatusLabel[inspection.status]}
              </Typography>
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />
        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Technician</Typography>
          <Box>
            <HStack>
              <Typography aria-label="inspection-technician">
                {inspection.certifiedTechnician ?? ''}
              </Typography>
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />
      </VStack>

      <VStack alignItems="start " w="30%">
        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Approval Status</Typography>
          <Box>
            <HStack>
              {inspection?.status !== InspectionStatus.InProgress &&
              inspection.approvalStatus ===
                InspectionApprovalStatus.WaitingForApproval &&
              inspection?.securityFlags?.canApprove ? (
                <InspectionApprovalModal
                  approveInspection={approveInspection}
                  isLoadingApproveInspection={isLoadingApproveInspection}
                  inspection={inspection}
                />
              ) : (
                <>
                  <InspectionApprovalStatusBadge
                    approvalStatus={inspection.approvalStatus}
                  />
                  <Typography aria-label="inspection-approval-status">
                    {InspectionApprovalStatusLabel[inspection.approvalStatus]}
                  </Typography>
                </>
              )}
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />

        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Approval Date</Typography>
          <Typography aria-label="inspection-approval-date">
            {formatDateString(inspection.approvalDate)}
          </Typography>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />
        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Approved by</Typography>
          <Box>
            <HStack>
              <Typography aria-label="inspection-signing-officer">
                {inspection.signingOfficer ?? ''}
              </Typography>
            </HStack>
          </Box>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />
      </VStack>
    </HStack>
  );
};

export default InspectionDetailsStatusInfo;
