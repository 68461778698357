import { Grid, GridItem, HStack } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import { ReactComponent as Logo } from 'assets/sgi-logo-black.svg';
import { InspectionDTO, InspectionStatus } from 'Types/InspectionDTO';
import FailedMessagePrintDetails from './FailedMessagePrintDetails';
import InspectionItemsTable from './InspectionItemsTable';
import InspectionPrintDetails from './InspectionPrintDetails';
import VehiclePrintDetails from './VehiclePrintDetails';
interface Props {
  inspection: InspectionDTO;
}

export const WorkPlan = ({ inspection }: Props) => {
  return (
    <>
      <div className="watermark">
        <div className="watermark__inner">
          {inspection.status === InspectionStatus.Fail && (
            <div className="watermark__body">Repairs Required</div>
          )}
        </div>
        <Grid templateColumns="repeat(11, 1fr)">
          <GridItem colSpan={4}>
            <Logo style={{ height: '2.25em' }} />
            <span style={{ fontSize: '12px' }}>
              Vehicle Standards and Inspection
            </span>
          </GridItem>
          <GridItem colSpan={7}>
            <HStack justify="flex-end">
              <Typography variant="h3">
                {`${inspection.program} Work Plan`}
              </Typography>
            </HStack>
          </GridItem>
        </Grid>
        <br />
        <div style={{ textAlign: 'center' }} className="print-section">
          This is not the proof of inspection.
        </div>
        <InspectionPrintDetails inspection={inspection} />
        <VehiclePrintDetails inspection={inspection} />
      </div>
      <br />
      <InspectionItemsTable
        items={inspection.body}
        program={inspection.program}
        type={inspection.type}
      />

      <div className="print-section">
        <Grid>
          <GridItem data-testid="print-additionalNotes">{`Additional Notes: ${
            inspection.additionalNote ? inspection.additionalNote : 'N/A'
          }`}</GridItem>
        </Grid>
      </div>
      <FailedMessagePrintDetails inspection={inspection} />
    </>
  );
};
