import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
} from '@chakra-ui/react';
import { Switch, Typography } from '@sgi/gravity';
import {
  InspectionApprovalStatus,
  InspectionStatus,
} from 'Types/InspectionDTO';

export interface Filter {
  label: string;
  value: InspectionStatus | InspectionApprovalStatus;
  isEnabled: boolean;
}

export interface FilterCategory {
  label: string;
  field: string;
  items: Filter[];
}

interface Props {
  filters: FilterCategory[];
  setFilters: Function;
}

export const InspectionTableFilter = ({ filters, setFilters }: Props) => {
  const toggleFilter = (catIndex: number, itemIndex: number) => {
    let tempFilters = [...filters];
    const toggle = filters[catIndex].items[itemIndex].isEnabled;

    tempFilters[catIndex].items[itemIndex].isEnabled = !toggle;

    setFilters([...tempFilters]);
  };

  return (
    <Accordion defaultIndex={[0]} allowMultiple={true}>
      {filters.map((category, catIndex) => (
        <AccordionItem border="none" key={catIndex}>
          <AccordionButton data-testid={`filter-accordion-${category.field}`}>
            <AccordionIcon />
            <Typography variant="body">{category.label}</Typography>
          </AccordionButton>
          <AccordionPanel>
            {category.items.map((item, itemIndex) => (
              <HStack key={itemIndex}>
                <Switch
                  size="sm"
                  data-testid={`inspection-filter-${category.field}-${item.value}`}
                  controlStyleProps={{ width: '25px' }}
                  name="switch"
                  isChecked={item.isEnabled}
                  onChange={() => toggleFilter(catIndex, itemIndex)}
                />
                <div>{item.label}</div>
              </HStack>
            ))}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
