import { Box, Center, HStack, Icon, VStack } from '@chakra-ui/react';
import {
  Button,
  Card,
  Input,
  Radio,
  RadioGroup,
  Typography,
  useToast,
} from '@sgi/gravity';
import ConfirmationDialogBox from 'App/Common/ConfirmationDialogBox';
import { faTrashIcon } from 'App/Common/Icons';
import {
  brakeInspectionMessages,
  inspectionMessages,
} from 'constants/messageConstants';
import {
  BrakeYesNoList,
  LightVehicleMinTotalWheels,
  WheelOnOffList,
  WheelPassesInspectionList,
} from 'constants/variableConstants';
import { getIn } from 'formik';
import { useReducer, useState } from 'react';
import { InspectionProgram } from 'Types/InspectionEnums';
import {
  AxleDTO,
  AxleWheelLimit,
  BrakeMeasurementUnit,
  BrakeTypes,
  WheelDTO,
  WheelInfoDTO,
} from 'Types/WheelInfoDTO';
import { BusinessFunctions } from 'Utils/BusinessFunctions';
import { InputMapper } from 'Utils/InputMapper';
import { patterns } from 'Utils/regex';
import BrakeMeasurementCategory from '../BrakeMeasurements/BrakeMeasurementCategory';
import WheelInspectionStatusBadge from './WheelInspectionStatusBadge';
import WheelStatusBadge from './WheelStatusBadge';

interface WheelDetailsProps {
  wheelInfo: WheelInfoDTO;
  axleWheelLimit: AxleWheelLimit;
  axle: AxleDTO;
  axleIndex: number;
  wheel: WheelDTO;
  wheelIndex: number;
  inspectionProgram: InspectionProgram | undefined;
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  isDisabled?: boolean;
}

const WheelDetails = ({
  wheelInfo,
  axleWheelLimit,
  axle,
  axleIndex,
  wheel,
  wheelIndex,
  inspectionProgram,
  setFieldValue,
  onChange,
  errors,
  isDisabled = false,
}: WheelDetailsProps) => {
  const { showToast } = useToast();
  const CloseConfirmBox = 'CloseConfirmBox';
  const DeleteAxleWheelConfirm = 'DeleteAxleWheelConfirm';

  const CanRemoveWheel = (axle: AxleDTO, wheel: WheelDTO) => {
    return (
      !isDisabled &&
      ((!HasNonBrakeOnWheelsForAxle(axle.id) &&
        axle.wheels.length > axleWheelLimit.minWheel) ||
        (HasNonBrakeOnWheelsForAxle(axle.id) && Boolean(wheel.nonBrake)))
    );
  };

  const HasNonBrakeOnWheelsForAxle = (axleId: number): boolean => {
    if (
      inspectionProgram === InspectionProgram.LightVehicle ||
      inspectionProgram === InspectionProgram.MidSizeTruck
    ) {
      let nonBrakeCnt = 0;
      wheelInfo.axles.forEach((obj) => {
        if (obj.id === axleId) {
          nonBrakeCnt = obj.wheels.filter(
            (x) => Boolean(x.nonBrake) === true,
          ).length;
        }
      });
      return nonBrakeCnt > 0;
    } else {
      return false;
    }
  };

  const handleDeleteWheel = (index: number, axle: AxleDTO) => {
    if (axle.wheels.length > axleWheelLimit.minWheel) {
      let tempWheels = [...axle.wheels];
      tempWheels.splice(index, 1);
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].wheels`,
        resetAxleWheelsIds(tempWheels),
      );
      closeModalHandler();
    } else {
      showToast({
        description: `${brakeInspectionMessages.minWheelsValidation} ${axleWheelLimit.minWheel}`,
        status: 'error',
      });
    }
  };

  const resetAxleWheelsIds = (tempWheels: WheelDTO[]) => {
    return tempWheels.map((wheel, index) => {
      return {
        ...wheel,
        id: index + 1,
      };
    });
  };

  const onValidatingPassingWheelBrakeInspection = (
    id: number,
    axleId: number,
    passInspection: boolean,
  ) => {
    let isValid = true;
    if (passInspection) {
      wheelInfo.axles.forEach((obj) => {
        if (obj.id === axleId) {
          if (
            obj.brakeType === BrakeTypes.None ||
            obj.brakeType === BrakeTypes.Unknown
          ) {
            isValid = false;
          }
          obj.wheels.forEach((objWheel) => {
            if (Boolean(objWheel.nonBrake) === false) {
              if (objWheel.id === id) {
                objWheel.brakeMeasurements.forEach((objBrakeMeasurements) => {
                  if (
                    objBrakeMeasurements.measurement &&
                    (objBrakeMeasurements.measurement < 0 ||
                      !objBrakeMeasurements.name)
                  ) {
                    isValid = false;
                  }
                });

                if (Boolean(objWheel.hasAdditionalBrake) === true) {
                  objWheel.additionalBrakeMeasurements.forEach(
                    (objAdditionalBrakeMeasurements) => {
                      if (
                        objAdditionalBrakeMeasurements.measurement &&
                        (objAdditionalBrakeMeasurements.measurement < 0 ||
                          !objAdditionalBrakeMeasurements.name)
                      ) {
                        isValid = false;
                      }
                    },
                  );
                }
              }
            }
          });
        }
      });
    }
    return isValid;
  };

  const onBrakeMeasurementsStatusChange = (
    value: boolean,
    id: number,
    axleId: number,
  ) => {
    if (onValidatingPassingWheelBrakeInspection(id, axleId, value)) {
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].passesBrakeMeasurements`,
        value,
      );
    } else {
      showToast({
        description: `${inspectionMessages.passWheelInspectionValidation}`,
        status: 'error',
        title: `${inspectionMessages.passWheelInspectionQuestion}`,
      });
    }
  };

  const onNonBrakeChange = (value: boolean, id: number, axleId: number) => {
    if (!value) {
      const nonBrakeCnt = GetNonBrakeOnWheelsForAxle(axleId);
      if (nonBrakeCnt === 2) {
        showToast({
          description: `${brakeInspectionMessages.nonBrakeAtleastValidation}`,
          status: 'error',
        });
        return;
      }
    }

    setFieldValue(
      `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].nonBrake`,
      !value ? true : false,
    );

    if (!value) {
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurements`,
        [],
      );
    }
  };

  const GetNonBrakeOnWheelsForAxle = (axleId: number) => {
    let nonBrakeCnt = axleWheelLimit.minWheel;
    if (
      inspectionProgram === InspectionProgram.LightVehicle ||
      inspectionProgram === InspectionProgram.MidSizeTruck
    ) {
      wheelInfo.axles.forEach((obj) => {
        if (obj.id === axleId) {
          nonBrakeCnt = obj.wheels.filter(
            (x) => Boolean(x.nonBrake) !== true,
          ).length;
        }
      });
    }
    return nonBrakeCnt;
  };

  const onHasAdditionalBrakeChange = (
    value: boolean,
    id: number,
    axleId: number,
  ) => {
    if (value) {
      const nonBrakeCnt = GetNonBrakeOnWheelsForAxle(axleId);
      if (nonBrakeCnt === 2) {
        showToast({
          description: `${brakeInspectionMessages.nonBrakeAtleastValidation}`,
          status: 'error',
        });
        return;
      }
    }

    setFieldValue(
      `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].hasAdditionalBrake`,
      value,
    );

    if (!value) {
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].additionalBrakeMeasurements`,
        [],
      );
    }
  };

  const validateLightVehicleWheelCombination = (axle: AxleDTO) => {
    let totalWheelCount = 0;
    let singleWheelCount = 0;
    let remainingWheelCount = 0;
    if (inspectionProgram === InspectionProgram.LightVehicle) {
      wheelInfo.axles.forEach((axle) => {
        totalWheelCount = totalWheelCount + axle.wheels.length;
        if (axle.wheels.length === 1) singleWheelCount = singleWheelCount + 1;
        if (axle.wheels.length > 2)
          remainingWheelCount = remainingWheelCount + 1;
      });
      if (axle.wheels.length === 2 && remainingWheelCount > 0) {
        showToast({
          description: `${brakeInspectionMessages.remainingAxleWheelsLightVehicleValidation}`,
          status: 'error',
        });
        return false;
      }

      if (
        wheelInfo.axles.length > axleWheelLimit.minAxle &&
        axle.wheels.length === 2
      ) {
        showToast({
          description: `${brakeInspectionMessages.maxAxlesWithSingleWheelValidation}${axleWheelLimit.minAxle}`,
          status: 'error',
        });
        return false;
      }

      if (singleWheelCount > 0 && axle.wheels.length === 2) {
        showToast({
          description: `${brakeInspectionMessages.remainingAxleWheelsLightVehicleValidation}`,
          status: 'error',
        });
        return false;
      }
      if (totalWheelCount <= LightVehicleMinTotalWheels) {
        showToast({
          description: `${brakeInspectionMessages.minWheelsLightVehicleValidation}`,
          status: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const [showConfirmCallToAction, setshowConfirmCallToAction] = useState(true);

  const modalReducer = (state: any, action: any) => {
    switch (action.type) {
      case DeleteAxleWheelConfirm:
        setshowConfirmCallToAction(true);
        return {
          heading: `Delete Wheel`,
          content: `Are you sure you want to delete Axle ${
            action.object.axle.id
          } - Wheel ${action.object.index + 1}?`,
          confirmButtonText: 'Yes, Delete',
          confirmButtonAction: () => {
            handleDeleteWheel(action.object.index, action.object.axle);
          },
          show: true,
        };
      case CloseConfirmBox:
        return {
          heading: '',
          content: '',
          confirmButtonText: '',
          confirmButtonAction: () => function () {},
          show: false,
        };
    }
  };

  const [modalState, modalDispatch] = useReducer(modalReducer, {
    heading: '',
    content: '',
    confirmButtonText: '',
    confirmButtonAction: () => function () {},
    show: false,
  });

  const dispatchModalAction = (
    action: string,
    index: number,
    axle: AxleDTO | null,
  ) => {
    modalDispatch({
      type: action,
      object: {
        index: index,
        axle: axle,
      },
    });
  };

  const closeModalHandler = () => {
    modalDispatch({
      type: CloseConfirmBox,
    });
  };

  return (
    <>
      <ConfirmationDialogBox
        showConfirmCallToAction={showConfirmCallToAction}
        show={modalState?.show}
        close={() => {
          closeModalHandler();
        }}
        title={modalState?.heading}
        content={modalState?.content}
        confirmButtonText={modalState?.confirmButtonText}
        confirmButtonAction={modalState?.confirmButtonAction}
      ></ConfirmationDialogBox>
      <Card
        data-testid={`wheel-header-${axle.id}`}
        cardStyleProps={{ width: '100%', padding: '0' }}
        key={`${wheel.id}.${wheelIndex}`}
      >
        <VStack>
          <Box w="100%" padding="8px 16px" borderTopRadius={8} bg={'#F4F4F5'}>
            <HStack justify={'space-between'}>
              <Typography variant="subheading2">
                {`Wheel ${wheel.id} `}
                <WheelInspectionStatusBadge
                  status={
                    BusinessFunctions.checkWheelStatus(
                      wheel,
                      axle.conditionAndExternalMeasurements,
                    )
                      ? 'Pass'
                      : 'Fail'
                  }
                ></WheelInspectionStatusBadge>
              </Typography>
              <>
                {CanRemoveWheel(axle, wheel) && (
                  <Button
                    type="button"
                    leftIcon={
                      <Icon
                        fill="red.500"
                        _hover={{ color: 'red.600' }}
                        aria-label={`delete-wheel-${axle.id}-${wheel.id}`}
                      >
                        {faTrashIcon}
                      </Icon>
                    }
                    isDestructive={true}
                    variant="ghost"
                    onClick={() => {
                      if (validateLightVehicleWheelCombination(axle)) {
                        dispatchModalAction(
                          DeleteAxleWheelConfirm,
                          wheelIndex,
                          axle,
                        );
                      }
                    }}
                  >
                    Delete
                  </Button>
                )}
              </>
            </HStack>
          </Box>
          <Box w="100%" padding="8px 16px" borderBottomRadius={8}>
            <VStack alignItems="start" w="100%">
              <HStack w="100%" justify="space-between" align="end">
                <Box>
                  <Input
                    inputRightAddon="mm"
                    isRequired={
                      Boolean(wheel.passesTreadDepth) === true ? true : false
                    }
                    label="Tire Tread Depth"
                    name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].tireTreadDepth`}
                    data-testid={`wheelInfo-axles-${axleIndex}-wheels-${wheelIndex}-tireTreadDepth`}
                    type="text"
                    isDisabled={isDisabled}
                    placeholder="Tire Tread Depth"
                    error={getIn(errors, `tireTreadDepth`)}
                    onChange={(e) => {
                      const ttdValue = e.target.value;
                      if (
                        !ttdValue ||
                        ttdValue.match(patterns.tireTreadDepthMeasurement)
                      ) {
                        setFieldValue(
                          `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].tireTreadDepth`,
                          e.target.value,
                        );
                      }
                    }}
                    value={
                      wheel.tireTreadDepth
                        ? wheel.tireTreadDepth?.toString()
                        : ''
                    }
                  />
                </Box>
                <Box>
                  <RadioGroup
                    value={
                      wheel.passesTreadDepth !== null
                        ? Boolean(wheel.passesTreadDepth)
                        : undefined
                    }
                    onChange={(e) => {
                      setFieldValue(
                        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].passesTreadDepth`,
                        Boolean(e),
                      );
                    }}
                    data-testid={`wheelInfo-axles-${axleIndex}-wheels-${wheelIndex}-passesTreadDepth`}
                    variant="button"
                    name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].passesTreadDepth`}
                  >
                    <HStack>
                      {WheelPassesInspectionList.length > 0 &&
                        WheelPassesInspectionList.map(
                          (item: any, i: number) => {
                            return (
                              <Radio
                                key={`radio-passesTreadDepth-${axle.id}${wheel.id}${i}`}
                                value={item.value.toString()}
                                isDisabled={isDisabled}
                                aria-label={`wheel-passesTreadDepth-button-${axle.id}-${wheel.id}-${item.value}`}
                              >
                                <Center>
                                  <HStack>
                                    <Box>{item.text}</Box>
                                    <WheelStatusBadge
                                      status={item.value}
                                      label={`passesTreadDepth-${axle.id}-${wheel.id}`}
                                    />
                                  </HStack>
                                </Center>
                              </Radio>
                            );
                          },
                        )}
                    </HStack>
                  </RadioGroup>
                </Box>
              </HStack>
              {axle.brakeType !== 99 &&
                Number(axle.brakeType) > 0 &&
                axle.wheels.length > 2 &&
                (axle.conditionAndExternalMeasurements
                  ? axle.conditionAndExternalMeasurements?.toString() ===
                    'false'
                  : true) && (
                  <RadioGroup
                    isRequired={true}
                    label="Does wheel have a brake?"
                    value={!wheel.nonBrake ? true : false}
                    data-testid={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}]-nonBrake`}
                    onChange={(e) => {
                      onNonBrakeChange(Boolean(e), wheel.id, axle.id);
                    }}
                    variant="button"
                    name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].nonBrake`}
                  >
                    <HStack>
                      {BrakeYesNoList.length > 0 &&
                        BrakeYesNoList.map((item: any, i: number) => {
                          return (
                            <Radio
                              key={`radio-nonBrake-${axle.id}${wheel.id}${i}`}
                              value={item.value.toString()}
                              isDisabled={isDisabled}
                              aria-label={`wheel-nonBrake-button-${axle.id}-${wheel.id}-${item.value}`}
                            >
                              <Center>
                                <HStack>
                                  <Box>{item.text}</Box>
                                </HStack>
                              </Center>
                            </Radio>
                          );
                        })}
                    </HStack>
                  </RadioGroup>
                )}

              {!wheel.nonBrake &&
                (axle.conditionAndExternalMeasurements
                  ? axle.conditionAndExternalMeasurements?.toString() ===
                    'false'
                  : true) && (
                  <>
                    {axle.brakeType !== 99 &&
                      Number(axle.brakeType) > 0 &&
                      inspectionProgram === InspectionProgram.MidSizeTruck && (
                        <RadioGroup
                          label="Are Wheel Off from the vehicle during inspection?"
                          value={Boolean(wheel.wheelOffInspection)}
                          onChange={(e) => {
                            setFieldValue(
                              `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].wheelOffInspection`,
                              Boolean(e),
                            );
                          }}
                          variant="button"
                          name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].wheelOffInspection`}
                        >
                          <HStack>
                            {WheelOnOffList.length > 0 &&
                              WheelOnOffList.map((item: any, i: number) => {
                                return (
                                  <Radio
                                    key={`radio-wheelOffInspection-${axle.id}${wheel.id}${i}`}
                                    value={item.value.toString()}
                                    isDisabled={isDisabled}
                                    aria-label={`wheel-wheelOffInspection-button-${axle.id}${wheel.id}`}
                                  >
                                    <Center>
                                      <HStack>
                                        <Box>{item.text}</Box>
                                      </HStack>
                                    </Center>
                                  </Radio>
                                );
                              })}
                          </HStack>
                        </RadioGroup>
                      )}

                    {axle.brakeType !== 99 && Number(axle.brakeType) > 0 && (
                      <>
                        <Box
                          borderBottom={'2px solid'}
                          borderColor={'#F4F4F5'}
                          w="100%"
                        ></Box>
                        <VStack align="start" w="100%">
                          <HStack w="100%" justify="space-between">
                            <Box>
                              <Typography variant={'bodyBold'}>
                                Brake Measurements
                              </Typography>
                            </Box>
                            <Box>
                              <RadioGroup
                                value={
                                  wheel.passesBrakeMeasurements !== null
                                    ? Boolean(wheel.passesBrakeMeasurements)
                                    : false
                                }
                                onChange={(e) => {
                                  onBrakeMeasurementsStatusChange(
                                    Boolean(e),
                                    wheel.id,
                                    axle.id,
                                  );
                                }}
                                variant="button"
                                name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].passesBrakeMeasurements`}
                              >
                                <HStack>
                                  {WheelPassesInspectionList.length > 0 &&
                                    WheelPassesInspectionList.map(
                                      (item: any, i: number) => {
                                        return (
                                          <Radio
                                            key={`radio-passesBrakeMeasurements-${axle.id}${wheel.id}${i}`}
                                            value={item.value.toString()}
                                            isDisabled={isDisabled}
                                            aria-label={`wheel-passesBrakeMeasurements-button-${axle.id}-${wheel.id}-${item.value}`}
                                          >
                                            <Center>
                                              <HStack>
                                                <Box>{item.text}</Box>
                                                <WheelStatusBadge
                                                  label={`passesBrakeMeasurements-${axle.id}-${wheel.id}`}
                                                  status={item.value}
                                                />
                                              </HStack>
                                            </Center>
                                          </Radio>
                                        );
                                      },
                                    )}
                                </HStack>
                              </RadioGroup>
                            </Box>
                          </HStack>
                          <RadioGroup
                            value={
                              wheel.brakeMeasurementUnit === null
                                ? undefined
                                : wheel.brakeMeasurementUnit?.toString()
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurementUnit`,
                                Number(e),
                              );
                            }}
                            variant="radio"
                            id={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurementUnit`}
                            name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurementUnit`}
                            isRequired={
                              Boolean(wheel.passesBrakeMeasurements) === true
                                ? true
                                : false
                            }
                            hideOptionalLabel={false}
                            error={getIn(errors, `brakeMeasurementUnit`)}
                          >
                            <HStack>
                              {InputMapper.bindTextValuePairs(
                                BrakeMeasurementUnit,
                                true,
                              ).length > 0 &&
                                InputMapper.bindTextValuePairs(
                                  BrakeMeasurementUnit,
                                  true,
                                ).map((item) => {
                                  return (
                                    <Radio
                                      isDisabled={isDisabled}
                                      value={item.value?.toString()}
                                      aria-label={`wheel-brakeMeasurementUnit-button-${axle.id}-${wheel.id}-${item.value}`}
                                      key={`wheel-brakeMeasurementUnit-button-${axle.id}-${wheel.id}-${item.value}`}
                                    >
                                      {item.text}
                                    </Radio>
                                  );
                                })}
                            </HStack>
                          </RadioGroup>
                          <HStack
                            justify={'space-between'}
                            align="end"
                            w={'100%'}
                          >
                            <BrakeMeasurementCategory
                              brakeMeasurementUnit={wheel.brakeMeasurementUnit}
                              isRequired={
                                Boolean(wheel.passesBrakeMeasurements) === true
                                  ? true
                                  : false
                              }
                              isAdditional={false}
                              program={inspectionProgram}
                              onChange={onChange}
                              setFieldValue={setFieldValue}
                              errors={getIn(errors, `brakeMeasurements`)}
                              isDisabled={isDisabled}
                              brakeMeasurements={wheel.brakeMeasurements}
                              brakeType={Number(axle.brakeType)}
                              namePrefix={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}]`}
                            ></BrakeMeasurementCategory>
                          </HStack>
                          {axle.wheels.length === 1 &&
                            (axle.conditionAndExternalMeasurements
                              ? axle.conditionAndExternalMeasurements?.toString() ===
                                'false'
                              : true) && (
                              <RadioGroup
                                isRequired={true}
                                label="Does wheel have an additional brake?"
                                value={
                                  wheel.hasAdditionalBrake
                                    ? Boolean(wheel.hasAdditionalBrake)
                                    : false
                                }
                                data-testid={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}]-hasAdditionalBrake`}
                                onChange={(e) => {
                                  onHasAdditionalBrakeChange(
                                    Boolean(e),
                                    wheel.id,
                                    axle.id,
                                  );
                                }}
                                variant="button"
                                name={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].hasAdditionalBrake`}
                              >
                                <HStack>
                                  {BrakeYesNoList.length > 0 &&
                                    BrakeYesNoList.map(
                                      (item: any, i: number) => {
                                        return (
                                          <Radio
                                            key={`radio-hasAdditionalBrake-${axle.id}${wheel.id}${i}`}
                                            value={item.value.toString()}
                                            isDisabled={isDisabled}
                                            aria-label={`wheel-hasAdditionalBrake-button-${axle.id}-${wheel.id}-${item.value}`}
                                          >
                                            <Center>
                                              <HStack>
                                                <Box>{item.text}</Box>
                                              </HStack>
                                            </Center>
                                          </Radio>
                                        );
                                      },
                                    )}
                                </HStack>
                              </RadioGroup>
                            )}

                          {wheel.hasAdditionalBrake && (
                            <>
                              <Box paddingTop={'8px'}>
                                <Typography>
                                  Additional Brake Measurement
                                </Typography>
                              </Box>
                              <BrakeMeasurementCategory
                                brakeMeasurementUnit={
                                  wheel.brakeMeasurementUnit
                                }
                                isRequired={
                                  Boolean(wheel.passesBrakeMeasurements) ===
                                  true
                                    ? true
                                    : false
                                }
                                isAdditional={true}
                                onChange={onChange}
                                setFieldValue={setFieldValue}
                                errors={getIn(
                                  errors,
                                  `additionalBrakeMeasurements`,
                                )}
                                isDisabled={isDisabled}
                                program={inspectionProgram}
                                brakeMeasurements={
                                  wheel.additionalBrakeMeasurements
                                }
                                brakeType={Number(axle.brakeType)}
                                namePrefix={`wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}]`}
                              ></BrakeMeasurementCategory>
                            </>
                          )}
                        </VStack>
                      </>
                    )}
                  </>
                )}
            </VStack>
          </Box>
        </VStack>
      </Card>
    </>
  );
};

export default WheelDetails;
