import { BooleanCheckbox, Button, Modal, useToast } from '@sgi/gravity';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ApproveInspectionDTO } from 'Types/ApproveInspectionDTO';
import { InspectionDTO } from 'Types/InspectionDTO';

interface InspectionApprovalModalProps {
  inspection: InspectionDTO;
  approveInspection: Function;
  isLoadingApproveInspection: boolean;
}

export const InspectionApprovalModal = ({
  inspection,
  approveInspection,
  isLoadingApproveInspection,
}: InspectionApprovalModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [overrideDate, setOverrideDate] = useState<boolean>(false);
  const today = new Date();
  const minDate = new Date(new Date().setDate(today.getDate() - 30));
  const [approvalDate, setApprovalDate] = useState<Date>(today);
  const { showToast } = useToast();

  const submitApproval = () => {
    if (overrideDate && approvalDate === undefined) {
      showToast({
        description: 'Invalid override date.',
        status: 'error',
        title: 'Date error',
      });
      return false;
    }

    const approveInspectionDTO: ApproveInspectionDTO = {
      id: inspection?.id ?? '',
      vin: inspection?.vin ?? '',
      dataVersion: inspection?.dataVersion,
      approvalDate: overrideDate ? approvalDate : today,
    };

    approveInspection(approveInspectionDTO);
  };

  return (
    <>
      <Button
        data-testid="inspection-approve-button"
        onClick={() => setIsOpen(true)}
      >
        Approve
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Approve Inspection"
        footer={
          <>
            <Button
              data-testid="approve-inspection-submit"
              marginRight="5px"
              disabled={isLoadingApproveInspection}
              onClick={submitApproval}
            >
              Approve Inspection
            </Button>
            <Button
              data-testid="approve-inspection-close"
              variant="secondary"
              disabled={isLoadingApproveInspection}
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          </>
        }
      >
        <BooleanCheckbox
          data-testid="override-checkbox"
          marginBottom="5px"
          name="overrideDate"
          onChange={setOverrideDate}
          value={overrideDate}
        >
          Override Approval Date?
        </BooleanCheckbox>
        <DatePicker
          data-testid="approval-date-input"
          aria-labelledby="approvalDate-label"
          disabled={!overrideDate}
          id="approvalDate"
          className="datepickerInput"
          name="approvalDate"
          dateFormat="dd MMM yyyy"
          maxDate={today}
          selected={approvalDate}
          minDate={
            new Date(inspection.startDate!) > minDate
              ? new Date(inspection.startDate!)
              : minDate
          }
          onChange={(date: Date) => {
            setApprovalDate(date);
          }}
          value={approvalDate?.toDateString() ?? today.toDateString()}
        />
      </Modal>
    </>
  );
};

export default InspectionApprovalModal;
