import { Box, VStack } from '@chakra-ui/react';
import { TablePagination } from '@sgi/gravity';
import React, { useEffect, useState } from 'react';
import { InspectionContactListDTO } from 'Types/InspectionContactListDTO';
import ContactSearchResult from './ContactSearchResult';

interface ContactResultPaginationProps {
  contacts: InspectionContactListDTO[];
  selected: InspectionContactListDTO | undefined;
  setSelected: Function;
}
const ContactResultPagination = ({
  contacts,
  selected,
  setSelected,
}: ContactResultPaginationProps) => {
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Sorting
  const [sorting] = useState([
    {
      id: 'lastName',
      desc: false,
    },
  ]);
  const sortContacts = (a: any, b: any) => {
    // Check to see if there are any columns to sort by
    if (sorting.length === 0) {
      return true;
    }

    if (!sorting[0].desc) {
      return a[sorting[0].id]
        ?.toString()
        .localeCompare(b[sorting[0].id]?.toString());
    } else {
      return b[sorting[0].id]
        ?.toString()
        .localeCompare(a[sorting[0].id]?.toString());
    }
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [contacts]);

  return (
    <VStack w="100%" align={'flex-start'} justify={'space-between'}>
      <Box w="100%">
        <TablePagination
          currentPage={pageIndex}
          onPageClick={(page) =>
            setPagination((prev) => ({ ...prev, pageIndex: page }))
          }
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize}
          pageCount={Math.ceil((contacts ?? []).length / pageSize) ?? -1}
          totalCount={(contacts ?? []).length}
          onPageSizeChange={(newPageSize) =>
            setPagination((prev) => ({
              pageIndex: 0,
              pageSize: newPageSize,
            }))
          }
        ></TablePagination>
      </Box>
      <React.Fragment>
        {[...(contacts ?? [])]
          .sort((a: any, b: any) => sortContacts(a, b))
          .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)
          .map((contact, index) => {
            return (
              <ContactSearchResult
                key={`contact-search-${index}`}
                contact={contact}
                selected={selected}
                setSelected={setSelected}
              />
            );
          })}
      </React.Fragment>
      <Box w="100%">
        <TablePagination
          currentPage={pageIndex}
          onPageClick={(page) =>
            setPagination((prev) => ({ ...prev, pageIndex: page }))
          }
          isManual={false}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize}
          pageCount={Math.ceil((contacts ?? []).length / pageSize) ?? -1}
          totalCount={(contacts ?? []).length}
          onPageSizeChange={(newPageSize) =>
            setPagination((prev) => ({
              pageIndex: 0,
              pageSize: newPageSize,
            }))
          }
        ></TablePagination>
      </Box>
    </VStack>
  );
};

export default ContactResultPagination;
