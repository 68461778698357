import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { ContactInfo } from 'Types/InspectionDTO';
import { formatPhone } from 'Utils/Format';

interface InspectionDetailsContactDetailsProp {
  contactInfo: ContactInfo;
  onClose: any;
}

export const InspectionDetailsContactDetails = ({
  contactInfo,
  onClose,
}: InspectionDetailsContactDetailsProp) => {
  return (
    <>
      <Box bg="#FFFFFF" w="100%" pb={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">{contactInfo?.firstName}</Typography>
            <Typography variant="h1">{contactInfo?.lastName}</Typography>
            <HStack>
              <Typography variant="bodyBold">Customer #</Typography>
              <Typography variant="bodyBold">
                {contactInfo?.customerNumber}
              </Typography>
            </HStack>
          </GridItem>
        </Grid>
      </Box>

      <Box bg="white" w="100%" borderRadius={8}>
        <Grid templateColumns="repeat(2,1fr)" gap={4}>
          <GridItem>
            <Card>
              <VStack w="100" alignItems="flex-start">
                <Typography variant="h3">Contact</Typography>
                <br />

                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Email</Typography>
                  <Typography aria-label="contact-display-email">
                    {contactInfo?.email}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Cell Phone</Typography>
                  <Typography aria-label="contact-display-cellPhone">
                    {contactInfo?.phoneInfo.cellPhone
                      ? formatPhone(contactInfo?.phoneInfo?.cellPhone)
                      : ''}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Home Phone</Typography>
                  <Typography aria-label="contact-display-homePhone">
                    {contactInfo?.phoneInfo.homePhone
                      ? formatPhone(contactInfo?.phoneInfo?.homePhone)
                      : ''}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Work Phone</Typography>
                  <Typography aria-label="contact-display-workPhone">
                    {contactInfo?.phoneInfo.workPhone
                      ? `${formatPhone(contactInfo?.phoneInfo?.workPhone)}`
                      : ''}
                    {contactInfo?.phoneInfo.workPhoneExtension
                      ? ` Ext: ${contactInfo?.phoneInfo.workPhoneExtension}`
                      : ''}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
              </VStack>
            </Card>
          </GridItem>
          <GridItem>
            <Card>
              <VStack w="100" alignItems="flex-start">
                <Typography variant="h3">Mailing Address</Typography>
                <br />

                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Address</Typography>
                  <Typography aria-label="contact-display-street">
                    {contactInfo?.address.street}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">City</Typography>
                  <Typography aria-label="contact-display-city">
                    {contactInfo?.address.city}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Province</Typography>
                  <Typography aria-label="contact-display-province">
                    {contactInfo?.address.province}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <SimpleGrid width="100%" columns={2}>
                  <Typography variant="bodyBold">Postal Code</Typography>
                  <Typography aria-label="contact-display-postalCode">
                    {contactInfo?.address.postalCode}
                  </Typography>
                </SimpleGrid>
                <Divider color="blackAlpha.100" borderBottom="1px" />
              </VStack>
            </Card>
          </GridItem>

          <GridItem colSpan={2} style={{ textAlign: 'right' }}>
            <Button
              variant="primary"
              data-testid="contact-close-button"
              onClick={onClose}
            >
              Close
            </Button>
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default InspectionDetailsContactDetails;
