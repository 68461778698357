declare global {
  interface Window {
    runConfig: {
      REACT_APP_MSAL_SIGNUPSIGNIN_POLICY: string;
      REACT_APP_MSAL_EDITPROFILE_POLICY: string;
      REACT_APP_MSAL_POLICY_AUTHORITY_ROOT: string;
      REACT_APP_MSAL_B2C_AUTHORITYDOMAIN: string;
    };
  }
}

export function GetB2CPolicyInfo() {
  return {
    names: {
      signUpSignIn:
        window['runConfig']?.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY ??
        process.env.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
      editProfile:
        window['runConfig']?.REACT_APP_MSAL_EDITPROFILE_POLICY ??
        process.env.REACT_APP_MSAL_EDITPROFILE_POLICY,
    },
    authorities: {
      signUpSignIn: {
        authority:
          (window['runConfig']?.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT ??
            process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT) +
            window['runConfig']?.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY ??
          process.env.REACT_APP_MSAL_SIGNUPSIGNIN_POLICY,
      },
      editProfile: {
        authority:
          (window['runConfig']?.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT ??
            process.env.REACT_APP_MSAL_POLICY_AUTHORITY_ROOT) +
            window['runConfig']?.REACT_APP_MSAL_EDITPROFILE_POLICY ??
          process.env.REACT_APP_MSAL_EDITPROFILE_POLICY,
      },
    },
    authorityDomain:
      window['runConfig']?.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN ??
      process.env.REACT_APP_MSAL_B2C_AUTHORITYDOMAIN,
  };
}

export default GetB2CPolicyInfo;
