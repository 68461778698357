import { HStack } from '@chakra-ui/react';
import { getIn } from 'formik';
import { useEffect } from 'react';
import { InspectionProgram } from 'Types/InspectionEnums';
import {
  BrakeMeasurementDTO,
  BrakeMeasurementUnit,
  BrakeTypes,
  HydraulicDiscBrakeMeasurementLabel,
  HydraulicDrumBrakeMeasurementLabel,
} from 'Types/WheelInfoDTO';
import BrakeMeasurement from './BrakeMeasurement';

interface BrakeMeasurementCategoryProps {
  isAdditional: boolean;
  brakeMeasurements: BrakeMeasurementDTO[];
  brakeType: number;
  program: InspectionProgram | undefined;
  namePrefix: string;
  brakeMeasurementUnit: BrakeMeasurementUnit | null;
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  isRequired: boolean;
  isDisabled?: boolean;
}

export const BrakeMeasurementCategory = ({
  brakeMeasurementUnit,
  isAdditional,
  brakeMeasurements,
  brakeType,
  program,
  namePrefix,
  setFieldValue,
  onChange,
  errors,
  isRequired,
  isDisabled = false,
}: BrakeMeasurementCategoryProps) => {
  function setBrakeMeasurement(measurements: BrakeMeasurementDTO[]) {
    if (isAdditional) {
      setFieldValue(`${namePrefix}.additionalBrakeMeasurements`, measurements);
    } else {
      setFieldValue(`${namePrefix}.brakeMeasurements`, measurements);
    }
  }

  function GetBrakeMeasurementLabelByBrakeType() {
    let measurementLabels: any = [];
    Object.keys(
      brakeType === BrakeTypes.HydraulicDisc
        ? HydraulicDiscBrakeMeasurementLabel
        : HydraulicDrumBrakeMeasurementLabel,
    )
      .filter((key) => isNaN(Number(key)))
      .forEach((value) => {
        measurementLabels.push({
          value,
          text:
            brakeType === BrakeTypes.HydraulicDisc
              ? HydraulicDiscBrakeMeasurementLabel[
                  value as keyof typeof HydraulicDiscBrakeMeasurementLabel
                ]
              : HydraulicDrumBrakeMeasurementLabel[
                  value as keyof typeof HydraulicDrumBrakeMeasurementLabel
                ],
        });
      });
    return measurementLabels;
  }

  function GetDefaultBrakeMeasurements() {
    if (!brakeMeasurements || brakeMeasurements?.length === 0) {
      let tempBrakeMeasurements: BrakeMeasurementDTO[] = [];
      const measurementLabelArr = GetBrakeMeasurementLabelByBrakeType();
      measurementLabelArr.forEach((el: any) => {
        tempBrakeMeasurements.push({
          name: el.text,
          measurement: null,
        });
      });
      setBrakeMeasurement(tempBrakeMeasurements);
    }
  }

  function CheckBrakeMeasurementsDetails() {
    if (brakeType === BrakeTypes.HydraulicDisc) {
      if (brakeMeasurements && brakeMeasurements?.length > 0) {
        const result = brakeMeasurements.find(
          (element) =>
            element.name ===
            HydraulicDiscBrakeMeasurementLabel.BRAKE_PAD_LINING_THICKNESS,
        );
        if (!result) {
          setBrakeMeasurement([]);
        }
      }
    }

    if (brakeType === BrakeTypes.HydraulicDrum) {
      if (brakeMeasurements && brakeMeasurements?.length > 0) {
        const result = brakeMeasurements.find(
          (element) =>
            element.name ===
            HydraulicDrumBrakeMeasurementLabel.BRAKE_SHOE_LINING_THICKNESS,
        );
        if (!result) {
          setBrakeMeasurement([]);
        }
      }
    }
  }

  useEffect(() => {
    CheckBrakeMeasurementsDetails();
    GetDefaultBrakeMeasurements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brakeType, brakeMeasurements]);

  return (
    <HStack align={'end'} justify="space-between" w="100%">
      {brakeMeasurements.map((item: BrakeMeasurementDTO, i: number) => {
        return (
          <BrakeMeasurement
            isRequired={isRequired}
            brakeMeasurementUnit={brakeMeasurementUnit}
            brakeIndex={i}
            key={`brake-m-catagory-${namePrefix}${i}`}
            brakeMeasurement={item}
            onChange={onChange}
            setFieldValue={setFieldValue}
            errors={getIn(errors, `[${i}]`)}
            isDisabled={isDisabled}
            namePrefix={
              isAdditional
                ? `${namePrefix}.additionalBrakeMeasurements[${i}]`
                : `${namePrefix}.brakeMeasurements[${i}]`
            }
          ></BrakeMeasurement>
        );
      })}
    </HStack>
  );
};

export default BrakeMeasurementCategory;
