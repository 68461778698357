export interface UpdateVinStatusDTO {
  vin: string;
  status: number | undefined;
  dataVersion: number | undefined;
}

export const defaultUpdateVinStatusDTO: UpdateVinStatusDTO = {
  vin: '',
  status: undefined,
  dataVersion: undefined,
};
