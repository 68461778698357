import { Route, Routes } from 'react-router';
import CreateVehiclePage from './CreateVehiclePage';
import ListVehiclePage from './ListVehiclePage';
import Vehicle from './Vehicle';

const VehicleRouter = () => {
  return (
    <Routes>
      <Route path={`/create`} element={<CreateVehiclePage />}></Route>
      <Route path={`/list`} element={<ListVehiclePage />}></Route>
      <Route path={`:vin`} element={<Vehicle />}></Route>
      {/* <Route
        path={`/updateVinStatus/:vin`}
        element={<UpdateVinStatusForm />}
      ></Route> */}
      <Route path="*" element={<div>No route match</div>} />
    </Routes>
  );
};

export default VehicleRouter;
