import { Box, HStack, Icon } from '@chakra-ui/react';
import { Button, Modal, useToast } from '@sgi/gravity';
import { useUpdateQuestionMutation } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import { faPencil } from 'App/Common/Icons';
import { validationMessages } from 'constants/messageConstants';
import { QuestionCodes } from 'constants/variableConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Question } from 'Types/InspectionDTO';
import * as Yup from 'yup';
import LocationAndDamages from './LocationAndDamages';

interface EditLocationAndDamagesProps {
  question: Question;
  inspectionId: string;
  vin: string;
  dataVersion?: number;
}

const EditLocationAndDamages = ({
  question,
  inspectionId,
  vin,
  dataVersion,
}: EditLocationAndDamagesProps) => {
  const [showUpdateQuestionsDialog, setShowUpdateQuestionsDialog] =
    useState<boolean>(false);
  const { showToast } = useToast();
  const [
    updateQuestion,
    { isError: isUpdateQuestionError, error: updateQuestionError },
  ] = useUpdateQuestionMutation();

  useEffect(() => {
    if (isUpdateQuestionError && updateQuestionError) {
      showToast({
        description: <ErrorList errors={updateQuestionError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateQuestionError, updateQuestionError]);

  const formik = useFormik({
    initialValues: { ...question },
    validationSchema: Yup.object({
      damageDetails: Yup.array()
        .nullable()
        .when('code', {
          is: (code: string) =>
            code === QuestionCodes.ctPreQuestionLocationAndDamages,
          then: Yup.array()
            .nullable()
            .required(validationMessages.DamageDetails.min)
            .min(1, validationMessages.DamageDetails.min)
            .of(
              Yup.object({
                location: Yup.string().required(
                  validationMessages.DamageDetails.inspectionDamageLocation,
                ),
                description: Yup.string().required(
                  validationMessages.DamageDetails.description,
                ),
              }),
            ),
        }),
    }),
    onSubmit: (value: Question) => {
      updateQuestion({
        id: inspectionId,
        vin: vin,
        question: value,
        dataVersion: dataVersion,
      });
    },
  });

  return (
    <>
      <Button
        type="button"
        rightIcon={
          <Icon
            fill="teal.500"
            _hover={{ color: 'teal.600' }}
            aria-label={`edit-location-damages`}
          >
            {faPencil}
          </Icon>
        }
        isDestructive={false}
        variant="solid"
        data-testid="LAD-edit-button"
        onClick={() => {
          setShowUpdateQuestionsDialog(true);
        }}
      >
        Edit
      </Button>
      <Modal
        data-testid="contact-updateQuestion-Dialog"
        modalContentProps={{ maxW: '800px', maxH: '-moz-fit-content' }}
        isOpen={showUpdateQuestionsDialog}
        onClose={() => {
          formik.resetForm();
          setShowUpdateQuestionsDialog(false);
        }}
        title={`Update Inspection Question`}
        isCentered={true}
        scrollBehavior={'inside'}
        showCloseButton={false}
        footer={
          <HStack w="100%" justifyContent={'flex-end'}>
            <Button
              variant="secondary"
              data-testid="LAD-edit-cancel-button"
              onClick={() => {
                formik.resetForm();
                setShowUpdateQuestionsDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              data-testid="updateQuestion-submit"
              aria-label="updateQuestion-Submit"
              variant="primary"
              type="submit"
              onClick={() => formik.handleSubmit()}
            >
              Update
            </Button>
          </HStack>
        }
      >
        <Box maxHeight="400px">
          <LocationAndDamages
            namePrefix=""
            question={formik.values}
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            errors={formik.errors}
            touched={formik.touched}
          />
        </Box>
      </Modal>
    </>
  );
};

export default EditLocationAndDamages;
