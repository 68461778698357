import { HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Link, Radio, RadioGroup } from '@sgi/gravity';
import { faArrowRightIcon } from 'App/Common/Icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface InspectionItemProps {
  step: number;
  setStep: Function;
}

export const EligibilityQuestions = ({
  step,
  setStep,
}: InspectionItemProps) => {
  const navigate = useNavigate();
  const [inspectionOption, setInspectionOption] = useState<string | boolean>(
    '',
  );
  const [inspectionReason, setinspectionReason] = useState<string | boolean>(
    '',
  );

  return (
    <>
      <VStack align="flex-start">
        <>
          <RadioGroup
            label="What kind of vehicle do you want to inspect?"
            value={inspectionOption}
            onChange={setInspectionOption}
            name="inspectionOption"
            data-testid="inspectionOption"
            isRequired
          >
            <HStack m={4} gap={4}>
              <Radio data-testid="lightVehicleOption" value="1">
                Light Vehicle
              </Radio>
              <Radio data-testid="motorcycleOption" value="2">
                Motorcycle
              </Radio>
              <Radio data-testid="othersOption" value="4">
                Others
              </Radio>
            </HStack>
          </RadioGroup>

          {inspectionOption === '1' && (
            <RadioGroup
              label="Is the reason for the inspection for Taxi / Rideshare OR Periodic Mechanical Vehicle Inspection?"
              value={inspectionReason}
              onChange={setinspectionReason}
              name="lightVehicle-reason"
              data-testid="lightVehicle-reason"
              isRequired
            >
              <HStack m={4} gap={4}>
                <Radio data-testid="lightVehicle-reason-yes" value="1">
                  Yes
                </Radio>
                <Radio data-testid="lightVehicle-reason-no" value="2">
                  No
                </Radio>
              </HStack>
            </RadioGroup>
          )}

          {(inspectionOption === '4' || inspectionReason === '1') && (
            <div data-testid="inspectionOption-error">
              <br />
              Please create the inspection in the eInspections application since
              it is not eligible to be completed in the Inspect Tech application
              under the Light Vehicle or Motorcycle inspection program.
              eInspections link:{' '}
              <Link href={`https://autofund.sgi.sk.ca/`}>
                https://autofund.sgi.sk.ca/
              </Link>
            </div>
          )}
        </>
        <br />

        <HStack w="100%" justifyContent="space-between">
          <Button
            variant="secondary"
            data-testid="Eligibility-Cancel"
            onClick={() => {
              navigate('/inspection/list');
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="Eligibility-Continue"
            disabled={
              inspectionOption === '' ||
              (inspectionOption === '1' && inspectionReason !== '2') ||
              inspectionOption === '4'
            }
            onClick={() => setStep(step + 1)}
            rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
          >
            Continue to the next step
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default EligibilityQuestions;
