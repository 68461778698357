export interface AdditionalVehicleInfoDTO {
  unitNumber: string | undefined;
  odometer: string;
  odometerUoD: number;
  plate: string | undefined;
  jurisdiction: number | undefined;
}

export const defaultAdditionalVehicleInfoDTO: AdditionalVehicleInfoDTO = {
  unitNumber: '',
  odometer: '',
  odometerUoD: 0,
  plate: '',
  jurisdiction: undefined,
};
