import { VinStatus } from './VehicleDTO';

export interface CreateVehicleDTO {
  vin: string;
  year: number | undefined;
  make: string;
  model: string;
  colour: string;
  bodyStyle: string;
  gvwr: number | undefined;
  fuelCode: number | undefined;
  status: number;
  vinExemptionReason: string[];
}

export const defaultCreateVehicleDTO: CreateVehicleDTO = {
  vin: '',
  year: undefined,
  make: '',
  model: '',
  colour: '',
  bodyStyle: '',
  gvwr: undefined,
  fuelCode: undefined,
  status: VinStatus.Normal,
  vinExemptionReason: [],
};
