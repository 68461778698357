import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Card, Select, Typography } from '@sgi/gravity';
import { faArrowLeftIcon, faArrowRightIcon } from 'App/Common/Icons';
import { useEffect, useState } from 'react';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import { InspectionProgram, InspectionType } from 'Types/InspectionEnums';
import { VinStatus } from 'Types/VehicleDTO';
interface AdditionalVehicleInfoProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
  setInspection: Function;
}
const InspectionInfo = ({
  step,
  setStep,
  inspection,
  inspectionHelperDetailsDTO,
  setInspection,
}: AdditionalVehicleInfoProps) => {
  const [selectedProgram, setSelectedProgram] = useState(
    inspection.program ?? '',
  );

  const [selectedType, setSelectedType] = useState(inspection.type ?? '');
  useEffect(() => {
    if (
      inspectionHelperDetailsDTO.vehicle &&
      (inspectionHelperDetailsDTO.vehicle.status === VinStatus.HULK ||
        inspectionHelperDetailsDTO.vehicle.status === VinStatus.VeryUnsafe)
    ) {
      handleProgramChange(InspectionProgram.BodyIntegrity);
    }
  }, [inspectionHelperDetailsDTO]);

  const handleProgramChange = (value: string) => {
    setSelectedProgram(value);

    if (value !== InspectionProgram.BodyIntegrity) {
      setSelectedType(InspectionType.Mechanical);
    } else {
      setSelectedType(InspectionType.Structural);
    }
  };

  const handleSubmit = () => {
    setInspection({
      ...inspection,
      program: selectedProgram,
      type: selectedType,

      questions:
        selectedProgram !== InspectionProgram.BodyIntegrity
          ? []
          : inspection.questions,
    });
    setStep(step + 1);
  };

  function getValidInspectionProgramKeys(): string[] {
    if (
      inspectionHelperDetailsDTO.vehicle &&
      (inspectionHelperDetailsDTO.vehicle.status === VinStatus.HULK ||
        inspectionHelperDetailsDTO.vehicle.status === VinStatus.VeryUnsafe)
    ) {
      return Object.keys(InspectionProgram)
        .filter((key) => isNaN(Number(key)))
        .filter(
          (k) =>
            InspectionProgram[k as keyof typeof InspectionProgram] ===
            InspectionProgram.BodyIntegrity,
        );
    } else {
      return Object.keys(InspectionProgram)
        .filter((key) => isNaN(Number(key)))
        .filter(
          (k) =>
            InspectionProgram[k as keyof typeof InspectionProgram] ===
              InspectionProgram.LightVehicle ||
            InspectionProgram[k as keyof typeof InspectionProgram] ===
              InspectionProgram.MotorCycle,
        );
    }
  }
  return (
    <Box>
      <VStack align="flex-start">
        <Card>
          <HStack alignItems={'start'}>
            <Select
              name="inspection-program"
              label="Program"
              data-testid="create-inspection-select-program"
              placeholder="Choose Inspection Program"
              value={selectedProgram}
              options={getValidInspectionProgramKeys().map((p) => {
                return {
                  value: InspectionProgram[p as keyof typeof InspectionProgram],
                  label: InspectionProgram[p as keyof typeof InspectionProgram],
                };
              })}
              isRequired
              onChange={(e) => {
                handleProgramChange(e.target.value);
              }}
            />

            {selectedProgram !== '' && (
              <VStack
                alignItems={'start'}
                gap={2}
                data-testid="inspection-type"
              >
                <Typography variant={'bodyBold'}>Type</Typography>

                <Typography>
                  {selectedProgram !== InspectionProgram.BodyIntegrity
                    ? InspectionType['Mechanical']
                    : InspectionType['Structural']}
                </Typography>
              </VStack>
            )}
          </HStack>
        </Card>
        <HStack w="100%" justifyContent="space-between">
          <Button
            variant="secondary"
            leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
            onClick={() => setStep(step - 1)}
          >
            Previous step
          </Button>
          <Button
            disabled={selectedProgram === '' || selectedType === ''}
            data-testid="InspectionInfo-Continue"
            onClick={handleSubmit}
            rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
            aria-label="InspectionInfo-Continue"
          >
            Continue to the next step
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
export default InspectionInfo;
