import { configureStore } from '@reduxjs/toolkit';
import { inspectTechUIAPI } from 'api/InspectTechUIAPI';
import { inspectTechUIFileUploadAPI } from 'api/InspectTechUIFileUploadAPI';

export default configureStore({
  reducer: {
    [inspectTechUIAPI.reducerPath]: inspectTechUIAPI.reducer,
    [inspectTechUIFileUploadAPI.reducerPath]:
      inspectTechUIFileUploadAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(inspectTechUIAPI.middleware)
      .concat(inspectTechUIFileUploadAPI.middleware),
});
