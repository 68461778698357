import { Box, HStack, Icon } from '@chakra-ui/react';
import { Alert, Button, Input, Radio, RadioGroup } from '@sgi/gravity';
import { useAnyInProgressInspectionQuery } from 'api/InspectTechUIAPI';
import { faArrowRightIcon } from 'App/Common/Icons';
import {
  inspectionMessages,
  maxOdometer,
  validationMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateReInspectionDTO } from 'Types/CreateReInspectionDTO';
import { VehicleInfo } from 'Types/InspectionDTO';
import { UnitOfDistance } from 'Types/InspectionEnums';
import { InputMapper } from 'Utils/InputMapper';
import * as Yup from 'yup';
import InspectionDetailsVehicleInfo from '../../InspectionDetailsVehicleInfo';

interface VerifyVehicleInfoDTO {
  odometer: string;
  odometerUoD: number;
}

interface AdditionalVehicleInfoProps {
  step: number;
  setStep: Function;
  vehicleInfo?: VehicleInfo;
  inspection: CreateReInspectionDTO;
  setInspection: React.Dispatch<React.SetStateAction<CreateReInspectionDTO>>;
}

const VerifyVehicleInformation = ({
  step,
  setStep,
  vehicleInfo,
  inspection,
  setInspection,
}: AdditionalVehicleInfoProps) => {
  const navigate = useNavigate();
  const [skip, setSkip] = useState(true);
  const [anyInProgressInspection, setAnyInProgressInspection] =
    useState<boolean>(false);

  useEffect(() => {
    if (inspection.vin) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const { data: anyInspectionInProgressResult } =
    useAnyInProgressInspectionQuery(inspection.vin ?? '', { skip });

  useEffect(() => {
    if (anyInspectionInProgressResult) {
      setSkip(true);
      setAnyInProgressInspection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anyInspectionInProgressResult]);

  const formik = useFormik({
    initialValues: {
      odometer: inspection.odometer ? inspection.odometer.toString() : '',
      odometerUoD: inspection.odometerUoD,
    },
    validationSchema: Yup.object().shape({
      odometer: Yup.number()
        .typeError(validationMessages.odometer.type)
        .min(1, validationMessages.odometer.range)
        .max(maxOdometer, validationMessages.odometer.range)
        .required(validationMessages.odometer.required)
        .integer(validationMessages.odometer.invalid),
      odometerUoD: Yup.number().required(
        validationMessages.odometerUoD.required,
      ),
    }),
    onSubmit: (values: VerifyVehicleInfoDTO) => {
      setInspection((prev) => {
        return {
          ...prev,
          odometer: Number(values.odometer),
          odometerUoD: Number(values.odometerUoD),
        };
      });

      setStep(step + 1);
    },
  });

  return (
    <Box>
      {anyInProgressInspection && (
        <Alert status="warning" showCloseButton={false}>
          {inspectionMessages.alreadyInProgressInspection + inspection.vin}
        </Alert>
      )}
      <Box paddingTop="5px">
        {vehicleInfo && (
          <InspectionDetailsVehicleInfo vehicleInfo={vehicleInfo} />
        )}
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box paddingTop="5px" width="25%">
          <HStack alignItems={'center'}>
            <Input
              name="odometer"
              data-testid="reinspection-create-odometer"
              placeholder="Odometer"
              hideOptionalLabel
              size="md"
              label="Odometer"
              error={
                formik.touched.odometer && formik.errors.odometer
                  ? formik.errors.odometer
                  : undefined
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.odometer?.toString()}
            />
            <RadioGroup
              name="odometerUoD"
              value={formik.values.odometerUoD.toString()}
              data-testid="reinspection-create-odometerUoD"
              onChange={(e) => {
                formik.setFieldValue('odometerUoD', e.toString());
              }}
              variant="radio"
              label="Unit of Distance"
              isRequired={true}
              hideOptionalLabel={true}
              error={formik.errors.odometerUoD}
            >
              <HStack>
                {InputMapper.bindTextValuePairs(UnitOfDistance, true).length >
                  0 &&
                  InputMapper.bindTextValuePairs(UnitOfDistance, true).map(
                    (item, i) => {
                      return (
                        <Radio
                          value={item.value}
                          key={`odometerUoD${item.value}`}
                        >
                          {item.text}
                        </Radio>
                      );
                    },
                  )}
              </HStack>
            </RadioGroup>
          </HStack>
        </Box>
        <HStack paddingTop="5px" w="100%" justifyContent="space-between">
          <Button
            variant="secondary"
            onClick={() => {
              navigate(`/inspection/${inspection.id}/${inspection.vin}`);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            data-testid="VerifyVehicleInfo-Continue"
            rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
          >
            Continue to the next step
          </Button>
        </HStack>
      </form>
    </Box>
  );
};

export default VerifyVehicleInformation;
