export interface SearchInspectionDTO {
  searchString: string;
  type: string;
  searchError: boolean | undefined;
}

export const defaultSearchInspectionDTO: SearchInspectionDTO = {
  searchString: '',
  type: '',
  searchError: undefined,
};

export interface FilterInspectionDTO {
  filterType: string;
  filterStatus: number[];
}

export const defaultFilterInspectionDTO: FilterInspectionDTO = {
  filterType: '',
  filterStatus: [],
};

export interface SearchInspectionCriteria {
  searchInspection: SearchInspectionDTO;
  filterInspection: FilterInspectionDTO;
  sortInspection: {
    ascending: boolean;
    field: string;
  };
}
