import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { useGetContactByIdQuery } from 'api/InspectTechUIAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { formatPhone } from 'Utils/Format';

export const Contact = () => {
  const { contactId } = useParams<'contactId'>();
  const navigate = useNavigate();

  const { data: contact, isFetching: isGetContactFetching } =
    useGetContactByIdQuery(contactId ?? '');

  return (
    <>
      {!isGetContactFetching && (
        <>
          <Box bg="#FFFFFF" w="100%" p={9}>
            <Grid templateColumns="repeat(3, 1fr)">
              <GridItem colSpan={1}>
                <Typography variant="h1">{contact?.firstName}</Typography>
                <Typography variant="h1">{contact?.lastName}</Typography>
                <HStack>
                  <Typography variant="bodyBold">Customer #</Typography>
                  <Typography variant="bodyBold">
                    {contact?.customerNumber}
                  </Typography>
                </HStack>
              </GridItem>
            </Grid>
          </Box>

          <Box pl="40px" pr="40px" pt={4}>
            <Box bg="white" w="100%" p="24px 40px" borderRadius={8}>
              <Grid templateColumns="repeat(2,1fr)" gap={4}>
                <GridItem>
                  <Card>
                    <VStack w="100" alignItems="flex-start">
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Email</Typography>
                        <Typography aria-label="contact-display-email">
                          {contact?.email}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Cell Phone</Typography>
                        <Typography aria-label="contact-display-cellPhone">
                          {contact?.phoneInfo.cellPhone !== null
                            ? formatPhone(contact?.phoneInfo?.cellPhone)
                            : ''}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Home Phone</Typography>
                        <Typography aria-label="contact-display-homePhone">
                          {contact?.phoneInfo.homePhone
                            ? formatPhone(contact?.phoneInfo?.homePhone)
                            : ''}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Work Phone</Typography>
                        <Typography aria-label="contact-display-workPhone">
                          {contact?.phoneInfo.workPhone
                            ? `${formatPhone(contact?.phoneInfo?.workPhone)}`
                            : ''}
                          {contact?.phoneInfo.workPhoneExtension
                            ? ` Ext: ${contact?.phoneInfo.workPhoneExtension}`
                            : ''}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                    </VStack>
                  </Card>
                </GridItem>
                <GridItem>
                  <Card>
                    <VStack w="100" alignItems="flex-start">
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Address</Typography>
                        <Typography aria-label="contact-display-street">
                          {contact?.address.street}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">City</Typography>
                        <Typography aria-label="contact-display-city">
                          {contact?.address.city}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Province</Typography>
                        <Typography aria-label="contact-display-province">
                          {contact?.address.province}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                      <SimpleGrid width="100%" columns={2}>
                        <Typography variant="bodyBold">Postal Code</Typography>
                        <Typography aria-label="contact-display-postalCode">
                          {contact?.address.postalCode}
                        </Typography>
                      </SimpleGrid>
                      <Divider color="blackAlpha.100" borderBottom="1px" />
                    </VStack>
                  </Card>
                </GridItem>
                <GridItem>
                  <HStack>
                    <Button
                      variant="secondary"
                      data-testid="contact-back-button"
                      onClick={() => {
                        navigate('/contact/list');
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      data-testid="contact-display-edit"
                      onClick={() => {
                        navigate(`/contact/${contact?.id}/edit`);
                      }}
                    >
                      Edit
                    </Button>
                  </HStack>
                </GridItem>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Contact;
