import { Box, Grid, GridItem } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { Step, Stepper } from 'App/Common/Stepper';
import { useState } from 'react';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
  defaultCreateInspectionDTO,
  defaultCreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import { InspectionProgram } from 'Types/InspectionEnums';
import AdditionalVehicleInfo from './StepForm/AdditionalVehicleInfo/AdditionalVehicleInfo';
import ConfirmInspection from './StepForm/ConfirmDetails/ConfirmInspection';
import ContactInfoStep from './StepForm/ContactInfo/ContactInfoStep';
import ContactPreInspectionQuestions from './StepForm/ContactPreInspectionQuestions/ContactPreInspectionQuestions';
import EligibilityQuestions from './StepForm/Eligibility/EligibilityQuestions';
import InspectionInfo from './StepForm/InspectionInfo/InspectionInfo';
import StationInfo from './StepForm/StationInfo/StationInfo';
import VehicleSearch from './StepForm/VIN Search/VehicleSearch';

export const CreateInspectionMultistepForm = () => {
  const [step, setStep] = useState<number>(0);
  const [createInspectionDTO, setCreateInspectionDTO] =
    useState<CreateInspectionDTO>(defaultCreateInspectionDTO);

  const [
    createInspectionHelperDetailsDTO,
    setCreateInspectionHelperDetailsDTO,
  ] = useState<CreateInspectionHelperDetailsDTO>(
    defaultCreateInspectionHelperDetailsDTO,
  );

  let steps: Step[] = [
    {
      subtitle: 'Eligibility',
      component: <EligibilityQuestions step={step} setStep={setStep} />,
    },
    {
      subtitle: 'Vehicle Search',
      component: (
        <VehicleSearch
          step={step}
          setStep={setStep}
          inspection={createInspectionDTO}
          setInspection={setCreateInspectionDTO}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
          setCreateInspectionHelperDetailsDTO={
            setCreateInspectionHelperDetailsDTO
          }
        />
      ),
    },
    {
      subtitle: 'Additional Vehicle Info',
      component: (
        <AdditionalVehicleInfo
          step={step}
          setStep={setStep}
          inspection={createInspectionDTO}
          setInspection={setCreateInspectionDTO}
        />
      ),
    },
    {
      subtitle: 'Inspection Info',
      component: (
        <InspectionInfo
          step={step}
          setStep={setStep}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
          inspection={createInspectionDTO}
          setInspection={setCreateInspectionDTO}
        />
      ),
    },
    {
      subtitle: 'Station Info',
      component: (
        <StationInfo
          step={step}
          setStep={setStep}
          inspection={createInspectionDTO}
          setInspection={setCreateInspectionDTO}
        />
      ),
    },
    {
      subtitle: 'Contact Info',
      component: (
        <ContactInfoStep
          step={step}
          setStep={setStep}
          contactId={createInspectionDTO.contactId}
          setInspection={setCreateInspectionDTO}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
          setCreateInspectionHelperDetailsDTO={
            setCreateInspectionHelperDetailsDTO
          }
        />
      ),
    },
    {
      subtitle: 'Confirm Details',
      component: (
        <ConfirmInspection
          step={step}
          setStep={setStep}
          inspection={createInspectionDTO}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
        />
      ),
    },
  ];

  if (createInspectionDTO.program === InspectionProgram.BodyIntegrity) {
    const contactInfoIndex = steps.findIndex(
      (s) => s.subtitle === 'Contact Info',
    );
    steps.splice(contactInfoIndex + 1, 0, {
      subtitle: 'Pre-Inspection Questions',
      component: (
        <ContactPreInspectionQuestions
          step={step}
          setStep={setStep}
          inspection={createInspectionDTO}
          setInspection={setCreateInspectionDTO}
        />
      ),
    });
  }

  return (
    <>
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Create Inspection</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" p={4} borderRadius={8}>
          {/*Replace this div with a proper banner component at some point */}
          <Typography variant="h3">{steps[step].subtitle}</Typography>
          <br />
          <Stepper steps={steps} index={step} />
          <br />
          <Card className="card-background">{steps[step].component}</Card>
        </Box>
      </Box>
    </>
  );
};

export default CreateInspectionMultistepForm;
