import { Route, Routes } from 'react-router';
import Contact from './Contact';
import ContactSummaryPage from './ContactSummaryPage';
import CreateContact from './CreateContact';
import UpdateContactForm from './UpdateContactForm';

export const ContactRouter = () => {
  return (
    <Routes>
      <Route path={'/create'} element={<CreateContact />}></Route>
      <Route path={'/list'} element={<ContactSummaryPage />}></Route>
      <Route path={':contactId'} element={<Contact />}></Route>
      <Route path={':contactId/edit'} element={<UpdateContactForm />}></Route>
      <Route path="*" element={<div>No route match</div>}></Route>
    </Routes>
  );
};

export default ContactRouter;
