import { ContactAddressDTO, ContactPhoneDTO,defaultContactAddressDTO, defaultContactPhoneDTO } from './ContactDTO';

export interface CreateContactDTO {
  firstName: string;
  lastName: string;
  customerNumber: number | undefined;
  address: ContactAddressDTO;
  phoneInfo: ContactPhoneDTO;
  email: string | undefined;
  
}

export const defaultCreateContactDTO: CreateContactDTO = {
  firstName: '',
  lastName: '',
  customerNumber: undefined,
  address: defaultContactAddressDTO,
  phoneInfo: defaultContactPhoneDTO,
  email: '',
};

export const initCreateContactDTO: CreateContactDTO = {
  firstName: '',
  lastName: '',
  customerNumber: undefined,
  address: {
    street: '',
    city: '',
    province: '',
    postalCode: '',
  },
  phoneInfo: {
    homePhone:'',
    cellPhone:'',
      workPhone: '',  
      workPhoneExtension:'',
    phoneError:undefined
},
  email: ''
};

export const updCreateContactDTO: CreateContactDTO = {
  firstName: '',
  lastName: '',
  customerNumber: undefined,
  address: {
    street: '',
    city: '',
    province: '',
    postalCode: '',
  },
  phoneInfo: {
    homePhone:'',
    cellPhone:'',
      workPhone: '',  
      workPhoneExtension:'',
    phoneError:undefined
  },
  email: ''
};
