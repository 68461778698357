import { Box, Grid, GridItem, VStack } from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Input,
  Select,
  Typography,
} from '@sgi/gravity';
import { useCreateVehicleMutation } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import {
  maxYear,
  validationMessages,
  vehicleMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CreateVehicleDTO,
  defaultCreateVehicleDTO,
} from 'Types/CreateVehicleDTO';
import { VinStatus, VinStatusLabel } from 'Types/VehicleDTO';
import {
  FuelCode,
  FuelCodeLabel,
  VinExemptionReason,
  VinExemptionReasonLabel,
} from 'Types/VehicleEnums';
import { vinSchema } from 'Utils/commonValidationSchemas';
import { InputMapper } from 'Utils/InputMapper';
import { patterns } from 'Utils/regex';
import * as Yup from 'yup';

interface VehicleCreateProps {
  parentType: string;
  handleSelectVehicle: any;
  vinFromParent: string | undefined;
}

export const VehicleCreateForm = ({
  parentType,
  handleSelectVehicle,
  vinFromParent,
}: VehicleCreateProps) => {
  const [searchParams] = useSearchParams();
  const vin = searchParams.get('vin');

  if (vin) defaultCreateVehicleDTO.vin = vin.toUpperCase();
  else if (vinFromParent)
    defaultCreateVehicleDTO.vin = vinFromParent.toUpperCase();
  else defaultCreateVehicleDTO.vin = '';

  const [vinExemptionReasonDisable, setVinExemptionReasonDisable] =
    useState(false);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { ...defaultCreateVehicleDTO },
    validationSchema: Yup.object({
      year: Yup.number()
        .typeError(validationMessages.year.type)
        .min(1850, validationMessages.year.range)
        .max(maxYear, validationMessages.year.range)
        .required(validationMessages.year.required)
        .integer(validationMessages.year.invalid),
      make: Yup.string().required(validationMessages.make.required),
      model: Yup.string().required(validationMessages.model.required),
      colour: Yup.string().required(validationMessages.colour.required),
      bodyStyle: Yup.string().required(validationMessages.bodyStyle.required),
      gvwr: Yup.number()
        .typeError(validationMessages.gvwr.type)
        .required(validationMessages.gvwr.required),
      fuelCode: Yup.number()
        .required(validationMessages.fuelCode.required)
        .min(1, validationMessages.fuelCode.required),
      vinExemptionReason: Yup.array()
        .of(Yup.number())
        .when(['year', 'vin'], {
          is: (year: number, vin: string) => year > 1980 && vin?.length < 17,
          then: Yup.array()
            .of(Yup.number())
            .min(1, validationMessages.vinExemptionReason.required),
        }),
    }).concat(vinSchema),
    onSubmit: (values: CreateVehicleDTO) => {
      createVehicle(values);
    },
  });

  useEffect(() => {
    if (
      (formik.values.vin && formik.values.vin.length >= 17) ||
      (formik.values?.year && formik.values?.year <= 1980)
    ) {
      formik.setFieldValue('vinExemptionReason', []);
      setVinExemptionReasonDisable(true);
    } else {
      setVinExemptionReasonDisable(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.vin, formik.values.year]);

  const [
    createVehicle,
    {
      data: createdVehicle,
      error: createVehicleError,
      isSuccess: isCreateVehicleSuccess,
      isError: isCreateVehicleError,
      isLoading: isCreateVehicleLoading,
    },
  ] = useCreateVehicleMutation();

  useEffect(() => {
    if (isCreateVehicleError) {
      toast.error(<ErrorList errors={createVehicleError} />);
    }
  }, [isCreateVehicleError, createVehicleError]);

  useEffect(() => {
    if (isCreateVehicleSuccess) {
      toast.success(vehicleMessages.created);
      if (parentType === 'Page') navigate(`/vehicle/${formik.values?.vin}`);
      else
        handleSelectVehicle({
          id: '',
          vin: formik.values?.vin,
          status: formik.values?.status,
          year: formik.values?.year,
          make: formik.values?.make,
          model: formik.values?.model,
          colour: formik.values?.colour,
          bodyStyle: formik.values?.bodyStyle,
          gvwr: formik.values?.gvwr,
          fuelCode: formik.values?.fuelCode,
          vinExemptionReason: formik.values?.vinExemptionReason,
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateVehicleSuccess, createdVehicle, navigate]);

  //Poplulate fuel code dropdown
  const fuelCodeArr = InputMapper.bindLabelValueWithLabelEnum(
    FuelCode,
    FuelCodeLabel,
    true,
  );

  const vinExemptionReasonArr = InputMapper.bindTextValueWithLabelEnum(
    VinExemptionReason,
    VinExemptionReasonLabel,
    true,
    true,
  );

  return (
    <>
      <LoadingModal show={isCreateVehicleLoading} />
      <Box bg="#FFFFFF" w="100%" p={9} hidden={parentType === 'Modal'}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Create Vehicle</Typography>
          </GridItem>
        </Grid>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box w="100%" p={4}>
          <Box bg="#FFFFFF" w="100%" p={parentType === 'Modal' ? 0 : 9}>
            <Grid
              templateColumns={
                parentType === 'Modal' ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'
              }
              templateRows="repeat(7, 1fr)"
              columnGap={4}
            >
              <GridItem>
                <Input
                  name="vin"
                  data-testid="vehicle-create-vin"
                  placeholder="VIN"
                  hideOptionalLabel
                  size="md"
                  label="VIN"
                  error={formik.touched.vin ? formik.errors.vin : undefined}
                  onInput={(e: any) => {
                    e.target.value = e.target.value.toUpperCase();
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.vin}
                />
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} rowStart={2} rowEnd={2}>
                <Select
                  hideOptionalLabel
                  label="VIN Status"
                  name="status"
                  onChange={formik.handleChange}
                  value={formik.values.status?.toString()}
                  data-testid="vehicle-create-status"
                  options={InputMapper.bindLabelValueWithLabelEnum(
                    VinStatus,
                    VinStatusLabel,
                    true,
                  )}
                  size="md"
                ></Select>
              </GridItem>
              <GridItem colSpan={1} rowSpan={1} rowStart={3} rowEnd={3}>
                <Input
                  name="year"
                  data-testid="vehicle-create-year"
                  placeholder="Year"
                  hideOptionalLabel
                  size="md"
                  label="Year"
                  error={formik.touched.year ? formik.errors.year : undefined}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.target.value = e.target.value.replace(patterns.year, '');
                  }}
                  value={formik.values.year?.toString() || ''}
                />
              </GridItem>
              <GridItem colSpan={1} rowStart={4} rowEnd={4}>
                <Input
                  name="make"
                  data-testid="vehicle-create-make"
                  hideOptionalLabel
                  placeholder="Make"
                  size="md"
                  label="Make"
                  error={formik.touched.make ? formik.errors.make : undefined}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.make}
                />
              </GridItem>
              <GridItem colSpan={1} rowStart={4} rowEnd={4}>
                <Input
                  name="model"
                  data-testid="vehicle-create-model"
                  hideOptionalLabel
                  placeholder="Model"
                  size="md"
                  label="Model"
                  error={formik.touched.model ? formik.errors.model : undefined}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.model}
                />
              </GridItem>
              <GridItem colSpan={1} rowStart={5} rowEnd={5}>
                <Input
                  name="colour"
                  data-testid="vehicle-create-colour"
                  placeholder="Colour"
                  hideOptionalLabel
                  size="md"
                  label="Colour"
                  error={
                    formik.touched.colour ? formik.errors.colour : undefined
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.colour}
                />
              </GridItem>
              <GridItem colSpan={1} rowStart={5} rowEnd={5}>
                <Input
                  name="bodyStyle"
                  data-testid="vehicle-create-bodyStyle"
                  hideOptionalLabel
                  placeholder="Body Style"
                  size="md"
                  label="Body Style"
                  error={
                    formik.touched.bodyStyle
                      ? formik.errors.bodyStyle
                      : undefined
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bodyStyle}
                />
              </GridItem>

              <GridItem colSpan={1} rowStart={6} rowEnd={6}>
                <Input
                  inputRightAddon="Kg"
                  name="gvwr"
                  data-testid="vehicle-create-gvwr"
                  hideOptionalLabel
                  size="md"
                  label="GVWR"
                  formHelperText="Enter Amount in KG"
                  error={formik.touched.gvwr ? formik.errors.gvwr : undefined}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gvwr?.toString() || ''}
                />
              </GridItem>
              <GridItem colSpan={1} rowStart={7} rowEnd={7}>
                <Select
                  name="fuelCode"
                  label="Fuel Type"
                  hideOptionalLabel
                  data-testid="vehicle-create-fuelCode"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fuelCode ? formik.errors.fuelCode : undefined
                  }
                  options={fuelCodeArr}
                />
              </GridItem>
              <GridItem rowStart={8} rowEnd={8}>
                <CheckboxGroup
                  value={formik.values.vinExemptionReason}
                  name="vinExemptionReason"
                  data-testid={`vehicle-create-vinExemptionReason`}
                  variant="checkbox"
                  onChange={(e) => {
                    formik.setFieldValue('vinExemptionReason', Array.from(e));
                  }}
                  error={
                    formik.touched.vinExemptionReason
                      ? formik.errors.vinExemptionReason?.toString()
                      : undefined
                  }
                  label="VIN Exemption"
                  hideOptionalLabel
                >
                  {vinExemptionReasonArr.length > 0 &&
                    vinExemptionReasonArr.map((item, i) => {
                      return (
                        <VStack
                          align="baseline"
                          key={`checkbox-vinExemptionReason-${item.value}`}
                        >
                          <Checkbox
                            isDisabled={vinExemptionReasonDisable}
                            value={item.value}
                            data-testid={`vehicle-create-vinExemptionReason-${item.value}`}
                          >
                            {item.text}
                          </Checkbox>
                        </VStack>
                      );
                    })}
                </CheckboxGroup>
              </GridItem>
            </Grid>
          </Box>

          <Grid templateColumns="repeat(10 , 1fr)" columnGap={4} pt="4">
            <GridItem colStart={1} colEnd={1}>
              <Button
                variant="primary"
                data-testid="vehicle-create-submit"
                type="submit"
                size="md"
              >
                Create Vehicle
              </Button>
            </GridItem>
            <GridItem colStart={2} colEnd={2}>
              {parentType === 'Page' && (
                <Button
                  color="Gray/800"
                  variant="secondary"
                  onClick={() => {
                    navigate('/vehicle/list');
                  }}
                >
                  Back
                </Button>
              )}
            </GridItem>
          </Grid>
        </Box>
      </form>
    </>
  );
};

export default VehicleCreateForm;
