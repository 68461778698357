import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Modal, Table } from '@sgi/gravity';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { VehicleListDTO, VinStatusLabel } from 'Types/VehicleDTO';
import UpdateVinStatusForm from '../UpdateVinStatusForm';

interface VehicleSummaryTableProps {
  vehicles: VehicleListDTO[];
}

export const VehicleSummaryTable = ({ vehicles }: VehicleSummaryTableProps) => {
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Sorting
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'vin',
      desc: false,
    },
  ]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedVIN, setSelectedVIN] = useState<string>('');

  const onClose = () => {
    setIsOpen(false);
  };
  const onOpen = (vin: string) => (event: any) => {
    setSelectedVIN(vin);
    setIsOpen(true);
  };

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [vehicles]);

  const sortVehicles = (a: any, b: any) => {
    // Check to see if there are any columns to sort by
    if (sorting.length === 0) {
      return true;
    }

    if (!sorting[0].desc) {
      return a[sorting[0].id]
        ?.toString()
        .localeCompare(b[sorting[0].id]?.toString());
    } else {
      return b[sorting[0].id]
        ?.toString()
        .localeCompare(a[sorting[0].id]?.toString());
    }
  };

  const columnHelper = createColumnHelper<VehicleListDTO>();
  const columns = [
    columnHelper.accessor('vin', {
      cell: (info) => (
        <Link href={`/vehicle/${info.getValue()}`}>{info.getValue()}</Link>
      ),
      header: () => 'VIN',
      meta: {
        columnName: 'VIN',
      },
    }),
    columnHelper.accessor('status', {
      cell: (info) => <span>{VinStatusLabel[info.getValue()]}</span>,
      header: () => 'Status',
      meta: {
        columnName: 'Status',
      },
    }),
    columnHelper.accessor('id', {
      cell: (info) => (
        <>
          <Link href="#">
            <FontAwesomeIcon
              icon={faPencil}
              size="lg"
              title="Edit VIN Status"
              onClick={onOpen(info.row.original.vin)}
            />
          </Link>
        </>
      ),
      header: () => 'Edit',
      meta: {
        columnName: 'Edit',
      },
    }),
  ] as ColumnDef<VehicleListDTO>[];

  return (
    <>
      <Table
        columns={columns}
        data={[...vehicles]
          .sort((a: any, b: any) => sortVehicles(a, b))
          .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
        data-testid="table-vehicles-list"
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={Math.ceil(vehicles.length / pageSize) ?? -1}
        totalCount={vehicles.length}
        onPageSizeChange={(newPageSize) =>
          setPagination((prev) => ({ pageIndex: 0, pageSize: newPageSize }))
        }
        onPageChange={(page) =>
          setPagination((prev) => ({ ...prev, pageIndex: page }))
        }
        sorting={sorting}
        onSortingChange={setSorting}
      />
      <Modal isOpen={isOpen} onClose={onClose} title="" isCentered={true}>
        <UpdateVinStatusForm
          vin={selectedVIN}
          onClose={onClose}
        ></UpdateVinStatusForm>
      </Modal>
    </>
  );
};

export default VehicleSummaryTable;
