import { Divider, Grid, GridItem, SimpleGrid, VStack } from '@chakra-ui/react';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Modal, Typography } from '@sgi/gravity';
import { useState } from 'react';
import { ContactInfo } from 'Types/InspectionDTO';
import { formatPhone } from 'Utils/Format';
import InspectionDetailsContactDetails from './InspectionDetailsContactDetails';

interface InspectionDetailsContactInfoProps {
  contactInfo: ContactInfo;
}

const InspectionDetailsContactInfo = ({
  contactInfo,
}: InspectionDetailsContactInfoProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onClose() {
    setIsOpen(false);
  }
  function onOpen() {
    setIsOpen(true);
  }

  return (
    <>
      <Modal
        title=""
        isCentered={true}
        isOpen={isOpen}
        onClose={onClose}
        data-testid="modal-InspectionDetails-ContactDetails"
        modalContentProps={{ maxW: '1200px', height: '510px' }}
      >
        <InspectionDetailsContactDetails
          contactInfo={contactInfo}
          onClose={onClose}
        ></InspectionDetailsContactDetails>
      </Modal>

      <VStack alignItems="start">
        <Grid templateColumns="repeat(2,1fr)" w="100%">
          <GridItem>
            <Typography variant="h3">Contact</Typography>
          </GridItem>
          <GridItem style={{ textAlign: 'right' }}>
            <Link href="#" data-testid="lnk-contact-details">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="lg"
                title="View Contact Details"
                onClick={onOpen}
              />
            </Link>
          </GridItem>
        </Grid>

        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Name</Typography>
          <Typography aria-label="contact-fullName">
            {`${contactInfo.firstName} ${contactInfo.lastName}`}
          </Typography>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />

        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Home Phone</Typography>
          <Typography aria-label="contact-homePhone">
            {formatPhone(contactInfo.phoneInfo.homePhone)}
          </Typography>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />

        <SimpleGrid width="100%" columns={2}>
          <Typography variant="bodyBold">Cell Phone</Typography>
          <Typography aria-label="contact-cellPhone">
            {formatPhone(contactInfo.phoneInfo.cellPhone)}
          </Typography>
        </SimpleGrid>
        <Divider color="blackAlpha.100" borderBottom="1px" />
      </VStack>
    </>
  );
};

export default InspectionDetailsContactInfo;
