import {
  Box,
  Flex,
  HStack,
  Image,
  Spacer,
  useStyleConfig,
} from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import SignInSignOutButton from '../SignInSignOutButton';
import SGILogo from './sgi_logo.svg';

export default function AppHeader() {
  const styles = useStyleConfig('Header');

  return (
    <Flex sx={styles} as="header" flexDir="row" p="10px">
      <HStack ml="5">
        <Image
          width="100px"
          src={SGILogo}
          alt="Brand Logo"
          display="inline-block"
        />
        <Typography variant="subheading1">Inspect Tech</Typography>
      </HStack>
      <Spacer />
      <Box gap="2">
        <HStack spacing="7">
          <SignInSignOutButton />
        </HStack>
      </Box>
    </Flex>
  );
}
