import { RecordOfInspectionMessages } from 'constants/messageConstants';
import React from 'react';
import { InspectionDTO } from 'Types/InspectionDTO';
import { InspectionProgram } from 'Types/InspectionEnums';
import {
  BrakeMeasurementDTO,
  BrakeMeasurementUnit,
  BrakeMeasurementUnitLabel,
  BrakeTypes,
  HydraulicBrakeTypeLabel,
  HydraulicBrakeTypes,
  WheelDTO,
} from 'Types/WheelInfoDTO';
import { BusinessFunctions } from 'Utils/BusinessFunctions';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  inspection: InspectionDTO;
}

export const BrakeMeasurements = ({ inspection }: Props) => {
  function getAxleStatus(
    wheels: WheelDTO[],
    conditionAndExternalMeasurements?: boolean,
  ): string {
    if (
      wheels.some(
        (w) =>
          !BusinessFunctions.checkWheelStatus(
            w,
            conditionAndExternalMeasurements,
          ),
      )
    )
      return 'Fail';
    else return 'Pass';
  }

  function getBrakeTypeText(brakeType: BrakeTypes): string {
    let brakeTypeText: string = '';

    if (brakeType === BrakeTypes.HydraulicDisc)
      brakeTypeText =
        HydraulicBrakeTypeLabel[HydraulicBrakeTypes.HydraulicDisc];
    else if (brakeType === BrakeTypes.HydraulicDrum)
      brakeTypeText =
        HydraulicBrakeTypeLabel[HydraulicBrakeTypes.HydraulicDrum];

    return brakeTypeText;
  }

  const tableBreak = () => {
    return (
      <tr className="footer-cell">
        <td className="footer-cell" colSpan={5}>
          &nbsp;
        </td>
      </tr>
    );
  };

  const brakeMeasurementsDetails = (
    bm: BrakeMeasurementDTO,
    unit: BrakeMeasurementUnit | null,
  ): string => {
    if (unit) {
      return ` - ${bm.name}: ${bm.measurement} ${BrakeMeasurementUnitLabel[unit]} `;
    } else {
      return ` - ${bm.name}: ${bm.measurement}`;
    }
  };

  const wheelDetails = (
    wheel: WheelDTO,
    conditionAndExternalMeasurements?: boolean,
  ) => {
    return (
      <td>
        Wheel {wheel.id} -{' '}
        {BusinessFunctions.checkWheelStatus(
          wheel,
          conditionAndExternalMeasurements,
        )
          ? 'Pass'
          : 'Fail'}{' '}
        <br />
        Tire Tread Depth: {wheel.tireTreadDepth} mm
        {/* Brake Measurements */}
        {wheel.nonBrake === false ? (
          <>
            <div>Brake Measurements:</div>
            {wheel.brakeMeasurements.map((bm) => {
              return (
                <React.Fragment key={uuidv4()}>
                  <div key={`brakeMeasurmentsDetails${uuidv4()}`}>
                    {brakeMeasurementsDetails(bm, wheel.brakeMeasurementUnit)}
                  </div>
                </React.Fragment>
              );
            })}
          </>
        ) : (
          <div>No Brake</div>
        )}
        {/* Additional Brake Measurements */}
        {wheel.additionalBrakeMeasurements &&
          wheel.additionalBrakeMeasurements.length > 0 && (
            <>
              <div>Additional Brake Measurements:</div>
              {wheel.additionalBrakeMeasurements.map((bm) => {
                return (
                  <React.Fragment key={uuidv4()}>
                    <div key={`addBrakeMeasurmentsDetails${uuidv4()}`}>
                      {brakeMeasurementsDetails(bm, wheel.brakeMeasurementUnit)}
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}
      </td>
    );
  };

  return (
    <>
      {inspection.wheelInfo &&
        inspection.wheelInfo.axles &&
        inspection.wheelInfo.axles.length > 0 && (
          <>
            <div>Brake/Tire Measurements:</div>
            <div style={{ fontSize: '15px' }}>
              <i>{RecordOfInspectionMessages.BrakeMeasurementsNote1}</i>
            </div>
            <table style={{ border: 'none' }}>
              <tbody>
                {inspection.wheelInfo.axles.map((axle, i) => {
                  return (
                    <React.Fragment key={axle.id}>
                      {i > 0 && tableBreak()}
                      <tr key={`axle${axle.id}`}>
                        <th>
                          Axle {axle.id} -{' '}
                          {getAxleStatus(
                            axle.wheels,
                            axle.conditionAndExternalMeasurements,
                          )}{' '}
                          &nbsp; &nbsp; Brake Type -{' '}
                          {getBrakeTypeText(axle.brakeType)}
                        </th>
                        <th />
                      </tr>
                      {axle.wheels.map((wheel, index) => {
                        return (
                          <React.Fragment key={uuidv4()}>
                            {index % 2 === 0 && (
                              <tr key={`wheel${uuidv4()}`}>
                                {wheelDetails(
                                  wheel,
                                  axle.conditionAndExternalMeasurements,
                                )}
                                {inspection.wheelInfo.axles[i].wheels[
                                  index + 1
                                ] !== undefined ? (
                                  wheelDetails(
                                    inspection.wheelInfo.axles[i].wheels[
                                      index + 1
                                    ],
                                    axle.conditionAndExternalMeasurements,
                                  )
                                ) : (
                                  <td></td>
                                )}
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>

            <table style={{ border: 'none' }}>
              <tbody>
                {(inspection.program === InspectionProgram.LightVehicle ||
                  inspection.wheelInfo.axles.some((a) =>
                    a.wheels.some((w) => w.wheelOffInspection === true),
                  )) && (
                  <tr>
                    <td style={{ borderTop: 'none' }}>
                      <b>{RecordOfInspectionMessages.BrakeMeasurementsNote2}</b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
    </>
  );
};

export default BrakeMeasurements;
