interface Props {
  touched: boolean | undefined;
  error: string | undefined;
  testId?: string;
}

function FormError({ touched, error, testId }: Props) {
  const showError = () => {
    return touched && error ? true : false;
  };

  return (
    <p data-testid={testId} style={{ color: 'red' }}>
      {showError() && error}
    </p>
  );
}

export default FormError;
