import { Box } from '@chakra-ui/react';
import { AxleDTO } from 'Types/WheelInfoDTO';
import { BusinessFunctions } from 'Utils/BusinessFunctions';
import AxleStatusBadge from './AxleStatusBadge';
interface AxleCategoryHeaderBadgesProps {
  axle: AxleDTO;
}

export const AxleCategoryHeaderBadges = ({
  axle,
}: AxleCategoryHeaderBadgesProps) => {
  return (
    <Box p="2">
      <AxleStatusBadge status={BusinessFunctions.checkAxleStatus(axle)} />
    </Box>
  );
};

export default AxleCategoryHeaderBadges;
