import { sortBy } from 'lodash';
import {
  InspectionApprovalStatus,
  InspectionStatus,
} from 'Types/InspectionDTO';

export class InputMapper {
  static bindTextValuePairs(enumType: any, removeEmptyOption?: boolean) {
    let arr = [];

    if (!removeEmptyOption) {
      arr.push({ text: '', value: '' });
    }

    Object.keys(enumType)
      .filter((key) => isNaN(Number(key)))
      .forEach((text) => {
        arr.push({
          text,
          value: enumType[text as keyof typeof enumType].toString(),
        });
      });

    if (enumType === InspectionStatus) {
      arr.forEach(function (part, index) {
        if (part.value.toString() === InspectionStatus.InProgress.toString())
          part.text = 'In Progress';
      }, arr);
    }

    if (enumType === InspectionApprovalStatus) {
      arr.sort((x, y) => {
        return +x.value === 2 ? -1 : +y.value === 2 ? 1 : 0;
      });

      arr.forEach(function (part, index) {
        if (
          part.value.toString() ===
          InspectionApprovalStatus.InProgress.toString()
        )
          part.text = 'In Progress';
        if (
          part.value.toString() ===
          InspectionApprovalStatus.WaitingForApproval.toString()
        )
          part.text = 'Waiting For Approval';
      }, arr);
    }

    return arr;
  }

  static bindTextValueWithLabelEnum(
    enumType: any,
    enumTypeLabel: any,
    sortByLabel?: boolean,
    removeEmptyOption?: boolean,
  ) {
    let arr = [];

    if (!removeEmptyOption) {
      arr.push({ text: '', value: '' });
    }

    Object.keys(enumType)
      .filter((key) => isNaN(Number(key)))
      .forEach((text) => {
        arr.push({
          text: enumTypeLabel[enumType[text as keyof typeof enumType]],
          value: enumType[text as keyof typeof enumType],
        });
      });

    if (sortByLabel) return sortBy(arr, ['text']);
    else return arr;
  }

  static bindLabelValueWithLabelEnum(
    enumType: any,
    enumTypeLabel: any,
    sortByLabel?: boolean,
  ) {
    let arr = [];
    arr.push({ label: '', value: '' });

    Object.keys(enumType)
      .filter((key) => isNaN(Number(key)))
      .forEach((label) => {
        arr.push({
          label: enumTypeLabel[enumType[label as keyof typeof enumType]],
          value: enumType[label as keyof typeof enumType],
        });
      });

    const index = arr.findIndex((x) => x.value === '');
    if (index > -1) {
      arr.splice(index, 1);
    }
    if (sortByLabel) return sortBy(arr, ['label']);
    else return arr;
  }
}
