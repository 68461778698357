import { Grid, GridItem } from '@chakra-ui/react';

interface Props {
  label: string;
  value: string | undefined;
  testId: string;
  labelWidth?: number;
}

function DisplayRow({ label, value, testId, labelWidth }: Props) {
  return (
    <Grid
      templateColumns="repeat(4, 1fr)"
      style={{ border: '1px solid gainsboro', padding: '8px' }}
    >
      <GridItem colSpan={1} style={{ fontWeight: 'bold' }}>
        {label}
      </GridItem>
      <GridItem colSpan={3} data-testid={testId}>
        {value}
      </GridItem>
    </Grid>
  );
}

export default DisplayRow;
