import { Icon } from '@chakra-ui/react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { InspectionApprovalStatus } from 'Types/InspectionDTO';
import { faCheckIcon, faPending, faQuestionIcon } from './Icons';

interface InspectionApprovalStatusBadgeProps {
  approvalStatus: InspectionApprovalStatus;
  size?: SizeProp;
  dataTestId?: string;
}

export const InspectionApprovalStatusBadge = ({
  approvalStatus,
  size = 'lg',
  dataTestId = '',
}: InspectionApprovalStatusBadgeProps) => {
  switch (approvalStatus) {
    case InspectionApprovalStatus.WaitingForApproval:
      return (
        <span title="Waiting For Approval">
          <Icon
            fill="gray.500"
            aria-label="inspection-approval-status-waitingForApproval-badge"
          >
            {faPending}
          </Icon>
        </span>
      );
    case InspectionApprovalStatus.Approved:
      return (
        <span title="Approved">
          <Icon
            fill="green.500"
            aria-label="inspection-approval-status-approved-badge"
          >
            {faCheckIcon}
          </Icon>
        </span>
      );
    case InspectionApprovalStatus.InProgress:
      return (
        <span title="In Progress">
          <Icon
            fill="gray.500"
            aria-label="inspection-approval-status-inProgress-badge"
          >
            {faPending}
          </Icon>
        </span>
      );
    default:
      return (
        <span title="Unknown">
          <Icon
            fill="gray.500"
            aria-label="inspection-approval-status-unknown-badge"
          >
            {faQuestionIcon}
          </Icon>
        </span>
      );
  }
};

export default InspectionApprovalStatusBadge;
