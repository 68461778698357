import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from '@azure/msal-react';
import { Button, LoadingIndicator, Typography } from '@sgi/gravity';
import { loginRequest, logoutRequest } from 'auth/authConfig';
import { removeAllLocalStorageItem } from 'Utils/localStorageUtils';

function SignInSignOutButton() {
  const { instance: msalInstance, inProgress, accounts } = useMsal();
  let account: AccountInfo | null;
  account = useAccount(accounts[0] || {});

  const onHandleSignOut = () => {
    removeAllLocalStorageItem();
    msalInstance.logoutRedirect(logoutRequest);
  };

  if (inProgress !== InteractionStatus.None) {
    return (
      <Button variant="secondary">
        <LoadingIndicator />
      </Button>
    );
  }

  return (
    <>
      <UnauthenticatedTemplate>
        <Button
          title="Sign In"
          onClick={() =>
            msalInstance.loginRedirect({
              ...loginRequest,
              redirectStartPage: '/',
            })
          }
        >
          Sign In
        </Button>
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <Typography variant="subheading1">{account?.name}</Typography>
        <Button onClick={onHandleSignOut}>Sign Out</Button>
      </AuthenticatedTemplate>
    </>
  );
}

export default SignInSignOutButton;
