import VehicleCreateForm from 'App/Vehicle/VehicleCreateForm';

export default function CreateVehiclePage() {
  return (
    <>
      <VehicleCreateForm
        parentType={'Page'}
        handleSelectVehicle={() => function () {}}
        vinFromParent={undefined}
      />
    </>
  );
}
