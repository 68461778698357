import { InspectionDTO, InspectionStatus } from 'Types/InspectionDTO';
import { formatDateString } from 'Utils/dateUtils';

interface Props {
  inspection: InspectionDTO;
}

export const FailedMessagePrintDetails = ({ inspection }: Props) => {
  return (
    <>
      {inspection.status === InspectionStatus.Fail && (
        <>
          <div className="page-break"></div>
          <div className="print-section">
            {`Fail: Vehicle failed inspection. Vehicle is eligible to return to
            this inspection facility until ${formatDateString(
              inspection.returnDate,
            )} for reinspection
            of the failed components.`}
            <br />
            <br />A vehicle that has failed inspection is unsafe for operation
            on public roads. This vehicle should not be operated until the
            repairs or adjustments have been made to restore it to a safe
            operating condition and a qualified technician has reinspected the
            vehicle.
          </div>
        </>
      )}
    </>
  );
};

export default FailedMessagePrintDetails;
