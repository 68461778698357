import { Button, Modal } from '@sgi/gravity';
import { filePreviewMessages } from 'constants/messageConstants';
import { useEffect, useState } from 'react';
import { AttachmentDTO } from 'Types/AttachmentDTO';

interface PreviewAttachmentProps {
  attachment: AttachmentDTO | undefined;
  onPreviewAttachmentModelClose: () => void;
}
export const PreviewAttachmentModal = ({
  attachment,
  onPreviewAttachmentModelClose,
}: PreviewAttachmentProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (attachment) {
      setShowModal(true);
    }
  }, [attachment]);

  function handleClose() {
    setShowModal(false);
    onPreviewAttachmentModelClose();
  }

  return (
    <>
      {attachment && (
        <Modal
          isOpen={showModal}
          onClose={handleClose}
          data-testid="attachment-preview-dialog"
          title={'Attachment Preview'}
          showCloseButton={false}
          //@ts-ignore
          maxW="1024px"
          footer={
            <Button
              data-testid={`attachment-preview-dialog-close`}
              onClick={handleClose}
            >
              Close
            </Button>
          }
        >
          <>
            {(() => {
              if (attachment.mimeType.includes('image/')) {
                return (
                  <img
                    alt="Attachment Preview"
                    src={`data:${attachment.mimeType};base64,${attachment.blobContent}#toolbar=0#view=FitH`}
                    height="500"
                    width="100%"
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                    data-testid="attachment-preview-img"
                  ></img>
                );
              } else if (attachment.mimeType.includes('/pdf')) {
                return (
                  <embed
                    type="text/html"
                    src={`data:${attachment.mimeType};base64,${attachment.blobContent}#toolbar=0`}
                    height="500"
                    width="100%"
                    onContextMenu={(e) => {
                      e.preventDefault();
                    }}
                    data-testid="attachment-preview-embed"
                  ></embed>
                );
              } else {
                return (
                  <div data-testid="attachment-preview-error-unsupported">
                    {filePreviewMessages.previewFileNotSupportedError}
                  </div>
                );
              }
            })()}
          </>
        </Modal>
      )}
    </>
  );
};
