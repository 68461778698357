import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { useCreateReInspectionMutation } from 'api/InspectTechUIAPI';
import { useGetAttachmentQuery } from 'api/InspectTechUIFileUploadAPI';
import ErrorList from 'App/Common/ErrorList';
import { faArrowLeftIcon, faEyeIcon, faFileLinesIcon } from 'App/Common/Icons';
import { PreviewAttachmentModal } from 'App/Common/PreviewAttachmentModal';
import { inspectionMessages } from 'constants/messageConstants';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateInspectionHelperDetailsDTO } from 'Types/CreateInspectionDTO';
import { CreateReInspectionDTO } from 'Types/CreateReInspectionDTO';
import {
  InspectionDTO,
  Question,
  QuestionTypeLabel,
} from 'Types/InspectionDTO';
import ConfirmDetails from './ConfirmDetails';
interface ConfirmReInspectionProps {
  step: number;
  setStep: Function;
  inspection: InspectionDTO;
  createDTO: CreateReInspectionDTO;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
}

interface GroupedQuestion {
  type: number;
  questions: Question[];
}
const ConfirmReInspection = ({
  step,
  setStep,
  inspection,
  createDTO,
  inspectionHelperDetailsDTO,
}: ConfirmReInspectionProps) => {
  const navigate = useNavigate();

  const groupQuestions = () => {
    var result = _(inspection.questions)
      .groupBy((x) => x.type)
      .map((value, key) => ({
        type: Number(key),
        questions: value,
      }))
      .value();
    return result;
  };
  const [groupedQuestions] = useState<GroupedQuestion[]>(groupQuestions());
  const [, setSkip] = useState(true);
  const [attachmentName, setAttachmentName] = useState('');

  const {
    data: attachment,
    isSuccess: isGetAttachmentSuccess,
    isError: isGetAttachmentError,
    error: getAttachmentError,
  } = useGetAttachmentQuery(attachmentName, {
    skip: attachmentName ? false : true,
  });

  useEffect(() => {
    if (isGetAttachmentError && getAttachmentError) {
      toast.error(<ErrorList errors={getAttachmentError} />);
    }
  }, [isGetAttachmentError, getAttachmentError]);
  const handleSubmit = () => {
    createReInspection(createDTO);
  };

  const [
    createReInspection,
    {
      data: createdReInspection,
      isError: isCreateReInspectionError,
      error: createReInspectionError,
      isSuccess: isCreateReInspectionSuccess,
    },
  ] = useCreateReInspectionMutation();

  useEffect(() => {
    if (isCreateReInspectionError) {
      toast.error(<ErrorList errors={createReInspectionError} />);
    }
  }, [isCreateReInspectionError, createReInspectionError]);

  useEffect(() => {
    if (isCreateReInspectionSuccess) {
      toast.success(inspectionMessages.created);
      navigate(`/inspection/${createdReInspection!.id}/${inspection.vin}`);
    }
  }, [
    isCreateReInspectionSuccess,
    createdReInspection,
    navigate,
    inspection.vin,
  ]);

  return (
    <>
      {attachment && isGetAttachmentSuccess && (
        <PreviewAttachmentModal
          attachment={attachment}
          onPreviewAttachmentModelClose={() => {
            setAttachmentName('');
            setSkip(true);
          }}
        />
      )}
      <Box>
        <VStack align="flex-start">
          <ConfirmDetails
            inspection={{
              ...inspection,
              odometer: createDTO.odometer ?? inspection.odometer,
              odometerUoD: createDTO.odometerUoD,
            }}
            inspectionHelperDetailsDTO={inspectionHelperDetailsDTO}
          />
          <VStack alignItems="start" width="100%">
            {groupedQuestions &&
              groupedQuestions.length > 0 &&
              Object.keys(groupedQuestions).map(
                (value: string, key: number) => (
                  <Card
                    key={`${value}${key}`}
                    cardStyleProps={{ width: '100%', padding: '0' }}
                  >
                    <VStack>
                      <Box
                        w="100%"
                        padding="8px 16px"
                        borderTopRadius={8}
                        bg={'gray.100'}
                      >
                        <VStack
                          justify={'space-between'}
                          alignItems="flex-start"
                        >
                          <Typography variant="subheading2">
                            {
                              QuestionTypeLabel[
                                groupedQuestions[key]
                                  .type as keyof typeof QuestionTypeLabel
                              ]
                            }
                          </Typography>
                        </VStack>
                      </Box>
                      <Box w="100%" padding="8px 16px">
                        <>
                          {groupedQuestions[key].questions.map(
                            (question, index) => (
                              <Box width="100%" key={index}>
                                <HStack alignItems="start" w="100%" p="16px">
                                  <Typography
                                    data-testid={`question-text-${question.text}`}
                                  >
                                    {question.text}
                                  </Typography>
                                  <Typography variant="bodyBold">
                                    {question.response ? 'Yes' : 'No'}
                                  </Typography>
                                </HStack>

                                <VStack alignItems="flex-start" p="16px">
                                  {question.response &&
                                    question.attachments &&
                                    question.attachments?.length > 0 &&
                                    question.attachments.map(
                                      (attachment, attIndex) => {
                                        return (
                                          <VStack
                                            alignItems="flex-start"
                                            w="100%"
                                            key={`${index}-${attIndex}`}
                                          >
                                            <Card
                                              cardStyleProps={{
                                                width: '100%',
                                                padding: '0',
                                              }}
                                            >
                                              <Grid
                                                data-testid={`uploaded-files`}
                                                templateColumns="repeat(5, 1fr)"
                                                p="8px 16px"
                                                gap="16px"
                                                w="100%"
                                                bg="gray.50"
                                              >
                                                <GridItem colSpan={4}>
                                                  <HStack alignItems="center">
                                                    <Icon
                                                      aria-label={`view-file-${index}}`}
                                                    >
                                                      {faFileLinesIcon}
                                                    </Icon>
                                                    <Typography variant="bodySmall">
                                                      {
                                                        attachment.clientFileName
                                                      }
                                                    </Typography>
                                                  </HStack>
                                                </GridItem>
                                                <GridItem>
                                                  <HStack
                                                    justify={'end'}
                                                    alignItems="center"
                                                  >
                                                    <Button
                                                      type="button"
                                                      rightIcon={
                                                        <Icon
                                                          fill="teal.500"
                                                          _hover={{
                                                            color: 'teal.600',
                                                          }}
                                                          aria-label={`view-file-${index}`}
                                                        >
                                                          {faEyeIcon}
                                                        </Icon>
                                                      }
                                                      isDestructive={false}
                                                      variant="tertiary"
                                                      onClick={() => {
                                                        setAttachmentName(
                                                          attachment.storageFileName,
                                                        );
                                                        setSkip(false);
                                                      }}
                                                    >
                                                      View
                                                    </Button>
                                                  </HStack>
                                                </GridItem>
                                              </Grid>
                                            </Card>
                                          </VStack>
                                        );
                                      },
                                    )}
                                  {!question.response &&
                                    question.reason &&
                                    question.reason !== '' && (
                                      <Box width="100%">
                                        <VStack alignItems="start">
                                          <Typography variant="bodyBold">
                                            Comment:
                                          </Typography>
                                          <Typography
                                            data-testid={`question-reason-${question.code}`}
                                          >
                                            {question.reason}
                                          </Typography>
                                        </VStack>
                                      </Box>
                                    )}
                                </VStack>
                              </Box>
                            ),
                          )}
                        </>
                      </Box>
                    </VStack>
                  </Card>
                ),
              )}
          </VStack>
          <HStack w="100%" justifyContent="space-between">
            <Button
              variant="secondary"
              leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
              onClick={() => setStep(step - 1)}
            >
              Previous step
            </Button>
            <Button data-testid="CreateReInspection" onClick={handleSubmit}>
              Create ReInspection
            </Button>
          </HStack>
        </VStack>
      </Box>
    </>
  );
};

export default ConfirmReInspection;
