import { Grid, GridItem } from '@chakra-ui/react';
import { Button, Modal } from '@sgi/gravity';
import * as React from 'react';
import { useState } from 'react';

import { useReactToPrint } from 'react-to-print';

interface Props {
  componentToPrint: JSX.Element;
  title?: string;
  buttonText?: string;
  documentName?: string;
  testId?: string;
}

export const PrintModal = ({
  title,
  buttonText,
  documentName,
  componentToPrint,
  testId,
}: Props) => {
  const componentRef = React.useRef(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onClose() {
    setIsOpen(false);
  }
  function onOpen() {
    setIsOpen(true);
  }
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef]);

  const getPageMargins = () => {
    return `@page { margin: 100px 50px !important; }`;
  };

  const getPageStyles = () => {
    return `
    .watermark {
        /* Used to position the watermark */
        position: relative;
    }
    
    .watermark__inner {
        /* Center the content */
        align-items: center;
        display: flex;
        justify-content: center;
    
        /* Absolute position */
        left: 0px;
        position: absolute;
        top: 200px;
    
        /* Take full size */
        height: 100%;
        width: 100%;
    }
    
    .watermark__body {
        /* Text color */
        color: rgba(0, 0, 0, 0.2);
    
        /* Text styles */
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;
    
        /* Rotate the text */
        transform: rotate(-45deg);
    
        /* Disable the selection */
        user-select: none;
    }

    .print-section {
      border: 2px solid;
      margin-top: 8px;
      padding: 0px 15px;
    }

    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-after: always;
    }

    table {
      width: 100%;
    }
    
    th,
    td {
      border: 2px solid;
      border-collapse: collapse;
      padding: 0px 10px;
    }
    
    .footer,
    .footer-space {
      height: 0px;
    }
    
    .footer-cell {
      border: none !important;
    }
    
    .status-col {
      text-align: center;
      padding-left: 0px !important;
    }
    `;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle:
      documentName ?? `InspectTech Document - ${new Date().toDateString()}`,
    removeAfterPrint: true,
  });

  const footer = (
    <React.Fragment>
      <Grid templateColumns="repeat(2, 1fr)" columnGap={2} rowGap={2}>
        <GridItem>
          <Button onClick={handlePrint}>Print</Button>
        </GridItem>
        <GridItem>
          <Button
            data-testid={testId ? `${testId}-close` : ''}
            variant="secondary"
            onClick={onClose}
          >
            Close
          </Button>
        </GridItem>
      </Grid>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <style>
        {getPageMargins()}
        {getPageStyles()}
      </style>
      <Button
        variant="secondary"
        onClick={onOpen}
        data-testid={testId ? `${testId}-button` : ''}
      >
        {buttonText ?? 'Print'}
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={title ?? 'Print Preview'}
        modalContentProps={{ maxW: '1024px', height: '645px' }}
        isCentered={true}
        scrollBehavior={'inside'}
        footer={footer}
        data-testid={testId ? `${testId}-title` : ''}
      >
        <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <div ref={componentRef}>{componentToPrint}</div>
        </div>
      </Modal>
    </React.Fragment>
  );
};
