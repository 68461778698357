export enum InspectionType {
  Mechanical = 'Mechanical',
  Structural = 'Structural',
}

export const InspectionTypeLabel: {
  [key in InspectionType]: string;
} = {
  [InspectionType.Mechanical]: 'Mechanical',
  [InspectionType.Structural]: 'Structural',
};

export enum InspectionProgram {
  LightVehicle = 'Light Vehicle',
  MidSizeTruck = 'Mid Size Truck',
  HeavyTruck = 'Heavy Truck',
  PowerUnit = 'Power Unit',
  SemiTrailer = 'Semi Trailer',
  RegularBus = 'Regular Bus',
  HighwayCoach = 'Highway Coach',
  SchoolBus = 'School Bus',
  MotorCycle = 'Motorcycle',
  Trailer = 'Trailer',
  BodyIntegrity = 'Body Integrity',
}

export enum UnitOfDistance {
  KM = 0,
  Miles = 1,
}

export enum InspectionJurisdiction {
  Default = 0,
  Alberta = 1,
  BritishColumbia = 2,
  Manitoba = 3,
  NewBrunswick = 4,
  NewfoundlandAndLabrador = 5,
  NorthwestTerritories = 6,
  NovaScotia = 7,
  Nunavut = 8,
  Ontario = 9,
  PrinceEdwardIsland = 10,
  Quebec = 11,
  Saskatchewan = 12,
  Yukon = 13,
  UnitedStates = 14,
  OtherCountry = 15,
}

export const InspectionJurisdictionLabel: {
  [key in InspectionJurisdiction]: string;
} = {
  [InspectionJurisdiction.Default]: '',
  [InspectionJurisdiction.Alberta]: 'Alberta',
  [InspectionJurisdiction.BritishColumbia]: 'British Columbia',
  [InspectionJurisdiction.Manitoba]: 'Manitoba',
  [InspectionJurisdiction.NewBrunswick]: 'New Brunswick',
  [InspectionJurisdiction.NewfoundlandAndLabrador]: 'Newfoundland and Labrador',
  [InspectionJurisdiction.NorthwestTerritories]: 'Northwest Territories',
  [InspectionJurisdiction.NovaScotia]: 'Nova Scotia',
  [InspectionJurisdiction.Nunavut]: 'Nunavut',
  [InspectionJurisdiction.Ontario]: 'Ontario',
  [InspectionJurisdiction.PrinceEdwardIsland]: 'Prince Edward Island',
  [InspectionJurisdiction.Quebec]: 'Quebec',
  [InspectionJurisdiction.Saskatchewan]: 'Saskatchewan',
  [InspectionJurisdiction.Yukon]: 'Yukon',
  [InspectionJurisdiction.UnitedStates]: 'United States',
  [InspectionJurisdiction.OtherCountry]: 'Other Country',
};
