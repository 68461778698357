import { Flex } from '@chakra-ui/react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LoadingModalProps {
  show: boolean;
}

const LoadingModal = ({ show }: LoadingModalProps) => {
  return (
    <>
      {show && (
        <Flex
          w="100%"
          h="100%"
          position={'fixed'}
          top={'0px'}
          left={'0px'}
          background="blackAlpha.500"
          zIndex={1400}
          alignItems="center"
          justify="center"
        >
          <FontAwesomeIcon icon={faSpinner} size="4x" className="fa-spin" />
        </Flex>
      )}
    </>
  );
};

export default LoadingModal;
