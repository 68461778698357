import { ContactAddressDTO, ContactPhoneDTO, defaultContactAddressDTO, defaultContactPhoneDTO } from './ContactDTO';

export interface UpdateContactDTO {
  id: string;
  firstName: string;
  lastName: string;
  address: ContactAddressDTO;
  phoneInfo: ContactPhoneDTO;
  email: string | undefined;
}

export const defaultUpdateContactDTO: UpdateContactDTO = {
  id: '',
  firstName: '',
  lastName: '',
  address: defaultContactAddressDTO,
  phoneInfo: defaultContactPhoneDTO,
  email: '',
};
