import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Typography } from '@sgi/gravity';
import { useNavigate } from 'react-router-dom';
import { InspectionDTO } from 'Types/InspectionDTO';
import { formatDateString } from 'Utils/dateUtils';

interface InspectionHeaderProps {
  inspection: InspectionDTO;
}

const InspectionDetailsHeader = ({ inspection }: InspectionHeaderProps) => {
  const navigate = useNavigate();

  return (
    <Box bg="#FFFFFF" w="100%" p={9}>
      <Grid
        templateColumns="repeat(3,1fr)"
        templateRows="repeat(3,1fr)"
        gap={4}
      >
        <GridItem colSpan={3} rowSpan={2}>
          <Typography variant="h1">
            {`${
              inspection.previousInspectionId ? 'Rei' : 'I'
            }nspection Details`}
          </Typography>
        </GridItem>
        <GridItem colSpan={2}>
          <HStack spacing={16} alignItems="start">
            <VStack align="flex-start">
              <Typography variant="bodyBold">VIN</Typography>
              <Typography variant="body" aria-label="header-vehicle-vin">
                {inspection.vin.toUpperCase()}
              </Typography>
            </VStack>
            <VStack align="flex-start">
              <Typography variant="bodyBold">Program</Typography>
              <Typography variant="body" aria-label="header-inspection-program">
                {inspection.program}
              </Typography>
            </VStack>
            <VStack align="flex-start">
              <Typography variant="bodyBold">Type</Typography>
              <Typography variant="body" aria-label="header-inspection-type">
                {inspection.type}
              </Typography>
            </VStack>
            <VStack align="flex-start">
              <Typography variant="bodyBold">Plate Number</Typography>
              <Typography variant="body" aria-label="header-vehicle-plate">
                {inspection.plate}
              </Typography>
            </VStack>
            <VStack align="flex-start" justify="start">
              <Typography variant="bodyBold">Start Date</Typography>
              <Typography
                variant="body"
                aria-label="header-inspection-startDate"
              >
                {inspection.startDate && formatDateString(inspection.startDate)}
              </Typography>
            </VStack>
          </HStack>
        </GridItem>
        <GridItem colSpan={1}>
          <div>
            {inspection.previousInspectionId && (
              <span style={{ margin: '0px 5px' }}>
                <Button
                  variant="secondary"
                  data-testid="view-previous-inspection-button"
                  onClick={() => {
                    navigate(
                      `/inspection/${inspection.previousInspectionId}/${inspection.vin}`,
                    );
                  }}
                >
                  View Previous Inspection
                </Button>
              </span>
            )}
            {inspection.reInspectionId && (
              <span style={{ margin: '0px 5px' }}>
                <Button
                  variant="secondary"
                  data-testid="view-reinspection-button"
                  onClick={() => {
                    navigate(
                      `/inspection/${inspection.reInspectionId}/${inspection.vin}`,
                    );
                  }}
                >
                  View Reinspection
                </Button>
              </span>
            )}
          </div>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default InspectionDetailsHeader;
