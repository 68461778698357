import {
  Box,
  Divider,
  Grid,
  GridItem,
  HStack,
  Icon,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  Alert,
  Button,
  Card,
  Modal,
  TabDataProps,
  Tabs,
  TextArea,
  Typography,
  useToast,
} from '@sgi/gravity';
import {
  useApproveInspectionMutation,
  useCompleteInspectionMutation,
  useGetInspectionByIdVinQuery,
  useGetInspectionQuestionsQuery,
  useStartInspectionMutation,
  useUndoCompleteInspectionMutation,
  useUpdateInspectionItemsAndWheelInfoMutation,
  useUpdateQuestionsMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import { faPencil } from 'App/Common/Icons';
import LoadingModal from 'App/Common/LoadingModal';
import { PrintModal } from 'App/Common/PrintModal';
import UnsavedBlockerDialogBox from 'App/Common/UnsavedBlockerDialogBox';
import {
  brakeInspectionMessages,
  InspectionItemText,
  inspectionMessages,
  validationMessages,
} from 'constants/messageConstants';
import {
  QuestionCodes,
  reinspectionExtensionDays,
} from 'constants/variableConstants';
import { getIn, useFormik } from 'formik';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { isEqual } from 'lodash';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CompleteInspectionDTO } from 'Types/CompleteInspectionDTO';
import {
  CommentDTO,
  InspectionApprovalStatus,
  InspectionBodyDTO,
  InspectionItemDTO,
  InspectionItemStatus,
  InspectionStatus,
  Question,
  QuestionType,
} from 'Types/InspectionDTO';
import { InspectionProgram, InspectionType } from 'Types/InspectionEnums';
import { UndoCompleteInspectionDTO } from 'Types/UndoCompleteInspectionDTO';
import { UpdateInspectionItemsAndWheelnfoDTO } from 'Types/UpdateInspectionItemsAndWheelnfoDTO';
import { AxleDTO, HydraulicBrakeTypes, WheelInfoDTO } from 'Types/WheelInfoDTO';
import { BusinessFunctions } from 'Utils/BusinessFunctions';
import * as Yup from 'yup';
import AxlesCategory from './components/Brakes/Axles/AxlesCategory';
import CTInspectionQuestions from './components/CTQuestions/CTInspectionQuestions';
import InspectionDetailsContactInfo from './components/InspectionDetailsContactInfo';
import InspectionDetailsHeader from './components/InspectionDetailsHeader';
import InspectionDetailsStatusInfo from './components/InspectionDetailsStatusInfo';
import InspectionDetailsVehicleInfo from './components/InspectionDetailsVehicleInfo';
import InspectionItemsCategory from './components/InspectionItemsCategory/InspectionItemsCategory';
import InspectionProgressBar from './components/InspectionProgressBar';
import InspectionQAs from './components/InspectionQAs';
import { ProofOfInspection } from './components/Print/ProofOfInspection';
import RecordOfInspection from './components/Print/RecordOfInspection';
import { WorkPlan } from './components/Print/WorkPlan';

export const Inspection = () => {
  const [isApproved, setIsApproved] = useState(false);
  const { inspectionId } = useParams<'inspectionId'>();
  const { vin } = useParams<'vin'>();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const { showToast } = useToast();
  const [
    showCTPostInspectionQuestionsDialog,
    setShowCTPostInspectionQuestionsDialog,
  ] = useState<boolean>(false);
  const [complete, setComplete] = useState(false);
  const [skip, setSkip] = useState(true);

  const {
    data: inspection,
    isFetching: isGetInspectionFetching,
    isSuccess: isGetInspectionSuccess,
    isLoading: isLoadingGetInspection,
  } = useGetInspectionByIdVinQuery({
    inspectionId: inspectionId ?? '',
    vin: vin ?? '',
  });

  const [inspectionCategories, setInspectionCategories] =
    useState<UpdateInspectionItemsAndWheelnfoDTO>({
      wheelInfo: inspection?.wheelInfo ?? null!,
      vin: inspection?.vin ?? '',
      id: inspection?.id ?? '',
      inspectionProgram: inspection?.program ?? '',
      dataVersion: inspection?.dataVersion ?? undefined,
      body: inspection?.body ?? [],
      additionalNote: inspection?.additionalNote,
    });

  const [
    updateInspectionItemsAndWheelInfo,
    {
      isSuccess: isUpdateInspectionItemsAndWheelInfoSuccess,
      isError: isUpdateInspectionItemsAndWheelInfoError,
      error: updateInspectionItemsAndWheelInfoError,
      isLoading: isLoadingUpdateInspectionItemsAndWheelInfo,
    },
  ] = useUpdateInspectionItemsAndWheelInfoMutation();

  const [
    completeInspection,
    {
      data: completedInspection,
      isSuccess: isCompleteInspectionSuccess,
      isError: isCompleteInspectionError,
      error: completeInspectionError,
      isLoading: isLoadingCompleteInspection,
    },
  ] = useCompleteInspectionMutation();

  const callCompleteInspection = () => {
    const completeInspectionDTO: CompleteInspectionDTO = {
      id: inspection?.id ?? '',
      vin: inspection?.vin ?? '',
      dataVersion: inspection?.dataVersion,
    };
    completeInspection(completeInspectionDTO);
  };

  const [
    undoCompleteInspection,
    {
      data: undoCompletedInspection,
      isSuccess: isUndoCompleteInspectionSuccess,
      isError: isUndoCompleteInspectionError,
      error: undoCompleteInspectionError,
      isLoading: isLoadingUndoCompleteInspection,
    },
  ] = useUndoCompleteInspectionMutation();

  const [
    approveInspection,
    {
      data: approvedInspection,
      isSuccess: isApproveInspectionSuccess,
      isError: isApproveInspectionError,
      error: approveInspectionError,
      isLoading: isLoadingApproveInspection,
    },
  ] = useApproveInspectionMutation();

  const [startInspection] = useStartInspectionMutation();

  const disableButtons = useCallback((): boolean => {
    let result: boolean = false;

    if (inspection?.body && inspection.body.length > 0) {
      for (let i = 0; i < inspection?.body.length; i++) {
        if (result) break;
        const category = inspection?.body[i];

        for (let j = 0; j < category.items.length; j++) {
          const item = category.items[j];

          if (item.status === InspectionItemStatus.Default) {
            result = true;
            break;
          }
        }
      }
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection?.body, inspection?.wheelInfo]);

  useEffect(() => {
    if (isGetInspectionSuccess) {
      const objUpdateInspectionItemsAndWheelnfoDTO: UpdateInspectionItemsAndWheelnfoDTO =
        {
          wheelInfo: inspection.wheelInfo,
          vin: inspection.vin,
          id: inspection.id,
          inspectionProgram: inspection.program,
          dataVersion: inspection.dataVersion,
          body: inspection.body,
          additionalNote: inspection?.additionalNote,
        };

      setIsApproved(
        inspection.approvalStatus === InspectionApprovalStatus.Approved,
      );
      setInspectionCategories(objUpdateInspectionItemsAndWheelnfoDTO);

      if (
        inspection.questions.findIndex((q) => q.response == null) === -1 &&
        inspection.completedDate == null &&
        inspection.approvalStatus === InspectionApprovalStatus.InProgress &&
        complete === true
      ) {
        callCompleteInspection();
        setComplete(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetInspectionSuccess, inspection, disableButtons]);

  useEffect(() => {
    if (isCompleteInspectionSuccess && completedInspection) {
      showToast({
        description: inspectionMessages.completed,
        status: 'success',
      });
      setIsApproved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleteInspectionSuccess, completedInspection]);

  useEffect(() => {
    if (isCompleteInspectionError && completeInspectionError) {
      toast.error(<ErrorList errors={completeInspectionError} />);
    }
  }, [isCompleteInspectionError, completeInspectionError]);

  useEffect(() => {
    if (isUndoCompleteInspectionSuccess && undoCompletedInspection) {
      toast.success(inspectionMessages.undoComplete);
      setIsApproved(true);
    }
  }, [isUndoCompleteInspectionSuccess, undoCompletedInspection]);

  useEffect(() => {
    if (isUndoCompleteInspectionError && undoCompleteInspectionError) {
      toast.error(<ErrorList errors={undoCompleteInspectionError} />);
    }
  }, [isUndoCompleteInspectionError, undoCompleteInspectionError]);

  useEffect(() => {
    if (isApproveInspectionSuccess && approvedInspection) {
      showToast({
        description: inspectionMessages.approved,
        status: 'success',
      });
      setIsApproved(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveInspectionSuccess, approvedInspection]);

  useEffect(() => {
    if (isApproveInspectionError && approveInspectionError) {
      showToast({
        description: <ErrorList errors={approveInspectionError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApproveInspectionError, approveInspectionError]);

  useEffect(() => {
    if (isUpdateInspectionItemsAndWheelInfoSuccess) {
      setShowDialog(false);
      toast.success(inspectionMessages.itemsSaved);
    }
  }, [isUpdateInspectionItemsAndWheelInfoSuccess]);

  useEffect(() => {
    if (isUpdateInspectionItemsAndWheelInfoError)
      toast.error(
        <ErrorList key={1} errors={updateInspectionItemsAndWheelInfoError} />,
      );
  }, [
    isUpdateInspectionItemsAndWheelInfoError,
    updateInspectionItemsAndWheelInfoError,
  ]);
  const brakeMeasurementSchema = Yup.object().shape({
    measurement: Yup.number()
      .nullable(true)
      .required(validationMessages.brakeMeasurement.required),
  });

  const formik = useFormik({
    initialValues: { ...inspectionCategories },
    validationSchema: Yup.object().shape({
      body: Yup.array().of(
        Yup.object({
          items: Yup.array().of(
            Yup.object({
              questions: Yup.array().of(
                Yup.object({
                  response: Yup.boolean()
                    .nullable()
                    .when('code', {
                      is: (code: string) =>
                        code !==
                          QuestionCodes.iiQuestionBodyMeasurementInvCopies &&
                        code !== QuestionCodes.iiQuestionEvidenceOfRepairs,
                      then: Yup.boolean()
                        .nullable()
                        .typeError(validationMessages.Question.response)
                        .required(validationMessages.Question.response),
                    })
                    .when(['code', 'inspectionItemStatus'], {
                      is: (code: string, inspectionItemStatus: any) =>
                        code ===
                          QuestionCodes.iiQuestionBodyMeasurementInvCopies &&
                        formik.values?.body[0].items[1].status === 1 &&
                        inspection?.previousInspectionId != null,

                      then: Yup.boolean()
                        .nullable()
                        .typeError(validationMessages.Question.response)
                        .required(validationMessages.Question.response),
                    })
                    .when('code', {
                      is: (code: string) =>
                        code === QuestionCodes.iiQuestionEvidenceOfRepairs &&
                        inspection?.previousInspectionId == null &&
                        formik.values?.body[0].items[1].status ===
                          InspectionItemStatus.Pass &&
                        formik.values?.body[0].items[1].questions?.find(
                          (q) =>
                            q.code ===
                            QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
                        )?.response === true,
                      then: Yup.boolean()
                        .nullable()
                        .typeError(validationMessages.Question.response)
                        .required(validationMessages.Question.response),
                    }),
                  reason: Yup.string()
                    .nullable()
                    .when(['response', 'code'], {
                      is: (response: boolean, code: string) =>
                        response === false &&
                        code !==
                          QuestionCodes.iiQuestionRepairedPriorToInitialInspection &&
                        code === QuestionCodes.iiQuestionEvidenceOfRepairs &&
                        formik.values?.body[0].items[1].questions?.find(
                          (q) =>
                            q.code ===
                            QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
                        )?.response === true,
                      then: Yup.string()
                        .nullable()
                        .required(validationMessages.Question.reason),
                    }),
                  attachments: Yup.array()
                    .nullable()
                    .when(['response', 'code'], {
                      is: (response: boolean, code: string) =>
                        response === true &&
                        code !==
                          QuestionCodes.iiQuestionRepairedPriorToInitialInspection &&
                        code === QuestionCodes.iiQuestionEvidenceOfRepairs &&
                        formik.values?.body[0].items[1].questions?.find(
                          (q) =>
                            q.code ===
                            QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
                        )?.response === true,
                      then: Yup.array()
                        .nullable()
                        .required(validationMessages.Question.attachments)
                        .min(1, validationMessages.Question.attachments),
                    }),
                }),
              ),
              //requiredField: boolean(),
              additionalFields: Yup.object()
                .when(['name', 'status', 'wasPreviouslyRejected'], {
                  is: (
                    name: string,
                    status: number,
                    wasPreviouslyRejected: boolean,
                  ) =>
                    name ===
                      InspectionItemText.BodyAndStructuralMeasurements.title &&
                    status === InspectionItemStatus.Pass &&
                    wasPreviouslyRejected,
                  then: Yup.object({
                    repairDetailNote: Yup.string()
                      .nullable()
                      .required(
                        validationMessages.AdditionalFields.RepairDetailNote,
                      ),
                    // ...etc
                  }),
                })
                .when(['name', 'status', 'questions'], {
                  is: (name: string, status: number, question: Question[]) =>
                    inspection?.program === InspectionProgram.BodyIntegrity &&
                    inspection?.previousInspectionId == null &&
                    name ===
                      InspectionItemText.BodyAndStructuralMeasurements.title &&
                    status === InspectionItemStatus.Pass &&
                    question.find(
                      (q) =>
                        q.code ===
                        QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
                    )?.response === true,
                  then: Yup.object({
                    repairDetailNote: Yup.string()
                      .nullable()
                      .required(
                        validationMessages.AdditionalFields.RepairDetailNote,
                      ),
                  }),
                }),
              comment: Yup.string()
                .max(250, validationMessages.inspectionItemComment.length)
                .when(['status', 'commentHistory'], {
                  is: (status: any, commentHistory: CommentDTO[]) => {
                    const sorted = commentHistory
                      ? [...commentHistory].sort((a, b) => {
                          return (
                            new Date(b.date).getTime() -
                            new Date(a.date).getTime()
                          );
                        })
                      : [];

                    if (
                      sorted.length > 0 &&
                      ((sorted[0].effectiveStatus === 3 &&
                        Number(status) === 3) ||
                        (sorted[0].effectiveStatus === 4 &&
                          Number(status) === 4))
                    ) {
                      return false;
                    }
                    return Number(status) === 3 || Number(status) === 4;
                  },
                  then: Yup.string().required(
                    validationMessages.inspectionItemComment.required,
                  ),
                })
                .when(['name', 'status'], {
                  is: (name: any, status: any) =>
                    Number(status) === 2 &&
                    name ===
                      InspectionItemText.BodyAndStructuralMeasurements.title &&
                    inspection?.program === InspectionProgram.BodyIntegrity,
                  then: Yup.string()
                    .required(validationMessages.inspectionItemRepair.required)
                    .max(250, validationMessages.inspectionItemRepair.length),
                }),
            }),
          ),
        }),
      ),
      wheelInfo: Yup.object({
        axles: Yup.array().of(
          Yup.object({
            wheels: Yup.array().of(
              Yup.object({
                tireTreadDepth: Yup.number()
                  .nullable(true)
                  .when('passesTreadDepth', {
                    is: (passesTreadDepth: boolean) =>
                      passesTreadDepth === true,
                    then: Yup.number()
                      .nullable(true)
                      .required(
                        validationMessages.wheelTireTreadDepth.required,
                      ),
                  }),
                brakeMeasurementUnit: Yup.number()
                  .nullable(true)
                  .when('passesBrakeMeasurements', {
                    is: (passesBrakeMeasurements: boolean) =>
                      passesBrakeMeasurements === true,
                    then: Yup.number()
                      .nullable(true)
                      .required(validationMessages.brakeUnit.required),
                  }),
                brakeMeasurements: Yup.array().when('passesBrakeMeasurements', {
                  is: (passesBrakeMeasurements: boolean) =>
                    passesBrakeMeasurements === true,
                  then: Yup.array().of(brakeMeasurementSchema),
                }),
                additionalBrakeMeasurements: Yup.array().when(
                  'passesBrakeMeasurements',
                  {
                    is: (passesBrakeMeasurements: boolean) =>
                      passesBrakeMeasurements === true,
                    then: Yup.array().of(brakeMeasurementSchema),
                  },
                ),
              }),
            ),
          }),
        ),
      }),
    }),
    onSubmit: (values: UpdateInspectionItemsAndWheelnfoDTO) => {
      if (validateWheelInfo(values)) {
        const categoriesToSave = values.body.map((category) => {
          return {
            ...category,
            items: category.items.map((item) => {
              return {
                ...item,
                status: Number(item.status),
                comment: item.comment ?? '',
                additionalFields: item.additionalFields ?? undefined,
                questions: item.questions ?? [],
              };
            }),
          };
        });

        const axlesToSave = values.wheelInfo?.axles.map((obj) => {
          const newStateWheel = obj.wheels.map((objWheel) => {
            const newStateMeasurements = objWheel.brakeMeasurements.map(
              (objBrakeMeasurements) => {
                return {
                  ...objBrakeMeasurements,
                  measurement: objBrakeMeasurements.measurement
                    ? Number(objBrakeMeasurements.measurement)
                    : null,
                };
              },
            );

            const newStateAdditionalMeasurements =
              objWheel.additionalBrakeMeasurements.map(
                (objAdditionalBrakeMeasurements) => {
                  return {
                    ...objAdditionalBrakeMeasurements,
                    measurement: objAdditionalBrakeMeasurements.measurement
                      ? Number(objAdditionalBrakeMeasurements.measurement)
                      : null,
                  };
                },
              );

            return {
              ...objWheel,
              passesTreadDepth: Boolean(objWheel.passesTreadDepth),
              passesBrakeMeasurements: Boolean(
                objWheel.passesBrakeMeasurements,
              ),
              tireTreadDepth: objWheel.tireTreadDepth
                ? Number(objWheel.tireTreadDepth)
                : null,
              brakeMeasurementUnit:
                objWheel.brakeMeasurementUnit !== null
                  ? Number(objWheel.brakeMeasurementUnit)
                  : null,
              nonBrake: Boolean(objWheel.nonBrake),
              hasAdditionalBrake: Boolean(objWheel.hasAdditionalBrake),
              brakeMeasurements: [...newStateMeasurements],
              additionalBrakeMeasurements: [...newStateAdditionalMeasurements],
            };
          });
          return {
            ...obj,
            wheels: [...newStateWheel],
            brakeType: Number(obj.brakeType),
            conditionAndExternalMeasurements:
              inspection?.program === InspectionProgram.MotorCycle &&
              Number(obj.brakeType) === HydraulicBrakeTypes.HydraulicDrum
                ? obj.conditionAndExternalMeasurements !== undefined &&
                  obj.conditionAndExternalMeasurements !== null
                  ? Boolean(obj.conditionAndExternalMeasurements)
                  : false
                : undefined,
          };
        });

        const wheelInfoToSave: WheelInfoDTO = {
          axles: axlesToSave ?? [],
        };

        updateInspectionItemsAndWheelInfo({
          id: inspection?.id ?? '',
          vin: inspection?.vin ?? '',
          body: categoriesToSave,
          dataVersion: inspection?.dataVersion,
          wheelInfo: wheelInfoToSave,
          inspectionProgram: values?.inspectionProgram,
          additionalNote: values?.additionalNote,
        });
      }
    },
    enableReinitialize: true,
  });

  const passPendingItems = (categoryIndex: number): boolean => {
    for (
      let i = 0;
      i <= formik.values.body[categoryIndex].items.length - 1;
      i++
    ) {
      if (formik.values.body[categoryIndex].items[i].status === 0) {
        formik.setFieldValue(
          'body[' + categoryIndex + '].items[' + i + '].status',
          Number(1),
        );
      }
    }
    return true;
  };

  function validateWheelInfo(values: UpdateInspectionItemsAndWheelnfoDTO) {
    let wheelCountPerAxle = 0;
    values.wheelInfo?.axles.forEach((obj) => {
      obj.wheels.forEach((objWheel) => {
        if (obj.wheels.length === 3) wheelCountPerAxle = wheelCountPerAxle + 1;
      });
    });
    if (wheelCountPerAxle > 0) {
      toast.error(brakeInspectionMessages.WheelsPerAxleLightVehicleValidation);
      return false;
    } else {
      return true;
    }
  }

  const formOnHandleBlur = (event: FormEvent) => {
    checkIfInspectionDataChanges();
  };

  const formOnHandleChange = (event: FormEvent) => {
    checkIfInspectionDataChanges();
  };

  const checkIfInspectionDataChanges = () => {
    const hasInspectionItemsChanged = !isEqual(
      getInspectionItem(formik.initialValues.body),
      getInspectionItem(formik.values.body),
    );

    const hasWheelInfoChanged =
      formik.initialValues.wheelInfo && formik.values.wheelInfo
        ? !isEqual(
            getWheelInfoItem(formik.initialValues.wheelInfo),
            getWheelInfoItem(formik.values.wheelInfo),
          )
        : false;

    setShowDialog(hasInspectionItemsChanged || hasWheelInfoChanged);
  };

  function getWheelInfoItem(wheelInfo: WheelInfoDTO) {
    const axlesToSave = wheelInfo?.axles.map((obj) => {
      const newStateWheel = obj.wheels.map((objWheel) => {
        return {
          ...objWheel,
          passesTreadDepth: Boolean(objWheel.passesTreadDepth),
          passesBrakeMeasurements: Boolean(objWheel.passesBrakeMeasurements),
          nonBrake: Boolean(objWheel.nonBrake),
          hasAdditionalBrake: Boolean(objWheel.hasAdditionalBrake),
          brakeMeasurementUnit:
            objWheel.brakeMeasurementUnit !== null
              ? Number(objWheel.brakeMeasurementUnit)
              : null,
          brakeMeasurements: [...objWheel.brakeMeasurements],
          additionalBrakeMeasurements: [
            ...objWheel.additionalBrakeMeasurements,
          ],
        };
      });
      return {
        ...obj,
        wheels: [...newStateWheel],
        brakeType: Number(obj.brakeType),
      };
    });

    const wheelInfoToSave: WheelInfoDTO = {
      axles: axlesToSave ?? [],
    };
    return wheelInfoToSave;
  }

  function getInspectionItem(inspectionItems: InspectionBodyDTO[]) {
    let values: any[] = [];
    inspectionItems.forEach((category) => {
      category.items.forEach((item) => {
        values.push({
          status: Number(item.status),
          id: item.id,
          name: item.name,
          comment: item.comment,
          additionalFields: item.additionalFields,
          questions: item.questions,
        });
      });
    });
    return values;
  }

  const {
    data: postInspectionQuestions,
    isError: isGetQuestionsResultsError,
    error: questionsError,
    isSuccess: isGetQuestionsSuccess,
  } = useGetInspectionQuestionsQuery(QuestionType.CTPostInspection, {
    skip,
  });

  useEffect(() => {
    if (isGetQuestionsSuccess && postInspectionQuestions) {
      setSkip(true);
      setShowCTPostInspectionQuestionsDialog(true);
      setComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetInspectionSuccess]);

  useEffect(() => {
    if (isGetQuestionsResultsError && questionsError) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetQuestionsResultsError]);

  const handleComplete = async (event: any) => {
    event.preventDefault();

    if (isGetInspectionSuccess) {
      if (inspection.status === InspectionStatus.InProgress)
        showToast({
          description:
            'Inspection items must be completed before the inspection can be marked ready for approval',
        });
      else {
        if (
          inspection.program === InspectionProgram.BodyIntegrity &&
          inspection.type === InspectionType.Structural &&
          inspection.status === InspectionStatus.Pass &&
          inspection.questions.findIndex(
            (q) => q.type === QuestionType.CTPostInspection,
          ) === -1
        ) {
          setSkip(false);
          setShowCTPostInspectionQuestionsDialog(true);
          setComplete(true);
        } else {
          callCompleteInspection();
        }
      }
    }
  };

  const handleUndoComplete = async (event: any) => {
    event.preventDefault();

    if (isGetInspectionSuccess) {
      if (inspection.approvalStatus === InspectionApprovalStatus.Approved)
        toast('Unable to edit an approved inspection');
      else if (
        inspection.approvalStatus === InspectionApprovalStatus.InProgress
      )
        toast('Inspection is already in progress.');
      else {
        const undoCompleteInspectionDTO: UndoCompleteInspectionDTO = {
          id: inspection?.id ?? '',
          vin: inspection?.vin ?? '',
          dataVersion: inspection?.dataVersion,
        };
        undoCompleteInspection(undoCompleteInspectionDTO);
      }
    }
  };

  const getInspectionItemTabIndicatorColor = (
    items: InspectionItemDTO[],
  ): string => {
    if (items.some((i) => i.status === InspectionItemStatus.Default)) {
      return 'var(--sgi-colors-blackAlpha-500)';
    }

    if (items.some((i) => i.status === InspectionItemStatus.Rejected)) {
      return 'var(--sgi-colors-red-500)';
    }

    if (items.some((i) => i.status === InspectionItemStatus.Attention)) {
      return 'var(--sgi-colors-orange-400)';
    }
    return 'var(--sgi-colors-white-500)';
  };

  const getBrakeTabIndicatorColor = (axles: AxleDTO[]) => {
    if (axles.some((a) => BusinessFunctions.checkAxleStatus(a) === 'Pending')) {
      return 'var(--sgi-colors-blackAlpha-500)';
    }

    if (axles.some((a) => BusinessFunctions.checkAxleStatus(a) === 'Fail')) {
      return 'var(--sgi-colors-red-500)';
    }
    return 'var(--sgi-colors-white-500)';
  };

  const getTabData = () => {
    let tabData = [] as TabDataProps[];

    formik.values.body.forEach((category, categoryIndex) => {
      tabData.push({
        tab: (
          <HStack>
            <Box
              data-testid={`category-${categoryIndex}-indicator`}
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '8px',
              }}
              backgroundColor={getInspectionItemTabIndicatorColor(
                category.items,
              )}
            />
            <Box>{`${category.categoryName}(${category.items.length})`}</Box>
          </HStack>
        ),
        content: (
          <>
            <InspectionItemsCategory
              category={category}
              categoryIndex={categoryIndex}
              program={inspection?.program}
              inspectionId={inspection?.id}
              previousInspectionId={inspection?.previousInspectionId}
              passPendingItems={passPendingItems}
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              errors={getIn(formik.errors, `body[${categoryIndex}]`)}
              touched={getIn(formik.touched, `body[${categoryIndex}]`)}
              isDisabled={
                inspection?.approvalStatus !==
                  InspectionApprovalStatus.InProgress || !inspection.startDate
              }
            />
            {inspection?.program === InspectionProgram.BodyIntegrity &&
              inspection?.type === InspectionType.Structural && (
                <Card
                  cardStyleProps={{ marginTop: '16px' }}
                  title="Additional Notes:"
                >
                  <TextArea
                    id="additionalNote"
                    name="additionalNote"
                    data-testid="inspection-additionalNote"
                    onChange={formik.handleChange}
                    value={formik.values.additionalNote ?? ''}
                    placeholder="Notes"
                  />
                </Card>
              )}
          </>
        ),
      });
    });

    if (
      formik.values.wheelInfo &&
      inspection?.program !== InspectionProgram.BodyIntegrity
    ) {
      tabData.push({
        tab: (
          <HStack>
            <Box
              data-testid={`brakes-indicator`}
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '8px',
              }}
              backgroundColor={getBrakeTabIndicatorColor(
                formik.values.wheelInfo.axles,
              )}
            />
            <Box>{`Axles(${formik.values.wheelInfo.axles.length})`}</Box>
          </HStack>
        ),
        content: (
          <AxlesCategory
            wheelInfo={formik.values.wheelInfo}
            program={inspection?.program}
            onChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            errors={getIn(formik.errors, `wheelInfo`)}
            isDisabled={
              inspection?.approvalStatus !==
                InspectionApprovalStatus.InProgress || !inspection.startDate
            }
          />
        ),
      });
    }

    if (inspection && inspection.questions && inspection.questions.length > 0) {
      tabData.push({
        tab: 'Inspection Q&A',
        content: (
          <InspectionQAs
            inspectionId={inspection.id}
            vin={inspection.vin}
            dataVersion={inspection.dataVersion}
            questions={inspection.questions ?? []}
            isDisabled={
              inspection?.approvalStatus !==
                InspectionApprovalStatus.InProgress || !inspection.startDate
            }
          ></InspectionQAs>
        ),
      });
    }

    return tabData;
  };

  const getInspectionItemsWithErrors = (): string[] => {
    const itemsWithErrors: string[] = [];
    if (getIn(formik.errors, 'body')) {
      Object.keys(getIn(formik.errors, 'body')).forEach((categoryIndex) => {
        if (getIn(formik.errors, `body[${categoryIndex}]`) !== undefined) {
          Object.keys(
            getIn(formik.errors, `body[${categoryIndex}].items`),
          ).forEach((itemIndex) => {
            if (
              getIn(
                formik.errors,
                `body[${categoryIndex}].items[${itemIndex}]`,
              ) !== undefined
            ) {
              itemsWithErrors.push(
                `${formik.values.body[Number(categoryIndex)].categoryName}: ${
                  formik.values.body[Number(categoryIndex)].items[
                    Number(itemIndex)
                  ].name
                }`,
              );
            }
          });
        }
      });
    }

    if (getIn(formik.errors, 'wheelInfo.axles')) {
      Object.keys(getIn(formik.errors, `wheelInfo.axles`)).forEach(
        (axleIndex) => {
          if (getIn(formik.errors, `wheelInfo.axles[${axleIndex}].wheels`)) {
            Object.keys(
              getIn(formik.errors, `wheelInfo.axles[${axleIndex}].wheels`),
            ).forEach((wheelIndex) => {
              if (
                getIn(
                  formik.errors,
                  `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].tireTreadDepth`,
                ) !== undefined
              ) {
                itemsWithErrors.push(
                  `Axle ${Number(axleIndex) + 1} - Wheel ${
                    Number(wheelIndex) + 1
                  } :${getIn(
                    formik.errors,
                    `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].tireTreadDepth`,
                  )}`,
                );
              }

              if (
                getIn(
                  formik.errors,
                  `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurementUnit`,
                ) !== undefined &&
                formik.values.wheelInfo?.axles[Number(axleIndex)]
                  .conditionAndExternalMeasurements !== true
              ) {
                itemsWithErrors.push(
                  `Axle ${Number(axleIndex) + 1} - Wheel ${
                    Number(wheelIndex) + 1
                  } :${getIn(
                    formik.errors,
                    `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurementUnit`,
                  )}`,
                );
              }

              if (
                getIn(
                  formik.errors,
                  `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurements`,
                ) &&
                formik.values.wheelInfo?.axles[Number(axleIndex)]
                  .conditionAndExternalMeasurements !== true
              ) {
                Object.keys(
                  getIn(
                    formik.errors,
                    `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurements`,
                  ),
                ).forEach((brakeIndex) => {
                  if (
                    getIn(
                      formik.errors,
                      `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurements[${brakeIndex}].measurement`,
                    ) !== undefined
                  ) {
                    itemsWithErrors.push(
                      `Axle ${Number(axleIndex) + 1} - Wheel ${
                        Number(wheelIndex) + 1
                      } (${
                        formik.values.wheelInfo?.axles[Number(axleIndex)]
                          .wheels[Number(wheelIndex)].brakeMeasurements[
                          Number(brakeIndex)
                        ]?.name
                      }) : ${getIn(
                        formik.errors,
                        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].brakeMeasurements[${brakeIndex}].measurement`,
                      )}`,
                    );
                  }
                });
              }

              if (
                getIn(
                  formik.errors,
                  `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].additionalBrakeMeasurements`,
                ) &&
                formik.values.wheelInfo?.axles[Number(axleIndex)]
                  .conditionAndExternalMeasurements !== true
              ) {
                Object.keys(
                  getIn(
                    formik.errors,
                    `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].additionalBrakeMeasurements`,
                  ),
                ).forEach((brakeIndex) => {
                  if (
                    getIn(
                      formik.errors,
                      `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].additionalBrakeMeasurements[${brakeIndex}].measurement`,
                    ) !== undefined
                  ) {
                    itemsWithErrors.push(
                      `Axle ${Number(axleIndex) + 1} - Wheel ${
                        Number(wheelIndex) + 1
                      } (Additional - ${
                        formik.values.wheelInfo?.axles[Number(axleIndex)]
                          .wheels[Number(wheelIndex)]
                          .additionalBrakeMeasurements[Number(brakeIndex)]?.name
                      }) : ${getIn(
                        formik.errors,
                        `wheelInfo.axles[${axleIndex}].wheels[${wheelIndex}].additionalBrakeMeasurements[${brakeIndex}].measurement`,
                      )}`,
                    );
                  }
                });
              }
            });
          }
        },
      );
    }

    return itemsWithErrors;
  };

  function extendedReturnDate(): Date {
    if (!inspection) {
      return new Date('0001-01-01T00:00:00Z');
    }
    const extension =
      inspection.program === InspectionProgram.BodyIntegrity
        ? reinspectionExtensionDays.bodyIntegrity
        : reinspectionExtensionDays.mechanical;

    const extendedReturnDate = new Date(inspection.returnDate);
    extendedReturnDate.setDate(extendedReturnDate.getDate() + extension);

    return extendedReturnDate;
  }

  const canCreateReinspection = (): boolean => {
    if (!inspection) return false;

    const now = new Date().setUTCHours(0, 0, 0, 0);

    return (
      inspection.status === InspectionStatus.Fail &&
      !inspection?.reInspectionId &&
      inspection?.approvalStatus === InspectionApprovalStatus.Approved &&
      now <= extendedReturnDate().setUTCHours(0, 0, 0, 0)
    );
  };

  const canStartInspection = (): boolean => {
    const now = new Date().setUTCHours(0, 0, 0, 0);
    if (inspection?.previousInspectionId && inspection?.returnDate) {
      return (
        !inspection?.startDate &&
        (inspection?.securityFlags?.canStart ? true : false) &&
        now <= extendedReturnDate().setUTCHours(0, 0, 0, 0)
      );
    } else if (!inspection?.previousInspectionId && !inspection?.returnDate) {
      return (
        !inspection?.startDate &&
        (inspection?.securityFlags?.canStart ? true : false)
      );
    } else {
      return false;
    }
  };

  const [
    updateQuestions,
    {
      data: updatedInspectionForQuestions,
      isSuccess: isUpdateQuestionsSuccess,
      isError: isUpdateQuestionsError,
      error: updateQuestionsError,
    },
  ] = useUpdateQuestionsMutation();

  useEffect(() => {
    if (isUpdateQuestionsSuccess) {
      showToast({
        description: inspectionMessages.questionsUpdated,
        status: 'success',
      });
      setShowCTPostInspectionQuestionsDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateQuestionsSuccess, updatedInspectionForQuestions]);

  useEffect(() => {
    if (isUpdateQuestionsError && updateQuestionsError) {
      showToast({
        description: <ErrorList errors={updateQuestionsError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateQuestionsError, updateQuestionsError]);

  const renderQuestionsOrItems = () => {
    if (
      inspection?.program === InspectionProgram.BodyIntegrity &&
      inspection?.type === InspectionType.Structural &&
      inspection.startDate &&
      inspection?.questions.findIndex(
        (q) =>
          q.type === QuestionType.CTPreInspection &&
          q.isHidden !== true &&
          ((q.response == null &&
            q.code !== QuestionCodes.ctPreQuestionLocationAndDamages) ||
            (q.code === QuestionCodes.ctPreQuestionLocationAndDamages &&
              (q.damageDetails?.length ?? 0) < 1)),
      ) > -1
    ) {
      return (
        <CTInspectionQuestions
          inspection={inspection}
          questionType={QuestionType.CTPreInspection}
          inputQuestions={inspection.questions.filter(
            (q) => q.type === QuestionType.CTPreInspection,
          )}
          updateQuestions={updateQuestions}
          setShowCTPostInspectionQuestionsDialog={() => {}}
        />
      );
    } else {
      return (
        <form
          onSubmit={formik.handleSubmit}
          onBlur={formOnHandleBlur}
          onChange={formOnHandleChange}
        >
          <InspectionProgressBar
            inspectionProgram={inspection?.program}
            inspectionBody={formik.values.body}
            wheelInfo={formik.values.wheelInfo}
          />
          <Tabs
            data={getTabData()}
            panelStyleProps={{ pl: '0px', pr: '0px' }}
          ></Tabs>

          <HStack p={4} justify="flex-start">
            <Button
              variant="secondary"
              data-testid="inspection-back-button"
              onClick={() => {
                navigate('/inspection/list');
              }}
            >
              Back
            </Button>
            {!isApproved && formik.values.body.length > 0 && (
              <Button
                type="submit"
                variant="primary"
                disabled={
                  !inspection?.startDate ||
                  Object.keys(formik.errors).length > 0
                }
              >
                Save Inspection Items
              </Button>
            )}
          </HStack>
          {Object.keys(formik.errors).length > 0 && (
            <Alert
              status="error"
              showCloseButton={false}
              title="There are errors on the following inspection items:"
            >
              <>
                {getInspectionItemsWithErrors().map((item, index) => {
                  return (
                    <Typography key={`${item}${index}`}>{item}</Typography>
                  );
                })}
              </>
            </Alert>
          )}
        </form>
      );
    }
  };

  return (
    <>
      <LoadingModal
        show={
          isLoadingUpdateInspectionItemsAndWheelInfo ||
          isLoadingApproveInspection ||
          isLoadingCompleteInspection ||
          isLoadingUndoCompleteInspection ||
          isLoadingGetInspection
        }
      />
      {!isGetInspectionFetching && (
        <>
          <UnsavedBlockerDialogBox
            // @ts-ignore
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
          {inspection?.securityFlags?.canView ? (
            <>
              <Divider color="gray.500" />
              <InspectionDetailsHeader inspection={inspection} />

              <SimpleGrid
                paddingLeft="48px"
                paddingTop="15px"
                paddingRight="48px"
                width="100%"
                columns={2}
              >
                <HStack justifyContent="flex-start">
                  {canStartInspection() && (
                    <Button
                      data-testid="start-inspection-button"
                      onClick={() => {
                        startInspection({
                          id: inspection.id,
                          vin: inspection.vin,
                          dataVersion: inspection.dataVersion,
                        });
                      }}
                    >
                      Start Inspection
                    </Button>
                  )}
                  {canCreateReinspection() && (
                    <Button
                      data-testid="create-reinspection-button"
                      onClick={() => {
                        navigate(
                          `/inspection/reinspection/${inspection.id}/${inspection.vin}`,
                        );
                      }}
                    >
                      Create Reinspection
                    </Button>
                  )}
                </HStack>

                <HStack justifyContent="flex-end">
                  {inspection.status !== InspectionStatus.InProgress &&
                    inspection.approvalStatus ===
                      InspectionApprovalStatus.InProgress &&
                    inspection?.securityFlags?.canComplete && (
                      <Button
                        data-testid="inspection-complete-button"
                        onClick={handleComplete}
                      >
                        Send For Approval
                      </Button>
                    )}
                  {inspection?.status !== InspectionStatus.InProgress &&
                    inspection.approvalStatus ===
                      InspectionApprovalStatus.WaitingForApproval &&
                    !isApproved &&
                    inspection?.securityFlags?.canComplete && (
                      <Button
                        variant="secondary"
                        onClick={handleUndoComplete}
                        data-testid="inspection-undo-complete-button"
                        leftIcon={<Icon fill="currentColor">{faPencil}</Icon>}
                      >
                        Edit Inspection
                      </Button>
                    )}
                  {inspection?.approvalStatus ===
                    InspectionApprovalStatus.Approved && (
                    <>
                      {inspection?.status === InspectionStatus.Pass && (
                        <PrintModal
                          title="Preview - Proof of Inspection"
                          buttonText="Proof of Inspection"
                          testId="proofOfInspection"
                          documentName={`ProofOfInspection-${inspection.vin}-${inspection.approvalDate}`}
                          componentToPrint={
                            <ProofOfInspection inspection={inspection} />
                          }
                        />
                      )}
                      {inspection.program === InspectionProgram.BodyIntegrity &&
                      inspection.type === InspectionType.Structural ? (
                        <PrintModal
                          title="Preview - Work Plan"
                          buttonText="Work Plan"
                          testId="workPlan"
                          documentName={`workPlan-${inspection.vin}-${inspection.startDate}`}
                          componentToPrint={
                            <WorkPlan inspection={inspection} />
                          }
                        />
                      ) : (
                        <PrintModal
                          title="Preview - Record of Inspection"
                          buttonText="Record of Inspection"
                          testId="recordOfInspection"
                          documentName={`RecordOfInspection-${inspection.vin}-${inspection.startDate}`}
                          componentToPrint={
                            <RecordOfInspection inspection={inspection} />
                          }
                        />
                      )}
                    </>
                  )}
                </HStack>
              </SimpleGrid>
              <Grid templateColumns="repeat(3,1fr)" pl="40px" pr="40px">
                <GridItem colSpan={3}>
                  <Box p={2} pt={4}>
                    <Box bg="white" p="24px 40px" borderRadius={8}>
                      <InspectionDetailsStatusInfo
                        inspection={inspection}
                        approveInspection={approveInspection}
                        isLoadingApproveInspection={isLoadingApproveInspection}
                      />
                    </Box>
                  </Box>
                </GridItem>
                <GridItem>
                  <Box p={2}>
                    <Box bg="white" p="24px 40px" borderRadius={8}>
                      <InspectionDetailsContactInfo
                        contactInfo={inspection.contactInfo}
                      />
                    </Box>
                  </Box>
                </GridItem>
                <GridItem colSpan={2}>
                  <Box p={2}>
                    <Box bg="white" p="24px 40px" borderRadius={8}>
                      <InspectionDetailsVehicleInfo
                        vehicleInfo={inspection.vehicleInfo}
                      />
                    </Box>
                  </Box>
                </GridItem>
                <GridItem colSpan={3}>
                  <Box p={2}>
                    <Box bg="white" p="24px 40px" borderRadius={8}>
                      {renderQuestionsOrItems()}
                    </Box>
                  </Box>
                </GridItem>
              </Grid>
            </>
          ) : (
            <Typography variant="h2">
              {inspectionMessages.viewPermissionError}
            </Typography>
          )}

          <Modal
            data-testid="CTPostInspectionQuestions-Dialog"
            modalContentProps={{ maxW: '800px', maxH: '-moz-fit-content' }}
            isOpen={showCTPostInspectionQuestionsDialog}
            onClose={() => {
              setShowCTPostInspectionQuestionsDialog(false);
            }}
            title=""
            isCentered={true}
            scrollBehavior={'inside'}
            showCloseButton={false}
          >
            {inspection && isGetQuestionsSuccess && (
              <CTInspectionQuestions
                inspection={inspection}
                questionType={QuestionType.CTPostInspection}
                inputQuestions={postInspectionQuestions!!}
                updateQuestions={updateQuestions}
                setShowCTPostInspectionQuestionsDialog={
                  setShowCTPostInspectionQuestionsDialog
                }
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};

export default Inspection;
