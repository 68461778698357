export const formatDateString = (
  date: string | undefined,
): string | undefined => {
  if (!date) return undefined;
  const dateToFormat = new Date(date);
  return dateToFormat.toLocaleDateString(['en-Gb'], {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const formatTimeString = (
  date: string | undefined,
): string | undefined => {
  if (!date) return undefined;
  const timeToFormat = new Date(date);
  return timeToFormat.toLocaleTimeString('en-US');
};
