export const formatId = (id: string): string => {
  const guidLength: number = 36;

  if (!id || !id.includes('-') || id.length !== guidLength) {
    return '';
  }

  const formatted = id.split('-').pop()?.toUpperCase();
  return formatted ? formatted : '';
};
