import { Alert, Button, Input, Typography } from '@sgi/gravity';
import {
  useGetPermissionsQuery,
  usePartialVinSearchQuery,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import { vehicleMessages } from 'constants/messageConstants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getLocalStorageItem,
  LocalStorageItemEnum,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'Utils/localStorageUtils';

import { Box, Grid, GridItem, HStack } from '@chakra-ui/react';
import VehicleSummaryTable from './components/VehicleSummaryTable';

export default function ListVehiclePage() {
  const [skip, setSkip] = useState(true);
  const [clear, setClear] = useState(false);
  const [isValidVIN, setIsValidVIN] = useState(false);
  const [queryVin, setQueryVin] = useState<string>('');
  const [inputVin, setInputVin] = useState<string>('');
  const { data: permissions, isLoading: isGetPermissionsLoading } =
    useGetPermissionsQuery();

  useEffect(() => {
    // Access initial value from session storage
    let sessionVinSearch = getLocalStorageItem<string>(
      LocalStorageItemEnum.VehicleSearch,
    );

    if (sessionVinSearch == null || sessionVinSearch === '') {
      sessionVinSearch = '';
      removeLocalStorageItem(LocalStorageItemEnum.VehicleSearch);
    } else {
      if (permissions?.canSearchVehicles) {
        // Update session storage
        setLocalStorageItem(
          LocalStorageItemEnum.VehicleSearch,
          sessionVinSearch,
        );
        setInputVin(sessionVinSearch);
        setQueryVin(sessionVinSearch);
        setSkip(false);
      }
    }
  }, [permissions?.canSearchVehicles]); //No depe

  const {
    data: vehicles,
    isError: isPartialVinResultsError,
    error: partialVinResultsError,
    isSuccess: isPartialVinResultsSuccess,
    isFetching: isPartialVinResultsFetching,
  } = usePartialVinSearchQuery(queryVin, { skip });

  const handleSearch = () => {
    setIsValidVIN(false);
    setQueryVin('');
    if (!inputVin.trim()) return;
    setSkip(false);
    setQueryVin(inputVin);
    setClear(false);
    setLocalStorageItem(LocalStorageItemEnum.VehicleSearch, inputVin);
  };

  const clearSearch = () => {
    setSkip(true);
    setInputVin('');
    setQueryVin('');
    setClear(true);
    setLocalStorageItem(LocalStorageItemEnum.VehicleSearch, '');
  };

  const setInputSearchValue = (value: string) => {
    if (value && value.trim()) {
      setInputVin(value);
    } else {
      clearSearch();
    }
  };

  useEffect(() => {
    if (isPartialVinResultsSuccess && (!vehicles || vehicles.length === 0)) {
      if (inputVin.length === 17) {
        setIsValidVIN(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicles]);

  useEffect(() => {
    if (isPartialVinResultsError) {
      toast.error(<ErrorList errors={partialVinResultsError} />);
    }
  }, [isPartialVinResultsError, partialVinResultsError]);

  const navigate = useNavigate();

  const renderSearchMessage = () => {
    if (vehicles?.length === 0) {
      if (isValidVIN === true) {
        return (
          <Alert
            status="info"
            showCloseButton={false}
            title=""
          >{`Vehicle not found, click the Create Vehicle button to proceed with creating the vehicle.`}</Alert>
        );
      } else {
        return <>No Vehicles Found</>;
      }
    }
  };

  return (
    <>
      <LoadingModal
        show={isPartialVinResultsFetching || isGetPermissionsLoading}
      />
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Vehicles</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="#FFFFFF" w="100%" p={4} borderRadius={8}>
          <Box w="100%" pb={4}>
            <Grid templateColumns="repeat(3, 1fr)">
              {permissions?.canSearchVehicles ? (
                <>
                  <GridItem colSpan={1}>
                    <Input
                      name="vininput"
                      value={inputVin}
                      placeholder="Search by Start or End of VIN"
                      onChange={(e) => setInputSearchValue(e.target.value)}
                      data-testid="vin-search-input"
                    ></Input>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Button
                      marginLeft={2}
                      disabled={!inputVin.trim()}
                      data-testid="vin-search-button"
                      variant="primary"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>

                    <Button
                      marginLeft={2}
                      disabled={!inputVin.trim()}
                      data-testid="vin-search-clear"
                      variant="secondary"
                      onClick={() => clearSearch()}
                    >
                      Clear
                    </Button>
                  </GridItem>
                </>
              ) : (
                <>
                  {!isGetPermissionsLoading ? (
                    <Typography variant="h2">
                      {vehicleMessages.searchPermissionError}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <GridItem colSpan={1}>
                {permissions?.canCreateVehicle && (
                  <HStack justify="flex-end">
                    {isValidVIN && queryVin ? (
                      <>
                        <Button
                          data-testid="create-vehicle-vin-btn"
                          onClick={() => {
                            navigate(`/vehicle/create?vin=${queryVin}`);
                          }}
                        >
                          + Create Vehicle
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          data-testid="create-vehicle-btn"
                          onClick={() => {
                            navigate('/vehicle/create');
                          }}
                        >
                          + Create Vehicle
                        </Button>
                      </>
                    )}
                  </HStack>
                )}
              </GridItem>
            </Grid>
          </Box>
          <Box bg="#FFFFFF" w="100%">
            {isPartialVinResultsSuccess && !clear && (
              <div data-testid="vin-search-resultCount">
                {vehicles?.length === 0 && renderSearchMessage()}
              </div>
            )}
            {!clear && vehicles && vehicles?.length > 0 ? (
              <VehicleSummaryTable vehicles={vehicles} />
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
