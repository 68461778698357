import { Box, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
import { Button, Typography } from '@sgi/gravity';
import {
  useGetPermissionsQuery,
  useGetVehicleQuery,
} from 'api/InspectTechUIAPI';
import DisplayRow from 'App/Common/DisplayRow';
import { vehicleMessages } from 'constants/messageConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { VinStatusLabel } from 'Types/VehicleDTO';
import { FuelCodeLabel, VinExemptionReasonLabel } from 'Types/VehicleEnums';

export const Vehicle = () => {
  const { isLoading: isGetPermissionsLoading } = useGetPermissionsQuery();

  const { vin } = useParams<'vin'>();
  const navigate = useNavigate();

  const {
    data: vehicle,
    isFetching: isGetVehicleFetching,
    isSuccess: isGetVehicleSuccess,
  } = useGetVehicleQuery(vin ?? '');

  function splitVinExemptionReasons() {
    let arrVinExemptionReasons = vehicle?.vinExemptionReason
      ?.toString()
      .split(',');

    let stringVinExemptionReasons: any = [];
    arrVinExemptionReasons?.forEach((el: any) => {
      stringVinExemptionReasons.push(
        VinExemptionReasonLabel[
          Number(el) as keyof typeof VinExemptionReasonLabel
        ],
      );
    });
    return stringVinExemptionReasons.join(', ');
  }
  return (
    <>
      {!isGetVehicleFetching && !isGetPermissionsLoading && (
        <>
          {vehicle?.securityFlags?.canView ? (
            <>
              <Box bg="#FFFFFF" w="100%" p={9}>
                <Grid templateColumns="repeat(3, 1fr)">
                  <GridItem colSpan={1}>
                    <Typography variant="h1">Vehicle Details</Typography>
                  </GridItem>
                </Grid>
              </Box>
              <Box w="100%" p={4}>
                <Box bg="#FFFFFF" w="100%" p={9}>
                  <SimpleGrid columns={[2, null, 3]}>
                    <GridItem colSpan={2}>
                      <DisplayRow
                        label="VIN"
                        value={vehicle?.vin}
                        testId="contact-display-vin"
                      />
                      <DisplayRow
                        label="VIN Status"
                        value={vehicle && VinStatusLabel[vehicle.status]}
                        testId="contact-display-status"
                      />
                      <DisplayRow
                        label="Year"
                        value={
                          vehicle?.year && vehicle?.year > 0
                            ? vehicle?.year.toString()
                            : ''
                        }
                        testId="contact-display-year"
                      />
                      <DisplayRow
                        label="Make"
                        value={vehicle?.make}
                        testId="contact-display-make"
                      />
                      <DisplayRow
                        label="Model"
                        value={vehicle?.model}
                        testId="contact-display-model"
                      />
                      <DisplayRow
                        label="Colour"
                        value={vehicle?.colour}
                        testId="contact-display-colour"
                      />
                      <DisplayRow
                        label="Body Style"
                        value={vehicle?.bodyStyle}
                        testId="contact-display-bodyStyle"
                      />
                      <DisplayRow
                        label="Gross Vehicle Weight Rating (GVWR)"
                        value={
                          vehicle?.gvwr && vehicle?.gvwr > 0
                            ? `${vehicle?.gvwr.toLocaleString()}Kg`
                            : ''
                        }
                        testId="contact-display-vin"
                      />
                      <DisplayRow
                        label="Fuel Code"
                        value={
                          FuelCodeLabel[
                            vehicle.fuelCode as keyof typeof FuelCodeLabel
                          ]
                        }
                        testId="contact-display-fuelCode"
                      />
                      <DisplayRow
                        label="VIN Exemption Reason"
                        value={splitVinExemptionReasons()}
                        testId="contact-display-vinExemptionReason"
                      />
                    </GridItem>
                  </SimpleGrid>
                  <br />
                  <div>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        navigate('/vehicle/list');
                      }}
                    >
                      Back
                    </Button>
                  </div>
                </Box>
              </Box>
            </>
          ) : (
            <>
              {isGetVehicleSuccess ? (
                <Typography variant="h2">
                  {vehicleMessages.viewPermissionError}
                </Typography>
              ) : (
                <div>Error fetching data.</div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Vehicle;
