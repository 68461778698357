import { HStack, VStack } from '@chakra-ui/react';
import {
  BooleanCheckbox,
  Button,
  Card,
  Radio,
  RadioGroup,
  useToast,
} from '@sgi/gravity';
import { brakeInspectionMessages } from 'constants/messageConstants';
import { MotorcycleTotalWheels } from 'constants/variableConstants';
import { FormikErrors, getIn } from 'formik';
import { InspectionProgram } from 'Types/InspectionEnums';
import { UpdateInspectionItemsAndWheelnfoDTO } from 'Types/UpdateInspectionItemsAndWheelnfoDTO';
import {
  AxleDTO,
  AxleWheelLimit,
  BrakeTypes,
  HydraulicBrakeTypeLabel,
  HydraulicBrakeTypes,
  WheelDTO,
  WheelInfoDTO,
} from 'Types/WheelInfoDTO';
import { InputMapper } from 'Utils/InputMapper';
import WheelDetails from '../Wheels/WheelDetails';

interface AxleDetailsProps {
  wheelInfo: WheelInfoDTO;
  axleWheelLimit: AxleWheelLimit;
  axle: AxleDTO;
  axleIndex: number;
  inspectionProgram: InspectionProgram | undefined;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<FormikErrors<UpdateInspectionItemsAndWheelnfoDTO>>
    | Promise<void>;
  onChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  isDisabled?: boolean;
}

const AxleDetails = ({
  wheelInfo,
  axle,
  axleIndex,
  axleWheelLimit,
  inspectionProgram,
  setFieldValue,
  setFieldTouched,
  onChange,
  errors,
  isDisabled = false,
}: AxleDetailsProps) => {
  const { showToast } = useToast();
  const getBrakeTypes = (program: InspectionProgram | undefined) => {
    let brakeType: any = [];
    if (
      program === InspectionProgram.LightVehicle ||
      program === InspectionProgram.MidSizeTruck ||
      program === InspectionProgram.MotorCycle
    ) {
      brakeType = InputMapper.bindTextValueWithLabelEnum(
        HydraulicBrakeTypes,
        HydraulicBrakeTypeLabel,
        undefined,
        true,
      );
      return brakeType;
    } else {
      return brakeType;
    }
  };

  const brakeTypesArr = getBrakeTypes(inspectionProgram);

  const handleAddNewWheel = (axle: AxleDTO) => {
    let hasTwoWheelOnAxle = false;
    let wheelCount = 0;
    let singleWheelCount = 0;
    if (inspectionProgram === InspectionProgram.LightVehicle) {
      wheelInfo.axles.forEach((axle) => {
        if (axle.wheels.length === axleWheelLimit.minWheel)
          singleWheelCount = singleWheelCount + 1;
      });

      if (singleWheelCount > 0 && axle.wheels.length === 2) {
        showToast({
          description: `${brakeInspectionMessages.remainingAxleWheelsLightVehicleValidation}`,
          status: 'error',
        });
        return;
      }

      if (axle.id === 1 && axle.wheels.length === 2) {
        showToast({
          description: `${brakeInspectionMessages.maxWheelsFrontAxleLightVehicleValidation}`,
          status: 'error',
        });
        return;
      }
    }

    let wheelsArr = [...axle.wheels];
    if (inspectionProgram === InspectionProgram.MotorCycle) {
      wheelInfo.axles.forEach((axle) => {
        wheelCount = wheelCount + axle.wheels.length;
      });
    }

    if (wheelCount === MotorcycleTotalWheels) {
      hasTwoWheelOnAxle = true;
    }

    if (hasTwoWheelOnAxle) {
      showToast({
        description: `${brakeInspectionMessages.maxWheelsMotorcycleValidation}`,
        status: 'error',
      });
      return;
    } else {
      if (axle.wheels.length < axleWheelLimit.maxWheel) {
        const newWheel: WheelDTO = {
          id: axle.wheels.length + 1,
          brakeMeasurements: [],
          passesTreadDepth: false,
          passesBrakeMeasurements: false,
          nonBrake: false,
          tireTreadDepth: null,
          brakeMeasurementUnit: null,
          wheelOffInspection: false,
          additionalBrakeMeasurements: [],
          hasAdditionalBrake: false,
        };
        wheelsArr.push(newWheel);
        setFieldValue(
          `wheelInfo.axles[${axleIndex}].wheels`,
          CheckAdditionalBrakeMeasurement(wheelsArr),
        );
      } else {
        showToast({
          description: `${brakeInspectionMessages.maxWheelsValidation} ${axleWheelLimit.maxWheel}`,
          status: 'error',
        });
      }
    }
  };

  const onOEMChange = (value: boolean) => {
    if (value === true) {
      let wheelsArr = [...axle.wheels];
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].wheels`,
        wheelsArr.map((objWheel) => {
          return {
            ...objWheel,
            brakeMeasurements: [],
            additionalBrakeMeasurements: [],
            passesBrakeMeasurements: false,
          };
        }),
      );
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].conditionAndExternalMeasurements`,
        value,
      );

      setTimeout(() => {
        setFieldTouched(
          `wheelInfo.axles[${axleIndex}].conditionAndExternalMeasurements`,
          true,
          true,
        );
      });
    } else {
      setFieldValue(
        `wheelInfo.axles[${axleIndex}].conditionAndExternalMeasurements`,
        value,
      );
    }
  };

  const CheckAdditionalBrakeMeasurement = (wheelsArr: WheelDTO[]) => {
    return wheelsArr.map((objWheel) => {
      return {
        ...objWheel,
        hasAdditionalBrake:
          wheelsArr.length === 1 ? objWheel.hasAdditionalBrake : false,
        additionalBrakeMeasurements:
          wheelsArr.length === 1
            ? [...objWheel.additionalBrakeMeasurements]
            : [],
      };
    });
  };

  return (
    <Card>
      <VStack alignItems="start">
        <HStack justify={'space-between'} w="100%">
          <RadioGroup
            label="Brake Type"
            name={`wheelInfo.axles[${axleIndex}].brakeType`}
            data-testid={`wheelInfo-axles[${axleIndex}]-brakeType`}
            value={axle.brakeType.toString()}
            onChange={(e) => {
              setFieldValue(
                `wheelInfo.axles[${axleIndex}].brakeType`,
                e.toString(),
              );
              if (
                Number(e) === BrakeTypes.HydraulicDisc &&
                inspectionProgram === InspectionProgram.MotorCycle
              ) {
                setFieldValue(
                  `wheelInfo.axles[${axleIndex}].conditionAndExternalMeasurements`,
                  false,
                );
              }
            }}
            variant="radio"
            isRequired={false}
            hideOptionalLabel={true}
            error={getIn(errors, `brakeType`)}
          >
            <HStack>
              {brakeTypesArr.length > 0 &&
                brakeTypesArr?.map((item: any, i: number) => {
                  return (
                    <Radio
                      isDisabled={isDisabled}
                      value={item.value.toString()}
                      key={`radio-brakeType-${axle.id}${i}`}
                    >
                      {item.text}
                    </Radio>
                  );
                })}
            </HStack>
          </RadioGroup>
          <Button
            type="button"
            disabled={isDisabled}
            variant="secondary"
            data-testid={`wheelInfo-add-wheel-btn-${axle.id}`}
            onClick={() => {
              handleAddNewWheel(axle);
            }}
          >
            + Add New Wheel
          </Button>
        </HStack>

        {inspectionProgram === InspectionProgram.MotorCycle &&
          Number(axle.brakeType) === HydraulicBrakeTypes.HydraulicDrum && (
            <BooleanCheckbox
              p={'16px 0px'}
              name={`wheelInfo.axles[${axleIndex}].conditionAndExternalMeasurements`}
              data-testid={`wheelInfo-axles-${axleIndex}-conditionAndExternalMeasurements`}
              isDisabled={isDisabled || axle.wasOEMPreviouslyUnchecked === true}
              onChange={(e) => {
                onOEMChange(Boolean(e));
              }}
              value={
                axle.conditionAndExternalMeasurements !== undefined &&
                axle.conditionAndExternalMeasurements !== null
                  ? Boolean(axle.conditionAndExternalMeasurements)
                  : false
              }
            >
              Condition and external measurements as per OEM
            </BooleanCheckbox>
          )}
        {axle.wheels.map((wheel, wheelIndex) => {
          return (
            <WheelDetails
              key={`wheel-${wheelIndex}`}
              wheelInfo={wheelInfo}
              wheel={wheel}
              axle={axle}
              axleIndex={axleIndex}
              wheelIndex={wheelIndex}
              axleWheelLimit={axleWheelLimit}
              onChange={onChange}
              inspectionProgram={inspectionProgram}
              setFieldValue={setFieldValue}
              errors={getIn(errors, `wheels[${wheelIndex}]`)}
              isDisabled={isDisabled}
            ></WheelDetails>
          );
        })}
      </VStack>
    </Card>
  );
};

export default AxleDetails;
