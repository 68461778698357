import { Navigate } from 'react-router-dom';
import {
  useIsAuthenticated,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

interface PrivateRouteProps {
  element: JSX.Element;
  redirectToLogin?: boolean;
}

export function PrivateRoute({
  element,
  redirectToLogin = false,
}: PrivateRouteProps) {
  const isAuthenticated = useIsAuthenticated();

  function returnMsalAuthenticationTemplate() {
    return (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {element}
      </MsalAuthenticationTemplate>
    );
  }

  function returnUnauthorized() {
    return (
      <>{isAuthenticated ? element : <Navigate to="/PageNotAuthorized" />}</>
    );
  }

  return (
    <>
      {redirectToLogin
        ? returnMsalAuthenticationTemplate()
        : returnUnauthorized()}
    </>
  );
}

export default PrivateRoute;
