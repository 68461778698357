import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { useVinSearchInspectionQuery } from 'api/InspectTechUIAPI';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import { InspectionDTO } from 'Types/InspectionDTO';
import {
  InspectionJurisdictionLabel,
  UnitOfDistance,
} from 'Types/InspectionEnums';
import { FuelCodeLabel } from 'Types/VehicleEnums';
import { formatPhone } from 'Utils/Format';
interface AdditionalVehicleInfoProps {
  inspection: CreateInspectionDTO | InspectionDTO;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
}

const ConfirmDetails = ({
  inspection,
  inspectionHelperDetailsDTO,
}: AdditionalVehicleInfoProps) => {
  const { data: vehicle } = useVinSearchInspectionQuery(inspection.vin ?? '');

  return (
    <>
      <>
        <Card cardStyleProps={{ width: '100%', padding: '0' }}>
          <VStack>
            <Box
              w="100%"
              padding="8px 16px"
              borderTopRadius={8}
              bg={'gray.100'}
            >
              <VStack justify={'space-between'} alignItems="flex-start">
                <Typography variant="subheading2">
                  Vehicle Information
                </Typography>
              </VStack>
            </Box>
            <Box w="100%" padding="8px 16px" borderBottomRadius={8}>
              <VStack alignItems="start" w="100%">
                <HStack w="100%" justify="space-between">
                  <Box w="100%">
                    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                      <GridItem>
                        <Typography>VIN</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-vin'}
                        >
                          {inspection.vin}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Year</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-year"
                        >
                          {(vehicle && vehicle[0].year) ?? 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Make</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-make"
                        >
                          {(vehicle && vehicle[0].make) ?? 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Model</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-model"
                        >
                          {(vehicle && vehicle[0].model) ?? 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Body Style</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-bodyStyle"
                        >
                          {(vehicle && vehicle[0].bodyStyle) ?? 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Colour</Typography>
                        <Typography variant="bodyBold">
                          {(vehicle && vehicle[0].colour) ?? 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>GVWR</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-gvwr"
                        >
                          {vehicle && vehicle[0].gvwr && vehicle[0].gvwr > 0
                            ? `${vehicle[0].gvwr.toLocaleString()} kg`
                            : 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Fuel Type</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-fuelCode"
                        >
                          {vehicle && vehicle[0].fuelCode
                            ? FuelCodeLabel[vehicle[0].fuelCode]
                            : 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Odometer</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-odometer"
                        >
                          {inspection.odometer && inspection.odometer > 0
                            ? ` ${inspection.odometer.toLocaleString()} ${UnitOfDistance[
                                inspection.odometerUoD
                              ].toLowerCase()}`
                            : ''}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Unit Number</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid="confirm-details-unitNumber"
                        >
                          {inspection.unitNumber
                            ? inspection.unitNumber
                            : 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Jurisdiction</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-jurisdiction'}
                        >
                          {inspection.jurisdiction &&
                          inspection.jurisdiction > 0
                            ? InspectionJurisdictionLabel[
                                inspection.jurisdiction as keyof typeof InspectionJurisdictionLabel
                              ]
                            : 'N/A'}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Plate Number</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-plate'}
                        >
                          {inspection.plate ? inspection.plate : 'N/A'}
                        </Typography>
                      </GridItem>
                    </Grid>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </Card>

        <Card cardStyleProps={{ width: '100%', padding: '0' }}>
          <VStack>
            <Box
              w="100%"
              padding="8px 16px"
              borderTopRadius={8}
              bg={'gray.100'}
            >
              <VStack justify={'space-between'} alignItems="flex-start">
                <Typography variant="subheading2">
                  Inspection Information
                </Typography>
              </VStack>
            </Box>

            <Box w="100%" padding="8px 16px" borderBottomRadius={8}>
              <VStack alignItems="start" w="100%">
                <HStack w="100%" justify="space-between">
                  <Box w="100%">
                    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                      <GridItem>
                        <Typography>Program</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-program'}
                        >
                          {inspection.program}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Type</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-type'}
                        >
                          {inspection.type}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Station</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-station'}
                        >
                          {inspection.station}
                        </Typography>
                      </GridItem>
                    </Grid>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </Card>
        <Card cardStyleProps={{ width: '100%', padding: '0' }}>
          <VStack>
            <Box
              w="100%"
              padding="8px 16px"
              borderTopRadius={8}
              bg={'gray.100'}
            >
              <VStack justify={'space-between'} alignItems="flex-start">
                <Typography variant="subheading2">
                  Contact Information
                </Typography>
              </VStack>
            </Box>
            <Box w="100%" padding="8px 16px" borderBottomRadius={8}>
              <VStack alignItems="start" w="100%">
                <HStack w="100%" justify="space-between">
                  <Box w="100%">
                    <Grid templateColumns="repeat(4, 1fr)" gap={6}>
                      <GridItem>
                        <Typography>Name</Typography>
                        <Typography
                          variant="bodyBold"
                          data-testid={'confirm-details-contact'}
                        >
                          {inspectionHelperDetailsDTO.contact.firstName +
                            ' ' +
                            inspectionHelperDetailsDTO.contact.lastName}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Email</Typography>
                        <Typography variant="bodyBold">
                          {inspectionHelperDetailsDTO.contact.email}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Home Phone</Typography>
                        <Typography variant="bodyBold">
                          {inspectionHelperDetailsDTO.contact.phoneInfo
                            .homePhone
                            ? formatPhone(
                                inspectionHelperDetailsDTO.contact.phoneInfo
                                  .homePhone,
                              )
                            : ''}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Cell Phone</Typography>
                        <Typography variant="bodyBold">
                          {inspectionHelperDetailsDTO.contact.phoneInfo
                            .cellPhone
                            ? formatPhone(
                                inspectionHelperDetailsDTO.contact.phoneInfo
                                  .cellPhone,
                              )
                            : ''}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Work Phone</Typography>
                        <Typography variant="bodyBold">
                          {inspectionHelperDetailsDTO.contact?.phoneInfo
                            .workPhone
                            ? `${formatPhone(
                                inspectionHelperDetailsDTO.contact?.phoneInfo
                                  ?.workPhone,
                              )}`
                            : ''}
                          {inspectionHelperDetailsDTO.contact?.phoneInfo
                            .workPhoneExtension
                            ? ` Ext: ${inspectionHelperDetailsDTO.contact?.phoneInfo.workPhoneExtension}`
                            : ''}
                        </Typography>
                      </GridItem>
                      <GridItem>
                        <Typography>Mailing Address</Typography>
                        <Typography variant="bodyBold">
                          {inspectionHelperDetailsDTO.contact.address.street +
                            ', ' +
                            inspectionHelperDetailsDTO.contact.address.city +
                            ', ' +
                            inspectionHelperDetailsDTO.contact.address
                              .province +
                            ', ' +
                            inspectionHelperDetailsDTO.contact.address
                              .postalCode}
                        </Typography>
                      </GridItem>
                    </Grid>
                  </Box>
                </HStack>
              </VStack>
            </Box>
          </VStack>
        </Card>
      </>
    </>
  );
};

export default ConfirmDetails;
