import { Route, Routes } from 'react-router';
import CreateStation from './CreateStation';
import Station from './Station';
import StationSummaryPage from './StationSummaryPage';

export const StationRouter = () => {
  return (
    <Routes>
      <Route path={'/create'} element={<CreateStation />}></Route>
      <Route path={'/list'} element={<StationSummaryPage />}></Route>
      <Route path={':stationId'} element={<Station />}></Route>
      <Route path="*" element={<div>No route match</div>}></Route>
    </Routes>
  );
};

export default StationRouter;
