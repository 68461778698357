import { Icon } from '@chakra-ui/react';
import { InspectionStatus } from 'Types/InspectionDTO';
import { faCheckIcon, faPending, faQuestionIcon, faXMark } from './Icons';

interface InspectionStatusBadgeProps {
  inspectionStatus: InspectionStatus;
}

export const InspectionStatusBadge = ({
  inspectionStatus,
}: InspectionStatusBadgeProps) => {
  switch (inspectionStatus) {
    case InspectionStatus.InProgress:
      return (
        <span title="In Progress">
          <Icon fill="gray.500" aria-label="inspection-status-pending-badge">
            {faPending}
          </Icon>
        </span>
      );
    case InspectionStatus.Pass:
      return (
        <span title="Pass">
          <Icon fill="green.500" aria-label="inspection-status-pass-badge">
            {faCheckIcon}
          </Icon>
        </span>
      );
    case InspectionStatus.Fail:
      return (
        <span title="Failed">
          <Icon fill="red.500" aria-label="inspection-status-fail-badge">
            {faXMark}
          </Icon>
        </span>
      );
    default:
      return (
        <span title="Unknown">
          <Icon fill="gray.500" aria-label="inspection-status-unknown-badge">
            {faQuestionIcon}
          </Icon>
        </span>
      );
  }
};

export default InspectionStatusBadge;
