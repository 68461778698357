import { Grid, GridItem } from '@chakra-ui/react';
import { Button, Select, Typography } from '@sgi/gravity';
import {
  useGetVehicleQuery,
  useUpdateVinStatusMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import {
  validationMessages,
  vehicleMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  defaultUpdateVinStatusDTO,
  UpdateVinStatusDTO,
} from 'Types/UpdateVinStatusDTO';
import { VinStatus, VinStatusLabel } from 'Types/VehicleDTO';
import { InputMapper } from 'Utils/InputMapper';
import * as Yup from 'yup';

interface UpdateVinStatusFormProp {
  vin: string;
  onClose: any;
}

export const UpdateVinStatusForm = ({
  vin,
  onClose,
}: UpdateVinStatusFormProp) => {
  const [initVehicle, setInitVehicle] = useState(defaultUpdateVinStatusDTO);
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initVehicle },
    validationSchema: Yup.object({
      status: Yup.number().min(1, validationMessages.status.required),
    }),
    onSubmit: (values: UpdateVinStatusDTO) => {
      values.status = Number(values.status);
      updateVinStatus(values);
    },
  });

  const {
    data: vehicle,
    isError: isGetVehicleError,
    error: getVehicleError,
    isSuccess: isGetVehicleSuccess,
    isLoading: isVehicleLoading,
  } = useGetVehicleQuery(vin);

  const [
    updateVinStatus,
    {
      data: updatedVehicle,
      isSuccess: isUpdateVehicleSuccess,
      isError: isUpdateVehicleError,
      error: updateVehicleError,
      isLoading: isUpdateVehicleLoading,
    },
  ] = useUpdateVinStatusMutation();

  useEffect(() => {
    if (isGetVehicleSuccess) {
      const objVehicle: UpdateVinStatusDTO = {
        status: vehicle.status !== 0 ? vehicle.status : undefined,
        vin: vehicle.vin,
        dataVersion: vehicle.dataVersion,
      };
      setInitVehicle(objVehicle);
    }
  }, [isGetVehicleSuccess, vehicle]);

  useEffect(() => {
    if (isGetVehicleError) {
      toast.error(<ErrorList errors={getVehicleError} />);
    }
  }, [isGetVehicleError, getVehicleError]);

  useEffect(() => {
    if (isUpdateVehicleError) {
      toast.error(<ErrorList errors={updateVehicleError} />);
    }
  }, [isUpdateVehicleError, updateVehicleError]);

  useEffect(() => {
    if (isUpdateVehicleSuccess) {
      toast.success(vehicleMessages.statusUpdated);
      navigate(`/vehicle/list`);
    }
  }, [isUpdateVehicleSuccess, updatedVehicle, navigate]);

  const gridStyle = {
    borderBottom: '1px solid gainsboro',
  };

  return (
    <>
      <LoadingModal show={isUpdateVehicleLoading || isVehicleLoading} />

      <Grid templateColumns="repeat(5, 1fr)">
        <GridItem>
          <Typography variant="h3">VIN# </Typography>
        </GridItem>
        <GridItem colSpan={4}>
          <Typography variant="h3">{vin}</Typography>
        </GridItem>
      </Grid>
      <br />
      {isGetVehicleError ? (
        <div>Error fetching data.</div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Grid
            templateRows="repeat(3, 1fr)"
            templateColumns="repeat(4, 1fr)"
            rowGap={4}
          >
            <GridItem style={gridStyle}>Year</GridItem>
            <GridItem style={gridStyle}>{vehicle?.year ?? ''}</GridItem>
            <GridItem style={gridStyle}>Color</GridItem>
            <GridItem style={gridStyle}>{vehicle?.colour}</GridItem>

            <GridItem style={gridStyle}>Make</GridItem>
            <GridItem style={gridStyle}>{vehicle?.make}</GridItem>
            <GridItem style={gridStyle}>Body Style</GridItem>
            <GridItem style={gridStyle}>{vehicle?.bodyStyle ?? ''}</GridItem>

            <GridItem style={gridStyle}>Model</GridItem>
            <GridItem style={gridStyle}>{vehicle?.model ?? ''}</GridItem>
            <GridItem style={gridStyle}>GVWR</GridItem>
            <GridItem style={gridStyle}>
              {vehicle?.gvwr && vehicle?.gvwr > 0 ? vehicle?.gvwr + 'Kg' : ''}
            </GridItem>
          </Grid>
          <br />
          <Grid templateColumns="repeat(3, 1fr)" columnGap={2} rowGap={2}>
            <GridItem colSpan={3}>
              <Select
                isRequired={false}
                hideOptionalLabel={true}
                error={formik.errors.status}
                label="VIN Status"
                name="status"
                onChange={formik.handleChange}
                value={formik.values.status?.toString()}
                data-testid="select-vin-status"
                options={InputMapper.bindLabelValueWithLabelEnum(
                  VinStatus,
                  VinStatusLabel,
                  true,
                )}
                size="md"
              ></Select>
            </GridItem>
            <GridItem></GridItem>
            <GridItem style={{ textAlign: 'right' }}>
              <Button
                variant="secondary"
                data-testid="update-vin-status-cancel"
                type="button"
                onClick={onClose}
              >
                Cancel
              </Button>
            </GridItem>
            <GridItem style={{ textAlign: 'right' }}>
              <Button
                variant="primary"
                data-testid="update-vin-status-submit"
                type="submit"
              >
                Update Status
              </Button>
            </GridItem>
          </Grid>
        </form>
      )}
    </>
  );
};

export default UpdateVinStatusForm;
