import { validationMessages } from 'constants/messageConstants';
import { patterns } from 'Utils/regex';
import * as Yup from 'yup';

export const vinSchema = Yup.object({
  vin: Yup.string()
    .max(17, validationMessages.vin.length)
    .required(validationMessages.vin.required)
    .matches(patterns.vin, validationMessages.vin.pattern),
});

export const addressSchema = Yup.object({
  address: Yup.object({
    street: Yup.string().required(
      validationMessages.contactAddressStreet.required,
    ),
    city: Yup.string().required(validationMessages.contactAddressCity.required),
    province: Yup.string().required(
      validationMessages.contactAddressProvince.required,
    ),
    postalCode: Yup.string().required(
      validationMessages.contactAddressPostalCode.required,
    ),
  }),
});

export const customerNumberSchema = Yup.object({
  customerNumber: Yup.number()
    .integer()
    .typeError(validationMessages.contactCustomerNumber.type),
});

export const phoneInfoSchema = Yup.object({
  phoneInfo: Yup.object({
    cellPhone: Yup.string()
      .matches(patterns.numbersOnly, validationMessages.contactPhone.type)
      .length(10, validationMessages.contactPhone.length),
    homePhone: Yup.string()
      .matches(patterns.numbersOnly, validationMessages.contactPhone.type)
      .length(10, validationMessages.contactPhone.length),
    workPhone: Yup.string()
      .matches(patterns.numbersOnly, validationMessages.contactPhone.type)
      .length(10, validationMessages.contactPhone.length),
    workPhoneExtension: Yup.string()
      .matches(
        patterns.numbersOnly,
        validationMessages.contactWorkPhoneExtension.type,
      )
      .max(4, validationMessages.contactWorkPhoneExtension.length),

    phoneError: Yup.bool().when(['cellPhone', 'homePhone', 'workPhone'], {
      is: (cellPhone: string, workPhone: string, homePhone: string) =>
        !cellPhone && !workPhone && !homePhone,
      then: Yup.bool().required(validationMessages.contactPhone.required),
      otherwise: undefined,
    }),
  }),
});
