import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import {
  Card,
  FormControl,
  Radio,
  RadioGroup,
  TextArea,
  Typography,
} from '@sgi/gravity';
import { InspectionItemText } from 'constants/messageConstants';
import { getIn } from 'formik';
import { InspectionItemDTO, InspectionItemStatus } from 'Types/InspectionDTO';
import { InspectionProgram } from 'Types/InspectionEnums';
import InspectionItemBadge from './InspectionItemBadge';
import InspectionItemCommentHistory from './InspectionItemCommentHistory';

interface InspectionItemDetailsProps {
  item: InspectionItemDTO;
  itemIndex: number;
  program: string | undefined;
  categoryIndex: number;
  statusKeys: string[];
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  errors: any;
  isDisabled?: boolean;
}

const getItemStatusText = (
  status: string,
  previouslyFailed: boolean,
  prevRejectedImmediatelyRepaired: boolean,
) => {
  switch (Number(status)) {
    case InspectionItemStatus.Default:
      return '';
    case InspectionItemStatus.Attention:
      return 'Attention';
    case InspectionItemStatus.Pass:
      return previouslyFailed ? 'Corrected' : 'Pass';
    case InspectionItemStatus.RepairedNow:
      return prevRejectedImmediatelyRepaired ? 'Corrected' : 'Repaired Now';
    default:
      return InspectionItemStatus[status as any];
  }
};

const InspectionItemDetails = ({
  item,
  itemIndex,
  program,
  categoryIndex,
  errors,
  statusKeys,
  setFieldValue,
  onChange,
  isDisabled = false,
}: InspectionItemDetailsProps) => {
  const getLastCommentEffectiveStatus = () => {
    const sorted = item.commentHistory
      ? [...item.commentHistory].sort((a, b) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime();
        })
      : [];
    if (sorted.length > 0) {
      return sorted[0].effectiveStatus;
    } else {
      return 0;
    }
  };

  const isRequiresAttentionWithNoCommentHistory =
    item.status === InspectionItemStatus.Attention &&
    getLastCommentEffectiveStatus() !== InspectionItemStatus.Attention;

  const isRejectedImmediatelyRepairedWithNoCommentHistory =
    item.status === InspectionItemStatus.RepairedNow &&
    getLastCommentEffectiveStatus() !== InspectionItemStatus.RepairedNow;

  return (
    <Card>
      <VStack alignItems="start">
        <Typography variant="h4">{item.name}</Typography>
        <FormControl
          name={`body[${categoryIndex}].items[${itemIndex}].comment`}
        >
          <RadioGroup
            value={item.status?.toString()}
            onChange={async (e) => {
              await setFieldValue(
                `body[${categoryIndex}].items[${itemIndex}].status`,
                Number(e),
              );
            }}
            variant="button"
            name={`body[${categoryIndex}].items[${itemIndex}].status`}
          >
            <HStack pb={'16px'}>
              {statusKeys
                .filter(
                  (k) =>
                    !isNaN(Number(k)) &&
                    k !== InspectionItemStatus.Default.toString(),
                )
                .map((status) => {
                  return (
                    <Radio
                      key={status}
                      value={status}
                      isDisabled={isDisabled}
                      aria-label={`inspection-item-status-button-${InspectionItemStatus[
                        Number(status)
                      ].toLowerCase()}`}
                    >
                      <Center>
                        <HStack>
                          <Box>
                            {getItemStatusText(
                              status,
                              item.wasPreviouslyRejected ?? false,
                              item.wasPrevRejectedImmediatelyRepaired ?? false,
                            )}
                          </Box>
                          <InspectionItemBadge status={Number(status)} />
                        </HStack>
                      </Center>
                    </Radio>
                  );
                })}
            </HStack>
          </RadioGroup>
          <TextArea
            isRequired={
              isRequiresAttentionWithNoCommentHistory ||
              isRejectedImmediatelyRepairedWithNoCommentHistory ||
              (item.status === InspectionItemStatus.Rejected &&
                item.name ===
                  InspectionItemText.BodyAndStructuralMeasurements.title)
            }
            label={
              item.status === InspectionItemStatus.Rejected &&
              item.name ===
                InspectionItemText.BodyAndStructuralMeasurements.title &&
              program === InspectionProgram.BodyIntegrity
                ? 'Repair Detail Note'
                : 'Comment'
            }
            name={`body[${categoryIndex}].items[${itemIndex}].comment`}
            value={item.comment}
            onChange={onChange}
            error={getIn(errors, `comment`)}
            isDisabled={isDisabled}
            // TODO: Add maxlength with newer gravity version 1.0.0-rc.8
          />
        </FormControl>
        {item.commentHistory?.length && item.commentHistory?.length > 0 && (
          <InspectionItemCommentHistory commentHistory={item.commentHistory} />
        )}
      </VStack>
    </Card>
  );
};

export default InspectionItemDetails;
