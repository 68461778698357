export interface CreateReInspectionDTO {
  id: string;
  vin: string;
  odometer: number | undefined;
  odometerUoD: number;
  hubometer: number | undefined;
  hubometerUoD: number | undefined;
  contactId: string;
}

export const defaultCreateReInspectionDTO: CreateReInspectionDTO = {
  id: '',
  vin: '',
  odometer: undefined,
  odometerUoD: 0,
  hubometer: undefined,
  hubometerUoD: 0,
  contactId: '',
};
