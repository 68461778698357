import { ContactInfo, Question } from './InspectionDTO';
import { VehicleDTO } from './VehicleDTO';

export interface CreateInspectionDTO {
  vin: string;
  unitNumber: string | undefined;
  odometer: number | undefined;
  odometerUoD: number;
  hubometer: number | undefined;
  hubometerUoD: number | undefined;
  plate: string | undefined;
  jurisdiction: number | undefined;
  program: string;
  type: string;
  station: string;
  expiryDate: Date | undefined;
  contactId: string;
  questions: Question[] | undefined;
}
export interface ContactPreQuestionDTO {
  questions: Question[] | undefined;
}

export const defaultContactPreQuestionDTO: ContactPreQuestionDTO = {
  questions: undefined,
  // [
  //   {
  //     type: 1,
  //     code: '',
  //     text: '',
  //     response: false,
  //     reason: '',
  //     attachments: undefined,
  //     damageDetails: undefined,
  //   },
  // ],
};

export const defaultCreateInspectionDTO: CreateInspectionDTO = {
  vin: '',
  unitNumber: '',
  odometer: undefined,
  odometerUoD: 0,
  hubometer: undefined,
  hubometerUoD: undefined,
  plate: '',
  jurisdiction: undefined,
  program: '',
  type: '',
  station: '',
  expiryDate: undefined,
  contactId: '',
  questions: undefined,
};

export interface CreateInspectionHelperDetailsDTO {
  contact: ContactInfo;
  vehicle: VehicleDTO;
}

export const defaultCreateInspectionHelperContactDTO: ContactInfo = {
  customerNumber: '',
  email: '',
  firstName: '',
  lastName: '',
  id: '',
  address: {
    street: '',
    city: '',
    province: '',
    postalCode: '',
  },
  phoneInfo: {
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    workPhoneExtension: '',
  },
};

export const defaultCreateInspectionHelperVehicleDTO: VehicleDTO = {
  id: '',
  vin: '',
  status: 0,
  year: 0,
  make: '',
  model: '',
  colour: '',
  bodyStyle: '',
  gvwr: 0,
  fuelCode: 0,
  vinExemptionReason: 0,
  dataVersion: 0,
};

export const defaultCreateInspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO =
  {
    contact: defaultCreateInspectionHelperContactDTO,
    vehicle: defaultCreateInspectionHelperVehicleDTO,
  };
