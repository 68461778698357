import { Box, Grid, GridItem } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { useGetInspectionByIdVinQuery } from 'api/InspectTechUIAPI';
import LoadingModal from 'App/Common/LoadingModal';
import { Step, Stepper } from 'App/Common/Stepper';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CreateInspectionHelperDetailsDTO,
  defaultCreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import {
  CreateReInspectionDTO,
  defaultCreateReInspectionDTO,
} from 'Types/CreateReInspectionDTO';
import ConfirmReInspection from './StepForm/ConfirmDetails/ConfirmReInspection';
import ContactInfoStep from './StepForm/ContactInfo/ContactInfoStep';
import VerifyVehicleInformation from './StepForm/VerifyVehicle/VerifyVehicleInformation';

export const CreateReInspectionMultistepForm = () => {
  const { inspectionId } = useParams<'inspectionId'>();
  const { vin } = useParams<'vin'>();
  const [step, setStep] = useState<number>(0);

  const [
    createInspectionHelperDetailsDTO,
    setCreateInspectionHelperDetailsDTO,
  ] = useState<CreateInspectionHelperDetailsDTO>(
    defaultCreateInspectionHelperDetailsDTO,
  );

  const {
    data: inspection,
    isSuccess: isGetInspectionSuccess,
    isLoading: isLoadingGetInspection,
  } = useGetInspectionByIdVinQuery({
    inspectionId: inspectionId ?? '',
    vin: vin ?? '',
  });

  const [createReInspectionDTO, setCreateReInspectionDTO] =
    useState<CreateReInspectionDTO>({
      ...defaultCreateReInspectionDTO,
      id: inspection?.id ?? '',
      vin: inspection?.vin ?? '',
      odometer: undefined,
      odometerUoD: inspection?.odometerUoD ?? 0,
      hubometer: inspection?.hubometer ?? undefined,
      hubometerUoD: inspection?.hubometerUoD ?? undefined,
      contactId: inspection?.contactId ?? '',
    });

  useEffect(() => {
    if (isGetInspectionSuccess) {
      setCreateReInspectionDTO({
        ...defaultCreateReInspectionDTO,
        id: inspection.id,
        vin: inspection.vin,
        odometer: undefined,
        odometerUoD: inspection.odometerUoD,
        hubometer: inspection.hubometer ?? undefined,
        hubometerUoD: inspection.hubometerUoD ?? undefined,
        contactId: inspection.contactId,
      });
      setCreateInspectionHelperDetailsDTO({
        ...createInspectionHelperDetailsDTO,
        contact: { ...inspection.contactInfo, id: inspection.contactId },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetInspectionSuccess, inspection]);

  const steps: Step[] = [
    {
      subtitle: 'Verify Vehicle Info',
      component: (
        <VerifyVehicleInformation
          step={step}
          setStep={setStep}
          vehicleInfo={inspection?.vehicleInfo}
          inspection={createReInspectionDTO}
          setInspection={setCreateReInspectionDTO}
        />
      ),
    },
    {
      subtitle: 'Contact Info',
      component: (
        <ContactInfoStep
          step={step}
          setStep={setStep}
          contactId={createReInspectionDTO?.contactId ?? ''}
          setInspection={setCreateReInspectionDTO}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
          setCreateInspectionHelperDetailsDTO={
            setCreateInspectionHelperDetailsDTO
          }
        />
      ),
    },
    {
      subtitle: 'Confirm Details',
      component: (
        <ConfirmReInspection
          step={step}
          setStep={setStep}
          inspection={inspection!}
          createDTO={createReInspectionDTO}
          inspectionHelperDetailsDTO={createInspectionHelperDetailsDTO}
        />
      ),
    },
  ];

  return (
    <>
      <LoadingModal
        show={isLoadingGetInspection}
        //show={isCreateReInspectionLoading || isLoadingGetInspection}
      />
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Create ReInspection</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" p={4} borderRadius={8}>
          {/*Replace this div with a proper banner component at some point */}
          <Typography variant="h3">{steps[step].subtitle}</Typography>
          <br />
          <Stepper steps={steps} index={step} />
          <br />
          <Card className="card-background">{steps[step].component}</Card>
        </Box>
      </Box>
    </>
  );
};

export default CreateReInspectionMultistepForm;
