import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import {
  Button,
  Card,
  ErrorAlert,
  Radio,
  RadioGroup,
  TextArea,
} from '@sgi/gravity';
import UploadDocument from 'App/Common/Questions/UploadDocument';
import { QuestionYesNoList } from 'constants/variableConstants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Question, QuestionAttachmentDto } from 'Types/InspectionDTO';
import { validationMessages } from 'constants/messageConstants';

interface UpdateInspectionQAProp {
  inspectionId: string;
  question: Question;
  vin: string;
  dataVersion?: number;
  updateQuestion: any;
  setShowUpdateQuestionsDialog: any;
}

export const UpdateInspectionQA = ({
  inspectionId,
  question,
  vin,
  dataVersion,
  updateQuestion,
  setShowUpdateQuestionsDialog,
}: UpdateInspectionQAProp) => {
  const formik = useFormik({
    initialValues: { ...question },
    validationSchema: Yup.object({
      response: Yup.boolean()
        .typeError(validationMessages.Question.response)
        .required(validationMessages.Question.response),
      reason: Yup.string()
        .nullable()
        .when('response', {
          is: (response: boolean) => response === false,
          then: Yup.string()
            .nullable()
            .required(validationMessages.Question.reason),
        }),
      attachments: Yup.array()
        .nullable()
        .when('response', {
          is: (response: boolean) => response === true,
          then: Yup.array()
            .nullable()
            .required(validationMessages.Question.attachments)
            .min(1, validationMessages.Question.attachments),
        }),
    }),
    onSubmit: (value: Question) => {
      updateQuestion({
        id: inspectionId,
        vin: vin,
        question: value,
        dataVersion: dataVersion,
      });
    },
  });

  const onDocumentChange = (
    fileNames: QuestionAttachmentDto[] | undefined,
    questionCode: string,
  ) => {
    if (question) {
      formik.setFieldValue(
        `attachments`,
        fileNames && fileNames.length > 0 ? fileNames : null,
      );
    }
  };

  const onResponseChange = async (value: boolean) => {
    await formik.setFieldValue(`response`, value);
    if (value) {
      await formik.setFieldValue(`reason`, null);
    } else {
      await formik.setFieldValue(`attachments`, null);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack w="100%" alignItems="start">
        <Card
          cardStyleProps={{ width: '100%' }}
          id={`question`}
          data-testid={question.code}
        >
          <VStack w="100%" alignItems="start">
            <RadioGroup
              isRequired={false}
              hideOptionalLabel={true}
              label={question.text}
              value={
                formik.values.response === undefined
                  ? undefined
                  : formik.values.response
              }
              error={
                formik.touched.response && formik.errors.response
                  ? formik.errors.response
                  : undefined
              }
              data-testid={`updateQuestion-response-${question.code}`}
              onChange={(e) => {
                onResponseChange(Boolean(e));
              }}
              variant="button"
              name={`response`}
            >
              <HStack>
                {QuestionYesNoList.length > 0 &&
                  QuestionYesNoList.map((item: any, i: number) => {
                    return (
                      <Radio
                        key={`radio-questions.response${item.value}`}
                        value={item.value.toString()}
                        aria-label={`radio-questions.response-${item.value}`}
                      >
                        <Center>
                          <HStack>
                            <Box>{item.text}</Box>
                          </HStack>
                        </Center>
                      </Radio>
                    );
                  })}
              </HStack>
            </RadioGroup>
            {formik.values.response === false && (
              <>
                <TextArea
                  value={formik.values.reason ? formik.values.reason : ''}
                  onChange={(e) =>
                    formik.setFieldValue(`reason`, e.target.value)
                  }
                  isRequired={false}
                  hideOptionalLabel={true}
                  data-testid={`updateQuestion-reason-${question.code}`}
                  label="Comment"
                  error={
                    formik.touched.reason && formik.errors.reason
                      ? formik.errors.reason
                      : undefined
                  }
                  name={`reason`}
                ></TextArea>
              </>
            )}
            {formik.values.response === true && (
              <>
                <UploadDocument
                  question={question}
                  onDocumentChange={onDocumentChange}
                  inspectionId={inspectionId}
                  isEditMode={true}
                ></UploadDocument>

                {formik.touched.attachments && formik.errors.attachments && (
                  <ErrorAlert showCloseButton={false}>
                    {formik.errors.attachments}
                  </ErrorAlert>
                )}
              </>
            )}
          </VStack>
        </Card>
        <HStack w="100%" justifyContent={'flex-end'}>
          <Button
            variant="secondary"
            data-testid="cancel-button"
            onClick={() => {
              setShowUpdateQuestionsDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            data-testid="updateQuestion-submit"
            aria-label="updateQuestion-Submit"
            variant="primary"
            type="submit"
          >
            Update
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default UpdateInspectionQA;
