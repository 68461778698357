import { Icon } from '@chakra-ui/react';
import { faCheckIcon, faQuestionIcon, faXMark } from 'App/Common/Icons';

interface WheelInspectionStatusBadgeProps {
  status: string;
}

export const WheelInspectionStatusBadge = ({
  status,
}: WheelInspectionStatusBadgeProps) => {
  switch (status) {
    case 'Pass':
      return (
        <Icon fill="green.500" aria-label="axle-wheel-pass-badge">
          {faCheckIcon}
        </Icon>
      );
    case 'Fail':
      return (
        <Icon fill="red.500" aria-label="axle-wheel-rejected-badge">
          {faXMark}
        </Icon>
      );
    default:
      return (
        <Icon fill="gray.500" aria-label="axle-wheel-unknown-badge">
          {faQuestionIcon}
        </Icon>
      );
  }
};

export default WheelInspectionStatusBadge;
