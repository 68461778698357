import { Route, Routes } from 'react-router';
import CreateInspectionMultistepForm from './components/CreateInspectionMultistepForm';
import CreateReInspection from './CreateReInspection';
import Inspection from './Inspection';
import InspectionSummaryPage from './InspectionSummaryPage';
export const InspectionRouter = () => {
  return (
    <Routes>
      <Route
        path={'/create'}
        element={<CreateInspectionMultistepForm />}
      ></Route>
      <Route path={'/list'} element={<InspectionSummaryPage />}></Route>
      <Route path={':inspectionId/:vin'} element={<Inspection />}></Route>
      <Route
        path={'/reinspection/:inspectionId/:vin'}
        element={<CreateReInspection />}
      ></Route>
      <Route path="*" element={<div>No route match</div>} />
    </Routes>
  );
};

export default InspectionRouter;
