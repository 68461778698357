async function readFileAsDataURL(file: File): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', (evt) => {
      if (reader.result) {
        resolve(reader.result as string);
      }
    });
    reader.readAsDataURL(file);
  });
}
const validateFileType = (fileName: string, accept?: string) => {
  if (accept) {
    const acceptDocTypes = accept.replaceAll(' ', '').replaceAll(',', '|');
    const re = new RegExp(
      `([a-zA-Z0-9\\s_\\\\.\\-():])+(${acceptDocTypes})$`,
      'i',
    );
    return re.test(fileName);
  }
  return true;
};

export { readFileAsDataURL, validateFileType };
