import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Alert, Button, Input, Radio, RadioGroup, Select } from '@sgi/gravity';
import { useAnyInProgressInspectionQuery } from 'api/InspectTechUIAPI';
import { faArrowLeftIcon, faArrowRightIcon } from 'App/Common/Icons';
import {
  inspectionMessages,
  maxOdometer,
  validationMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CreateInspectionDTO } from 'Types/CreateInspectionDTO';
import {
  InspectionJurisdiction,
  InspectionJurisdictionLabel,
  UnitOfDistance,
} from 'Types/InspectionEnums';
import { InputMapper } from 'Utils/InputMapper';
import * as Yup from 'yup';
import {
  AdditionalVehicleInfoDTO,
  defaultAdditionalVehicleInfoDTO,
} from './AdditionalVehicleInfoDto';

interface AdditionalVehicleInfoProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  setInspection: React.Dispatch<React.SetStateAction<CreateInspectionDTO>>;
}

const AdditionalVehicleInfo = ({
  step,
  setStep,
  inspection,
  setInspection,
}: AdditionalVehicleInfoProps) => {
  const [skip, setSkip] = useState(true);
  const [anyInProgressInspection, setAnyInProgressInspection] =
    useState<boolean>(false);

  useEffect(() => {
    if (inspection.vin) {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  const { data: anyInspectionInProgressResult } =
    useAnyInProgressInspectionQuery(inspection.vin ?? '', { skip });

  useEffect(() => {
    if (anyInspectionInProgressResult) {
      setSkip(true);
      setAnyInProgressInspection(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anyInspectionInProgressResult]);

  const formik = useFormik({
    initialValues: {
      ...defaultAdditionalVehicleInfoDTO,
      unitNumber: inspection.unitNumber,
      odometer: inspection.odometer ? inspection.odometer.toString() : '',
      odometerUoD: inspection.odometerUoD,
      plate: inspection.plate === undefined ? '' : inspection.plate,
      jurisdiction: !inspection.jurisdiction
        ? undefined
        : Number(inspection.jurisdiction),
    },
    validationSchema: Yup.object().shape({
      unitNumber: Yup.string(),
      odometer: Yup.number()
        .typeError(validationMessages.odometer.type)
        .min(1, validationMessages.odometer.range)
        .max(maxOdometer, validationMessages.odometer.range)
        .required(validationMessages.odometer.required)
        .integer(validationMessages.odometer.invalid),
      odometerUoD: Yup.number().required(
        validationMessages.odometerUoD.required,
      ),
      plate: Yup.string(),
      jurisdiction: Yup.number().when('plate', {
        is: (plate: string) => !!plate,
        then: Yup.number().required(validationMessages.jurisdiction.required),
      }),
    }),

    onSubmit: (values: AdditionalVehicleInfoDTO) => {
      setInspection((prev) => {
        return {
          ...prev,
          unitNumber: values.unitNumber,
          odometer: Number(values.odometer),
          odometerUoD: Number(values.odometerUoD),
          plate: values.plate === undefined ? '' : values.plate,
          jurisdiction: !values.jurisdiction
            ? undefined
            : Number(values.jurisdiction),
        };
      });

      setStep(step + 1);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <VStack align="flex-start">
          {anyInProgressInspection && (
            <Alert status="warning" showCloseButton={false}>
              {inspectionMessages.alreadyInProgressInspection + inspection.vin}
            </Alert>
          )}

          <Grid
            templateColumns="repeat(4, 1fr)"
            templateRows="repeat(4, 1fr)"
            columnGap={4}
            rowGap={4}
          >
            <GridItem colSpan={1} rowSpan={1}>
              <Input
                name="vin"
                data-testid="inspection-create-vin"
                placeholder="VIN"
                isRequired={true}
                size="md"
                label="VIN"
                isDisabled={true}
                value={inspection.vin}
              />
            </GridItem>
            <GridItem colSpan={1} rowSpan={1} rowStart={2} rowEnd={2}>
              <Input
                name="unitNumber"
                data-testid="inspection-create-unitNumber"
                placeholder="Unit Number"
                isRequired={false}
                size="md"
                label="Unit Number"
                error={formik.errors.unitNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.unitNumber}
              />
            </GridItem>
            <GridItem colSpan={2} rowStart={3} rowEnd={3}>
              <HStack alignItems={'center'}>
                <Input
                  name="odometer"
                  data-testid="inspection-create-odometer"
                  placeholder="Odometer"
                  isRequired={false}
                  hideOptionalLabel={true}
                  size="md"
                  label="Odometer"
                  error={
                    formik.touched.odometer && formik.errors.odometer
                      ? formik.errors.odometer
                      : undefined
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.odometer?.toString()}
                />
                <RadioGroup
                  name="odometerUoD"
                  value={formik.values.odometerUoD.toString()}
                  data-testid="inspection-create-odometerUoD"
                  onChange={(e) => {
                    formik.setFieldValue('odometerUoD', e.toString());
                  }}
                  variant="radio"
                  label="Unit of Distance"
                  isRequired={true}
                  hideOptionalLabel={true}
                  error={formik.errors.odometerUoD}
                >
                  <HStack>
                    {InputMapper.bindTextValuePairs(UnitOfDistance, true)
                      .length > 0 &&
                      InputMapper.bindTextValuePairs(UnitOfDistance, true).map(
                        (item, i) => {
                          return (
                            <Radio
                              value={item.value}
                              key={`odometerUoD${item.value}`}
                            >
                              {item.text}
                            </Radio>
                          );
                        },
                      )}
                  </HStack>
                </RadioGroup>
              </HStack>
            </GridItem>
            <GridItem colSpan={1} rowStart={4} rowEnd={4}>
              <Input
                name="plate"
                data-testid="inspection-create-plate"
                placeholder="Plate"
                size="md"
                label="Plate Number"
                error={formik.errors.plate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.plate}
              />
            </GridItem>
            <GridItem colSpan={1} rowStart={4} rowEnd={4}>
              <Select
                hideOptionalLabel={true}
                error={formik.errors.jurisdiction}
                label="Jurisdiction"
                name="jurisdiction"
                onChange={formik.handleChange}
                value={
                  formik.values.jurisdiction
                    ? formik.values.jurisdiction?.toString()
                    : ''
                }
                data-testid="inspection-create-jurisdiction"
                options={InputMapper.bindLabelValueWithLabelEnum(
                  InspectionJurisdiction,
                  InspectionJurisdictionLabel,
                )}
                size="md"
              />
            </GridItem>
          </Grid>

          <HStack w="100%" justifyContent="space-between">
            <Button
              variant="secondary"
              leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
              onClick={() => setStep(step - 1)}
            >
              Previous step
            </Button>
            <Button
              variant="primary"
              type="submit"
              data-testid="AdditionalVehicleInfo-Continue"
              rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
            >
              Continue to the next step
            </Button>
          </HStack>
        </VStack>
      </Box>
    </form>
  );
};

export default AdditionalVehicleInfo;
