export const formatPhone = (phone: string | undefined) => {
  if (!phone) return undefined;

  if (phone.length > 11 || phone.length < 10) return phone;
  //Checking for country code for US and Canada
  if (phone.length === 11 && phone.charAt(0) !== '1') return phone;

  //Filter only numbers from the input
  let cleaned = ('' + phone).replace(/\D/g, '');

  //Check if the input is of correct
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return undefined;
};

export const formatPostalCode = (postalCode: string | undefined) => {
  return postalCode === undefined
    ? null
    : postalCode.toUpperCase().replace(/\W/g, '').replace(/(...)/, '$1 ');
};
