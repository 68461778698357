import { Grid, GridItem } from '@chakra-ui/react';
import {
  InspectionApprovalStatus,
  InspectionDTO,
  InspectionStatus,
} from 'Types/InspectionDTO';
import { formatDateString } from 'Utils/dateUtils';
import { formatId } from 'Utils/inspectionUtils';

interface Props {
  inspection: InspectionDTO;
  proofOfInspection?: boolean;
}

export const InspectionPrintDetails = ({
  inspection,
  proofOfInspection,
}: Props) => {
  return (
    <>
      <div className="print-section">
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem
            data-testid="print-station"
            colSpan={1}
          >{`Station Name: ${inspection.station}`}</GridItem>
          <GridItem
            data-testid="print-certifiedTechnician"
            colSpan={1}
          >{`Certified Technician: ${inspection.certifiedTechnician}`}</GridItem>
          <GridItem
            data-testid="print-signingOfficer"
            colSpan={1}
          >{`Signing Officer: ${inspection.signingOfficer}`}</GridItem>
        </Grid>
      </div>
      {inspection.status === InspectionStatus.Pass && (
        <div className="print-section">
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem
              data-testid="print-inspectionNumber"
              colSpan={1}
            >{`Inspection Number: ${formatId(inspection.id)}`}</GridItem>
            {inspection.approvalStatus ===
              InspectionApprovalStatus.Approved && (
              <GridItem data-testid="print-approvalDate" colSpan={1}>
                {`Inspection Completed Date: ${
                  inspection.approvalDate
                    ? formatDateString(inspection.approvalDate)
                    : 'N/A'
                }`}
              </GridItem>
            )}
            {proofOfInspection && (
              <GridItem data-testid="print-expiryDate" colSpan={1}>
                {`Inspection Expiry Date: ${
                  inspection.expiryDate
                    ? formatDateString(inspection.expiryDate)
                    : 'N/A'
                }`}
              </GridItem>
            )}
            {!proofOfInspection && (
              <GridItem data-testid="print-passMessage" colSpan={1}>
                PASS - vehicle passes inspection
              </GridItem>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default InspectionPrintDetails;
