import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { faCommentIcon } from 'App/Common/Icons';
import InspectionQuestions from 'App/Common/Questions/InspectionQuestions';
import { InspectionItemText } from 'constants/messageConstants';
import { QuestionCodes } from 'constants/variableConstants';
import { getIn } from 'formik';
import { useEffect, useState } from 'react';
import {
  InspectionBodyDTO,
  InspectionItemStatus,
  Question,
} from 'Types/InspectionDTO';
import { InspectionProgram } from 'Types/InspectionEnums';
import InspectionItemAdditionalFields from './InspectionItemAdditionalFields';
import InspectionItemBadge from './InspectionItemBadge';
import InspectionItemDetails from './InspectionItemDetails';

interface InspectionItemsCategoryProps {
  category: InspectionBodyDTO;
  categoryIndex: number;
  program: string | undefined;
  inspectionId: string | undefined;
  previousInspectionId: string | undefined;
  passPendingItems: any;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  setFieldValue: (field: string, value: any) => void;
  errors: any;
  touched: any;
  isDisabled?: boolean;
}

export interface InspectionItem {
  id: string;
  index: number;
  name: string;
  comment?: string;
  status: number | undefined;
  questions: Question[] | undefined;
  wasPreviouslyRejected?: boolean;
  wasPrevRejectedImmediatelyRepaired?: boolean;
}
const questionVisibility = (status: number): boolean | undefined => {
  if (status === 1) return false;
  else return undefined;
};
const defaultInspectionItem: InspectionItem = {
  id: '',
  index: 0,
  name: '',
  status: undefined,
  questions: [],
};

const InspectionItemsCategory = ({
  category,
  categoryIndex,
  program,
  inspectionId,
  previousInspectionId,
  passPendingItems,
  onChange,
  setFieldValue,
  errors,
  touched,
  isDisabled = false,
}: InspectionItemsCategoryProps) => {
  const [selectedItem, setSelectedItem] = useState<InspectionItem>(
    defaultInspectionItem,
  );
  const [statusKeys, setStatusKeys] = useState([] as string[]);

  useEffect(() => {
    switch (program) {
      case InspectionProgram.BodyIntegrity: {
        setStatusKeys(
          Object.keys(InspectionItemStatus)
            .filter((k) => !isNaN(Number(k)))
            .filter(
              (k) =>
                k === InspectionItemStatus.Default.toString() ||
                k === InspectionItemStatus.Pass.toString() ||
                k === InspectionItemStatus.Rejected.toString(),
            ),
        );
        break;
      }
      default: {
        setStatusKeys(
          Object.keys(InspectionItemStatus).filter((k) => !isNaN(Number(k))),
        );
      }
    }
  }, [program]);

  return (
    <Grid templateColumns="repeat(3,1fr)" gap={4}>
      <GridItem>
        <Card>
          <VStack
            alignItems="start"
            maxHeight="700px"
            overflowY="auto"
            display={'inline-block'}
            __css={{
              '&::-webkit-scrollbar': {
                w: '2',
              },
              '&::-webkit-scrollbar-track': {
                w: '6',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '15',
                bg: `gray.200`,
              },
            }}
          >
            <Button
              width={'405px'}
              variant="primary"
              data-testid="inspection-passpendingitems-button"
              disabled={isDisabled}
              onClick={() => {
                passPendingItems(categoryIndex);
              }}
            >
              Pass Pending Items
            </Button>
            {category.items.map((item, itemIndex) => {
              return (
                <Box
                  key={itemIndex}
                  w="100%"
                  padding="4px 16px"
                  borderRadius={8}
                  _hover={{ cursor: 'pointer', background: 'gray.100' }}
                  onClick={() => {
                    setSelectedItem({ ...item, index: itemIndex });
                  }}
                  bg={
                    selectedItem.id === item.id
                      ? 'teal.50'
                      : getIn(errors, `items[${itemIndex}]`)
                      ? 'red.100'
                      : ''
                  }
                >
                  <HStack width="100%" justify="space-between">
                    <HStack>
                      <Typography>{item.name}</Typography>
                      {item.comment && (
                        <Icon
                          fill="gray.800"
                          data-testid="existing-comment-icon"
                        >
                          {faCommentIcon}
                        </Icon>
                      )}
                    </HStack>

                    <InspectionItemBadge
                      status={
                        getIn(errors, `items[${itemIndex}]`)
                          ? InspectionItemStatus.Default
                          : item.status
                      }
                    />
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        </Card>
      </GridItem>
      <GridItem colSpan={2}>
        <>
          {selectedItem.id !== '' && (
            <Box>
              <InspectionItemDetails
                item={category.items[selectedItem.index]}
                onChange={onChange}
                program={program}
                categoryIndex={categoryIndex}
                itemIndex={selectedItem.index}
                setFieldValue={setFieldValue}
                statusKeys={statusKeys}
                errors={getIn(errors, `items[${selectedItem.index}]`)}
                isDisabled={isDisabled}
              />
              <br />
              <InspectionQuestions
                data-testid="inspection-questions"
                inspectionQuestions={
                  category.items[selectedItem.index].questions
                }
                isHidden={questionVisibility(
                  category.items[selectedItem.index].status === undefined
                    ? 0
                    : Number(category.items[selectedItem.index].status),
                )}
                previousInspectionId={previousInspectionId}
                onChange={onChange}
                namePrefix={`body[${categoryIndex}].items[${selectedItem.index}].questions`}
                setFieldValue={setFieldValue}
                errors={getIn(errors, `items[${selectedItem.index}].questions`)}
                touched={getIn(
                  touched,
                  `items[${selectedItem.index}].questions`,
                )}
                inspectionId={inspectionId}
                isDisabled={isDisabled}
              />

              {(category.items[selectedItem.index].wasPreviouslyRejected ===
                true ||
                category.items[selectedItem.index]
                  .wasPrevRejectedImmediatelyRepaired === true) &&
                category.items[selectedItem.index].name ===
                  InspectionItemText.BodyAndStructuralMeasurements.title &&
                category.items[selectedItem.index].status === 1 && (
                  <InspectionItemAdditionalFields
                    item={category.items[selectedItem.index]}
                    onChange={onChange}
                    program={program}
                    categoryIndex={categoryIndex}
                    itemIndex={selectedItem.index}
                    setFieldValue={setFieldValue}
                    statusKeys={statusKeys}
                    errors={getIn(errors, `items[${selectedItem.index}]`)}
                    isDisabled={isDisabled}
                  />
                )}

              {program === InspectionProgram.BodyIntegrity &&
                previousInspectionId == null &&
                category.items[selectedItem.index].name ===
                  InspectionItemText.BodyAndStructuralMeasurements.title &&
                category.items[selectedItem.index].status ===
                  InspectionItemStatus.Pass &&
                category.items[selectedItem.index].questions?.find(
                  (q) =>
                    q.code ===
                    QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
                )?.response === true && (
                  <InspectionItemAdditionalFields
                    item={category.items[selectedItem.index]}
                    onChange={onChange}
                    program={program}
                    categoryIndex={categoryIndex}
                    itemIndex={selectedItem.index}
                    setFieldValue={setFieldValue}
                    statusKeys={statusKeys}
                    errors={getIn(errors, `items[${selectedItem.index}]`)}
                    isDisabled={isDisabled}
                  />
                )}
            </Box>
          )}
        </>
      </GridItem>
    </Grid>
  );
};

export default InspectionItemsCategory;
