import { Grid, GridItem } from '@chakra-ui/react';
import { InspectionDTO } from 'Types/InspectionDTO';
import { InspectionJurisdiction, UnitOfDistance } from 'Types/InspectionEnums';

interface Props {
  inspection: InspectionDTO;
  proofOfInspection?: boolean;
}

export const VehiclePrintDetails = ({
  inspection,
  proofOfInspection,
}: Props) => {
  return (
    <>
      <div className="print-section">
        <Grid templateColumns="repeat(2, 1fr)">
          <GridItem colSpan={2}>Vehicle Details:</GridItem>
          <GridItem
            data-testid="print-vin"
            colSpan={1}
          >{`VIN: ${inspection.vin}`}</GridItem>
          <GridItem data-testid="print-odometer" colSpan={1}>
            {`Odometer Reading: ${inspection.odometer.toLocaleString()} ${UnitOfDistance[
              inspection.odometerUoD
            ].toLowerCase()}`}
          </GridItem>
          <GridItem
            data-testid="print-year"
            colSpan={1}
          >{`Year: ${inspection.vehicleInfo.year}`}</GridItem>
          <GridItem
            data-testid="print-makeModel"
            colSpan={1}
          >{`Vehicle Make/Model: ${inspection.vehicleInfo.make} ${inspection.vehicleInfo.model}`}</GridItem>
          {proofOfInspection && (
            <>
              <GridItem
                data-testid="print-plate"
                colSpan={1}
              >{`Plate: ${inspection.plate}`}</GridItem>
              <GridItem data-testid="print-jurisdiction" colSpan={1}>
                {`Jurisdiction: ${
                  InspectionJurisdiction[inspection.jurisdiction] ?? 'N/A'
                }`}
              </GridItem>
            </>
          )}
          <GridItem
            data-testid="print-unit"
            colSpan={1}
          >{`Unit: ${inspection.unitNumber}`}</GridItem>
        </Grid>
      </div>
    </>
  );
};

export default VehiclePrintDetails;
