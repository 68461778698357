import { VStack } from '@chakra-ui/react';
import { Card, FormControl, TextArea } from '@sgi/gravity';
import { getIn } from 'formik';
import { InspectionItemDTO } from 'Types/InspectionDTO';

interface InspectionItemAdditionalFieldsProps {
  item: InspectionItemDTO;
  itemIndex: number;
  program: string | undefined;
  categoryIndex: number;
  statusKeys: string[];
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  errors: any;
  isDisabled?: boolean;
}

const InspectionItemAdditionalFields = ({
  item,
  itemIndex,
  program,
  categoryIndex,
  errors,
  statusKeys,
  setFieldValue,
  onChange,
  isDisabled = false,
}: InspectionItemAdditionalFieldsProps) => {
  return (
    <Card>
      <VStack alignItems="start">
        <FormControl
          name={`body[${categoryIndex}].items[${itemIndex}].additionalFields`}
        >
          <TextArea
            hideOptionalLabel={true}
            label={'Repair Detail Note'}
            name={`body[${categoryIndex}].items[${itemIndex}].additionalFields.repairDetailNote`}
            data-testid="repairDetailNote"
            value={item.additionalFields?.repairDetailNote}
            onChange={onChange}
            error={getIn(errors, `additionalFields.repairDetailNote`)}
            maxLength={20}
            isDisabled={isDisabled}
          />
          <br />
          <TextArea
            label={'Donor Vehicle (VINs)'}
            name={`body[${categoryIndex}].items[${itemIndex}].additionalFields.donorVehicle`}
            data-testid="donorVehicle"
            value={item.additionalFields?.donorVehicle}
            onChange={onChange}
            error={getIn(errors, `additionalFields.donorVehicle`)}
            maxLength={20}
            isDisabled={isDisabled}
          />
        </FormControl>
      </VStack>
    </Card>
  );
};

export default InspectionItemAdditionalFields;
