import { Divider, HStack, VStack } from '@chakra-ui/react';
import {
  Button,
  Card,
  ErrorMessage,
  Select,
  TextArea,
  Typography,
} from '@sgi/gravity';
import { FormikErrors, FormikTouched, getIn } from 'formik';
import { useEffect, useState } from 'react';
import {
  InspectionDamageDetails,
  InspectionDamageLocation,
  InspectionDamageLocationLabel,
  Question,
} from 'Types/InspectionDTO';
import { InputMapper } from 'Utils/InputMapper';
import { v4 as uuid } from 'uuid';

interface LocationAndDamagesProps {
  namePrefix: string;
  question: Question;
  onChange: (event: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  errors: FormikErrors<Question>;
  touched: FormikTouched<Question>;
}

const LocationAndDamages = ({
  namePrefix,
  question,
  onChange,
  setFieldValue,
  touched,
  errors,
}: LocationAndDamagesProps) => {
  const [selectedLocations, setSelectedLocations] = useState<
    InspectionDamageLocation[]
  >([]);
  const locationArr = InputMapper.bindLabelValueWithLabelEnum(
    InspectionDamageLocation,
    InspectionDamageLocationLabel,
    true,
  );

  const addDamage = () => {
    setFieldValue(`${namePrefix}damageDetails`, [
      ...(question.damageDetails ?? []),
      { location: '', description: '', key: uuid() },
    ]);
  };

  const deleteDamage = (index: number) => {
    const tempArray: InspectionDamageDetails[] = [...question.damageDetails!];
    tempArray.splice(index, 1);
    setFieldValue(`${namePrefix}damageDetails`, [...tempArray]);
  };

  const filteredLocations = (
    selectedLocation: InspectionDamageLocation | undefined,
  ) => {
    let temp = [...locationArr];
    temp = temp.filter(
      (l) =>
        selectedLocation?.toString() === l.value.toString() ||
        !selectedLocations.find((s) => s.toString() === l.value.toString()),
    );
    return temp;
  };

  useEffect(() => {
    let tempArray: InspectionDamageLocation[] = [];
    question.damageDetails?.forEach(
      (d) => d.location && tempArray.push(d.location),
    );

    setSelectedLocations(tempArray);
  }, [question.damageDetails]);

  return (
    <Card cardStyleProps={{ width: '100%' }}>
      <>
        <Typography paddingBottom="10px" variant="bodyBold">
          {question.text}
        </Typography>
        {question.damageDetails &&
          question.damageDetails.map((d, i) => {
            return (
              <VStack align="start" paddingBottom="10px" key={d.key ?? i}>
                {i > 0 && <Divider />}
                <HStack align="baseline">
                  <Select
                    name={`${namePrefix}damageDetails[${i}].location`}
                    hideOptionalLabel
                    data-testid={`damageDetails[${i}]-location`}
                    error={
                      getIn(touched, `damageDetails[${i}].location`) &&
                      getIn(errors, `damageDetails[${i}].location`)
                        ? getIn(errors, `damageDetails[${i}].location`)
                        : undefined
                    }
                    onChange={onChange}
                    value={
                      question.damageDetails![i].location?.toString() ??
                      undefined
                    }
                    options={[
                      { label: '', value: '' },
                      ...filteredLocations(question.damageDetails![i].location),
                    ]}
                  />
                  <Button
                    variant="secondary"
                    data-testid={`damageDetails[${i}]-delete`}
                    onClick={() => {
                      deleteDamage(i);
                    }}
                  >
                    Delete
                  </Button>
                </HStack>
                <TextArea
                  width="100%"
                  name={`${namePrefix}damageDetails[${i}].description`}
                  data-testid={`damageDetails[${i}]-description`}
                  value={d.description}
                  onChange={onChange}
                  error={
                    getIn(touched, `damageDetails[${i}].description`) &&
                    getIn(errors, `damageDetails[${i}].description`)
                      ? getIn(errors, `damageDetails[${i}].description`)
                      : undefined
                  }
                />
              </VStack>
            );
          })}
        <Button
          variant="secondary"
          data-testid="add-damage"
          onClick={() => addDamage()}
        >
          Add New Damage Location
        </Button>
        {errors?.damageDetails &&
          typeof errors?.damageDetails === 'string' &&
          touched?.damageDetails && (
            <ErrorMessage>
              <>{errors.damageDetails ?? ''}</>
            </ErrorMessage>
          )}
      </>
    </Card>
  );
};

export default LocationAndDamages;
