export const maxYear = new Date().getFullYear() + 1;
export const maxOdometer: number = 999999999;

export const validationMessages = {
  vin: {
    required: 'Please enter the VIN',
    length: 'VIN must be 17 characters or less',
    pattern: 'Vin contains invalid characters',
  },
  status: { required: 'Please select a Status' },
  odometer: {
    required: 'Please enter the Odometer Value',
    type: 'Odometer value must be a number',
    range: `Odometer value must be between 1 and ${maxOdometer}`,
    invalid: 'Odometer value is invalid',
  },
  contactId: { required: 'Please associate a contact to the inspection' },
  odometerUoD: { required: 'Please enter the Odometer Unit of Measurement' },
  hubometer: {
    type: 'Hubometer value must be a number',
    invalid: 'Hubometer value is invalid',
  },
  hubometerUoD: { required: 'Please enter the Hubometer Unit of Measurement' },
  jurisdiction: { required: 'Please select a Jurisdiction' },
  stationName: {
    required: 'Please enter the Station Name',
    nonEligible: 'is not eligible to perform the inspection.',
  },
  certifiedTechnicianName: {
    required: 'Please enter the Certified Technician Name',
  },
  year: {
    required: 'Please enter the Year',
    type: 'Year must be a number',
    range: `Year must be between 1850 and ${maxYear}`,
    invalid: 'Year is invalid',
  },
  make: { required: 'Please enter the Make' },
  model: { required: 'Please enter the Model' },
  colour: { required: 'Please enter the Colour' },
  bodyStyle: { required: 'Please enter the Body Style' },
  gvwr: {
    required: 'Please enter the GVWR',
    type: 'GVWR must be a number',
  },
  fuelCode: {
    required: 'Please select the Fuel Type',
  },
  vinExemptionReason: {
    required:
      'Please enter the VIN Exemption Reason as the vehicle year is 1981 or newer and the VIN is less than 17 digits',
  },
  expiryDate: {
    range: 'Invalid Date',
    type: 'Invalid Date',
  },
  startDate: {
    minRange: 'Start Date cannot be a date prior to ',
    maxRange: 'Start Date cannot be a future date',
    type: 'Invalid Date',
    required: 'Please select a Start Date',
  },
  inspectionProgram: {
    required: 'Please select an Inspection Program',
  },
  inspectionType: {
    required: 'Please select an Inspection Type',
    invalidCombination: 'Invalid combination of program and type',
  },
  questionReason: {
    required: 'Please enter the comment',
  },
  inspectionItemComment: {
    required: 'Please enter the comment',
    length: 'Comments cannot be greater than 250 characters',
  },
  inspectionItemRepair: {
    required: 'Please enter the repair detail note',
    length: 'Repair Detail Note cannot be greater than 250 characters',
  },
  wheelTireTreadDepth: {
    required: 'Please enter the Tire Tread Depth',
  },
  brakeMeasurement: {
    required: 'Please enter the Measurement',
  },
  brakeUnit: {
    required: 'Please select the Unit',
  },
  contactCustomerNumber: {
    type: 'Customer Number is invalid',
  },
  contactFirstName: {
    required: 'Please enter the First Name',
  },
  contactLastName: {
    required: 'Please enter the Last Name',
  },
  contactPhone: {
    required: 'At least one contact number is required.',
    type: 'Phone Number must be a number',
    invalid: 'Phone Number is invalid',
    length: 'Please enter a phone number that is 10 digits in length',
  },
  contactWorkPhoneExtension: {
    type: 'Extension must be a number',
    length: 'Work Extension can only be 4 digits',
  },
  contactAddressStreet: {
    required: 'Please enter the Street',
  },
  contactAddressCity: {
    required: 'Please enter the City',
  },
  contactAddressProvince: {
    required: 'Please enter the Province',
  },
  contactAddressPostalCode: {
    required: 'Please enter the Postal Code',
  },
  StationName: {
    required: 'Please enter the Inspection Station Name',
  },
  ShopOwner: {
    required: 'Please enter the Owner of Shop',
  },
  Question: {
    response: 'Please choose the response',
    reason: 'Please provide the reason',
    attachments: 'Attachments must be uploaded',
  },
  AdditionalFields: {
    RepairDetailNote: 'Please enter the Repair Detail Note',
  },
  DamageDetails: {
    min: 'Please enter at least one damage location and description',
    inspectionDamageLocation: 'Please select a damage location',
    description: 'Please enter a description of the damage',
  },
};

export const vehicleMessages = {
  created: 'Vehicle created',
  statusUpdated: 'VIN Status Updated',
  viewPermissionError: 'You do not have permission to view vehicles',
  updateStatusPermissionError:
    'You do not have permission to update vehicles status',
  searchPermissionError: 'You do not have permission to search vehicles',
};

export const inspectionMessages = {
  created: 'Inspection created',
  itemsSaved: 'Inspection Items saved',
  statusUpdated: 'Inspection Status updated',
  completed: 'Inspection complete. Awaiting approval.',
  undoComplete:
    "Inspection has been set back to 'in progress', and can now be edited.",
  approved: 'Inspection approved',
  viewPermissionError: 'You do not have permission to view inspections',
  searchPermissionError: 'You do not have permission to search inspections',
  passInspectionQuestion: 'Want to Pass an inspection?',
  passInspectionValidation:
    'All inspection items must be marked as Pass or Requires Attention and all wheel inspections must be Pass.',
  passWheelInspectionQuestion: 'Want to Pass a wheel inspection?',
  passWheelInspectionValidation:
    'Axle brake type must be selected and all brake measurements must be supplied to pass the wheel inspection.',
  failInspectionQuestion: 'Want to Fail an inspection?',
  failInspectionValidation:
    'All inspection items must be completed and at least one item must be marked as Rejected or at least one wheel inspections must be Failed.',
  bodyIntegrityVehicleId:
    "Pass - Verified VIN's found on the vehicle match the Ownership Documents",
  alreadyInProgressInspection:
    'There is already an in-progress inspection for the VIN ',
  questionsUpdated: 'Question responses are updated',
  questionUpdated: 'Question response are updated',
};

export const reinspectionMessages = {
  created: 'Reinspection created',
};

export const contactMessages = {
  created: 'Contact created',
  updated: 'Contact updated',
  viewPermissionError: 'You do not have permission to view contacts',
  searchPermissionError: 'You do not have permission to search contacts',
  noResultsMessage: 'No Contacts Found',
};

export const brakeInspectionMessages = {
  nonBrakeAtleastValidation: 'At least 2 wheels must have brake measurements.',
  maxAxlesValidation: 'Total maximum allowed number of axles is:',
  maxAxlesWithSingleWheelValidation:
    'Total maximum allowed number of axles, when one of the axles has one wheel is:',
  minAxlesValidation: 'Total minimum required number of axles is:',
  maxWheelsValidation: 'Total maximum allowed number of wheels per axle is:',
  minWheelsValidation: 'Total minimum required number of wheels per axle is:',
  maxWheelsMotorcycleValidation:
    'A motorcycle is allowed to have a maximum of three wheels',
  maxWheelsFrontAxleLightVehicleValidation:
    'A light vehicle is allowed to have a maximum of two wheels on front axle',
  WheelsPerAxleLightVehicleValidation:
    'A light vehicle is not allowed to have three wheels on an axle',
  minWheelsLightVehicleValidation:
    'A light vehicle is allowed to have a minimum of three wheels',
  remainingAxleWheelsLightVehicleValidation:
    'A light vehicle is allowed to have two wheels on the remaining axles when one axle has one wheel',
};

export const stationMessages = {
  created: 'Inspection Station created',
  viewPermissionError: 'You do not have permission to view inspection stations',
  shopOwnerLabelInfo: 'This is the owner as listed on ISC documents',
  searchPermissionError: 'You do not have permission to search inspections',
  noResultsMessage: 'No Stations Found',
};

export const fileUploadMessages = {
  dragAndDropFilesLabel: 'Drag and drop files here',
};

export const filePreviewMessages = {
  previewFileNotSupportedError:
    'Sorry, this file is not supported for preview.',
};

export const RecordOfInspectionMessages = {
  BrakeMeasurementsNote1:
    'Note: Sitting in the driver seat. Axle 1 refers to the front axle and the axle number increases moving towards the rear. Wheel 1 refers to the left most wheel on the axle and the wheel number increases moving to the right.',
  BrakeMeasurementsNote2:
    'One or more wheels were removed for inspection. Warning: Wheel nuts MUST be RE-TORQUED within 100-150 km. Failure to do so may result in damage or serious injury.',
};

export const InspectionItemText = {
  BodyAndStructuralMeasurements: {
    title: 'Body and Structural Measurements',
  },
};
