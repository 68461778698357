import { HStack } from '@chakra-ui/react';
import { Button, Modal, Typography } from '@sgi/gravity';

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
}
function handleClose() {
  return;
}
const UnsavedBlockerDialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Modal
      showCloseButton={false}
      isOpen={showDialog}
      onClose={handleClose}
      data-testid="unsaved-blocker-dialog"
      title={'Are you sure you want to navigate from this page?'}
      footer={
        <HStack>
          <Button variant="secondary" onClick={cancelNavigation}>
            No, stay
          </Button>
          <Button onClick={confirmNavigation}>Yes, ignore my changes</Button>
        </HStack>
      }
    >
      <Typography>
        You have unsaved changes. These changes will be lost if you navigate
        from this page.
      </Typography>
    </Modal>
  );
};
export default UnsavedBlockerDialogBox;
