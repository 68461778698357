import App from 'App';
import { initializeMsalInstance } from 'auth/initializeMsal';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from 'store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';

fetch('/config.json')
  .then((response) => {
    return response.json();
  })
  .then((config) => {
    (window as any)['runConfig'] = config;

    initializeMsalInstance();

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <ToastContainer />
          <App />
        </Provider>
      </React.StrictMode>,
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
