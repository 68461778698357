export enum VinExemptionReason {
  RightHandDriveVehicle = 1,
  OutofCountryVehicle = 2,
  OlderSaskAssignedVIN = 3,
}

export const VinExemptionReasonLabel: {
  [key in VinExemptionReason]: string;
} = {
  [VinExemptionReason.RightHandDriveVehicle]: 'Right Hand Drive Vehicle',
  [VinExemptionReason.OutofCountryVehicle]: 'Out of Country Vehicle',
  [VinExemptionReason.OlderSaskAssignedVIN]: 'Older Sask Assigned VIN',
};

export enum FuelCode {
  Default = 0,
  Butane = 1,
  Diesel = 2,
  Electric = 3,
  ElectricDieselHybrid = 4,
  EthanolFuelOnly = 5,
  FlexibleFuel = 6,
  ElectricGasHybrid = 7,
  Gasoline = 8,
  GasolineandNaturalGas = 9,
  HydrogenFuelCell = 10,
  LiquidPropane = 11,
  MethanolGasOnly = 12,
  NaturalGasOnly = 13,
  Other = 14,
  Propane = 15,
  Unknown = 16,
}

export const FuelCodeLabel: {
  [key in FuelCode]: string;
} = {
  [FuelCode.Default]: '',
  [FuelCode.Butane]: 'Butane',
  [FuelCode.Diesel]: 'Diesel',
  [FuelCode.Electric]: 'Electric',
  [FuelCode.ElectricDieselHybrid]: 'Electric Diesel Hybrid',
  [FuelCode.EthanolFuelOnly]: 'Ethanol Fuel Only',
  [FuelCode.FlexibleFuel]: 'Flexible Fuel',
  [FuelCode.ElectricGasHybrid]: 'Electric Gas Hybrid',
  [FuelCode.Gasoline]: 'Gasoline',
  [FuelCode.GasolineandNaturalGas]: 'Gasoline and Natural Gas',
  [FuelCode.HydrogenFuelCell]: 'Hydrogen Fuel Cell',
  [FuelCode.LiquidPropane]: 'Liquid Propane',
  [FuelCode.MethanolGasOnly]: 'Methanol Gas Only',
  [FuelCode.NaturalGasOnly]: 'Natural Gas Only',
  [FuelCode.Other]: 'Other',
  [FuelCode.Propane]: 'Propane',
  [FuelCode.Unknown]: 'Unknown',
};
