import { FuelCode, VinExemptionReason } from './VehicleEnums';

export type VehicleDTO = {
  id: string;
  vin: string;
  status: VinStatus;
  year: number | undefined;
  make: string;
  model: string;
  colour: string;
  bodyStyle: string;
  gvwr: number | undefined;
  fuelCode: FuelCode;
  vinExemptionReason: VinExemptionReason;
  dataVersion: number | undefined;
  securityFlags?: VehicleSecurityFlags;
};

export enum VinStatus {
  Default = 0, // Default is empty string while displayed
  Normal = 1,
  Mechanical = 2,
  FTR = 3,
  HULK = 4,
  Rebuilt = 5,
  InspectionRequired = 6,
  BodyIntegrity = 7,
  VeryUnsafe = 8,
  Cosmetic = 9,
  RepairsRequired = 10,
  RegisterableTLV = 11,
  Stolen = 12,
  NonRepairable = 13,
  Dismantled = 14,
  SpecialRequest = 15,
}

export const VinStatusLabel: {
  [key in VinStatus]: string;
} = {
  [VinStatus.Default]: '',
  [VinStatus.Normal]: 'Normal',
  [VinStatus.Mechanical]: 'Mechanical',
  [VinStatus.FTR]: 'First Time Registered (FTR)',
  [VinStatus.HULK]: 'HULK',
  [VinStatus.Rebuilt]: 'Rebuilt',
  [VinStatus.InspectionRequired]: 'Inspection Required',
  [VinStatus.BodyIntegrity]: 'Body Integrity',
  [VinStatus.VeryUnsafe]: 'Very Unsafe',
  [VinStatus.Cosmetic]: 'Cosmetic',
  [VinStatus.RepairsRequired]: 'Repairs Required',
  [VinStatus.RegisterableTLV]: 'Registerable Total Loss Vehicle (TLV)',
  [VinStatus.Stolen]: 'Stolen',
  [VinStatus.NonRepairable]: 'Non-Repairable',
  [VinStatus.Dismantled]: 'Dismantled',
  [VinStatus.SpecialRequest]: 'Special Request',
};

export const defaultVehicle: VehicleDTO = {
  id: '',
  vin: '',
  status: VinStatus.Default,
  year: undefined,
  make: '',
  model: '',
  colour: '',
  bodyStyle: '',
  gvwr: undefined,
  fuelCode: FuelCode.Default,
  vinExemptionReason: VinExemptionReason.OlderSaskAssignedVIN,
  dataVersion: undefined,
};

interface VehicleSecurityFlags {
  canUpdateStatus?: boolean;
  canView?: boolean;
}

export type VehicleListDTO = {
  id: string;
  vin: string;
  status: VinStatus;
  securityFlags?: VehicleSecurityFlags;
};
