import { Box, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, useToast } from '@sgi/gravity';
import { faArrowLeftIcon, faArrowRightIcon } from 'App/Common/Icons';

import { useGetInspectionQuestionsQuery } from 'api/InspectTechUIAPI';
import InspectionQuestions from 'App/Common/Questions/InspectionQuestions';
import { validationMessages } from 'constants/messageConstants';
import { getIn, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  ContactPreQuestionDTO,
  CreateInspectionDTO,
  defaultContactPreQuestionDTO,
} from 'Types/CreateInspectionDTO';
import * as Yup from 'yup';

interface ContactPreInspectionQuestionProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  setInspection: React.Dispatch<React.SetStateAction<CreateInspectionDTO>>;
}

const ContactPreInspectionQuestions = ({
  step,
  setStep,
  inspection,
  setInspection,
}: ContactPreInspectionQuestionProps) => {
  const [skip, setSkip] = useState(true);

  const { showToast } = useToast();
  const formik = useFormik({
    initialValues: {
      ...defaultContactPreQuestionDTO,
    },
    validationSchema: Yup.object().shape({
      questions: Yup.array()
        .of(
          Yup.object({
            response: Yup.boolean()
              .typeError(validationMessages.Question.response)
              .required(validationMessages.Question.response),
            reason: Yup.string()
              .nullable()
              .when('response', {
                is: (response: boolean) => response === false,
                then: Yup.string()
                  .nullable()
                  .required(validationMessages.Question.reason),
              }),
            attachments: Yup.array()
              .nullable()
              .when('response', {
                is: (response: boolean) => response === true,
                then: Yup.array()
                  .nullable()
                  .required(validationMessages.Question.attachments),
              }),
          }),
        )
        .required(),
    }),
    onSubmit: (values: ContactPreQuestionDTO) => {
      setInspection((prev) => {
        return {
          ...prev,
          questions: !values.questions ? undefined : values.questions,
        };
      });
      setStep(step + 1);
    },
  });

  useEffect(() => {
    if (inspection.questions && inspection.questions.length > 0) {
      setSkip(true);
      formik.setFieldValue('questions', inspection.questions);
    } else {
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspection.questions]);

  const {
    data: inspectionQuestions,
    isError: isGetContactQuestionsResultsError,
    error: ContactQuestionsError,
    isSuccess: isGetInspectionQuestionsSuccess,
  } = useGetInspectionQuestionsQuery(1, { skip });

  useEffect(() => {
    if (isGetInspectionQuestionsSuccess && inspectionQuestions) {
      formik.setFieldValue('questions', inspectionQuestions);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetInspectionQuestionsSuccess]);

  useEffect(() => {
    if (isGetContactQuestionsResultsError) {
      showToast({
        description: `${ContactQuestionsError}`,
        status: 'error',
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetContactQuestionsResultsError]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <VStack align="flex-start">
          <InspectionQuestions
            data-testid="preinspection-questions"
            inspectionQuestions={formik.values.questions}
            isHidden={false}
            previousInspectionId={''}
            onChange={formik.handleChange}
            namePrefix={`questions`}
            setFieldValue={formik.setFieldValue}
            errors={getIn(formik.errors, `questions`)}
            touched={getIn(formik.touched, `questions`)}
          />
          <HStack w="100%" justifyContent="space-between">
            <Button
              variant="secondary"
              leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
              onClick={() => setStep(step - 1)}
            >
              Previous step
            </Button>
            <Button
              data-testid="ContactPreInspectionQuestions-Continue"
              rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
              aria-label="ContactPreInspectionQuestions-Continue"
              variant="primary"
              type="submit"
            >
              Continue to the next step
            </Button>
          </HStack>
        </VStack>
      </Box>
    </form>
  );
};

export default ContactPreInspectionQuestions;
