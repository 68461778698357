import { Link, Table } from '@sgi/gravity';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import {
  InspectionApprovalStatusLabel,
  InspectionListDTO,
  InspectionStatusLabel,
} from 'Types/InspectionDTO';
import { formatDateString } from 'Utils/dateUtils';
import { formatId } from 'Utils/inspectionUtils';
import {
  getLocalStorageItem,
  LocalStorageItemEnum,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'Utils/localStorageUtils';
import {
  FilterCategory,
  InspectionTableFilter,
} from './InspectionTable/InspectionTableFilter';

interface InspectionSummaryTableProps {
  inspections: InspectionListDTO[];
  filters: FilterCategory[];
  setFilters: Function;
  permission?: boolean;
}

const columnHelper = createColumnHelper<InspectionListDTO>();
const columns = [
  columnHelper.accessor('id', {
    cell: (info) => (
      <Link href={`/inspection/${info.getValue()}/${info.row.original.vin}`}>
        {formatId(info.getValue())}
      </Link>
    ),
    header: () => 'ID',
    meta: {
      columnName: 'ID',
    },
    enableHiding: false,
  }),
  columnHelper.accessor('vin', {
    cell: (info) => info.getValue(),
    header: () => 'VIN',
    meta: {
      columnName: 'VIN',
    },
    enableHiding: false,
  }),
  columnHelper.accessor('type', {
    cell: (info) => info.getValue(),
    header: () => 'Type',
    meta: {
      columnName: 'Type',
    },
  }),
  columnHelper.accessor('program', {
    cell: (info) => info.getValue(),
    header: () => 'Program',
    meta: {
      columnName: 'Program',
    },
  }),
  columnHelper.accessor('station', {
    cell: (info) => info.getValue(),
    header: () => 'Station Name',
    meta: {
      columnName: 'Station Name',
    },
  }),
  columnHelper.accessor('certifiedTechnician', {
    cell: (info) => info.getValue(),
    header: () => 'Certified Technician',
    meta: {
      columnName: 'Certified Technician',
    },
  }),
  columnHelper.accessor('signingOfficer', {
    cell: (info) => info.getValue(),
    header: () => 'Signing Officer',
    meta: {
      columnName: 'Signing Officer',
    },
  }),
  columnHelper.accessor('startDate', {
    cell: (info) =>
      info.getValue() ? formatDateString(info.getValue().toLocaleString()) : '',
    header: () => 'Start Date',
    meta: {
      columnName: 'Start Date',
    },
  }),
  columnHelper.accessor('status', {
    cell: (info) => InspectionStatusLabel[info.getValue()],
    header: () => 'Inspection Status',
    meta: {
      columnName: 'Inspection Status',
    },
  }),
  columnHelper.accessor('approvalDate', {
    cell: (info) =>
      info.getValue() ? formatDateString(info.getValue().toLocaleString()) : '',
    header: () => 'Approval Date',
    meta: {
      columnName: 'Approval Date',
    },
  }),
  columnHelper.accessor('approvalStatus', {
    cell: (info) => InspectionApprovalStatusLabel[info.getValue()],
    header: () => 'Approval Status',
    meta: {
      columnName: 'Approval Status',
    },
  }),
] as ColumnDef<InspectionListDTO>[];

export const InspectionSummaryTable = ({
  inspections,
  filters,
  setFilters,
  permission,
}: InspectionSummaryTableProps) => {
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnVisibility, onColumnVisibilityChange] =
    useState<VisibilityState>({});

  // Sorting
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'startDate',
      desc: true,
    },
  ]);

  useEffect(() => {
    if (Object.keys(columnVisibility).length !== 0) {
      setLocalStorageItem(LocalStorageItemEnum.InspectionTableColumns, {
        ...columnVisibility,
      });
    }
  }, [columnVisibility]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [inspections]);

  const sortInspections = (a: any, b: any) => {
    // Check to see if there are any columns to sort by
    if (sorting.length === 0) {
      return true;
    }

    if (!sorting[0].desc) {
      return a[sorting[0].id]
        ?.toString()
        .localeCompare(b[sorting[0].id]?.toString());
    } else {
      return b[sorting[0].id]
        ?.toString()
        .localeCompare(a[sorting[0].id]?.toString());
    }
  };

  //Checks for the local storage, then sets the value for the Search and Filter form fields if localstoage exist else display form as default.
  useEffect(() => {
    let inspectionTableColumns = getLocalStorageItem<VisibilityState>(
      LocalStorageItemEnum.InspectionTableColumns,
    );
    if (inspectionTableColumns == null) {
      inspectionTableColumns = null;
      removeLocalStorageItem(LocalStorageItemEnum.InspectionTableColumns);
    } else {
      if (inspectionTableColumns !== null) {
        //Set fields value for search inspection form
        onColumnVisibilityChange({ ...inspectionTableColumns });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permission]);

  return (
    <Table
      columns={columns}
      pageIndex={pageIndex}
      pageSize={pageSize}
      renderFilterComponent={
        <InspectionTableFilter filters={filters} setFilters={setFilters} />
      }
      columnsControl
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={onColumnVisibilityChange}
      pageCount={Math.ceil(inspections.length / pageSize) ?? -1}
      totalCount={inspections.length}
      onPageSizeChange={(newPageSize) =>
        setPagination((prev) => ({ pageIndex: 0, pageSize: newPageSize }))
      }
      onPageChange={(page) =>
        setPagination((prev) => ({ ...prev, pageIndex: page }))
      }
      data={inspections
        .sort((a: any, b: any) => sortInspections(a, b))
        .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
      sorting={sorting}
      onSortingChange={setSorting}
      data-testid="table-Inspections-list"
    />
  );
};

export default InspectionSummaryTable;
