import { Grid, GridItem, VStack } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import { ReactComponent as Logo } from 'assets/sgi-logo-black.svg';
import { InspectionDTO } from 'Types/InspectionDTO';
import InspectionPrintDetails from './InspectionPrintDetails';
import VehiclePrintDetails from './VehiclePrintDetails';

interface Props {
  inspection: InspectionDTO;
}

export const ProofOfInspection = ({ inspection }: Props) => {
  return (
    <>
      <Grid templateColumns="repeat(11, 1fr)">
        <GridItem colSpan={4}>
          <Logo style={{ height: '2.25em' }} />
          <span style={{ fontSize: '12px' }}>
            Vehicle Standards and Inspection
          </span>
        </GridItem>
        <GridItem colSpan={7}>
          <VStack alignItems="flex-end">
            <Typography variant="h3">
              {`${inspection.program} Inspection Certificate`}
            </Typography>
            <span style={{ fontSize: '12px' }}>
              This Certificate is issued under the authority of the Province of
              Saskatchewan
            </span>
          </VStack>
        </GridItem>
      </Grid>
      <br />
      <InspectionPrintDetails inspection={inspection} proofOfInspection />
      <VehiclePrintDetails inspection={inspection} proofOfInspection />
    </>
  );
};

export default ProofOfInspection;
