import { VStack } from '@chakra-ui/react';
import { Link, Typography } from '@sgi/gravity';
import { Link as RouterLink } from 'react-router-dom';

export const PageNotFound = () => {
  return (
    <VStack mt={24}>
      <Typography variant="h1">Page Not Found</Typography>
      <Typography>The page you're looking for could not be found</Typography>
      <Link href="/" as={RouterLink}>
        Home Page
      </Link>
    </VStack>
  );
};

export default PageNotFound;
