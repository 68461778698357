import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Alert, Button, Card, Typography, useToast } from '@sgi/gravity';
import ConfirmationDialogBox from 'App/Common/ConfirmationDialogBox';
import { faTrashIcon } from 'App/Common/Icons';
import { brakeInspectionMessages } from 'constants/messageConstants';
import {
  LightVehicleBrakeInspection,
  MidSizeTruckBrakeInspection,
  MotorcycleBrakeInspection,
} from 'constants/variableConstants';
import { FormikErrors, getIn } from 'formik';
import { useReducer, useState } from 'react';
import { InspectionProgram } from 'Types/InspectionEnums';
import { UpdateInspectionItemsAndWheelnfoDTO } from 'Types/UpdateInspectionItemsAndWheelnfoDTO';
import { AxleDTO, AxleWheelLimit, WheelInfoDTO } from 'Types/WheelInfoDTO';
import AxleCategoryHeaderBadges from './AxleCategoryHeaderBadges';
import AxleDetails from './AxleDetails';

interface AxlesCategoryProps {
  wheelInfo: WheelInfoDTO;
  program: InspectionProgram | undefined;
  onChange: (event: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) =>
    | Promise<FormikErrors<UpdateInspectionItemsAndWheelnfoDTO>>
    | Promise<void>;
  errors: any;
  isDisabled?: boolean;
}

const AxlesCategory = ({
  wheelInfo,
  program,
  onChange,
  setFieldValue,
  setFieldTouched,
  errors,
  isDisabled = false,
}: AxlesCategoryProps) => {
  const [selectedAxleIndex, setSelectedAxleIndex] = useState<number>(-1);
  const { showToast } = useToast();
  const CloseConfirmBox = 'CloseConfirmBox';
  const DeleteAxleConfirm = 'DeleteAxleConfirm';

  const getAxleAndWheelLimit = (): AxleWheelLimit => {
    switch (program) {
      case InspectionProgram.LightVehicle:
        return LightVehicleBrakeInspection;
      case InspectionProgram.MidSizeTruck:
        return MidSizeTruckBrakeInspection;
      case InspectionProgram.MotorCycle:
        return MotorcycleBrakeInspection;
      default:
        return MidSizeTruckBrakeInspection;
    }
  };

  const axleWheelLimit = getAxleAndWheelLimit();

  const handleDeleteAxle = (index: number) => {
    let axlesArr = [...wheelInfo.axles];
    if (axlesArr.length > axleWheelLimit.minAxle) {
      axlesArr.splice(index, 1);
      setFieldValue(`wheelInfo.axles`, resetAxleIds(axlesArr));
      closeModalHandler();
      setSelectedAxleIndex(0);
    } else {
      showToast({
        description: `${brakeInspectionMessages.minAxlesValidation} ${axleWheelLimit.minAxle}`,
        status: 'error',
      });
    }
  };

  const resetAxleIds = (axlesArr: AxleDTO[]) => {
    return axlesArr.map((axle, index) => {
      return {
        ...axle,
        id: index + 1,
      };
    });
  };

  const handleAddNewAxle = (event: any) => {
    let singleWheelCount = 0;

    let axlesArr = [...wheelInfo.axles];
    if (program === InspectionProgram.LightVehicle) {
      axlesArr.forEach((axle) => {
        if (axle.wheels.length === axleWheelLimit.minWheel)
          singleWheelCount = singleWheelCount + 1;
      });
      if (singleWheelCount > 0 && axlesArr.length === axleWheelLimit.minAxle) {
        showToast({
          description: `${brakeInspectionMessages.maxAxlesWithSingleWheelValidation} ${axlesArr.length}`,
          status: 'error',
        });
        return;
      }
    }

    if (axlesArr.length < axleWheelLimit.maxAxle) {
      const newAxle: AxleDTO = {
        id: axlesArr.length + 1,
        brakeType: 99,
        conditionAndExternalMeasurements: undefined,
        wasOEMPreviouslyUnchecked: false,
        wheels: [
          {
            id: 1,
            brakeMeasurements: [],
            passesBrakeMeasurements: false,
            passesTreadDepth: false,
            nonBrake: false,
            brakeMeasurementUnit: null,
            tireTreadDepth: null,
            wheelOffInspection: false,
            additionalBrakeMeasurements: [],
            hasAdditionalBrake: false,
          },
          {
            id: 2,
            brakeMeasurements: [],
            passesBrakeMeasurements: false,
            passesTreadDepth: false,
            nonBrake: false,
            tireTreadDepth: null,
            brakeMeasurementUnit: null,
            wheelOffInspection: false,
            additionalBrakeMeasurements: [],
            hasAdditionalBrake: false,
          },
        ],
      };
      axlesArr.push(newAxle);
      setFieldValue(`wheelInfo.axles`, axlesArr);
    } else {
      showToast({
        description: `${brakeInspectionMessages.maxAxlesValidation} ${axleWheelLimit.maxAxle}`,
        status: 'error',
      });
    }
  };

  const [showConfirmCallToAction, setshowConfirmCallToAction] = useState(true);

  const modalReducer = (state: any, action: any) => {
    switch (action.type) {
      case DeleteAxleConfirm:
        setshowConfirmCallToAction(true);
        return {
          heading: `Delete Axle`,
          content: `Are you sure you want to Delete Axle ${
            action.object.index + 1
          }?`,
          confirmButtonText: 'Yes, Delete',
          confirmButtonAction: () => {
            handleDeleteAxle(action.object.index);
          },
          show: true,
        };
      case CloseConfirmBox:
        return {
          heading: '',
          content: '',
          confirmButtonText: '',
          confirmButtonAction: () => function () {},
          show: false,
        };
    }
  };

  const [modalState, modalDispatch] = useReducer(modalReducer, {
    heading: '',
    content: '',
    confirmButtonText: '',
    confirmButtonAction: () => function () {},
    show: false,
  });

  const dispatchModalAction = (
    action: string,
    index: number,
    axle: AxleDTO | null,
  ) => {
    modalDispatch({
      type: action,
      object: {
        index: index,
        axle: axle,
      },
    });
  };

  const closeModalHandler = () => {
    modalDispatch({
      type: CloseConfirmBox,
    });
  };

  return (
    <>
      <Alert
        status="info"
        showCloseButton={false}
        title={`When sitting ${
          program === InspectionProgram.MotorCycle
            ? 'on the motorcycle'
            : 'in the vehicle'
        }:`}
        alertStyleProps={{ marginBottom: '4px' }}
      >
        <VStack align="start">
          <>
            Axle 1 refers to the front most axle and the number increases moving
            towards the rear of the vehicle. <br />
            Tire 1 refers to the left most wheel and the number increases moving
            towards the right side of the vehicle.
          </>
        </VStack>
      </Alert>
      <ConfirmationDialogBox
        showConfirmCallToAction={showConfirmCallToAction}
        show={modalState?.show}
        close={() => {
          closeModalHandler();
        }}
        title={modalState?.heading}
        content={modalState?.content}
        confirmButtonText={modalState?.confirmButtonText}
        confirmButtonAction={modalState?.confirmButtonAction}
      ></ConfirmationDialogBox>
      <Grid templateColumns="repeat(3,1fr)" gap={4}>
        <GridItem>
          <Card>
            <VStack
              alignItems="start"
              maxHeight="700px"
              overflowY="auto"
              __css={{
                '&::-webkit-scrollbar': {
                  w: '2',
                },
                '&::-webkit-scrollbar-track': {
                  w: '6',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '15',
                  bg: `gray.200`,
                },
              }}
            >
              {' '}
              <Box w="100%" padding="4px" borderRadius={8}>
                <HStack justify={'space-between'}>
                  <Button
                    type="button"
                    disabled={isDisabled}
                    variant="secondary"
                    data-testid="wheelInfo-add-axle-btn"
                    onClick={handleAddNewAxle}
                  >
                    + Add New Axle
                  </Button>
                  {selectedAxleIndex >= 0 &&
                    !isDisabled &&
                    wheelInfo.axles.length > axleWheelLimit.minAxle && (
                      <Button
                        type="button"
                        leftIcon={
                          <Icon fill="red.500" aria-label="axle-delete">
                            {faTrashIcon}
                          </Icon>
                        }
                        isDestructive={true}
                        variant="secondary"
                        data-testid={`wheelInfo-delete-axle-btn-${selectedAxleIndex}`}
                        onClick={() => {
                          dispatchModalAction(
                            DeleteAxleConfirm,
                            selectedAxleIndex,
                            null,
                          );
                        }}
                      >
                        Delete Axle {selectedAxleIndex + 1}
                      </Button>
                    )}
                </HStack>
              </Box>
              {wheelInfo.axles.map((axle, axleIndex) => {
                return (
                  <Box
                    data-testid="axle-header"
                    key={axleIndex}
                    w="100%"
                    padding="4px 16px"
                    borderRadius={8}
                    _hover={{ cursor: 'pointer', background: 'gray.100' }}
                    onClick={() => {
                      setSelectedAxleIndex(axleIndex);
                    }}
                    bg={
                      selectedAxleIndex === axleIndex
                        ? 'teal.50'
                        : getIn(errors, `axles[${axleIndex}]`)
                        ? 'red.100'
                        : ''
                    }
                  >
                    <HStack width="100%" justify="space-between">
                      <HStack>
                        <Typography>
                          {`Axle ${axle.id}`}
                          {axleIndex === 0 && ' - Front Axle'}
                        </Typography>
                      </HStack>
                      <AxleCategoryHeaderBadges
                        key={`axle-badge-${axle.id}`}
                        axle={axle}
                      ></AxleCategoryHeaderBadges>
                    </HStack>
                  </Box>
                );
              })}
            </VStack>
          </Card>
        </GridItem>
        <GridItem colSpan={2}>
          {selectedAxleIndex >= 0 && (
            <AxleDetails
              wheelInfo={wheelInfo}
              axle={wheelInfo.axles[selectedAxleIndex]}
              axleIndex={selectedAxleIndex}
              onChange={onChange}
              inspectionProgram={program}
              axleWheelLimit={axleWheelLimit}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              errors={getIn(errors, `axles[${selectedAxleIndex}]`)}
              isDisabled={isDisabled}
            />
          )}
        </GridItem>
      </Grid>
    </>
  );
};

export default AxlesCategory;
