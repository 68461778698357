import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Table } from '@sgi/gravity';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { ContactListDTO } from 'Types/ContactListDTO';
import { formatPhone } from 'Utils/Format';

const columnHelper = createColumnHelper<ContactListDTO>();
const columns = [
  columnHelper.accessor('customerNumber', {
    cell: (info) => info.getValue(),
    header: () => 'Customer #',
    meta: {
      columnName: 'Customer #',
    },
  }),
  columnHelper.accessor('firstName', {
    cell: (info) => info.getValue(),
    header: () => 'First Name',
    meta: {
      columnName: 'First Name',
    },
  }),
  columnHelper.accessor('lastName', {
    cell: (info) => info.getValue(),
    header: () => 'Last Name',
    meta: {
      columnName: 'Last Name',
    },
  }),
  columnHelper.accessor('homePhone', {
    cell: (info) => formatPhone(info.getValue()) ?? '',
    header: () => 'Home Phone Number',
    meta: {
      columnName: 'Home Phone Number',
    },
  }),
  columnHelper.accessor('email', {
    cell: (info) => info.getValue(),
    header: () => 'Email',
    meta: {
      columnName: 'Email',
    },
  }),
  columnHelper.display({
    id: 'actions',
    cell: (info) => (
      <Link href={`/contact/${info.row.original.id}`}>
        <FontAwesomeIcon icon={faPenToSquare} size="1x" title="Edit Contact" />
      </Link>
    ),
  }),
] as ColumnDef<ContactListDTO>[];

interface ContactSummaryTableProps {
  contacts: ContactListDTO[];
}

export const ContactSummaryTable = ({ contacts }: ContactSummaryTableProps) => {
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Sorting
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'lastName',
      desc: false,
    },
  ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [contacts]);

  const sortContacts = (a: any, b: any) => {
    // Check to see if there are any columns to sort by
    if (sorting.length === 0) {
      return true;
    }

    if (!sorting[0].desc) {
      return a[sorting[0].id]
        ?.toString()
        .localeCompare(b[sorting[0].id]?.toString());
    } else {
      return b[sorting[0].id]
        ?.toString()
        .localeCompare(a[sorting[0].id]?.toString());
    }
  };

  return (
    <Table
      columns={columns}
      data={[...contacts]
        .sort((a: any, b: any) => sortContacts(a, b))
        .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
      data-testid="table-contacts-list"
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageCount={Math.ceil(contacts.length / pageSize) ?? -1}
      totalCount={contacts.length}
      onPageSizeChange={(newPageSize) =>
        setPagination((prev) => ({ pageIndex: 0, pageSize: newPageSize }))
      }
      onPageChange={(page) =>
        setPagination((prev) => ({ ...prev, pageIndex: page }))
      }
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

export default ContactSummaryTable;
