import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { useEffect, useState } from 'react';
import { InspectionBodyDTO, InspectionItemStatus } from 'Types/InspectionDTO';
import { InspectionProgram } from 'Types/InspectionEnums';
import { AxleStatus, WheelInfoDTO } from 'Types/WheelInfoDTO';
import { BusinessFunctions } from 'Utils/BusinessFunctions';

interface ProgressBarCounts {
  total: number;
  pending: number;
  passed: number;
  rejected: number;
  attn: number;
  repNow: number;
}

interface InspectionProgressBarProps {
  inspectionProgram?: InspectionProgram;
  inspectionBody: InspectionBodyDTO[];
  wheelInfo: WheelInfoDTO | undefined;
}

export const InspectionProgressBar = ({
  inspectionProgram,
  inspectionBody,
  wheelInfo,
}: InspectionProgressBarProps) => {
  const [counts, setCounts] = useState<ProgressBarCounts>({
    total: 0,
    pending: 0,
    passed: 0,
    rejected: 0,
    attn: 0,
    repNow: 0,
  });

  const isBodyIntegrityInspection: boolean =
    inspectionProgram === InspectionProgram.BodyIntegrity;

  useEffect(() => {
    let total = 0;
    let pending = 0;
    let passed = 0;
    let rejected = 0;
    let attn = 0;
    let repNow = 0;

    inspectionBody.forEach((c) => {
      total += c.items.length;
      pending += c.items.filter(
        (i) => i.status === InspectionItemStatus.Default,
      ).length;
      passed += c.items.filter(
        (i) => i.status === InspectionItemStatus.Pass,
      ).length;
      repNow += c.items.filter(
        (i) => i.status === InspectionItemStatus.RepairedNow,
      ).length; //Might have to do something about this?
      rejected += c.items.filter(
        (i) => i.status === InspectionItemStatus.Rejected,
      ).length;
      attn += c.items.filter(
        (i) => i.status === InspectionItemStatus.Attention,
      ).length;
    });

    wheelInfo?.axles.forEach((axle) => {
      total += 1;
      pending +=
        BusinessFunctions.checkAxleStatus(axle) === AxleStatus.Pending ? 1 : 0;
      rejected +=
        BusinessFunctions.checkAxleStatus(axle) === AxleStatus.Fail ? 1 : 0;
      passed +=
        BusinessFunctions.checkAxleStatus(axle) === AxleStatus.Pass ? 1 : 0;
    });

    setCounts({
      total: total,
      pending: pending,
      passed: passed,
      rejected: rejected,
      attn: attn,
      repNow: repNow,
    });
  }, [inspectionBody, wheelInfo]);

  const ProgressLabel = (label: string, color: string, count: number) => {
    return (
      <VStack alignItems="start" style={{ paddingRight: '70px' }}>
        <Typography variant="body">{label}</Typography>
        <HStack>
          <Box
            style={{
              width: '16px',
              height: '16px',
              borderRadius: '8px',
            }}
            backgroundColor={color}
          >
            &nbsp;
          </Box>
          <Typography
            data-testid={`${label.replace(/ /g, '')}-count`}
            variant="subheading2"
          >
            {count}
          </Typography>
        </HStack>
      </VStack>
    );
  };

  return (
    <Card cardStyleProps={{ padding: '20px 30px' }}>
      <>
        <Typography variant="h3">Progress</Typography>
        <Grid
          gap={3}
          templateColumns={`repeat(${counts.total}, 1fr)`}
          padding="20px 0px"
        >
          {counts.passed > 0 && (
            <GridItem
              data-testid="Pass-bar"
              colSpan={counts.passed}
              className="progress-bar-segment"
              backgroundColor="var(--sgi-colors-green-500)"
            >
              &nbsp;
            </GridItem>
          )}
          {counts.rejected > 0 && (
            <GridItem
              data-testid="Rejected-bar"
              colSpan={counts.rejected}
              className="progress-bar-segment"
              backgroundColor="var(--sgi-colors-red-500)"
            >
              &nbsp;
            </GridItem>
          )}
          {counts.attn > 0 && !isBodyIntegrityInspection && (
            <GridItem
              data-testid="Attention-bar"
              colSpan={counts.attn}
              className="progress-bar-segment"
              backgroundColor="var(--sgi-colors-orange-400)"
            >
              &nbsp;
            </GridItem>
          )}
          {counts.repNow > 0 && !isBodyIntegrityInspection && (
            <GridItem
              data-testid="RepairedNow-bar"
              colSpan={counts.repNow}
              className="progress-bar-segment"
              backgroundColor="#B141D8"
            >
              &nbsp;
            </GridItem>
          )}
          {counts.pending > 0 && (
            <GridItem
              data-testid="Pending-bar"
              colSpan={counts.pending}
              className="progress-bar-segment"
              backgroundColor="var(--sgi-colors-blackAlpha-500)"
            >
              &nbsp;
            </GridItem>
          )}
        </Grid>
        <HStack>
          {ProgressLabel('Pass', 'var(--sgi-colors-green-500)', counts.passed)}
          {ProgressLabel(
            'Rejected',
            'var(--sgi-colors-red-500)',
            counts.rejected,
          )}
          {!isBodyIntegrityInspection &&
            ProgressLabel(
              'Attention',
              'var(--sgi-colors-orange-400)',
              counts.attn,
            )}
          {!isBodyIntegrityInspection &&
            ProgressLabel('Repaired Now', '#B141D8', counts.repNow)}
          {ProgressLabel(
            'Pending',
            'var(--sgi-colors-blackAlpha-500)',
            counts.pending,
          )}
        </HStack>
      </>
    </Card>
  );
};

export default InspectionProgressBar;
