import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Card, Input, Tooltip, useToast } from '@sgi/gravity';
import { useCreateStationMutation } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import UnsavedBlockerDialogBox from 'App/Common/UnsavedBlockerDialogBox';
import {
  stationMessages,
  validationMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { isEqual } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateStationDTO,
  defaultCreateStationDTO,
  initCreateStationDTO,
  updCreateStationDTO,
} from 'Types/CreateStationDTO';
import * as Yup from 'yup';

export const CreateStationForm = () => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const { showSuccessToast, showErrorToast } = useToast();
  const [
    createStation,
    {
      data: createdStation,
      isError: isCreateStationError,
      error: createStationError,
      isSuccess: isCreateStationSuccess,
    },
  ] = useCreateStationMutation();

  useEffect(() => {
    if (isCreateStationError) {
      showErrorToast({
        description: <ErrorList errors={createStationError} />,
      });
    }
  }, [isCreateStationError, createStationError, showErrorToast]);

  useEffect(() => {
    if (isCreateStationSuccess) {
      showSuccessToast({ description: stationMessages.created });
      navigate(`/station/${createdStation}`);
    }
  }, [isCreateStationSuccess, createdStation, navigate, showSuccessToast]);

  const formik = useFormik({
    initialValues: { ...defaultCreateStationDTO },
    validationSchema: Yup.object({
      stationName: Yup.string().required(
        validationMessages.StationName.required,
      ),
      shopOwner: Yup.string().required(validationMessages.ShopOwner.required),
    }),
    onSubmit: (values: CreateStationDTO) => {
      setShowDialog(false);
      createStation(values);
    },
  });

  const formOnHandleBlur = (event: FormEvent) => {
    checkIfStationDataChanged();
  };

  const formOnHandleChange = (event: FormEvent) => {
    checkIfStationDataChanged();
  };

  const checkIfStationDataChanged = () => {
    const keys = Object.keys(formik.initialValues);
    let initCreateStation: any = initCreateStationDTO;
    let updCreateStation: any = updCreateStationDTO;

    keys.forEach((key) => {
      updCreateStation[key as keyof CreateStationDTO] =
        formik.values[key as keyof CreateStationDTO];
    });

    const hasChanged = !isEqual(initCreateStation, updCreateStation);
    setShowDialog(hasChanged);
  };

  return (
    <>
      <UnsavedBlockerDialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <Box w="100%" p={4}>
        <form
          onSubmit={formik.handleSubmit}
          onBlur={formOnHandleBlur}
          onChange={formOnHandleChange}
        >
          <VStack alignItems="flex-start">
            <Box w="100%">
              <Card>
                <Grid templateColumns="repeat(4,1fr)">
                  <GridItem>
                    <VStack alignItems="flex-start">
                      <Input
                        id="stationName"
                        name="stationName"
                        label="Inspection Station Name"
                        data-testid="station-create-stationName"
                        placeholder="Inspection Station Name"
                        hideOptionalLabel
                        value={formik.values.stationName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.stationName
                            ? formik.errors.stationName
                            : undefined
                        }
                      />

                      <Tooltip
                        label={stationMessages.shopOwnerLabelInfo}
                        placement="right"
                      >
                        <Input
                          id="shopOwner"
                          name="shopOwner"
                          label="Shop Owner"
                          data-testid="station-create-shopOwner"
                          placeholder="Shop Owner"
                          hideOptionalLabel
                          value={formik.values.shopOwner}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.shopOwner
                              ? formik.errors.shopOwner
                              : undefined
                          }
                        />
                      </Tooltip>
                    </VStack>
                  </GridItem>
                </Grid>
              </Card>
            </Box>
            <HStack>
              <Button
                variant="secondary"
                data-testid="station-create-BackToList"
                onClick={() => {
                  navigate('/station/list');
                }}
              >
                Back
              </Button>
              <Button
                variant="primary"
                data-testid="station-create-submit"
                type="submit"
              >
                Create Inspection Station
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default CreateStationForm;
