import { Link, Table } from '@sgi/gravity';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  SortingState,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { StationListDTO } from 'Types/StationListDTO';
import { formatId } from 'Utils/inspectionUtils';

const columnHelper = createColumnHelper<StationListDTO>();
const columns = [
  columnHelper.accessor('id', {
    cell: (info) => (
      <Link href={`/station/${info.getValue()}`}>
        {formatId(info.getValue())}
      </Link>
    ),
    header: () => 'ID',
    meta: {
      columnName: 'ID',
    },
  }),
  columnHelper.accessor('stationName', {
    cell: (info) => info.getValue(),
    header: () => 'Station Name',
    meta: {
      columnName: 'Station Name',
    },
  }),
  columnHelper.accessor('shopOwner', {
    cell: (info) => info.getValue(),
    header: () => 'Shop Owner',
    meta: {
      columnName: 'Shop Owner',
    },
  }),
] as ColumnDef<StationListDTO>[];

interface StationSummaryTableProps {
  stations: StationListDTO[];
}

export const StationSummaryTable = ({ stations }: StationSummaryTableProps) => {
  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Sorting
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'stationName',
      desc: false,
    },
  ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [stations]);

  const sortStations = (a: any, b: any) => {
    // Check to see if there are any columns to sort by
    if (sorting.length === 0) {
      return true;
    }

    if (!sorting[0].desc) {
      return a[sorting[0].id]
        ?.toString()
        .localeCompare(b[sorting[0].id]?.toString());
    } else {
      return b[sorting[0].id]
        ?.toString()
        .localeCompare(a[sorting[0].id]?.toString());
    }
  };

  return (
    <Table
      columns={columns}
      data={[...stations]
        .sort((a: any, b: any) => sortStations(a, b))
        .slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)}
      data-testid="table-stations-list"
      pageIndex={pageIndex}
      pageSize={pageSize}
      pageCount={Math.ceil(stations.length / pageSize) ?? -1}
      totalCount={stations.length}
      onPageSizeChange={(newPageSize) =>
        setPagination((prev) => ({ pageIndex: 0, pageSize: newPageSize }))
      }
      onPageChange={(page) =>
        setPagination((prev) => ({ ...prev, pageIndex: page }))
      }
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

export default StationSummaryTable;
