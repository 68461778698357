import { AxleDTO, AxleStatus, BrakeTypes, WheelDTO } from 'Types/WheelInfoDTO';

export class BusinessFunctions {
  static checkWheelStatus(
    wheel: WheelDTO,
    conditionAndExternalMeasurements?: boolean,
  ): boolean {
    let result: boolean = false;

    if (conditionAndExternalMeasurements) result = true;
    else if (wheel.nonBrake) result = true;
    else if (wheel.passesBrakeMeasurements) result = true;

    if (result === true && wheel.passesTreadDepth === true) return true;
    else if (result === false || wheel.passesTreadDepth === false) return false;
    else return false;
  }

  static checkAxleStatus(axle: AxleDTO): string {
    let status: string = '';
    let result: boolean = false;
    axle.wheels.forEach((wheel, index) => {
      if (
        axle.brakeType === BrakeTypes.Unknown ||
        axle.brakeType === BrakeTypes.None
      ) {
        status = AxleStatus.Pending;
      }

      if (!status || status === AxleStatus.Pass) {
        if (axle.conditionAndExternalMeasurements) result = true;
        else if (wheel.nonBrake) result = true;
        else if (wheel.passesBrakeMeasurements) result = true;
        else result = false;

        if (result === true && wheel.passesTreadDepth === true) {
          status = AxleStatus.Pass;
        } else if (result === false || wheel.passesTreadDepth === false) {
          status = AxleStatus.Fail;
        }
      }
    });
    return status;
  }
}
