import { HStack } from '@chakra-ui/react';
import { Input } from '@sgi/gravity';
import { getIn } from 'formik';
import { BrakeMeasurementDTO, BrakeMeasurementUnit } from 'Types/WheelInfoDTO';
import { patterns } from 'Utils/regex';

interface BrakeMeasurementProps {
  brakeMeasurement: BrakeMeasurementDTO;
  brakeIndex: number;
  brakeMeasurementUnit: BrakeMeasurementUnit | null;
  namePrefix: string;
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  isRequired: boolean;
  isDisabled?: boolean;
}

export const BrakeMeasurement = ({
  brakeMeasurement,
  brakeMeasurementUnit,
  brakeIndex,
  namePrefix,
  setFieldValue,
  onChange,
  errors,
  isRequired,
  isDisabled = false,
}: BrakeMeasurementProps) => {
  return (
    <HStack align={'end'} justify="space-between">
      <Input
        id={`${namePrefix}.measurement`}
        name={`${namePrefix}.measurement`}
        inputRightAddon={`${
          brakeMeasurementUnit !== null
            ? BrakeMeasurementUnit[brakeMeasurementUnit]
            : ''
        }`}
        isRequired={isRequired}
        hideOptionalLabel={false}
        size="md"
        label={brakeMeasurement.name}
        onChange={(e) => {
          const measValue = e.target.value;
          if (!measValue || measValue.match(patterns.measurement)) {
            onChange(e);
          }
        }}
        value={
          brakeMeasurement.measurement
            ? brakeMeasurement.measurement?.toString()
            : ''
        }
        error={getIn(errors, `measurement`)}
        isDisabled={isDisabled}
      />
    </HStack>
  );
};

export default BrakeMeasurement;
