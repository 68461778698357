import { useToast } from '@sgi/gravity';
import {
  useDeleteAttachmentQuery,
  useGetAttachmentQuery,
  useUploadAttachmentsMutation,
} from 'api/InspectTechUIFileUploadAPI';
import ErrorList from 'App/Common/ErrorList';
import FileInfoList from 'App/Common/FileUpload/FileInfoList';
import FileUpload from 'App/Common/FileUpload/FileUpload';
import LoadingModal from 'App/Common/LoadingModal';
import { PreviewAttachmentModal } from 'App/Common/PreviewAttachmentModal';
import { QuestionCodes } from 'constants/variableConstants';
import { useEffect, useState } from 'react';
import {
  Question,
  QuestionAttachmentDto,
  UploadFile,
} from 'Types/InspectionDTO';

interface UploadDocumentProps {
  question: Question;
  onDocumentChange: (
    fileNames: QuestionAttachmentDto[] | undefined,
    questionCode: string,
  ) => void;
  inspectionId: string;
  isEditMode: boolean;
}

export default function UploadDocument({
  question,
  onDocumentChange,
  inspectionId,
  isEditMode,
}: UploadDocumentProps) {
  const { showToast } = useToast();
  const maxFileCount = 5;
  const maxPhotosCount = 25;
  const [newFiles, setNewFiles] = useState<UploadFile[]>();
  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>();
  const [isValid, setIsValid] = useState(false);
  const [deleteAttachmentName, setDeleteAttachmentName] = useState('');
  const [viewAttachmentName, setViewAttachmentName] = useState('');

  useEffect(() => {
    if (
      question.attachments &&
      question.attachments.length > 0 &&
      (uploadedFiles === undefined || uploadedFiles?.length === 0)
    ) {
      let tempUploadedFiles: UploadFile[] = [];
      question.attachments.forEach((attachment) => {
        const newFile: UploadFile = {
          tempId: tempUploadedFiles.length + 1,
          clientFileName: attachment.clientFileName,
          file: null,
          storageFileName: attachment.storageFileName,
        };
        tempUploadedFiles.push(newFile);
      });
      setUploadedFiles(tempUploadedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question.attachments]);

  const {
    data: attachment,
    isSuccess: isGetAttachmentSuccess,
    isError: isGetAttachmentError,
    error: getAttachmentError,
  } = useGetAttachmentQuery(viewAttachmentName, {
    skip: viewAttachmentName ? false : true,
  });

  const {
    data: deleteAttachmentResponse,
    isSuccess: isDeleteAttachmentSuccess,
    isError: isDeleteAttachmentError,
    error: deleteAttachmentError,
  } = useDeleteAttachmentQuery(deleteAttachmentName, {
    skip: deleteAttachmentName ? false : true,
  });

  useEffect(() => {
    if (isDeleteAttachmentError && deleteAttachmentError) {
      showToast({
        description: <ErrorList errors={deleteAttachmentError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteAttachmentError, deleteAttachmentError]);

  useEffect(() => {
    if (isDeleteAttachmentSuccess && deleteAttachmentResponse) {
      if (deleteAttachmentResponse === true) {
        removeFile(deleteAttachmentName);
      }
      showToast({
        description: 'Removed Successful',
        status: 'success',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleteAttachmentSuccess, deleteAttachmentResponse]);

  const [
    uploadAttachments,
    {
      data: uploadAttachmentsResponse,
      error: uploadAttachmentsError,
      isError: isUploadAttachmentsError,
      isLoading: isUploadAttachmentsLoading,
      isSuccess: isUploadAttachmentsSuccess,
    },
  ] = useUploadAttachmentsMutation();

  useEffect(() => {
    if (isGetAttachmentError && getAttachmentError) {
      showToast({
        description: <ErrorList errors={getAttachmentError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGetAttachmentError, getAttachmentError]);

  useEffect(() => {
    if (isUploadAttachmentsError) {
      showToast({
        description: <ErrorList errors={uploadAttachmentsError} />,
        status: 'error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadAttachmentsError, isUploadAttachmentsError]);

  useEffect(() => {
    if (uploadedFiles !== undefined) {
      let uploadedDocuments: QuestionAttachmentDto[] = [];
      uploadedFiles.forEach((file) => {
        if (file.storageFileName !== null) {
          uploadedDocuments.push({
            clientFileName: file.clientFileName,
            storageFileName: file.storageFileName,
          });
        }
      });
      onDocumentChange(uploadedDocuments, question.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles]);

  useEffect(() => {
    if (!isValid && uploadedFiles && uploadedFiles.length > 0) {
      setIsValid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    if (isUploadAttachmentsSuccess) {
      showToast({
        description: 'Uploaded Successful',
        status: 'success',
      });
      UpdateUploadedFiles(uploadAttachmentsResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadAttachmentsSuccess]);

  const uploadAttachment = async (files: UploadFile[]) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`files`, file.file as File);
      });

      formData.append('questionCode', question.code);
      formData.append('inspectionId', inspectionId as string);
      uploadAttachments(formData);
    } else {
      return;
    }
  };

  const UpdateUploadedFiles = (
    uploadAttachmentsResponse: QuestionAttachmentDto[] | undefined,
  ) => {
    if (
      newFiles !== undefined &&
      uploadAttachmentsResponse &&
      newFiles.length > 0 &&
      uploadAttachmentsResponse.length === newFiles.length
    ) {
      let tempUploadedFiles =
        uploadedFiles !== undefined ? [...uploadedFiles] : [];

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];

        const newFile: UploadFile = {
          tempId: tempUploadedFiles.length + 1,
          clientFileName: file.clientFileName,
          file: file.file,
          storageFileName: uploadAttachmentsResponse[i].storageFileName,
        };
        tempUploadedFiles.push(newFile);
      }
      setUploadedFiles(tempUploadedFiles);
      setNewFiles([]);
    }
  };

  const handleFilePreview = (fileName: string | null) => {
    if (fileName) {
      setViewAttachmentName(fileName);
    }
  };

  const removeFile = (fileName: string) => {
    let tempUploadedFiles =
      uploadedFiles !== undefined ? [...uploadedFiles] : [];
    const index = tempUploadedFiles.findIndex(
      (x) => x.storageFileName === fileName,
    );
    tempUploadedFiles.splice(index, 1);
    setUploadedFiles(tempUploadedFiles);
    setDeleteAttachmentName('');
  };

  const handleFileDelete = (fileName: string | null) => {
    if (fileName) {
      // Soft delete if edit mode else deletes the file from file location
      if (!isEditMode) {
        setDeleteAttachmentName(fileName);
      } else {
        removeFile(fileName);
      }
    }
  };

  const onFileUploadChanged = (isValid: boolean, files: UploadFile[]) => {
    if (files && files.length > 0) {
      setIsValid(isValid);
      addNewFiles(files);
    } else {
      setIsValid(false);
      setNewFiles([]);
    }
  };

  const addNewFiles = (files: UploadFile[]) => {
    let tempUploadFiles = [];
    const uploadedFilesCount =
      uploadedFiles !== undefined ? uploadedFiles?.length : 0;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (file) {
        if (
          tempUploadFiles.length + uploadedFilesCount >= maxFileCount &&
          question.code !== QuestionCodes.ctPreQuestionRequiredNumberOfPhotos
        ) {
          showToast({
            description: `Maximum ${maxFileCount} documents can be uploaded.`,
            status: 'error',
          });
          return;
        } else if (
          tempUploadFiles.length + uploadedFilesCount >= maxPhotosCount &&
          question.code === QuestionCodes.ctPreQuestionRequiredNumberOfPhotos
        ) {
          showToast({
            description: `Maximum ${maxPhotosCount} documents can be uploaded.`,
            status: 'error',
          });
          return;
        } else {
          const newFile: UploadFile = {
            tempId: tempUploadFiles.length + 1,
            clientFileName: file.clientFileName,
            file: file.file,
            storageFileName: null,
          };
          tempUploadFiles.push(newFile);
        }
      }
    }
    if (tempUploadFiles.length > 0) {
      setNewFiles(tempUploadFiles);
      uploadAttachment(tempUploadFiles);
    }
  };

  return (
    <>
      {attachment && isGetAttachmentSuccess && (
        <PreviewAttachmentModal
          attachment={attachment}
          onPreviewAttachmentModelClose={() => {
            setViewAttachmentName('');
          }}
        />
      )}
      <LoadingModal show={isUploadAttachmentsLoading}></LoadingModal>
      <FileUpload
        testId={`preQuestion-Upload-Document-${question.code}`}
        onFileUploadChanged={onFileUploadChanged}
        accept={'.pdf, .jpg, .jpeg, .png, .heic'}
      />
      <FileInfoList
        fileInfos={uploadedFiles}
        onFilePreview={handleFilePreview}
        onFileDelete={handleFileDelete}
      />
    </>
  );
}
