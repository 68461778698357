import React from 'react';
import { InspectionBodyDTO, InspectionItemStatus } from 'Types/InspectionDTO';
import { InspectionProgram, InspectionType } from 'Types/InspectionEnums';

interface Props {
  items: InspectionBodyDTO[];
  program: InspectionProgram;
  type: InspectionType;
}

export const InspectionItemsTable = ({ items, program, type }: Props) => {
  const tableBreak = () => {
    return (
      <tr className="footer-cell">
        <td className="footer-cell" colSpan={5}>
          &nbsp;
        </td>
      </tr>
    );
  };
  return (
    <>
      <div>Inspection Details:</div>
      <table>
        <tbody>
          {items.map((category, i) => {
            return (
              <React.Fragment key={category.id}>
                {i > 0 && tableBreak()}
                <tr>
                  <th>Pass</th>
                  {program !== InspectionProgram.BodyIntegrity &&
                    type !== InspectionType.Structural && <th>Req Attn</th>}
                  <th>Rejected</th>
                  <th>{category.categoryName}</th>
                  <th>Comments</th>
                </tr>
                {category.items.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="status-col">
                        {/*This will need to be updated to show corrected as well*/}
                        {(item.status === InspectionItemStatus.Pass &&
                          item.wasPreviouslyRejected) ||
                        item.status === InspectionItemStatus.RepairedNow
                          ? 'C'
                          : item.status === InspectionItemStatus.Rejected ||
                            item.status === InspectionItemStatus.Attention
                          ? ''
                          : 'X'}
                        {/*item.status === InspectionItemStatus.Pass && 'X'*/}
                      </td>
                      {program !== InspectionProgram.BodyIntegrity &&
                        type !== InspectionType.Structural && (
                          <td className="status-col">
                            {item.status === InspectionItemStatus.Attention &&
                              'X'}
                          </td>
                        )}
                      <td className="status-col">
                        {item.status === InspectionItemStatus.Rejected && 'X'}
                      </td>
                      <td>{item.name}</td>
                      <td>{item.comment ?? ''}</td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
        <tfoot>
          <tr className="footer-cell">
            <td className="footer-cell">
              <div className="footer-space"> </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
};

export default InspectionItemsTable;
