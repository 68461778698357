import { Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { VehicleDTO, VinStatusLabel } from 'Types/VehicleDTO';

interface VehicleSearchProps {
  vehicle: VehicleDTO;
  selected: string;
  setSelected: Function;
}

export const VehicleSearchResult = ({
  vehicle,
  selected,
  setSelected,
}: VehicleSearchProps) => {
  return (
    <>
      <Card
        data-testid="vehicle-search-result-card"
        className={`search-result-card ${
          selected === vehicle.vin ? 'selected-card' : ''
        }`}
      >
        <div
          data-testid="vehicle-search-result-row"
          onClick={() => {
            setSelected(vehicle.vin);
          }}
        >
          <Grid templateColumns="repeat(3,1fr)" gap={5}>
            <GridItem colEnd={2}>
              <Typography variant="subheading1">{vehicle.vin}</Typography>
            </GridItem>
            <GridItem colStart={2}>
              <VStack align="flex-start">
                <HStack>
                  <Typography variant="bodyBold">VIN Status:</Typography>
                  <Typography variant="body">
                    {VinStatusLabel[vehicle.status]}
                  </Typography>
                </HStack>
                <HStack>
                  <Typography variant="bodyBold">Year:</Typography>
                  <Typography variant="body">
                    {vehicle.year?.toString()}
                  </Typography>
                </HStack>
                <HStack>
                  <Typography variant="bodyBold">Make:</Typography>
                  <Typography variant="body">{vehicle.make}</Typography>
                </HStack>
                <HStack>
                  <Typography variant="bodyBold">Model:</Typography>
                  <Typography variant="body">{vehicle.model}</Typography>
                </HStack>
              </VStack>
            </GridItem>
            <GridItem colStart={3}>
              <VStack align="flex-start">
                <HStack>
                  <Typography variant="bodyBold">Colour:</Typography>
                  <Typography variant="body">{vehicle.colour}</Typography>
                </HStack>
                <HStack>
                  <Typography variant="bodyBold">Body Style:</Typography>
                  <Typography variant="body">{vehicle.bodyStyle}</Typography>
                </HStack>
                <HStack>
                  <Typography variant="bodyBold">GVWR:</Typography>
                  <Typography variant="body">
                    {vehicle?.gvwr && vehicle?.gvwr > 0
                      ? `${vehicle?.gvwr.toLocaleString()}Kg`
                      : ''}
                  </Typography>
                </HStack>
              </VStack>
            </GridItem>
          </Grid>
        </div>
      </Card>
    </>
  );
};

export default VehicleSearchResult;
