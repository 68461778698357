export enum LocalStorageItemEnum {
  VehicleSearch,
  InspectionSearch,
  ContactSearch,
  StationSearch,
  InspectionTableColumns,
}

export const setLocalStorageItem = (
  key: LocalStorageItemEnum,
  value: any,
): void => {
  localStorage.setItem(LocalStorageItemEnum[key], JSON.stringify({ value }));
};

export const getLocalStorageItem = <T>(key: LocalStorageItemEnum): T | null => {
  const data: string | null = localStorage.getItem(LocalStorageItemEnum[key]);

  if (data !== undefined && data !== null) {
    return JSON.parse(data).value;
  }

  return null;
};

export const removeLocalStorageItem = (key: LocalStorageItemEnum): void => {
  localStorage.removeItem(LocalStorageItemEnum[key]);
};

export const removeAllLocalStorageItem = (): void => {
  Object.keys(LocalStorageItemEnum)
    .filter((key) => isNaN(Number(key)))
    .forEach((text) => {
      localStorage.removeItem(text);
    });
};
