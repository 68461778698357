import { HStack, Icon, VStack } from '@chakra-ui/react';
import {
  Button,
  Modal,
  SearchInput,
  Table,
  Typography,
  useToast,
} from '@sgi/gravity';
import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
} from '@tanstack/react-table';
import { useVinSearchInspectionQuery } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import {
  faArrowLeftIcon,
  faArrowRightIcon,
  faMagnifyingGlass,
} from 'App/Common/Icons';
import LoadingModal from 'App/Common/LoadingModal';
import VehicleCreateForm from 'App/Vehicle/VehicleCreateForm';
import { useEffect, useState } from 'react';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
} from 'Types/CreateInspectionDTO';
import { VehicleDTO, VinStatus } from 'Types/VehicleDTO';
import { VehicleSearchResult } from './VehicleSearchResult';

interface VehicleSearchProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  setInspection: Function;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
  setCreateInspectionHelperDetailsDTO: Function;
}

export const VehicleSearch = ({
  step,
  setStep,
  inspection,
  setInspection,
  inspectionHelperDetailsDTO,
  setCreateInspectionHelperDetailsDTO,
}: VehicleSearchProps) => {
  const [searchVin, setSearchVin] = useState<string>(
    inspection.vin ?? undefined,
  );
  const [query, setQuery] = useState<string>('');
  const [skip, setSkip] = useState<boolean>(
    inspection.vin && query ? false : true,
  );
  const [selected, setSelected] = useState<string>(inspection.vin ?? undefined);
  const { showToast } = useToast();
  const columnHelper = createColumnHelper<VehicleDTO>();
  const [showCreateVehicleDialog, setShowCreateVehicleDialog] =
    useState<boolean>(false);

  const {
    data: vehicles,
    isError: isVinResultsError,
    error: vinResultsError,
    isSuccess: isVinResultsSuccess,
    isFetching: isVinResultsFetching,
  } = useVinSearchInspectionQuery(query, { skip });

  useEffect(() => {
    if (searchVin && searchVin.trim() && searchVin !== query) {
      setQuery(searchVin.trim());
      setSkip(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVin]);

  useEffect(() => {
    if (isVinResultsSuccess) {
      setSelected('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVinResultsSuccess]);

  useEffect(() => {
    if (isVinResultsError) {
      setSelected('');
      showToast({
        description: <ErrorList errors={vinResultsError} />, //TODO: Change this
        status: 'error',
        title: 'VIN Search Error',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVinResultsError, vinResultsError]);

  const handleSubmit = () => {
    let selectedVehicle = {} as VehicleDTO;

    if (selected) {
      if (vehicles && vehicles.length > 0) {
        selectedVehicle = {
          ...vehicles!.filter((v) => v.vin === selected)[0],
        };
      } else if (
        (!vehicles || vehicles.length === 0) &&
        inspectionHelperDetailsDTO &&
        inspectionHelperDetailsDTO.vehicle
      ) {
        selectedVehicle = inspectionHelperDetailsDTO.vehicle;
      }

      if (selectedVehicle.status === VinStatus.Default) {
        showToast({
          description: 'Vehicle must have a VIN status',
          status: 'error',
          title: 'Vehicle Selection Error',
        });
        return false;
      } else {
        const updatedInspection: CreateInspectionDTO = {
          ...inspection,
          vin: selected,
        };
        setInspection(updatedInspection);
        setCreateInspectionHelperDetailsDTO({
          ...inspectionHelperDetailsDTO,
          vehicle: selectedVehicle,
        });
        setStep(step + 1);
      }
    }
  };

  const handleCreateVehicle = (createdVehicle: VehicleDTO) => {
    setSelected(createdVehicle.vin);
    setCreateInspectionHelperDetailsDTO({
      ...inspectionHelperDetailsDTO,
      vehicle: createdVehicle,
    });
    setInspection({ ...inspection, vin: createdVehicle.vin });
    setShowCreateVehicleDialog(false);
    setStep(step + 1);
  };

  // Pagination
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [vehicles]);

  const columns = [
    columnHelper.display({
      id: 'actions',
      cell: (info) => (
        <VehicleSearchResult
          vehicle={info.row.original}
          selected={selected}
          setSelected={setSelected}
        />
      ),
    }),
  ] as ColumnDef<VehicleDTO>[];

  return (
    <>
      <Modal
        data-testid="Create-Vehicle-Dialog"
        modalContentProps={{ maxW: '800px', maxH: '-moz-fit-content' }}
        isOpen={showCreateVehicleDialog}
        onClose={() => {
          setShowCreateVehicleDialog(false);
        }}
        title="Create Vehicle"
        isCentered={true}
        scrollBehavior={'inside'}
      >
        <VehicleCreateForm
          parentType={'Modal'}
          handleSelectVehicle={handleCreateVehicle}
          vinFromParent={
            searchVin.trim().length === 17 ? searchVin.trim() : undefined
          }
        />
      </Modal>

      <VStack align="flex-start">
        <LoadingModal show={isVinResultsFetching} />
        <HStack width="45%">
          <SearchInput
            hasButton
            name="vinSearch"
            data-testid="vin-Searchbox"
            onSearch={setSearchVin}
            placeholder="Search by full VIN"
          ></SearchInput>
        </HStack>
        {vehicles && vehicles.length > 0 && (
          <Table
            columns={columns}
            data={[...(vehicles ?? [])].slice(
              pageIndex * pageSize,
              (pageIndex + 1) * pageSize,
            )}
            data-testid="table-vehicles-list"
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={Math.ceil((vehicles ?? []).length / pageSize) ?? -1}
            totalCount={(vehicles ?? []).length}
            onPageSizeChange={(newPageSize) =>
              setPagination((prev) => ({
                pageIndex: 0,
                pageSize: newPageSize,
              }))
            }
            onPageChange={(page) =>
              setPagination((prev) => ({ ...prev, pageIndex: page }))
            }
          />
        )}
        {vehicles &&
          vehicles.length === 0 &&
          inspectionHelperDetailsDTO &&
          inspectionHelperDetailsDTO.vehicle &&
          (inspectionHelperDetailsDTO.vehicle.vin.startsWith(searchVin) ||
            inspectionHelperDetailsDTO.vehicle.vin.endsWith(searchVin)) && (
            <Table
              columns={columns}
              data={[...([inspectionHelperDetailsDTO.vehicle] ?? [])].slice(
                pageIndex * pageSize,
                (pageIndex + 1) * pageSize,
              )}
              data-testid="table-vehicles-list"
              pageIndex={pageIndex}
              pageSize={pageSize}
              pageCount={Math.ceil((vehicles ?? []).length / pageSize) ?? -1}
              totalCount={(vehicles ?? []).length}
              onPageSizeChange={(newPageSize) =>
                setPagination((prev) => ({
                  pageIndex: 0,
                  pageSize: newPageSize,
                }))
              }
              onPageChange={(page) =>
                setPagination((prev) => ({ ...prev, pageIndex: page }))
              }
            />
          )}

        {isVinResultsSuccess &&
          !isVinResultsFetching &&
          vehicles.length === 0 &&
          (!inspectionHelperDetailsDTO ||
            !inspectionHelperDetailsDTO.vehicle ||
            (!inspectionHelperDetailsDTO.vehicle.vin.startsWith(searchVin) &&
              !inspectionHelperDetailsDTO.vehicle.vin.endsWith(searchVin))) && (
            <VStack width="100%">
              <Icon w="24" h="24">
                {faMagnifyingGlass}
              </Icon>
              <Typography variant="h4">
                VIN may be incorrect. Please verify VIN from vehicle and
                re-enter. Contact Vehicle Standards & Inspection if you still
                require assistance.
              </Typography>
              <Button
                data-testid="create-vehicle-btn"
                onClick={() => {
                  setShowCreateVehicleDialog(true);
                }}
              >
                + Create Vehicle
              </Button>
            </VStack>
          )}
        <HStack w="100%" justifyContent="space-between">
          <Button
            variant="secondary"
            leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
            onClick={() => setStep(step - 1)}
          >
            Previous step
          </Button>
          <Button
            disabled={!selected}
            data-testid="VehicleSearch-Continue"
            onClick={() => handleSubmit()}
            rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
          >
            Continue to the next step
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default VehicleSearch;
