import { Box, Button, HStack, VStack } from '@chakra-ui/react';
import { BooleanCheckbox, Typography } from '@sgi/gravity';
import InspectionQuestions from 'App/Common/Questions/InspectionQuestions';
import { validationMessages } from 'constants/messageConstants';
import { QuestionCodes } from 'constants/variableConstants';
import { getIn, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CTQuestionDTO,
  defaultCTQuestionDTO,
  InspectionDTO,
  Question,
  QuestionType,
} from 'Types/InspectionDTO';
import * as Yup from 'yup';
import LocationAndDamages from './LocationAndDamages';

interface CTInspectionQuestionsProps {
  inspection: InspectionDTO;
  questionType: QuestionType;
  inputQuestions: Question[];
  updateQuestions: any;
  setShowCTPostInspectionQuestionsDialog: any;
}

const CTInspectionQuestions = ({
  inspection,
  questionType,
  inputQuestions,
  updateQuestions,
  setShowCTPostInspectionQuestionsDialog,
}: CTInspectionQuestionsProps) => {
  const navigate = useNavigate();
  const [acknowledgment, setAcknowledgment] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: { ...defaultCTQuestionDTO, questions: inputQuestions },
    validationSchema: Yup.object().shape({
      questions: Yup.array()
        .of(
          Yup.object({
            response: Yup.boolean()
              .nullable()
              .when('code', {
                is: (code: string) =>
                  (code !== QuestionCodes.ctPreQuestionLocationAndDamages &&
                    code !== QuestionCodes.ctPreQuestionFullDamageAnalysis) ||
                  (code === QuestionCodes.ctPreQuestionFullDamageAnalysis &&
                    formik.values.questions.find(
                      (q) =>
                        q.code ===
                        QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired,
                    )?.response === true),
                then: Yup.boolean()
                  .nullable()
                  .typeError(validationMessages.Question.response)
                  .required(validationMessages.Question.response),
              }),
            reason: Yup.string()
              .nullable()
              .when(['response', 'code'], {
                is: (response: boolean, code: string) =>
                  response === false &&
                  code !==
                    QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired &&
                  code !== QuestionCodes.ctPreQuestionLocationAndDamages,
                then: Yup.string()
                  .nullable()
                  .required(validationMessages.Question.reason),
              }),

            attachments: Yup.array()
              .nullable()
              .when(['response', 'code'], {
                is: (response: boolean, code: string) =>
                  response === true &&
                  code !==
                    QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired &&
                  code !== QuestionCodes.ctPreQuestionFullDamageAnalysis,
                then: Yup.array()
                  .nullable()
                  .required(validationMessages.Question.attachments)
                  .min(1, validationMessages.Question.attachments),
              }),
            //Location And Damages here
            damageDetails: Yup.array()
              .nullable()
              .when('code', {
                is: (code: string) =>
                  code === QuestionCodes.ctPreQuestionLocationAndDamages,
                then: Yup.array()
                  .nullable()
                  .required(validationMessages.DamageDetails.min)
                  .min(1, validationMessages.DamageDetails.min)
                  .of(
                    Yup.object({
                      location: Yup.string().required(
                        validationMessages.DamageDetails
                          .inspectionDamageLocation,
                      ),
                      description: Yup.string().required(
                        validationMessages.DamageDetails.description,
                      ),
                    }),
                  ),
              }),
          }),
        )
        .required(),
    }),
    onSubmit: (value: CTQuestionDTO) => {
      updateQuestions({
        id: inspection.id,
        vin: inspection.vin,
        questions:
          questionType === QuestionType.CTPreInspection
            ? inspection.questions
                .filter((q) => q.type === QuestionType.Contact)
                .concat(value.questions)
            : inspection.questions
                .filter(
                  (q) =>
                    q.type === QuestionType.Contact ||
                    q.type === QuestionType.CTPreInspection,
                )
                .concat(value.questions),
        dataVersion: inspection.dataVersion,
      });
    },
  });

  const LADIndex = formik.values.questions.findIndex(
    (e) => e.code === 'CTPRE003',
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <VStack align="flex-start">
          <Typography variant="h3">
            {questionType === QuestionType.CTPreInspection
              ? 'Pre-Inspection Question'
              : 'Post-Inspection Questions'}
          </Typography>
          <InspectionQuestions
            data-testid="ctInspection-questions"
            inspectionQuestions={formik.values.questions}
            isHidden={false}
            previousInspectionId={''}
            onChange={formik.handleChange}
            namePrefix={`questions`}
            setFieldValue={formik.setFieldValue}
            errors={getIn(formik.errors, `questions`)}
            touched={getIn(formik.touched, `questions`)}
            inspectionId={inspection.id}
          />
          {questionType === QuestionType.CTPostInspection && (
            <>
              <BooleanCheckbox
                name="postSignaturesAcknowledgement"
                onChange={setAcknowledgment}
                value={acknowledgment}
                size="md"
                data-testid="postSignaturesAcknowledgement"
              >
                <Typography variant="bodyBold">
                  I acknowledge that all signatures have been submitted, and all
                  instructions been followed, and information supplied is true
                  and complete.
                </Typography>
              </BooleanCheckbox>
            </>
          )}

          {inputQuestions.filter((q) => q.code === 'CTPRE003').length > 0 && (
            <LocationAndDamages
              namePrefix={`questions[${LADIndex}].`}
              question={formik.values.questions[LADIndex]}
              onChange={formik.handleChange}
              setFieldValue={formik.setFieldValue}
              errors={getIn(formik.errors, `questions[${LADIndex}]`)}
              touched={getIn(formik.touched, `questions[${LADIndex}]`)}
            />
          )}
          <HStack
            w="100%"
            justifyContent={
              questionType === QuestionType.CTPreInspection
                ? 'flex-start'
                : 'flex-end'
            }
          >
            {questionType === QuestionType.CTPreInspection && (
              <Button
                variant="secondary"
                data-testid="inspection-back-button"
                onClick={() => {
                  navigate('/inspection/list');
                }}
              >
                Back
              </Button>
            )}

            {questionType === QuestionType.CTPostInspection && (
              <>
                <Button
                  variant="secondary"
                  data-testid="cancel-button"
                  onClick={() => {
                    setShowCTPostInspectionQuestionsDialog(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
            <Button
              data-testid="CTInspectionQuestions-submit"
              aria-label="CTInspectionQuestions-Submit"
              variant="primary"
              type="submit"
              isDisabled={
                !acknowledgment &&
                questionType === QuestionType.CTPostInspection
              }
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      </Box>
    </form>
  );
};

export default CTInspectionQuestions;
