import { HStack } from '@chakra-ui/react';
import { Button, Modal } from '@sgi/gravity';
import React, { useEffect, useState } from 'react';

const ConfirmationDialogBox = React.forwardRef((props: any, ref) => {
  const {
    show,
    confirmButtonText,
    confirmButtonAction,
    showConfirmCallToAction,
    title,
    content,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  function handleClose() {
    setShowModal(false);
    props.close();
  }

  const modelFooter = (
    <HStack>
      <Button
        variant="secondary"
        data-testid="confirmation-cancel-btn"
        onClick={handleClose}
      >
        Close
      </Button>
      {showConfirmCallToAction && showConfirmCallToAction === true && (
        <Button
          isDestructive={true}
          variant="primary"
          data-testid="confirmation-ok-btn"
          onClick={confirmButtonAction}
        >
          {confirmButtonText}
        </Button>
      )}
    </HStack>
  );

  return (
    <Modal
      isOpen={showModal}
      onClose={handleClose}
      footer={modelFooter}
      title={title}
      data-testid="confirmation-dialog"
    >
      <>{content}</>
    </Modal>
  );
});
export default ConfirmationDialogBox;
