import { VStack } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';

export default function NotAuthorized() {
  return (
    <VStack mt={24}>
      <Typography variant="h1">Unauthorized</Typography>
      <Typography> You are not authorized to view this page</Typography>
    </VStack>
  );
}
