import { HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Modal, SearchInput, Typography, useToast } from '@sgi/gravity';

import { useSearchContactsFromLookupQuery } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import {
  faArrowLeftIcon,
  faArrowRightIcon,
  faMagnifyingGlass,
} from 'App/Common/Icons';
import CreateContactForm from 'App/Contact/CreateContactForm';
import React, { useEffect, useState } from 'react';
import { ContactDTO } from 'Types/ContactDTO';
import {
  CreateInspectionDTO,
  CreateInspectionHelperDetailsDTO,
  defaultCreateInspectionHelperContactDTO,
} from 'Types/CreateInspectionDTO';
import { CreateReInspectionDTO } from 'Types/CreateReInspectionDTO';
import { InspectionContactListDTO } from 'Types/InspectionContactListDTO';
import ContactResultPagination from './ContactResultPagination';

interface ContactInfoProps {
  step: number;
  setStep: Function;
  contactId: string;
  setInspection:
    | React.Dispatch<React.SetStateAction<CreateInspectionDTO>>
    | React.Dispatch<React.SetStateAction<CreateReInspectionDTO>>;
  inspectionHelperDetailsDTO: CreateInspectionHelperDetailsDTO;
  setCreateInspectionHelperDetailsDTO: Function;
}

const ContactInfoStep = ({
  step,
  setStep,
  contactId,
  setInspection,
  inspectionHelperDetailsDTO,
  setCreateInspectionHelperDetailsDTO,
}: ContactInfoProps) => {
  const [showCreateContactDialog, setShowCreateContactDialog] =
    useState<boolean>(false);
  const [contactToSearch, setContactToSearch] = useState(contactId ?? '');
  const [skip, setSkip] = useState(contactId ? false : true);
  const [selected, setSelected] = useState<
    InspectionContactListDTO | undefined
  >(
    inspectionHelperDetailsDTO && inspectionHelperDetailsDTO.contact
      ? inspectionHelperDetailsDTO.contact
      : undefined,
  );
  const { showToast } = useToast();
  const [cachedInspectionContactListDTO, setCachedInspectionContactListDTO] =
    useState<InspectionContactListDTO[]>([]);

  useEffect(() => {
    if (
      inspectionHelperDetailsDTO &&
      inspectionHelperDetailsDTO.contact &&
      inspectionHelperDetailsDTO.contact.id !== ''
    ) {
      let tempInspectionContactListDTO = [];
      tempInspectionContactListDTO.push(inspectionHelperDetailsDTO.contact);
      setCachedInspectionContactListDTO(tempInspectionContactListDTO);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionHelperDetailsDTO.contact]);

  const {
    data: contacts,
    isError: isSearchContactsResultsError,
    error: searchContactsResultsError,
    isFetching: isSearchContactResultsFetching,
  } = useSearchContactsFromLookupQuery(contactToSearch, { skip });

  const handleSearch = (searchString: string) => {
    if (searchString) {
      setContactToSearch(searchString);
      setSkip(false);
      setSelected(undefined);
      setCachedInspectionContactListDTO([]);

      setCreateInspectionHelperDetailsDTO({
        contact: defaultCreateInspectionHelperContactDTO,
        vehicle: inspectionHelperDetailsDTO.vehicle,
      });

      setInspection((prev: any) => {
        return { ...prev, contactId: '' };
      });
    }
  };

  const handleContinue = () => {
    if (selected) {
      setInspection((prev: any) => {
        return { ...prev, contactId: selected.id };
      });
      setCreateInspectionHelperDetailsDTO({
        ...inspectionHelperDetailsDTO,
        contact: selected,
      });
      setStep(step + 1);
    }
  };

  const handleCreateContact = (contact: ContactDTO) => {
    setSelected(contact);
    setInspection((prev: any) => {
      return { ...prev, contactId: contact.id };
    });
    setCreateInspectionHelperDetailsDTO({
      ...inspectionHelperDetailsDTO,
      contact: contact,
    });
    setShowCreateContactDialog(false);
    setStep(step + 1);
  };

  useEffect(() => {
    if (isSearchContactsResultsError) {
      showToast({
        status: 'error',
        title: 'Search Error',
        description: <ErrorList errors={searchContactsResultsError} />,
      });
    }
  }, [isSearchContactsResultsError, searchContactsResultsError, showToast]);

  return (
    <>
      <Modal
        data-testid="Create-Contact-Dialog"
        modalContentProps={{ maxW: '1024px', height: '645px' }}
        isOpen={showCreateContactDialog}
        onClose={() => {
          setShowCreateContactDialog(false);
        }}
        title="Create Contact"
        isCentered={true}
        scrollBehavior={'inside'}
      >
        <CreateContactForm
          parentType={'Modal'}
          handleSelectContact={handleCreateContact}
        />
      </Modal>
      <VStack align="flex-start">
        <VStack align="flex-start" w="100%">
          <HStack width="100%" justify={'space-between'} align="start">
            <VStack align="flex-start" width={'45%'}>
              <SearchInput
                size="md"
                hasButton
                placeholder=""
                name="contact-search"
                onSearch={handleSearch}
                aria-label="contact-search"
              />
              <Typography variant={'bodySmall'} color={'gray.600'}>
                Search by: Name, Customer Number
              </Typography>
            </VStack>
            {contacts &&
              contacts?.length > 0 &&
              (!inspectionHelperDetailsDTO.contact ||
                inspectionHelperDetailsDTO.contact?.id === '') && (
                <Button
                  data-testid="create-contact-btn"
                  onClick={() => {
                    setShowCreateContactDialog(true);
                  }}
                >
                  Create Contact
                </Button>
              )}
          </HStack>
          {contacts && contacts?.length > 0 && (
            <ContactResultPagination
              contacts={contacts}
              selected={selected}
              setSelected={setSelected}
            ></ContactResultPagination>
          )}
          {contacts &&
            contacts?.length === 0 &&
            !isSearchContactResultsFetching &&
            (!inspectionHelperDetailsDTO.contact ||
              inspectionHelperDetailsDTO.contact?.id === '') && (
              <VStack width="100%">
                <Icon w="24" h="24">
                  {faMagnifyingGlass}
                </Icon>
                <Typography variant="h3">
                  We couldn't find anything for {contactToSearch}
                </Typography>
                <Button
                  data-testid="create-contact-btn"
                  onClick={() => {
                    setShowCreateContactDialog(true);
                  }}
                >
                  + Create Contact
                </Button>
              </VStack>
            )}
          {contacts &&
            contacts?.length === 0 &&
            !isSearchContactResultsFetching &&
            inspectionHelperDetailsDTO.contact.id !== '' &&
            cachedInspectionContactListDTO &&
            cachedInspectionContactListDTO?.length > 0 && (
              <ContactResultPagination
                contacts={cachedInspectionContactListDTO}
                selected={selected}
                setSelected={setSelected}
              ></ContactResultPagination>
            )}
        </VStack>
        <HStack w="100%" justifyContent="space-between">
          <Button
            variant="secondary"
            leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
            onClick={() => setStep(step - 1)}
          >
            Previous step
          </Button>
          <Button
            disabled={!selected || !selected.id}
            data-testid="ContactInfo-Continue"
            onClick={handleContinue}
            rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
          >
            Continue to the next step
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default ContactInfoStep;
