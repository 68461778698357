import { Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Typography } from '@sgi/gravity';
import { UploadFile } from 'Types/InspectionDTO';
import { faEyeIcon, faFileLinesIcon, faTrashIcon } from '../Icons';

interface FileInfoListProps {
  fileInfos?: UploadFile[];
  onFilePreview: (fileName: string | null) => void;
  onFileDelete: (fileName: string | null, index: number) => void;
}

export default function FileInfoList({
  fileInfos,
  onFilePreview,
  onFileDelete,
}: FileInfoListProps) {
  if (!fileInfos) {
    return null;
  }

  return (
    <VStack w="100%" align={'start'}>
      {fileInfos.map((file, index) => {
        return (
          <Grid
            data-testid={`uploaded-files`}
            key={`${file.tempId + index}`}
            templateColumns="repeat(5, 1fr)"
            p="16px"
            gap="16px"
            w="100%"
          >
            <GridItem colSpan={4}>
              <HStack>
                <Icon aria-label={`view-file-${index}}`}>
                  {faFileLinesIcon}
                </Icon>
                <Typography variant="bodySmall">
                  {file.clientFileName}
                </Typography>
              </HStack>
            </GridItem>
            <GridItem>
              <HStack justify={'end'}>
                <Button
                  type="button"
                  rightIcon={
                    <Icon
                      fill="teal.500"
                      _hover={{ color: 'teal.600' }}
                      aria-label={`view-file-${index}`}
                    >
                      {faEyeIcon}
                    </Icon>
                  }
                  isDestructive={false}
                  variant="tertiary"
                  onClick={() => onFilePreview(file.storageFileName)}
                >
                  View
                </Button>
                <Button
                  type="button"
                  rightIcon={
                    <Icon
                      fill="red.500"
                      _hover={{ color: 'red.600' }}
                      aria-label={`delete-file-${index}`}
                    >
                      {faTrashIcon}
                    </Icon>
                  }
                  isDestructive={true}
                  variant="tertiary"
                  onClick={() => onFileDelete(file.storageFileName, index)}
                >
                  Delete
                </Button>
              </HStack>
            </GridItem>
          </Grid>
        );
      })}
    </VStack>
  );
}
