import ContactRouter from 'App/Contact/ContactRouter';
import Home from 'App/Home';
import InspectionRouter from 'App/Inspection/InspectionRouter';
import StationRouter from 'App/Station/StationRouter';
import VehicleRouter from 'App/Vehicle/VehicleRouter';
import { Route, Routes } from 'react-router-dom';
import NotAuthorized from '../NotAuthorized';
import PageNotFound from '../PageNotFound';
import PrivateRoute from '../PrivateRoute';

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Home></Home>} />
      <Route
        path="/vehicle/*"
        element={
          <PrivateRoute redirectToLogin={true} element={<VehicleRouter />} />
        }
      />
      <Route
        path="/inspection/*"
        element={
          <PrivateRoute redirectToLogin={true} element={<InspectionRouter />} />
        }
      />
      <Route
        path="/contact/*"
        element={
          <PrivateRoute redirectToLogin={true} element={<ContactRouter />} />
        }
      />
      <Route
        path="/station/*"
        element={
          <PrivateRoute redirectToLogin={true} element={<StationRouter />} />
        }
      />
      <Route path="/PageNotAuthorized" element={<NotAuthorized />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Routing;
