export interface CreateStationDTO {
  stationName: string;
  shopOwner: string;
}

export const defaultCreateStationDTO: CreateStationDTO = {
  stationName: '',
  shopOwner: '',
};

export const initCreateStationDTO: CreateStationDTO = {
  stationName: '',
  shopOwner: '',
};

export const updCreateStationDTO: CreateStationDTO = {
  stationName: '',
  shopOwner: '',
};
