import { useIsAuthenticated } from '@azure/msal-react';
import { SideBar, SideBarCategory } from '@sgi/gravity';
import { useGetPermissionsQuery } from 'api/InspectTechUIAPI';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCar, faClipboard, faContact, faHouse, faStation } from '../Icons';

export default function AppSideBar() {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const [skip, setSkip] = useState(true);
  const { data: permissions } = useGetPermissionsQuery(undefined, { skip });

  useEffect(() => {
    if (isAuthenticated) {
      setSkip(false);
    }
  }, [isAuthenticated]);

  return (
    <SideBar>
      <div onClick={() => navigate('/')}>
        <SideBarCategory label="Home" icon={faHouse} />
      </div>

      {(permissions?.canSearchInspections ||
        permissions?.canCreateInspection) && (
        <div onClick={() => navigate('/inspection/list')}>
          <SideBarCategory label="Inspections" icon={faClipboard} />
        </div>
      )}

      {(permissions?.canSearchVehicles || permissions?.canCreateVehicle) && (
        <div onClick={() => navigate('vehicle/list')}>
          <SideBarCategory label="Vehicles" icon={faCar} />
        </div>
      )}

      {(permissions?.canSearchContacts || permissions?.canCreateContact) && (
        <div onClick={() => navigate('contact/list')}>
          <SideBarCategory label="Contacts" icon={faContact} />
        </div>
      )}

      {(permissions?.canCreateStation || permissions?.canSearchStations) && (
        <div onClick={() => navigate('station/list')}>
          <SideBarCategory label="Inspection Stations" icon={faStation} />
        </div>
      )}
    </SideBar>
  );
}
