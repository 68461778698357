import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Input, Typography, useToast } from '@sgi/gravity';
import { useCreateContactMutation } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import UnsavedBlockerDialogBox from 'App/Common/UnsavedBlockerDialogBox';
import {
  contactMessages,
  validationMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { isEqual } from 'lodash';
import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactAddressDTO, updContactAddressDTO } from 'Types/ContactDTO';
import {
  CreateContactDTO,
  defaultCreateContactDTO,
  initCreateContactDTO,
  updCreateContactDTO,
} from 'Types/CreateContactDTO';
import {
  addressSchema,
  customerNumberSchema,
  phoneInfoSchema,
} from 'Utils/commonValidationSchemas';
import * as Yup from 'yup';
interface CreateContactProps {
  parentType: string;
  handleSelectContact: any;
}

export const CreateContactForm = ({
  parentType,
  handleSelectContact,
}: CreateContactProps) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const [
    createContact,
    {
      data: createdContact,
      isError: isCreateContactError,
      error: createContactError,
      isSuccess: isCreateContactSuccess,
      isLoading: isCreateContactLoading,
    },
  ] = useCreateContactMutation();

  const { showErrorToast, showSuccessToast } = useToast();
  useEffect(() => {
    if (isCreateContactError) {
      showErrorToast({
        description: <ErrorList errors={createContactError} />,
      });
    }
  }, [isCreateContactError, createContactError, showErrorToast]);

  useEffect(() => {
    if (isCreateContactSuccess) {
      showSuccessToast({ description: contactMessages.created });
      if (parentType === 'Page') {
        navigate(`/contact/${createdContact?.id}`);
      } else {
        handleSelectContact(createdContact);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateContactSuccess, createdContact?.id, navigate, showSuccessToast]);

  const formik = useFormik({
    initialValues: { ...defaultCreateContactDTO },
    validationSchema: Yup.object()
      .shape({
        firstName: Yup.string().required(
          validationMessages.contactFirstName.required,
        ),
        lastName: Yup.string().required(
          validationMessages.contactLastName.required,
        ),
        email: Yup.string(),
      })
      .concat(addressSchema)
      .concat(customerNumberSchema)
      .concat(phoneInfoSchema),
    onSubmit: (values: CreateContactDTO) => {
      setShowDialog(false);
      createContact(values);
    },
  });

  const isExtensionDisabled = !formik.values.phoneInfo?.workPhone;

  useEffect(() => {
    if (isExtensionDisabled) {
      formik.setFieldValue('phoneInfo.workPhoneExtension', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtensionDisabled]);

  const formOnHandleBlur = (event: FormEvent) => {
    checkIfContactDataChanged();
  };

  const formOnHandleChange = (event: FormEvent) => {
    checkIfContactDataChanged();
  };

  const checkIfContactDataChanged = () => {
    const keys = Object.keys(formik.initialValues);
    let initCreateContact: any = initCreateContactDTO;
    let updCreateContact: any = updCreateContactDTO;
    let updContactAddress: ContactAddressDTO = updContactAddressDTO;

    keys.forEach((key) => {
      if ((key as keyof CreateContactDTO) === 'address') {
        const addressKeys = Object.keys(formik.initialValues.address);

        addressKeys.forEach((addKey) => {
          updContactAddress[addKey as keyof ContactAddressDTO] =
            formik.values.address[addKey as keyof ContactAddressDTO];
        });

        updCreateContact.address = updContactAddress;
      } else {
        updCreateContact[key as keyof CreateContactDTO] =
          formik.values[key as keyof CreateContactDTO];
      }
    });

    const hasChanged = !isEqual(initCreateContact, updCreateContact);
    setShowDialog(hasChanged);
  };

  return (
    <>
      <LoadingModal show={isCreateContactLoading} />
      <UnsavedBlockerDialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Box w="100%" p={4}>
        <form
          onSubmit={formik.handleSubmit}
          onBlur={formOnHandleBlur}
          onChange={formOnHandleChange}
        >
          <Box w="100%">
            <VStack w="100%" alignItems="flex-start">
              <Box
                borderWidth={'1px'}
                w="100%"
                borderColor={'#F4F4F5'}
                p={4}
                borderRadius={8}
              >
                <Grid
                  templateColumns={
                    parentType === 'Modal' ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'
                  }
                  templateRows="repeat(2, 1fr)"
                  columnGap={4}
                >
                  <GridItem colSpan={2}>
                    <HStack align={'baseline'}>
                      <Input
                        name="firstName"
                        data-testid="contact-create-firstName"
                        placeholder="First Name"
                        hideOptionalLabel
                        size="md"
                        label="First Name"
                        error={
                          formik.touched.firstName
                            ? formik.errors.firstName
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />
                      <Input
                        name="lastName"
                        data-testid="contact-create-lastName"
                        placeholder="Last Name"
                        hideOptionalLabel
                        size="md"
                        label="Last Name"
                        error={
                          formik.touched.lastName
                            ? formik.errors.lastName
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />
                    </HStack>
                  </GridItem>
                  <GridItem rowStart={2} rowEnd={2}>
                    <Input
                      name="customerNumber"
                      data-testid="contact-create-customerNumber"
                      placeholder="Customer #"
                      size="md"
                      label="Customer #"
                      error={
                        formik.touched.customerNumber
                          ? formik.errors.customerNumber
                          : undefined
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.customerNumber?.toString() || ''}
                    />
                  </GridItem>
                </Grid>
              </Box>
              <Box
                borderWidth={'1px'}
                borderColor={'#F4F4F5'}
                p={4}
                mt={3}
                w="100%"
                borderRadius={8}
              >
                <Grid
                  templateColumns={
                    parentType === 'Modal' ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'
                  }
                  templateRows="repeat(4, 1fr)"
                  columnGap={4}
                >
                  <GridItem colSpan={1} rowSpan={1} rowStart={1} rowEnd={1}>
                    <VStack align={'start'}>
                      <Typography variant="h4">Contact Information</Typography>
                      <Input
                        name="email"
                        data-testid="contact-create-email"
                        placeholder="Email Address"
                        size="md"
                        label="Email Address"
                        error={
                          formik.touched.email ? formik.errors.email : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={1} rowSpan={1} rowStart={2} rowEnd={2}>
                    <Input
                      name="phoneInfo.homePhone"
                      data-testid="contact-create-homePhone"
                      placeholder="Home Phone Number"
                      hideOptionalLabel
                      size="md"
                      label="Home Phone Number"
                      error={
                        formik.touched.phoneInfo?.homePhone
                          ? formik.errors.phoneInfo?.homePhone ||
                            formik.errors.phoneInfo?.phoneError
                          : undefined
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneInfo.homePhone || ''}
                    />
                  </GridItem>
                  <GridItem colSpan={1} rowSpan={1} rowStart={3} rowEnd={3}>
                    <Input
                      name="phoneInfo.cellPhone"
                      data-testid="contact-create-cellPhone"
                      placeholder="Cell Phone Number"
                      hideOptionalLabel
                      size="md"
                      label="Cell Phone Number"
                      error={
                        formik.touched.phoneInfo?.cellPhone
                          ? formik.errors.phoneInfo?.cellPhone ||
                            formik.errors.phoneInfo?.phoneError
                          : undefined
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneInfo.cellPhone || ''}
                    />
                  </GridItem>
                  <GridItem colSpan={2} rowSpan={1} rowStart={4} rowEnd={4}>
                    <HStack align={'baseline'}>
                      <Input
                        name="phoneInfo.workPhone"
                        data-testid="contact-create-workPhone"
                        placeholder="Work Phone Number"
                        hideOptionalLabel
                        size="md"
                        label="Work Phone Number"
                        error={
                          formik.touched.phoneInfo?.workPhone
                            ? formik.errors.phoneInfo?.workPhone ||
                              formik.errors.phoneInfo?.phoneError
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneInfo.workPhone || ''}
                      />
                      <Input
                        name="phoneInfo.workPhoneExtension"
                        data-testid="contact-create-workPhoneExtension"
                        placeholder="Extension"
                        hideOptionalLabel
                        size="md"
                        label="Extension"
                        error={
                          formik.touched.phoneInfo?.workPhoneExtension
                            ? formik.errors.phoneInfo?.workPhoneExtension
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isDisabled={isExtensionDisabled}
                        value={formik.values.phoneInfo.workPhoneExtension || ''}
                      />
                    </HStack>
                  </GridItem>
                </Grid>
              </Box>
              <Box
                borderWidth={'1px'}
                borderColor={'#F4F4F5'}
                p={4}
                mt={3}
                w="100%"
                borderRadius={8}
              >
                <Grid
                  templateColumns={
                    parentType === 'Modal' ? 'repeat(3, 1fr)' : 'repeat(4, 1fr)'
                  }
                  templateRows="repeat(2, 1fr)"
                  columnGap={4}
                >
                  <GridItem colSpan={2} rowStart={1} rowEnd={1}>
                    <VStack align={'start'} width="100%">
                      <Typography variant="h4">Mailing Address</Typography>
                      <HStack align={'baseline'} width="100%">
                        <Input
                          name="address.street"
                          data-testid="contact-create-street"
                          placeholder="Address"
                          hideOptionalLabel
                          size="md"
                          label="Address"
                          error={
                            formik.touched.address?.street
                              ? formik.errors.address?.street
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.street}
                        />
                        <Input
                          name="address.city"
                          data-testid="contact-create-city"
                          placeholder="City"
                          hideOptionalLabel
                          size="md"
                          label="City"
                          error={
                            formik.touched.address?.city
                              ? formik.errors.address?.city
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.city}
                        />
                      </HStack>
                    </VStack>
                  </GridItem>
                  <GridItem colSpan={2} rowStart={2} rowEnd={2}>
                    <HStack align={'baseline'}>
                      <Input
                        name="address.province"
                        data-testid="contact-create-province"
                        placeholder="Province"
                        hideOptionalLabel
                        size="md"
                        label="Province"
                        error={
                          formik.touched.address?.province
                            ? formik.errors.address?.province
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address?.province}
                      />

                      <Input
                        name="address.postalCode"
                        data-testid="contact-create-postalCode"
                        placeholder="Postal Code"
                        hideOptionalLabel
                        size="md"
                        label="Postal Code"
                        error={
                          formik.touched.address?.postalCode
                            ? formik.errors.address?.postalCode
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.address?.postalCode}
                      />
                    </HStack>
                  </GridItem>
                </Grid>
              </Box>
              <HStack>
                {parentType === 'Page' && (
                  <Button
                    variant="secondary"
                    onClick={() => {
                      navigate('/contact/list');
                    }}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="primary"
                  data-testid="contact-create-submit"
                  type="submit"
                  size="md"
                >
                  Create Contact
                </Button>
              </HStack>
            </VStack>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default CreateContactForm;
