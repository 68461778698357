import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Input, Typography, useToast } from '@sgi/gravity';
import {
  useGetContactByIdQuery,
  useUpdateContactMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import UnsavedBlockerDialogBox from 'App/Common/UnsavedBlockerDialogBox';
import {
  contactMessages,
  validationMessages,
} from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useCallbackPrompt } from 'hooks/useCallbackPrompt';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  defaultUpdateContactDTO,
  UpdateContactDTO,
} from 'Types/UpdateContactDTO';
import {
  addressSchema,
  customerNumberSchema,
  phoneInfoSchema,
} from 'Utils/commonValidationSchemas';
import * as Yup from 'yup';

export const UpdateContactForm = () => {
  const navigate = useNavigate();
  const { contactId } = useParams<'contactId'>();
  const [initialContact, setInitialContact] = useState<UpdateContactDTO>(
    defaultUpdateContactDTO,
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const { data: contact, isSuccess: isGetContactSuccess } =
    useGetContactByIdQuery(contactId ?? '');

  const { showErrorToast, showSuccessToast } = useToast();

  useEffect(() => {
    if (isGetContactSuccess) {
      const objContact: UpdateContactDTO = {
        id: contact.id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        phoneInfo: contact.phoneInfo,
        email: contact.email,
        address: { ...contact.address },
      };
      setInitialContact(objContact);
    }
  }, [isGetContactSuccess, contact]);

  const [
    updateContact,
    {
      data: updatedId,
      isError: isUpdateContactError,
      error: updateContactError,
      isSuccess: isUpdateContactSuccess,
      isLoading: isUpdateContactLoading,
    },
  ] = useUpdateContactMutation();

  useEffect(() => {
    if (isUpdateContactError) {
      showErrorToast({
        description: <ErrorList errors={updateContactError} />,
      });
    }
  }, [isUpdateContactError, updateContactError, showErrorToast]);

  useEffect(() => {
    if (isUpdateContactSuccess) {
      showSuccessToast({ description: contactMessages.updated });
      navigate(`/contact/${updatedId}`);
    }
  }, [isUpdateContactSuccess, updatedId, navigate, showSuccessToast]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { ...initialContact },
    validationSchema: Yup.object({
      firstName: Yup.string().required(
        validationMessages.contactFirstName.required,
      ),
      lastName: Yup.string().required(
        validationMessages.contactLastName.required,
      ),
      email: Yup.string(),
    })
      .concat(addressSchema)
      .concat(customerNumberSchema)
      .concat(phoneInfoSchema),
    onSubmit: (values: UpdateContactDTO) => {
      setShowDialog(false);
      updateContact(values);
    },
  });

  const isExtensionDisabled = !formik.values.phoneInfo?.workPhone;

  useEffect(() => {
    if (isExtensionDisabled) {
      formik.setFieldValue('phoneInfo.workPhoneExtension', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtensionDisabled]);

  useEffect(() => {
    setShowDialog(formik.values !== formik.initialValues);
  }, [formik.values, formik.initialValues]);

  return (
    <>
      <UnsavedBlockerDialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <LoadingModal show={isUpdateContactLoading} />
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Edit Contact</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" borderRadius={8}>
          <form onSubmit={formik.handleSubmit}>
            <Box w="100%" p={4}>
              <VStack alignItems="flex-start">
                <Box
                  borderWidth={'1px'}
                  w="100%"
                  borderColor={'#F4F4F5'}
                  p={4}
                  borderRadius={8}
                >
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    templateRows="repeat(1, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={2}>
                      <HStack align={'baseline'}>
                        <Input
                          name="firstName"
                          data-testid="contact-update-firstName"
                          placeholder="First Name"
                          hideOptionalLabel
                          size="md"
                          label="First Name"
                          error={
                            formik.touched.firstName
                              ? formik.errors.firstName
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        <Input
                          name="lastName"
                          data-testid="contact-update-lastName"
                          placeholder="Last Name"
                          hideOptionalLabel
                          size="md"
                          label="Last Name"
                          error={
                            formik.touched.lastName
                              ? formik.errors.lastName
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                      </HStack>
                    </GridItem>
                  </Grid>
                </Box>
                <Box
                  borderWidth={'1px'}
                  borderColor={'#F4F4F5'}
                  p={4}
                  mt={3}
                  w="100%"
                  borderRadius={8}
                >
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    templateRows="repeat(5, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1}>
                      <Typography variant="h3">Contact Information</Typography>
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1} rowStart={2} rowEnd={2}>
                      <Input
                        name="email"
                        data-testid="contact-update-email"
                        placeholder="Email Address"
                        size="md"
                        label="Email Address"
                        error={
                          formik.touched.email ? formik.errors.email : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1} rowStart={3} rowEnd={3}>
                      <Input
                        name="phoneInfo.homePhone"
                        data-testid="contact-update-homePhone"
                        placeholder="Home Phone Number"
                        hideOptionalLabel
                        size="md"
                        label="Home Phone Number"
                        error={
                          formik.touched.phoneInfo?.homePhone
                            ? formik.errors.phoneInfo?.homePhone ||
                              formik.errors.phoneInfo?.phoneError
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneInfo?.homePhone || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={1} rowSpan={1} rowStart={4} rowEnd={4}>
                      <Input
                        name="phoneInfo.cellPhone"
                        data-testid="contact-update-cellPhone"
                        placeholder="Cell Phone Number"
                        hideOptionalLabel
                        size="md"
                        label="Cell Phone Number"
                        error={
                          formik.touched.phoneInfo?.cellPhone
                            ? formik.errors.phoneInfo?.cellPhone ||
                              formik.errors.phoneInfo?.phoneError
                            : undefined
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneInfo?.cellPhone || ''}
                      />
                    </GridItem>
                    <GridItem colSpan={2} rowSpan={1} rowStart={5} rowEnd={5}>
                      <HStack align={'baseline'}>
                        <Input
                          name="phoneInfo.workPhone"
                          data-testid="contact-update-workPhone"
                          placeholder="Work Phone Number"
                          hideOptionalLabel
                          size="md"
                          label="Work Phone Number"
                          error={
                            formik.touched.phoneInfo?.workPhone
                              ? formik.errors.phoneInfo?.workPhone ||
                                formik.errors.phoneInfo?.phoneError
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneInfo?.workPhone || ''}
                        />
                        <Input
                          name="phoneInfo.workPhoneExtension"
                          data-testid="contact-update-workPhoneExtension"
                          placeholder="Extension"
                          hideOptionalLabel
                          size="md"
                          label="Extension"
                          error={
                            formik.touched.phoneInfo?.workPhoneExtension
                              ? formik.errors.phoneInfo?.workPhoneExtension
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={
                            formik.values.phoneInfo?.workPhoneExtension || ''
                          }
                          isDisabled={isExtensionDisabled}
                        />
                      </HStack>
                    </GridItem>
                  </Grid>
                </Box>
                <Box
                  borderWidth={'1px'}
                  borderColor={'#F4F4F5'}
                  p={4}
                  mt={3}
                  w="100%"
                  borderRadius={8}
                >
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    templateRows="repeat(3, 1fr)"
                    columnGap={4}
                  >
                    <GridItem colSpan={1} rowStart={1} rowEnd={1}>
                      <Typography variant="h3">Mailing Address</Typography>
                    </GridItem>
                    <GridItem colSpan={2} rowStart={2} rowEnd={2}>
                      <HStack align={'baseline'}>
                        <Input
                          name="address.street"
                          data-testid="contact-update-street"
                          placeholder="Address"
                          hideOptionalLabel
                          size="md"
                          label="Address"
                          error={
                            formik.touched.address?.street
                              ? formik.errors.address?.street
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.street}
                        />
                        <Input
                          name="address.city"
                          data-testid="contact-update-city"
                          placeholder="City"
                          hideOptionalLabel
                          size="md"
                          label="City"
                          error={
                            formik.touched.address?.city
                              ? formik.errors.address?.city
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.city}
                        />
                      </HStack>
                    </GridItem>
                    <GridItem colSpan={2} rowStart={3} rowEnd={3}>
                      <HStack align={'baseline'}>
                        <Input
                          name="address.province"
                          data-testid="contact-update-province"
                          placeholder="Province"
                          hideOptionalLabel
                          size="md"
                          label="Province"
                          error={
                            formik.touched.address?.province
                              ? formik.errors.address?.province
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.province}
                        />

                        <Input
                          name="address.postalCode"
                          data-testid="contact-update-postalCode"
                          placeholder="Postal Code"
                          hideOptionalLabel
                          size="md"
                          label="Postal Code"
                          error={
                            formik.touched.address?.postalCode
                              ? formik.errors.address?.postalCode
                              : undefined
                          }
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address?.postalCode}
                        />
                      </HStack>
                    </GridItem>
                  </Grid>
                </Box>
                <HStack>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      navigate(`/contact/${contact?.id}`);
                    }}
                  >
                    Back
                  </Button>
                  <Button data-testid="contact-update-submit" type="submit">
                    Update Contact
                  </Button>
                </HStack>
              </VStack>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default UpdateContactForm;
