import { Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Card, Typography } from '@sgi/gravity';
import { InspectionContactListDTO } from 'Types/InspectionContactListDTO';
import { formatPhone } from 'Utils/Format';

interface ContactSearchResultProps {
  contact: InspectionContactListDTO;
  selected: InspectionContactListDTO | undefined;
  setSelected: Function;
}
const ContactSearchResult = ({
  contact,
  selected,
  setSelected,
}: ContactSearchResultProps) => {
  return (
    <Card
      data-testid="search-contact-item"
      cardStyleProps={{ width: '100%' }}
      className={selected && selected.id === contact.id ? 'selected-card' : ''}
      aria-label="search-result-card"
    >
      <div
        onClick={() => setSelected(contact)}
        style={{ cursor: 'pointer' }}
        aria-label="search-result"
      >
        <Grid templateColumns="repeat(4,1fr)">
          <GridItem colStart={1}>
            <Typography variant="h4">{`${contact.lastName}, ${contact.firstName}`}</Typography>
          </GridItem>
          <GridItem colStart={2}>
            <VStack align="flex-start">
              <HStack>
                <Typography variant="bodyBold">Home Phone:</Typography>
                <Typography variant="body">
                  {formatPhone(contact.phoneInfo?.homePhone)}
                </Typography>
              </HStack>
              <HStack>
                <Typography variant="bodyBold">Cell Phone:</Typography>
                <Typography variant="body">
                  {formatPhone(contact.phoneInfo?.cellPhone)}
                </Typography>
              </HStack>
              <HStack>
                <Typography variant="bodyBold">Work Phone:</Typography>
                <Typography variant="body">
                  {contact.phoneInfo?.workPhone &&
                    formatPhone(contact.phoneInfo?.workPhone) +
                      (contact.phoneInfo?.workPhoneExtension
                        ? ` Ext: ${contact.phoneInfo?.workPhoneExtension}`
                        : '')}
                </Typography>
              </HStack>
            </VStack>
          </GridItem>
          <GridItem colStart={3} colSpan={2}>
            <VStack align="flex-start">
              <HStack>
                <Typography variant="bodyBold">Customer Number:</Typography>
                <Typography variant="body">{contact.customerNumber}</Typography>
              </HStack>
              <HStack>
                <Typography variant="bodyBold">Email:</Typography>
                <Typography variant="body">{contact.email}</Typography>
              </HStack>
              <HStack>
                <Typography variant="bodyBold">Mailing Address:</Typography>
                <Typography variant="body">{`${contact.address?.street}, ${contact.address?.city}, ${contact.address?.province}, ${contact.address?.postalCode}`}</Typography>
              </HStack>
            </VStack>
          </GridItem>
        </Grid>
      </div>
    </Card>
  );
};

export default ContactSearchResult;
