import {
  InspectionJurisdiction,
  InspectionProgram,
  InspectionType,
  UnitOfDistance,
} from './InspectionEnums';
import { VinStatus } from './VehicleDTO';
import { WheelInfoDTO } from './WheelInfoDTO';

export interface InspectionDTO {
  id: string;
  vin: string;
  startDate?: string;
  status: InspectionStatus;
  approvalStatus: InspectionApprovalStatus;
  approvalDate: string;
  returnDate: string;
  unitNumber: string;
  odometer: number;
  odometerUoD: UnitOfDistance;
  hubometer: number;
  hubometerUoD: UnitOfDistance;
  plate: string;
  jurisdiction: InspectionJurisdiction;
  program: InspectionProgram;
  body: InspectionBodyDTO[];
  type: InspectionType;
  station: string;
  certifiedTechnician: string;
  signingOfficer: string;
  expiryDate?: string;
  vehicleInfo: VehicleInfo;
  contactId: string;
  contactInfo: ContactInfo;
  securityFlags?: InspectionSecurityFlags;
  dataVersion: number | undefined;
  wheelInfo: WheelInfoDTO;
  additionalNote: string | undefined;
  previousInspectionId: string | undefined;
  reInspectionId: string | undefined;
  completedDate: string | undefined;
  questions: Question[];
}

export interface VehicleInfo {
  make: string;
  model: string;
  year: number | undefined;
  bodyStyle: string;
  gvwr: number | undefined;
  color: string;
  status: VinStatus | undefined;
}

export interface ContactInfo {
  id: string;
  firstName: string;
  lastName: string;
  customerNumber: string;
  address: ContactAddress;
  phoneInfo: ContactPhone;
  email: string | undefined;
}

export interface ContactAddress {
  street: string;
  city: string;
  province: string;
  postalCode: string;
}

export interface ContactPhone {
  homePhone?: string;
  cellPhone?: string;
  workPhone?: string;
  workPhoneExtension?: string;
}

export interface InspectionDamageDetails {
  location?: InspectionDamageLocation;
  description: string;
  key?: string;
}

export interface UploadFile {
  tempId: number;
  clientFileName: string;
  storageFileName: string | null;
  file: File | null;
}

export interface Question {
  type: QuestionType;
  code: string;
  inspectionItem: string | undefined;
  isHidden: boolean | undefined;
  text: string;
  response: boolean | undefined;
  reason: string | undefined;
  attachments: QuestionAttachmentDto[] | undefined;
  damageDetails: InspectionDamageDetails[] | undefined;
}

export interface QuestionAttachmentDto {
  clientFileName: string;
  storageFileName: string;
}

export enum InspectionStatus {
  InProgress = 0,
  Pass = 1,
  Fail = 2,
}

export enum QuestionType {
  Contact = 1,
  CTPreInspection = 2,
  CTPostInspection = 3,
  InspectionItem = 4,
}

export const QuestionTypeLabel: {
  [key in QuestionType]: string;
} = {
  [QuestionType.Contact]: 'Pre-Inspection Question - Contact',
  [QuestionType.CTPreInspection]:
    'Pre-Inspection Question - Certified Technician',
  [QuestionType.CTPostInspection]:
    'Post-Inspection Question - Certified Technician',
  [QuestionType.InspectionItem]: 'Inspection Items Question',
};

export enum InspectionDamageLocation {
  Front = 1,
  Rear = 2,
  Roof = 3,
  Hood = 4,
  Trunk = 5,
  RightFrontCorner = 6,
  RightFrontSide = 7,
  RightSide = 8,
  RightRearSide = 9,
  RightRearCorner = 10,
  LeftRearCorner = 11,
  LeftRearSide = 12,
  LeftSide = 13,
  LeftFrontSide = 14,
  LeftFrontCorner = 15,
  Other = 16,
}

export const InspectionDamageLocationLabel: {
  [key in InspectionDamageLocation]: string;
} = {
  [InspectionDamageLocation.Front]: 'Front',
  [InspectionDamageLocation.Rear]: 'Rear',
  [InspectionDamageLocation.Roof]: 'Roof',
  [InspectionDamageLocation.Hood]: 'Hood',
  [InspectionDamageLocation.Trunk]: 'Trunk',
  [InspectionDamageLocation.RightFrontCorner]: 'Right Front Corner',
  [InspectionDamageLocation.RightFrontSide]: 'Right Front Side',
  [InspectionDamageLocation.RightSide]: 'Right Side',
  [InspectionDamageLocation.RightRearSide]: 'Right Rear Side',
  [InspectionDamageLocation.RightRearCorner]: 'Right Rear Corner',
  [InspectionDamageLocation.LeftRearCorner]: 'Left Rear Corner',
  [InspectionDamageLocation.LeftRearSide]: 'Left Rear Side',
  [InspectionDamageLocation.LeftSide]: 'Left Side',
  [InspectionDamageLocation.LeftFrontSide]: 'Left Front Side',
  [InspectionDamageLocation.LeftFrontCorner]: 'Left Front Corner',
  [InspectionDamageLocation.Other]: 'Other',
};

export const InspectionStatusLabel: {
  [key in InspectionStatus]: string;
} = {
  [InspectionStatus.InProgress]: 'In Progress',
  [InspectionStatus.Pass]: 'Pass',
  [InspectionStatus.Fail]: 'Fail',
};

export enum InspectionApprovalStatus {
  WaitingForApproval = 0,
  Approved = 1,
  InProgress = 2,
}

export const InspectionApprovalStatusLabel: {
  [key in InspectionApprovalStatus]: string;
} = {
  [InspectionApprovalStatus.WaitingForApproval]: 'Waiting For Approval',
  [InspectionApprovalStatus.Approved]: 'Approved',
  [InspectionApprovalStatus.InProgress]: 'In Progress',
};

export interface InspectionBodyDTO {
  id: string;
  categoryName: string;
  items: InspectionItemDTO[];
}
export interface InspectionItemDTO {
  id: string;
  name: string;
  comment?: string;
  commentHistory?: CommentDTO[];
  status: number | undefined;
  additionalFields: AdditionalFields | undefined;
  questions: Question[] | undefined;
  wasPreviouslyRejected?: boolean;
  wasPrevRejectedImmediatelyRepaired?: boolean;
}

export interface AdditionalFields {
  repairDetailNote: string;
  donorVehicle: string;
}

export interface CommentDTO {
  date: string;
  user: string;
  value: string;
  effectiveStatus: number;
}

export enum InspectionItemStatus {
  Default = 0,
  Pass = 1,
  Rejected = 2,
  Attention = 3,
  RepairedNow = 4,
}

interface InspectionSecurityFlags {
  canApprove?: boolean;
  canView?: boolean;
  canComplete?: boolean;
  canStart?: boolean;
  canUpdateQuestions?: boolean;
}

export type InspectionListDTO = {
  id: string;
  vin: string;
  startDate: string;
  status: InspectionStatus;
  approvalStatus: InspectionApprovalStatus;
  approvalDate: string;
  program: InspectionProgram;
  type: InspectionType;
  station: string;
  certifiedTechnician: string;
  signingOfficer: string;
  securityFlags?: InspectionSecurityFlags;
};

export interface CTQuestionDTO {
  questions: Question[];
}

export const defaultCTQuestionDTO: CTQuestionDTO = {
  questions: [],
};
