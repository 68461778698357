export interface SearchContactDTO {
  firstName: string;
  lastName: string;
  customerNumber: string;
  searchError: boolean | undefined;
}

export const defaultSearchContactDTO: SearchContactDTO = {
  firstName: '',
  lastName: '',
  customerNumber: '',
  searchError: undefined,
};

export interface SearchContactCriteria {
  searchContact: SearchContactDTO;
}
