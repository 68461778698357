import { Box, Icon, VStack } from '@chakra-ui/react';
import { Alert, Button, Input, Typography } from '@sgi/gravity';
import { fileUploadMessages } from 'constants/messageConstants';
import React, { useRef, useState } from 'react';
import { UploadFile } from 'Types/InspectionDTO';
import { faFileCirclePlusIcon } from '../Icons';
import { validateFileType } from './FileManager';
import './FileUpload.css';

const DEFAULT_MAX_FILE_SIZE_IN_MB = 10;
const MEGA_BYTES_PER_BYTE = 1048576;

interface FileUploadProps {
  onFileUploadChanged?: (isValid: boolean, files: UploadFile[]) => void;
  accept?: string;
  maxFileSizeInMB?: number;
  testId?: string;
}

export default function FileUpload({
  onFileUploadChanged,
  accept = '.pdf,.jpeg, .docx, .doc, .png, .jpg, .heic',
  maxFileSizeInMB = DEFAULT_MAX_FILE_SIZE_IN_MB,
  testId,
}: FileUploadProps) {
  const fileInputField = useRef<HTMLInputElement>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // drag state
  const [dragActive, setDragActive] = React.useState(false);

  const formatAcceptText = (value: string) => {
    return accept.replaceAll('.', '').replaceAll(',', ' /').toUpperCase();
  };

  const handleAlert = function () {
    setShowAlert(false);
  };
  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleUploadBtnClick = () => {
    fileInputField.current!.click();
  };

  const handleNewFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: newFiles } = e.currentTarget;
    handleFile(newFiles);
  };

  const handleFile = (newFiles: FileList | null) => {
    let tempUploadedFiles = [];

    if (newFiles != null && newFiles?.length) {
      let newFileAlert = false;

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i];

        if (file) {
          if (file.size > maxFileSizeInMB * MEGA_BYTES_PER_BYTE) {
            newFileAlert = true;
            setShowAlert(true);
            setErrorMessage(
              `${file.name} exceeds maximum size ${DEFAULT_MAX_FILE_SIZE_IN_MB}MB per document.`,
            );
            break;
          }

          if (!validateFileType(file.name, accept)) {
            newFileAlert = true;
            setShowAlert(true);
            setErrorMessage(
              `${
                file.name
              } document type is not supported. Please use ${formatAcceptText(
                accept,
              )} document type.`,
            );
            break;
          }

          const newFile: UploadFile = {
            tempId: tempUploadedFiles.length + 1,
            clientFileName: file.name,
            file: file,
            storageFileName: null,
          };

          tempUploadedFiles.push(newFile);
        }
      }

      onFileUploadChanged?.(!(newFileAlert || showAlert), tempUploadedFiles);
    }
  };

  return (
    <VStack w="100%">
      <Box
        className={
          dragActive ? 'drag-drop-zone inside-drag-area' : 'drag-drop-zone'
        }
        onDrop={handleDrop}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        bg="gray.50"
        w="100%"
        border={'2px dashed #D3D4D6'}
        borderRadius="8px"
        p="24px"
      >
        <VStack>
          <Icon fontSize={'32px'} aria-label={`file-upload}`}>
            {faFileCirclePlusIcon}
          </Icon>
          <Typography variant="bodySmall">
            {fileUploadMessages.dragAndDropFilesLabel}
          </Typography>
          <Typography variant="bodySmall">OR</Typography>
          <Button variant="tertiary" onClick={handleUploadBtnClick}>
            Browse
          </Button>
          <Typography variant="bodySmall">
            File Format: {formatAcceptText(accept)}
          </Typography>
          <Typography variant="bodySmall">
            Maximum Size: {maxFileSizeInMB}MB
          </Typography>
        </VStack>
        <Input
          name=""
          type="file"
          ref={fileInputField}
          id="FileUploadPicker"
          accept={accept}
          onChange={handleNewFileUpload}
          title=""
          value=""
          multiple
          data-testid={testId}
          className="uploadFileField"
        ></Input>
      </Box>
      {showAlert && (
        <Alert
          status="warning"
          showCloseButton={true}
          onClose={handleAlert}
          alertStyleProps={{ marginBottom: '4px' }}
        >
          {errorMessage}
        </Alert>
      )}
    </VStack>
  );
}
