interface Props {
  errors: any;
}

function ErrorList({ errors }: Props) {
  const errorList = errors.data.messages;
  return (
    <ul>
      {errorList && errorList.length > 0 ? (
        errorList.map((err: any) => {
          return (
            <li data-testid="errorList-error" key={`${err.message}`}>
              {err.message}
            </li>
          );
        })
      ) : (
        <li>API error occurred</li>
      )}
    </ul>
  );
}

export default ErrorList;
