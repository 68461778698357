import { MsalProvider } from '@azure/msal-react';
import { Divider, Grid, GridItem } from '@chakra-ui/react';
import { Footer, theme, ThemeProvider } from '@sgi/gravity';
import AppHeader from 'App/Common/Layout/AppHeader';
import AppSidebar from 'App/Common/Layout/AppSidebar';
import Routing from 'App/Common/Layout/Routing';
import { msalInstance } from 'auth/initializeMsal';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <Router>
          <ThemeProvider theme={theme}>
            <Grid
              templateAreas={`"header header" "nav main" "nav footer"`}
              gridTemplateRows={'64px 1fr 112px'}
              gridTemplateColumns={'96px 1fr'}
            >
              <GridItem area="header">
                <AppHeader />
              </GridItem>
              <GridItem bg="var(--sgi-colors-teal-500)" area="nav">
                <AppSidebar />
              </GridItem>
              <GridItem p="0em" bg="gray.50" area="main">
                <Divider color="blackAlpha.100" borderBottom="1px" />
                <Routing />
              </GridItem>
              <GridItem bg="gray.50" area="footer">
                <Footer />
              </GridItem>
            </Grid>
          </ThemeProvider>
        </Router>
      </MsalProvider>
    </div>
  );
}

export default App;
