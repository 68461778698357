export interface SearchStationDTO {  
  stationName: string;
  shopOwner: string;
  searchError: boolean | undefined;
}

export const defaultSearchStationDTO: SearchStationDTO = { 
  stationName: '',
  shopOwner: '', 
  searchError: undefined,
};

export interface SearchStationCriteria {
  searchStation: SearchStationDTO;
}
