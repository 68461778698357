import { Box, Grid, GridItem, HStack, VStack } from '@chakra-ui/react';
import { Button, Input, Typography, useToast } from '@sgi/gravity';
import {
  useGetPermissionsQuery,
  useSearchContactsMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import FormError from 'App/Common/FormElements/FormError';
import LoadingModal from 'App/Common/LoadingModal';
import { contactMessages } from 'constants/messageConstants';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  defaultSearchContactDTO,
  SearchContactCriteria,
  SearchContactDTO,
} from 'Types/SearchContactDTO';
import {
  getLocalStorageItem,
  LocalStorageItemEnum,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'Utils/localStorageUtils';
import * as Yup from 'yup';
import ContactSummaryTable from './Components/ContactSummaryTable';

export const ContactSummaryPage = () => {
  const navigate = useNavigate();

  const { data: permissions, isLoading: isGetPermissionsLoading } =
    useGetPermissionsQuery();

  const [initSearchContactCriteria, setSearchContactCriteria] = useState(
    defaultSearchContactDTO,
  );
  const [isSearchReset, setIsSearchReset] = useState(true);
  const { showErrorToast } = useToast();
  const [
    searchContacts,
    {
      data: contacts,
      isError: isContactListError,
      error: contactListError,
      isLoading: isContactListLoading,
      isSuccess: contactListSuccess,
    },
  ] = useSearchContactsMutation();

  //Create Formik Component for Search Contacts
  const formikSearch = useFormik({
    enableReinitialize: true,
    initialValues: { ...initSearchContactCriteria },
    validationSchema: Yup.object().shape({
      firstName: Yup.string(),
      lastName: Yup.string(),
      customerNumber: Yup.string(),
      searchError: Yup.bool().when(
        ['firstName', 'lastName', 'customerNumber'],
        {
          is: (firstName: string, lastName: string, customerNumber: string) =>
            !firstName && !lastName && !customerNumber,
          then: Yup.bool().required(
            'At least one criteria is required to perform a search.',
          ),
          otherwise: undefined,
        },
      ),
    }),
    onSubmit: (values: SearchContactDTO) => {
      if (permissions?.canSearchContacts) {
        setIsSearchReset(false);

        //Updating local storage variable with search criteria
        setSearchCriteriaSession();

        //Invoke search Contct API
        searchContacts({
          firstName: values?.firstName ?? '',
          lastName: values?.lastName ?? '',
          customerNumber: values?.customerNumber ?? '',
          searchError: values?.searchError ?? false,
        });
      }
    },
  });

  //Checks for the local storage, then sets the value for the Search and Filter form fields if localstoage exist else display form as default.
  useEffect(() => {
    let sessionContactSearch = getLocalStorageItem<SearchContactCriteria>(
      LocalStorageItemEnum.ContactSearch,
    );

    if (
      sessionContactSearch == null ||
      sessionContactSearch.searchContact === null
    ) {
      sessionContactSearch = null;
      removeLocalStorageItem(LocalStorageItemEnum.ContactSearch);
    } else {
      if (permissions?.canSearchContacts) {
        //Update session storage
        setLocalStorageItem(
          LocalStorageItemEnum.ContactSearch,
          sessionContactSearch,
        );

        if (sessionContactSearch.searchContact !== null) {
          //Set fields value for search Contact form
          setSearchContactCriteria({
            ...sessionContactSearch.searchContact,
            searchError: undefined,
          });
        }

        if (!isEqual(defaultSearchContactDTO, formikSearch.values)) {
          setIsSearchReset(false);

          //Invoke search Contact API
          searchContacts({
            firstName: formikSearch.initialValues?.firstName ?? '',
            lastName: formikSearch.initialValues?.lastName ?? '',
            customerNumber: formikSearch.initialValues?.customerNumber ?? '',
            searchError: formikSearch.initialValues?.searchError ?? false,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.canSearchContacts, formikSearch.initialValues]);

  useEffect(() => {
    if (isContactListError) {
      showErrorToast({ description: <ErrorList errors={contactListError} /> });
    }
  }, [isContactListError, contactListError, showErrorToast]);

  useEffect(() => {
    if (isEqual(defaultSearchContactDTO, formikSearch.values)) {
      clearSearchResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikSearch.values]);

  //Sets selected/Provided Search crietria into local storage
  function setSearchCriteriaSession() {
    let searchContactCriteria = {
      searchContact: formikSearch.values,
    };
    setLocalStorageItem(
      LocalStorageItemEnum.ContactSearch,
      searchContactCriteria,
    );
  }

  //Clears the search results and form on clear button click
  const clearSearchForm = () => {
    formikSearch.resetForm();
    clearSearchResult();
    setSearchContactCriteria({
      ...defaultSearchContactDTO,
      searchError: undefined,
    });
    setLocalStorageItem(LocalStorageItemEnum.ContactSearch, null);
  };

  //Clears the search results
  function clearSearchResult() {
    setIsSearchReset(true);
  }

  return (
    <>
      <LoadingModal show={isContactListLoading || isGetPermissionsLoading} />
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Contacts</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" p={4} borderRadius={8}>
          <VStack w="100%">
            <Box w="100%">
              <form onSubmit={formikSearch.handleSubmit}>
                <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                  {permissions?.canSearchContacts ? (
                    <>
                      <GridItem colSpan={1}>
                        <Input
                          id="firstName"
                          name="firstName"
                          data-testid="contact-search-firstname"
                          type="text"
                          placeholder="First Name"
                          onChange={formikSearch.handleChange}
                          onBlur={formikSearch.handleBlur}
                          value={formikSearch.values.firstName}
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Input
                          id="lastName"
                          name="lastName"
                          data-testid="contact-search-lastname"
                          type="text"
                          placeholder="Last Name"
                          onChange={formikSearch.handleChange}
                          onBlur={formikSearch.handleBlur}
                          value={formikSearch.values.lastName}
                        />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <Input
                          id="customerNumber"
                          name="customerNumber"
                          data-testid="contact-search-customernumber"
                          type="text"
                          placeholder="Customer Number"
                          onChange={formikSearch.handleChange}
                          onBlur={formikSearch.handleBlur}
                          value={formikSearch.values.customerNumber}
                        />
                      </GridItem>
                      <GridItem colSpan={2}>
                        <HStack justify="flex-start">
                          <Button
                            type="submit"
                            data-testid="contact-search-submit"
                          >
                            Search
                          </Button>
                          <Button
                            type="reset"
                            variant="secondary"
                            data-testid="contact-search-clear"
                            onClick={clearSearchForm}
                          >
                            Clear
                          </Button>
                        </HStack>
                      </GridItem>
                      <GridItem rowStart={2} colSpan={2}>
                        <FormError
                          testId="contact-search-searchError-error"
                          touched={formikSearch.touched?.searchError}
                          error={formikSearch.errors?.searchError}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <>
                      {!isGetPermissionsLoading ? (
                        <Typography variant="h2">
                          {contactMessages.searchPermissionError}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                  <GridItem colSpan={1}>
                    <HStack w="100%" justifyContent="flex-end">
                      {permissions?.canCreateContact && (
                        <Button
                          onClick={() => {
                            navigate('/contact/create');
                          }}
                        >
                          + Create Contact
                        </Button>
                      )}
                    </HStack>
                  </GridItem>
                </Grid>
              </form>
            </Box>
            {!isSearchReset &&
            formikSearch.isValid &&
            contacts &&
            contacts?.length > 0 ? (
              <Box w="100%">
                <ContactSummaryTable contacts={contacts} />
              </Box>
            ) : (
              <>
                {contactListSuccess &&
                  !isContactListLoading &&
                  !isSearchReset &&
                  formikSearch.isValid && (
                    <p data-testid="contacts-list">
                      {contactMessages.noResultsMessage}
                    </p>
                  )}
              </>
            )}
          </VStack>
        </Box>
      </Box>
    </>
  );
};

export default ContactSummaryPage;
