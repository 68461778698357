export interface WheelInfoDTO {
  axles: AxleDTO[];
}

export interface AxleDTO {
  id: number; // e.g. Axle 1 or Axle 2
  wheels: WheelDTO[];
  conditionAndExternalMeasurements: boolean | undefined;
  wasOEMPreviouslyUnchecked: boolean;
  brakeType: BrakeTypes;
}

export interface WheelDTO {
  id: number; // e.g. Wheel 1 or Wheel 2, etc.
  nonBrake: boolean;
  tireTreadDepth: number | null;
  passesTreadDepth: boolean | undefined;
  brakeMeasurements: BrakeMeasurementDTO[];
  passesBrakeMeasurements: boolean | undefined;
  brakeMeasurementUnit: BrakeMeasurementUnit | null;
  wheelOffInspection: boolean;
  hasAdditionalBrake: boolean;
  additionalBrakeMeasurements: BrakeMeasurementDTO[];
}

export enum AxleStatus {
  Pass = 'Pass',
  Fail = 'Fail',
  Pending = 'Pending',
}

export interface BrakeMeasurementDTO {
  name: string;
  measurement: number | null;
}

export enum BrakeMeasurementUnit {
  mm = 0,
  inches = 1,
}

export const BrakeMeasurementUnitLabel: {
  [key in BrakeMeasurementUnit]: string;
} = {
  [BrakeMeasurementUnit.mm]: 'mm',
  [BrakeMeasurementUnit.inches]: 'in',
};

export enum SCAMType {
  Ext15Inch = 0,
  Std15Inch = 1,
}

export enum BrakeTypes {
  None = 0,
  HydraulicDisc = 1,
  HydraulicDrum = 2,
  Unknown = 99,
}

//Make sure key and value align with BrakeTypes Enum
export enum HydraulicBrakeTypes {
  HydraulicDisc = 1,
  HydraulicDrum = 2,
}

export const HydraulicBrakeTypeLabel: {
  [key in HydraulicBrakeTypes]: string;
} = {
  [HydraulicBrakeTypes.HydraulicDisc]: 'Disc',
  [HydraulicBrakeTypes.HydraulicDrum]: 'Drum ',
};

export const HydraulicDiscBrakeMeasurementLabel = {
  BRAKE_PAD_LINING_THICKNESS: 'Brake Pad Lining Thickness',
  BRAKE_ROTOR: 'Brake Rotor',
};

export const HydraulicDrumBrakeMeasurementLabel = {
  BRAKE_SHOE_LINING_THICKNESS: 'Brake Shoe Lining Thickness',
  DRUM_BRAKE: 'Drum Brake',
};

export interface AxleWheelLimit {
  minAxle: number;
  maxAxle: number;
  minWheel: number;
  maxWheel: number;
}
