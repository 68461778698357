import { Box, Grid, GridItem, HStack, Icon, VStack } from '@chakra-ui/react';
import { Button, Input, useToast } from '@sgi/gravity';
import { useGetStationEligibleQuery } from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import { faArrowLeftIcon, faArrowRightIcon } from 'App/Common/Icons';
import LoadingModal from 'App/Common/LoadingModal';
import { validationMessages } from 'constants/messageConstants';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CreateInspectionDTO } from 'Types/CreateInspectionDTO';
import * as Yup from 'yup';
import { StationInfoViewModel } from './StationInfoViewModel';

interface AdditionalVehicleInfoProps {
  step: number;
  setStep: Function;
  inspection: CreateInspectionDTO;
  setInspection: React.Dispatch<React.SetStateAction<CreateInspectionDTO>>;
}

const StationInfo = ({
  step,
  setStep,
  inspection,
  setInspection,
}: AdditionalVehicleInfoProps) => {
  const [skip, setSkip] = useState(true);
  const { showToast } = useToast();
  const [queryStation, setQueryStation] = useState('');
  const formik = useFormik({
    initialValues: { station: inspection.station },
    validationSchema: Yup.object({
      station: Yup.string().required(validationMessages.stationName.required),
    }),
    onSubmit: (values: StationInfoViewModel) => {
      if (values.station) {
        setSkip(false);
        setQueryStation(values.station);
      } else {
        setQueryStation('');
        setSkip(true);
      }
    },
  });

  const {
    data: stationEligible,
    isError: isGetStationEligibleResultsError,
    error: stationEligibleError,
    isFetching: isGetStationEligibleFetching,
  } = useGetStationEligibleQuery(queryStation, { skip });

  const onStationInputChange = (value: string) => {
    setInspection((prev) => {
      return { ...prev, station: value };
    });
  };

  useEffect(() => {
    if (isGetStationEligibleResultsError) {
      showToast({
        description: <ErrorList errors={stationEligibleError} />,
        status: 'error',
      });
    }
  }, [isGetStationEligibleResultsError, stationEligibleError, showToast]);

  useEffect(() => {
    if (queryStation) {
      if (stationEligible === false) {
        showToast({
          description: `${queryStation} ${validationMessages.stationName.nonEligible}`,
          status: 'error',
        });
      } else {
        setStep(step + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationEligible]);

  return (
    <>
      <LoadingModal show={isGetStationEligibleFetching} />
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid
            templateColumns="repeat(4, 1fr)"
            templateRows="repeat(1, 1fr)"
            columnGap={4}
          >
            <GridItem colSpan={1} rowSpan={1}>
              <Input
                name="station"
                data-testid="station-Info-station"
                isRequired={false}
                hideOptionalLabel={true}
                placeholder="Station Name"
                size="md"
                label="Station Name"
                error={
                  formik.touched.station || formik.errors.station
                    ? formik.errors.station
                    : undefined
                }
                onChange={(e) => {
                  formik.setFieldValue('station', e.target.value);
                  onStationInputChange(e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.station}
              />
            </GridItem>
          </Grid>
          <VStack align="flex-start" pt={4}>
            <HStack w="100%" justifyContent="space-between">
              <Button
                variant="secondary"
                onClick={() => setStep(step - 1)}
                leftIcon={<Icon>{faArrowLeftIcon}</Icon>}
              >
                Previous step
              </Button>
              <Button
                type="submit"
                data-testid="StationInfo-Continue"
                rightIcon={<Icon fill="currentColor">{faArrowRightIcon}</Icon>}
              >
                Continue to the next step
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
    </>
  );
};

export default StationInfo;
