import { Box, Grid, GridItem, HStack } from '@chakra-ui/react';
import { Button, Input, IOption, Select, Typography } from '@sgi/gravity';
import {
  useGetPermissionsQuery,
  useSearchInspectionsMutation,
} from 'api/InspectTechUIAPI';
import ErrorList from 'App/Common/ErrorList';
import LoadingModal from 'App/Common/LoadingModal';
import { inspectionMessages } from 'constants/messageConstants';
import { useFormik } from 'formik';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  InspectionApprovalStatus,
  InspectionListDTO,
  InspectionStatus,
} from 'Types/InspectionDTO';
import { InspectionType, InspectionTypeLabel } from 'Types/InspectionEnums';
import {
  defaultSearchInspectionDTO,
  SearchInspectionCriteria,
  SearchInspectionDTO,
} from 'Types/SearchInspectionDTO';
import { InputMapper } from 'Utils/InputMapper';
import {
  getLocalStorageItem,
  LocalStorageItemEnum,
  removeLocalStorageItem,
  setLocalStorageItem,
} from 'Utils/localStorageUtils';
import * as Yup from 'yup';
import InspectionSummaryTable from './components/InspectionSummaryTable';
import { FilterCategory } from './components/InspectionTable/InspectionTableFilter';

const defaultFilters: FilterCategory[] = [
  {
    label: 'Inspection Status',
    field: 'status',
    items: [
      {
        label: 'In Progress',
        value: InspectionStatus.InProgress,
        isEnabled: true,
      },
      {
        label: 'Pass',
        value: InspectionStatus.Pass,
        isEnabled: true,
      },
      {
        label: 'Fail',
        value: InspectionStatus.Fail,
        isEnabled: true,
      },
    ],
  },
  {
    label: 'Approval Status',
    field: 'approvalStatus',
    items: [
      {
        label: 'In Progress',
        value: InspectionApprovalStatus.InProgress,
        isEnabled: true,
      },
      {
        label: 'Awaiting Approval',
        value: InspectionApprovalStatus.WaitingForApproval,
        isEnabled: true,
      },
      {
        label: 'Approved',
        value: InspectionApprovalStatus.Approved,
        isEnabled: true,
      },
    ],
  },
];

export const InspectionSummaryPage = () => {
  const navigate = useNavigate();
  const [isSearchReset, setIsSearchReset] = useState(true);
  const [initSearchCriteria, setSearchCriteria] = useState(
    defaultSearchInspectionDTO,
  );

  const [filteredInspections, setFilteredInspections] = useState<
    InspectionListDTO[]
  >([]);
  const [inspectionsList, setInspectionsList] = useState<InspectionListDTO[]>(
    [],
  );
  const [filters, setFilters] = useState<FilterCategory[]>([...defaultFilters]);

  const { data: permissions, isLoading: isGetPermissionsLoading } =
    useGetPermissionsQuery();

  const [
    searchInspections,
    {
      data: inspections,
      isError: isInspectionListError,
      error: inspectionListError,
      isLoading: isInspectionListLoading,
      isSuccess: inspectionListSuccess,
    },
  ] = useSearchInspectionsMutation();

  //Create Formik Component for Search Inspections
  const formikSearch = useFormik({
    enableReinitialize: true,
    initialValues: { ...initSearchCriteria },
    validationSchema: Yup.object().shape({
      searchString: Yup.string(),
      type: Yup.string(),
      searchError: Yup.bool().when(['searchString', 'type'], {
        is: (searchString: string, type: string) => !searchString && !type,
        then: Yup.bool().required(
          'At least one criteria is required to perform a search.',
        ),
        otherwise: undefined,
      }),
    }),
    onSubmit: (values: SearchInspectionDTO) => {
      if (permissions?.canSearchInspections) {
        //Reset Inspection list return from API
        setInspectionsList([]);

        //Reset Filtered Inspection list which was displayed on screen
        setFilteredInspections([]);
        setIsSearchReset(false);

        //Updating local storage variable with search criteria
        setSearchCriteriaSession();

        //Invoke search Inspection API
        searchInspections({
          searchString: values?.searchString ?? '',
          type: values?.type ?? '',
          searchError: values?.searchError ?? false,
        });
      }
    },
  });

  //Checks for the local storage, then sets the value for the Search and Filter form fields if localstoage exist else display form as default.
  useEffect(() => {
    let sessionInspectionSearch = getLocalStorageItem<SearchInspectionCriteria>(
      LocalStorageItemEnum.InspectionSearch,
    );

    if (
      sessionInspectionSearch == null ||
      sessionInspectionSearch.searchInspection === null
    ) {
      sessionInspectionSearch = null;
      removeLocalStorageItem(LocalStorageItemEnum.InspectionSearch);
    } else {
      if (permissions?.canSearchInspections) {
        //Update session storage
        setLocalStorageItem(
          LocalStorageItemEnum.InspectionSearch,
          sessionInspectionSearch,
        );

        if (sessionInspectionSearch.searchInspection !== null) {
          //Set fields value for search inspection form
          setSearchCriteria({
            ...sessionInspectionSearch.searchInspection,
            searchError: undefined,
          });
        }

        if (!isEqual(defaultSearchInspectionDTO, formikSearch.values)) {
          //Reset Inspection list return from API
          setInspectionsList([]);

          //Reset Filtered Inspection list which was displayed on screen
          setFilteredInspections([]);
          setIsSearchReset(false);

          //Invoke search Inspection API
          searchInspections({
            searchString: formikSearch.initialValues?.searchString ?? '',
            type: formikSearch.initialValues?.type ?? '',
            searchError: formikSearch.initialValues?.searchError ?? false,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions?.canSearchInspections, formikSearch.initialValues]);

  function setSearchCriteriaSession() {
    let searchInspectionCriteria = {
      searchInspection: formikSearch.values,
    };
    setLocalStorageItem(
      LocalStorageItemEnum.InspectionSearch,
      searchInspectionCriteria,
    );
  }

  useEffect(() => {
    if (isInspectionListError) {
      toast.error(<ErrorList errors={inspectionListError} />);
    }
  }, [isInspectionListError, inspectionListError]);

  useEffect(() => {
    if (inspectionListSuccess && inspections && inspections.length > 0) {
      let tempList: InspectionListDTO[] = [];
      inspections?.forEach((i) => tempList.push(i));
      setInspectionsList(tempList);
      setFilteredInspections(tempList);

      if (tempList && tempList?.length > 0) {
        filterInspections(tempList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionListSuccess]);

  //Filters searched results based on criteria provided/selected
  const filterInspections = useCallback(
    (inspections: InspectionListDTO[]) => {
      let tempList: InspectionListDTO[] = [];
      inspections?.forEach((i) => tempList.push(i));

      const statuses = filters[0].items
        .filter((i) => i.isEnabled)
        .map((item) => {
          return Number(item.value);
        });

      const approvalStatuses = filters[1].items
        .filter((i) => i.isEnabled)
        .map((item) => {
          return Number(item.value);
        });

      tempList = tempList.filter((i) => statuses.includes(i.status));
      tempList = tempList.filter((i) =>
        approvalStatuses.includes(i.approvalStatus),
      );

      setFilteredInspections(tempList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters],
  );

  useEffect(() => {
    if (inspectionsList && inspectionsList?.length > 0) {
      filterInspections(inspectionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (isEqual(defaultSearchInspectionDTO, formikSearch.values)) {
      clearSearchResult();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikSearch.values]);

  //Clears the search results and form on clear button click
  const clearSearchForm = () => {
    formikSearch.resetForm();
    clearSearchResult();
    setSearchCriteria({
      ...defaultSearchInspectionDTO,
      searchError: undefined,
    });
    setLocalStorageItem(LocalStorageItemEnum.InspectionSearch, null);
    clearFilters();
  };

  //Clears the search results
  function clearSearchResult() {
    setInspectionsList([]);
    setFilteredInspections([]);
    setIsSearchReset(true);
  }

  const clearFilters = () => {
    setFilters([...defaultFilters]);
  };

  return (
    <>
      <LoadingModal show={isInspectionListLoading || isGetPermissionsLoading} />
      <Box bg="#FFFFFF" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={1}>
            <Typography variant="h1">Inspections</Typography>
          </GridItem>
        </Grid>
      </Box>

      <Box paddingLeft="40px !important" pr="40px !important" w="100%" p={4}>
        <Box bg="#FFFFFF" w="100%" p={4} borderRadius={8}>
          <form onSubmit={formikSearch.handleSubmit}>
            <Grid templateColumns="repeat(6,1fr)" gap={6} paddingBottom="15px">
              {permissions?.canSearchInspections ? (
                <>
                  <GridItem colSpan={2}>
                    <Input
                      id="searchString"
                      name="searchString"
                      data-testid="inspection-search-searchString"
                      type="text"
                      placeholder="Search by VIN, Inspection ID, or Station"
                      onChange={formikSearch.handleChange}
                      onBlur={formikSearch.handleBlur}
                      value={formikSearch.values.searchString}
                      error={
                        formikSearch.touched.searchError
                          ? formikSearch.errors.searchError
                          : undefined
                      }
                    />
                  </GridItem>
                  <GridItem colSpan={1}>
                    <Select
                      isRequired={false}
                      hideOptionalLabel={true}
                      name="type"
                      onChange={formikSearch.handleChange}
                      value={formikSearch.values.type?.toString()}
                      data-testid="inspection-search-type"
                      options={[
                        {
                          label: 'Select an Inspection Type',
                          value: '',
                        } as unknown as IOption<string>,
                        ...InputMapper.bindLabelValueWithLabelEnum(
                          InspectionType,
                          InspectionTypeLabel,
                          true,
                        ),
                      ]}
                      size="md"
                    ></Select>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <HStack justify="flex-start">
                      <Button
                        type="submit"
                        data-testid="inspection-search-submit"
                        variant="primary"
                      >
                        Search
                      </Button>
                      <Button
                        type="reset"
                        data-testid="inspection-search-clear"
                        variant="secondary"
                        onClick={clearSearchForm}
                      >
                        Clear
                      </Button>
                    </HStack>
                  </GridItem>
                </>
              ) : (
                <>
                  {!isGetPermissionsLoading ? (
                    <Typography variant="h2">
                      {inspectionMessages.searchPermissionError}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <GridItem colSpan={1}>
                <HStack justify="flex-end">
                  {permissions?.canCreateInspection && (
                    <Button
                      onClick={() => {
                        navigate('/inspection/create');
                      }}
                    >
                      + Create Inspection
                    </Button>
                  )}
                </HStack>
              </GridItem>
            </Grid>
          </form>
          {!isSearchReset &&
          formikSearch.isValid &&
          inspectionsList?.length > 0 ? (
            <InspectionSummaryTable
              filters={filters}
              setFilters={setFilters}
              inspections={filteredInspections}
              permission={permissions?.canSearchInspections}
            />
          ) : (
            <>
              {inspectionListSuccess &&
                !isInspectionListLoading &&
                !isSearchReset && (
                  <p data-testid="inspections-list">No Inspections Found</p>
                )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InspectionSummaryPage;
