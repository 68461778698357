import { Box, Grid, GridItem } from '@chakra-ui/react';
import { Typography } from '@sgi/gravity';
import CreateStationForm from './CreateStationForm';

export const CreateStation = () => {
  return (
    <>
      <Box bg="white" w="100%" p={9}>
        <Grid templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={2}>
            <Typography variant="h1">Create Inspection Station</Typography>
          </GridItem>
        </Grid>
      </Box>
      <Box pl="40px" pr="40px" pt={4}>
        <Box bg="white" w="100%" borderRadius={8}>
          <CreateStationForm />
        </Box>
      </Box>
    </>
  );
};

export default CreateStation;
