import { Box, Center, HStack, VStack } from '@chakra-ui/react';
import {
  Alert,
  Card,
  ErrorAlert,
  Radio,
  RadioGroup,
  TextArea,
} from '@sgi/gravity';
import { QuestionCodes, QuestionYesNoList } from 'constants/variableConstants';
import { getIn } from 'formik';
import React, { useState } from 'react';
import {
  Question,
  QuestionAttachmentDto,
  QuestionType,
} from 'Types/InspectionDTO';
import UploadDocument from './UploadDocument';
import UploadStagingDocument from './UploadStagingDocument';

interface InspectionQuestionsProps {
  inspectionQuestions?: Question[];
  isHidden?: boolean;
  previousInspectionId?: string;
  namePrefix: string;
  setFieldValue: (field: string, value: any) => void;
  onChange: (event: React.ChangeEvent<any>) => void;
  errors: any;
  touched: any;
  inspectionId?: string;
  isDisabled?: boolean;
}

export const InspectionQuestions = ({
  inspectionQuestions,
  isHidden,
  previousInspectionId,
  setFieldValue,
  namePrefix,
  onChange,
  errors,
  touched,
  isDisabled = false,
  inspectionId,
}: InspectionQuestionsProps) => {
  const [repairedPriorToInspection, setRepairedPriorToInspection] = useState<
    boolean | undefined
  >(
    inspectionQuestions?.find(
      (q) =>
        q.code === QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
    )?.response,
  );

  const onDocumentChange = (
    fileNames: QuestionAttachmentDto[] | undefined,
    questionCode: string,
  ) => {
    if (inspectionQuestions) {
      const index = inspectionQuestions.findIndex(
        (x) => x.code === questionCode,
      );
      setFieldValue(
        `${namePrefix}[${index}].attachments`,
        fileNames && fileNames.length > 0 ? fileNames : null,
      );
    }
  };

  const onResponseChange = async (value: boolean, index: number) => {
    await setFieldValue(`${namePrefix}[${index}].response`, value);
    if (value) {
      await setFieldValue(`${namePrefix}[${index}].reason`, null);
    } else {
      await setFieldValue(`${namePrefix}[${index}].attachments`, null);
    }
    await updateQuestionVisibility(value, index);
  };

  const updateQuestionVisibility = async (value: boolean, index: number) => {
    let ctPre004Index = inspectionQuestions?.findIndex(
      (q) => q.code === QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired,
    );

    if (index === ctPre004Index) {
      let ctPre005Index = inspectionQuestions?.findIndex(
        (q) => q.code === QuestionCodes.ctPreQuestionFullDamageAnalysis,
      );

      if (value) {
        setFieldValue(`${namePrefix}[${ctPre005Index}].isHidden`, false);
      } else {
        setFieldValue(`${namePrefix}[${ctPre005Index}].isHidden`, true);
      }
    }

    let ii003Index = inspectionQuestions?.findIndex(
      (q) =>
        q.code === QuestionCodes.iiQuestionRepairedPriorToInitialInspection,
    );

    if (index === ii003Index) {
      let ii004Index = inspectionQuestions?.findIndex(
        (q) => q.code === QuestionCodes.iiQuestionEvidenceOfRepairs,
      );

      if (value && !isHidden) {
        setFieldValue(`${namePrefix}[${ii004Index}].isHidden`, false);
        setRepairedPriorToInspection(true);
      } else {
        setFieldValue(`${namePrefix}[${ii004Index}].isHidden`, true);
        setRepairedPriorToInspection(false);
      }
    }
  };

  const setVisibility = (
    question: Question,
    previousInspectionId?: string,
  ): boolean => {
    if (
      question.code !==
        QuestionCodes.iiQuestionRepairedPriorToInitialInspection &&
      question.code !== QuestionCodes.iiQuestionEvidenceOfRepairs
    ) {
      if (
        question.isHidden === undefined ||
        question.isHidden === null ||
        (question.isHidden === isHidden && previousInspectionId !== undefined)
      )
        return true;
      else return false;
    } else if (
      question.code ===
        QuestionCodes.iiQuestionRepairedPriorToInitialInspection &&
      previousInspectionId == null
    )
      return true;
    else if (question.code === QuestionCodes.iiQuestionEvidenceOfRepairs) {
      if (
        question.isHidden == null ||
        (question.isHidden === isHidden &&
          previousInspectionId == null &&
          repairedPriorToInspection)
      ) {
        return true;
      } else return false;
    } else return false;
  };

  return (
    <>
      {inspectionQuestions
        ?.filter((q) => q.isHidden !== true)
        .map((question: Question, index: number) => {
          return question.code !==
            QuestionCodes.ctPreQuestionLocationAndDamages &&
            setVisibility(question, previousInspectionId) ? (
            <Card
              cardStyleProps={{
                width: '100%',
              }}
              key={`question-${question.code}`}
              id={`question-${index}`}
              data-testid={question.code}
            >
              <>
                {question.code ===
                  QuestionCodes.ctPreQuestionRequiredNumberOfPhotos && (
                  <>
                    <Alert
                      status="info"
                      title="Photos to upload"
                      showCloseButton={false}
                      alertStyleProps={{ marginBottom: '16px' }}
                    >
                      7 or more coloured photos consisting of the rear, front
                      and each side, area of the damage, the public VIN and the
                      compliance label.
                    </Alert>
                  </>
                )}

                <VStack
                  w="100%"
                  alignItems="start"
                  gap="8px"
                  padding="16px, 60px, 16px, 52px"
                >
                  {setVisibility(question, previousInspectionId) && (
                    <RadioGroup
                      isRequired={false}
                      hideOptionalLabel={true}
                      label={question.text}
                      value={
                        question.response === undefined
                          ? undefined
                          : question.response
                      }
                      error={
                        getIn(touched, `[${index}].response`) &&
                        getIn(errors, `[${index}].response`)
                          ? getIn(errors, `[${index}].response`)
                          : undefined
                      }
                      data-testid={`${namePrefix}[${index}].response`}
                      onChange={(e) => {
                        onResponseChange(Boolean(e), index);
                      }}
                      variant="button"
                      name={`${namePrefix}[${index}].response`}
                    >
                      <HStack>
                        {QuestionYesNoList.length > 0 &&
                          QuestionYesNoList.map((item: any, i: number) => {
                            return (
                              <Radio
                                key={`radio-questions[${index}].response${i}`}
                                value={item.value.toString()}
                                aria-label={`radio-questions[${index}].response-${item.value}`}
                                isDisabled={isDisabled}
                              >
                                <Center>
                                  <HStack>
                                    <Box>{item.text}</Box>
                                  </HStack>
                                </Center>
                              </Radio>
                            );
                          })}
                      </HStack>
                    </RadioGroup>
                  )}

                  {question.code ===
                  QuestionCodes.ctPreQuestionRequiredNumberOfPhotos
                    ? setVisibility(question, previousInspectionId) &&
                      question.response === false &&
                      question.code !==
                        QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired &&
                      question.code !==
                        QuestionCodes.iiQuestionRepairedPriorToInitialInspection &&
                      question.type !== QuestionType.Contact &&
                      inspectionId !== null &&
                      inspectionId !== undefined && (
                        <>
                          <>
                            <UploadDocument
                              question={question}
                              onDocumentChange={onDocumentChange}
                              inspectionId={inspectionId}
                              isEditMode={false}
                            ></UploadDocument>
                          </>

                          <TextArea
                            value={question.reason ? question.reason : ''}
                            onChange={(e) =>
                              setFieldValue(
                                `${namePrefix}[${index}].reason`,
                                e.target.value,
                              )
                            }
                            isRequired={false}
                            hideOptionalLabel={true}
                            data-testid={`preQuestion-reason-${question.code}`}
                            label="Please enter the reason why required number of photos are not provided"
                            error={
                              getIn(touched, `[${index}].reason`) &&
                              getIn(errors, `[${index}].reason`)
                                ? getIn(errors, `[${index}].reason`)
                                : undefined
                            }
                            name={`${namePrefix}[${index}].reason`}
                            isDisabled={isDisabled}
                          ></TextArea>
                        </>
                      )
                    : setVisibility(question, previousInspectionId) &&
                      question.response === false &&
                      question.code !==
                        QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired &&
                      question.code !==
                        QuestionCodes.iiQuestionRepairedPriorToInitialInspection && (
                        <>
                          <TextArea
                            value={question.reason ? question.reason : ''}
                            onChange={(e) =>
                              setFieldValue(
                                `${namePrefix}[${index}].reason`,
                                e.target.value,
                              )
                            }
                            isRequired={false}
                            hideOptionalLabel={true}
                            data-testid={`preQuestion-reason-${question.code}`}
                            label="Comment"
                            error={
                              getIn(touched, `[${index}].reason`) &&
                              getIn(errors, `[${index}].reason`)
                                ? getIn(errors, `[${index}].reason`)
                                : undefined
                            }
                            name={`${namePrefix}[${index}].reason`}
                            isDisabled={isDisabled}
                          ></TextArea>
                        </>
                      )}

                  {setVisibility(question, previousInspectionId) &&
                    question.response === true && (
                      <>
                        {question.type === QuestionType.Contact &&
                          inspectionId == null && (
                            <>
                              <UploadStagingDocument
                                question={question}
                                onDocumentChange={onDocumentChange}
                              ></UploadStagingDocument>
                            </>
                          )}
                        {question.type !== QuestionType.Contact &&
                          inspectionId !== null &&
                          inspectionId !== undefined &&
                          question.code !==
                            QuestionCodes.ctPreQuestionVehiclePreviouslyRepaired &&
                          question.code !==
                            QuestionCodes.ctPreQuestionFullDamageAnalysis &&
                          question.code !==
                            QuestionCodes.iiQuestionRepairedPriorToInitialInspection && (
                            <>
                              <UploadDocument
                                question={question}
                                onDocumentChange={onDocumentChange}
                                inspectionId={inspectionId}
                                isEditMode={false}
                              ></UploadDocument>
                            </>
                          )}
                        {question.code ===
                          QuestionCodes.ctPreQuestionRequiredNumberOfPhotos &&
                          !question.attachments &&
                          question.response && (
                            <>
                              <Alert status="warning" showCloseButton={false}>
                                You need to upload at least 1 photograph to
                                proceed
                              </Alert>
                            </>
                          )}
                        {getIn(touched, `[${index}].attachments`) &&
                          getIn(errors, `[${index}].attachments`) && (
                            <ErrorAlert showCloseButton={false}>
                              {getIn(errors, `[${index}].attachments`)}
                            </ErrorAlert>
                          )}
                      </>
                    )}
                </VStack>
              </>
            </Card>
          ) : (
            <React.Fragment key={`question-${question.code}`}></React.Fragment>
          );
        })}
    </>
  );
};

export default InspectionQuestions;
