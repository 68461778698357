import { Icon } from '@chakra-ui/react';
import {
  faCheckIcon,
  faExclamation,
  faPending,
  faQuestionIcon,
  faWrench,
  faXMark,
} from 'App/Common/Icons';
import { InspectionItemStatus } from 'Types/InspectionDTO';

interface InspectionItemBadgeProps {
  status: number | undefined;
  overrideRepairedImmediately?: boolean;
}

const InspectionItemBadge = ({
  status,
  overrideRepairedImmediately: override = false,
}: InspectionItemBadgeProps) => {
  switch (status) {
    case InspectionItemStatus.Pass:
      return (
        <Icon fill="green.500" aria-label="inspection-item-pass-badge">
          {faCheckIcon}
        </Icon>
      );

    case InspectionItemStatus.RepairedNow:
      if (override) {
        return (
          <Icon
            fill="green.500"
            aria-label="inspection-item-rejected-immediately-repaired-badge"
          >
            {faCheckIcon}
          </Icon>
        );
      } else {
        return (
          <Icon
            fill="#B141D8"
            aria-label="inspection-item-repaired-immediately-badge"
          >
            {faWrench}
          </Icon>
        );
      }

    case InspectionItemStatus.Rejected:
      return (
        <Icon fill="red.500" aria-label="inspection-item-rejected-badge">
          {faXMark}
        </Icon>
      );

    case InspectionItemStatus.Attention:
      return (
        <Icon
          fill="orange.400"
          aria-label="inspection-item-requires-attention-badge"
        >
          {faExclamation}
        </Icon>
      );

    case InspectionItemStatus.Default:
      return (
        <Icon fill="gray.500" aria-label="inspection-item-pending-badge">
          {faPending}
        </Icon>
      );

    default:
      return (
        <Icon fill="gray.500" aria-label="inspection-item-unknown-badge">
          {faQuestionIcon}
        </Icon>
      );
  }
};

export default InspectionItemBadge;
