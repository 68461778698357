import { createApi } from '@reduxjs/toolkit/query/react';
import { AttachmentDTO } from 'Types/AttachmentDTO';
import { QuestionAttachmentDto } from 'Types/InspectionDTO';
import { axiosBaseQuery } from './axiosBaseQuery';
import { RtkTags } from './RtkTags';

export const inspectTechUIFileUploadAPI = createApi({
  reducerPath: 'inspectTechUIFileUpload',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(RtkTags),
  endpoints: (builder) => ({
    uploadAttachments: builder.mutation<QuestionAttachmentDto[], FormData>({
      query: (formData: FormData) => ({
        url: 'attachments/uploadAttachments',
        headers: {
          'content-type': 'multipart/form-data',
        },
        method: 'POST',
        data: formData,
      }),
      transformResponse: (response: QuestionAttachmentDto[]) => {
        return response;
      },
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Attachment, id: 'LIST' }] : [],
    }),
    getAttachment: builder.query<AttachmentDTO, string>({
      query: (attachmentName: string) => ({
        url: `attachments/getAttachment?attachmentName=${attachmentName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Attachment, id: arg }] : [],
    }),
    deleteAttachment: builder.query<boolean, string>({
      query: (attachmentName: string) => ({
        url: `attachments/deleteAttachment?attachmentName=${attachmentName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Attachment, id: arg }] : [],
    }),
    uploadStagingAttachments: builder.mutation<
      QuestionAttachmentDto[],
      FormData
    >({
      query: (formData: FormData) => ({
        url: 'attachments/uploadStagingAttachments',
        headers: {
          'content-type': 'multipart/form-data',
        },
        method: 'POST',
        data: formData,
      }),
      transformResponse: (response: QuestionAttachmentDto[]) => {
        return response;
      },
      invalidatesTags: (result) =>
        result ? [{ type: RtkTags.Attachment, id: 'LIST' }] : [],
    }),
    deleteStagingAttachment: builder.query<boolean, string>({
      query: (attachmentName: string) => ({
        url: `attachments/deleteStagingAttachment?attachmentName=${attachmentName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Attachment, id: arg }] : [],
    }),
    getStagingAttachment: builder.query<AttachmentDTO, string>({
      query: (attachmentName: string) => ({
        url: `attachments/getStagingAttachment?attachmentName=${attachmentName}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 0,
      providesTags: (result, error, arg) =>
        result ? [{ type: RtkTags.Attachment, id: arg }] : [],
    }),
  }),
});

export const {
  useUploadAttachmentsMutation,
  useGetAttachmentQuery,
  useDeleteAttachmentQuery,
  useUploadStagingAttachmentsMutation,
  useGetStagingAttachmentQuery,
  useDeleteStagingAttachmentQuery,
} = inspectTechUIFileUploadAPI;
