import { AxleWheelLimit } from 'Types/WheelInfoDTO';

export const LightVehicleBrakeInspection: AxleWheelLimit = {
  minAxle: 2,
  maxAxle: 3,
  minWheel: 1,
  maxWheel: 4,
};

export const MidSizeTruckBrakeInspection: AxleWheelLimit = {
  minAxle: 2,
  maxAxle: 3,
  minWheel: 2,
  maxWheel: 4,
};

export const MotorcycleTotalWheels: number = 3;
export const LightVehicleMinTotalWheels: number = 3;

export const MotorcycleBrakeInspection: AxleWheelLimit = {
  minAxle: 2,
  maxAxle: 2,
  minWheel: 1,
  maxWheel: 2,
};

export const WheelPassesInspectionList = [
  {
    value: true,
    text: 'Pass',
  },
  {
    value: false,
    text: 'Fail',
  },
];

export const WheelOnOffList = [
  {
    value: false,
    text: 'On',
  },
  {
    value: true,
    text: 'Off',
  },
];

export const BrakeYesNoList = [
  {
    value: true,
    text: 'Yes',
  },
  {
    value: false,
    text: 'No',
  },
];

export const QuestionYesNoList = [
  {
    value: true,
    text: 'Yes',
  },
  {
    value: false,
    text: 'No',
  },
];

export const InspectionItemConstants = {
  bodyIntegrityVehicleIdentification: 'Vehicle Identification',
};

export const reinspectionExtensionDays = {
  mechanical: 5,
  bodyIntegrity: 15,
};

export const QuestionCodes = {
  ctPreQuestionDiagnosticScan: 'CTPRE001',
  ctPreQuestionRequiredNumberOfPhotos: 'CTPRE002',
  ctPreQuestionLocationAndDamages: 'CTPRE003',
  ctPreQuestionVehiclePreviouslyRepaired: 'CTPRE004',
  ctPreQuestionFullDamageAnalysis: 'CTPRE005',

  ctPostQuestionPostDiagnosticScan: 'CTPOST001',
  ctPostQuestionPostRepairBodyMeasurement: 'CTPOST002',

  iiQuestionBodyMeasurementInvCopies: 'II002',
  iiQuestionRepairedPriorToInitialInspection: 'II003',
  iiQuestionEvidenceOfRepairs: 'II004',
  contactQuestionProofOfOwnership: 'CON001',
};
