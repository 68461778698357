import {
  Box,
  Divider,
  Grid,
  GridItem,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { Button, Card, Typography } from '@sgi/gravity';
import { useGetStationByIdQuery } from 'api/InspectTechUIAPI';
import { stationMessages } from 'constants/messageConstants';
import { useNavigate, useParams } from 'react-router-dom';
import { formatId } from 'Utils/inspectionUtils';

export const Station = () => {
  const { stationId } = useParams<'stationId'>();
  const navigate = useNavigate();
  const { data: station, isFetching: isGetStationFetching } =
    useGetStationByIdQuery(stationId ?? '');

  return (
    <>
      {!isGetStationFetching && (
        <>
          {station?.securityFlags?.canView ? (
            <>
              <Box bg="white" w="100%" p={9}>
                <Grid templateColumns="repeat(1, 1fr)">
                  <GridItem colSpan={1}>
                    <Typography variant="h1">Inspection Station</Typography>
                  </GridItem>
                </Grid>
              </Box>
              <Box pl="40px" pr="40px" pt={4}>
                <Box bg="white" w="100%" p={4} borderRadius={8}>
                  <VStack alignItems="flex-start">
                    <Box w="100%">
                      <Card>
                        <Grid templateColumns="repeat(4,1fr)">
                          <VStack>
                            <SimpleGrid width="100%" columns={2}>
                              <Typography variant="bodyBold">
                                Station Id
                              </Typography>
                              <Typography aria-label="station-display-id">
                                {formatId(station?.id ?? '')}
                              </Typography>
                            </SimpleGrid>
                            <Divider color="gray.500" />
                            <SimpleGrid width="100%" columns={2}>
                              <Typography variant="bodyBold">
                                Inspection Station Name
                              </Typography>
                              <Typography aria-label="station-display-station-name">
                                {station.stationName ?? ''}
                              </Typography>
                            </SimpleGrid>
                            <Divider color="gray.500" />
                            <SimpleGrid width="100%" columns={2}>
                              <Typography variant="bodyBold">
                                Shop Owner
                              </Typography>
                              <Typography aria-label="station-display-shopOwner">
                                {station.shopOwner ?? ''}
                              </Typography>
                            </SimpleGrid>
                            <Divider color="gray.500" />
                          </VStack>
                        </Grid>
                      </Card>
                    </Box>
                    <Button
                      variant="secondary"
                      data-testid="inspection-back-button"
                      onClick={() => {
                        navigate('/station/list');
                      }}
                    >
                      Back to list
                    </Button>
                  </VStack>
                </Box>
              </Box>
            </>
          ) : (
            <Typography variant="h2">
              {stationMessages.viewPermissionError}
            </Typography>
          )}
        </>
      )}
    </>
  );
};

export default Station;
